import React, { FC } from "react";
import PropTypes from "prop-types";
import { AuthProvider } from "./authContext";

export const MainProvider = ({ children }) => {
	return (
		<AuthProvider>
			{children}
		</AuthProvider>
	);
};


MainProvider.propTypes = {
    children: PropTypes.any,
};