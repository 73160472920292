import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import { Spinner } from "reactstrap";

const colourStyles = {
  control: styles => ({ ...styles}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? '#005691' : isFocused ? '#e5f2fa' : 'white',
      cursor: isDisabled ? 'not-allowed' : 'default',
    }
  },
  menuPortal: base => ({ ...base, zIndex: 9999 })
}

const MySelect = prop => {
  const {loading, placeholder} = prop
  if (prop.allowSelectAll) {
    return (
      <ReactSelect
        {...prop}
        options={prop.options}
        menuPortalTarget={document.body} 
        styles={colourStyles}
        placeholder={placeholder || "Select..."}
        onChange={selected => {
          prop.isMulti &&
          selected.length
            ? prop.onChange(selected)
            : !prop.isMulti
              ? prop.onChange((selected && selected.value) || null)
              : prop.onChange(selected);
        }}
        noOptionsMessage={() =>
          loading ?
              <div><Spinner color="primary" className="text-lbusers" /></div>
          : "No data found"
        }
      />
    );
  }

  return <ReactSelect {...prop} />;
};

MySelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

export default MySelect;
