import React,{useEffect, useState, useContext} from "react"
import { Modal, Label, Input, Row, Col, InputGroup, Form, Card, CardBody, NavItem, NavLink, TabContent, TabPane, Spinner, Table, Nav } from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { newPost, get } from 'helpers/axios_helper';
import { useFormik } from "formik";
import { object, string, number, date } from "yup";
import AppConfig from 'constants/config';
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom"
import { AuthContext } from "context/authContext"
import classnames from "classnames"
import axios from "axios";

const UsefulLife = (prop) => {
    const navigate = useNavigate();
    const { reloadProcessingStatus, mode, userData } = useContext(AuthContext);
    const { usefulLife, toggleUseful, setUsefulLife, leaseId, refreshData } = prop;
    const [leaseDetails, setLeaseDetails] = useState({});
    const [loadingUseful, setLoadingUseful] = useState(false)
    const [isCheck, setIsCheck] = useState(false)
    const [ usefulLifeData, setUsefulLifeData ] = useState([])
    const [activeTab, setActiveTab] = useState("2");
    const [leaseDraftData, setLeaseDraftData] = useState({})
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [clientName, setClientName] = useState('')

    const validUseful = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: moment().add(1,'days').format('MM/DD/YYYY'),
            typeStep: 'months',
            howLong: 0,
            endDate:  moment().add(1,'days').format('MM/DD/YYYY'),
        },
        validationSchema: object().shape({
            date: date().required("Please enter processing date"),
            endDate: date().required("Please enter end date"),
        }),
        onSubmit: (values) => {
            hitUsefulLife(values)
        }
    });

    const hitUsefulLife = async(data) => {
        try{
            setLoadingUseful(true)
            let newDate = { lease_id: leaseId, date: moment(data.date).format('YYYY-MM-DD'), endDate: moment(data.endDate).format('YYYY-MM-DD'), mode: mode ? mode : 'lessee'}
            let value = await newPost(`${AppConfig.baseUrl}/rou_asset/use_ful_life`, newDate, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if(value.error === false) {
                reloadProcessingStatus()
                let data1 = { activity: 'useful_life', lease_id: leaseId, client_id: leaseDetails.client_id._id, client_name: leaseDetails.client_id.client, mode: mode ? mode : 'lessee' }
                await newPost(`${AppConfig.baseUrl}/activity/add_activity`, data1, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    }
                })
                toast.success(value.title);

                if(window.location.href.includes(`/leases/details/${leaseId}`)){
                    window.location.href = `/${localStorage.getItem('company')}/leases/details/${leaseId}/refresh`
                }else{
                    navigate(`/${localStorage.getItem('company')}/leases/details/${leaseId}/refresh`);
                }
            } else {
                toast.error(value.title);
            }
            setUsefulLife(!usefulLife)
            setLoadingUseful(false)
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        if(leaseId){
            getDraftData()
            getdetails()
        }
    }, [mode,leaseId])

    const getDraftData = async()=>{
        setLoadingUseful(true)
        try {
            let { data } = await get(`${AppConfig.baseUrl}/lease_draft/get_draft?id=${leaseId}&mode=${mode ? mode : 'lessee'}&customer_id=${userData.cust_info._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (data && data.lease) {
                setLeaseDraftData(data.lease)
                let value = await get(`${AppConfig.baseUrl}/user_client/get_user_client?id=${data.lease.user_client_id}&customer_id=${userData.cust_info._id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    }
                })
                let clientData = value.userData.client_id
                setClientName(clientData.client)   
                let usefulData = data.lease && data.lease.usefulLifes ? data.lease.usefulLifes : []
                if(usefulData && usefulData.length < 1) setActiveTab("2")
            }
        } catch (err) {
            console.log(err)
        } 
    }

    const getdetails = async() => {
        try{
            let value = await get(`${AppConfig.baseUrl}/lease/lease_details?Id=${leaseId}&type=other&mode=${mode || 'lessee'}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if(value.error === false) {
                setLeaseDetails(value.data)
            }
        }catch(err){
            console.log(err)
        }        
    }

    useEffect(() => {
        if(leaseDraftData && Object.keys(leaseDraftData).length > 0){ 
            getClientData()
            let impairData = leaseDraftData && leaseDraftData.impairments && leaseDraftData.impairments.length > 0 ? leaseDraftData.impairments : []
            let usefulData = leaseDraftData && leaseDraftData.usefulLifes && leaseDraftData.usefulLifes.length > 0 ? leaseDraftData.usefulLifes : []
            setUsefulLifeData(usefulData)
            if(usefulData && usefulData.length > 0 && impairData && impairData.length > 0){
                if(moment(impairData[impairData.length-1].date).isSameOrAfter(moment(usefulData[usefulData.length-1].date))){
                    const date = moment(impairData[impairData.length-1].date).add(1,'day').format('MM/DD/YYYY')
                    setMinDate(date)
                    setMaxDate(moment(leaseDraftData.terminalLeaseEndDate).format('MM/DD/YYYY'))
                    validUseful.setFieldValue('date', date)
                    validUseful.setFieldValue('endDate', date)
                }else{
                    const date = moment(usefulData[usefulData.length-1].date).add(1,'day').format('MM/DD/YYYY')
                    setMinDate(date)
                    setMaxDate(moment(leaseDraftData.terminalLeaseEndDate).format('MM/DD/YYYY'))
                    validUseful.setFieldValue('date', date)
                    validUseful.setFieldValue('endDate', date)
                }                    
            }else if(impairData && impairData.length > 0){
                const date = moment(impairData[impairData.length-1].date).add(1,'day').format('MM/DD/YYYY')
                setMinDate(date)
                setMaxDate(moment(leaseDraftData.terminalLeaseEndDate).format('MM/DD/YYYY'))
                validUseful.setFieldValue('date', date)
                validUseful.setFieldValue('endDate', date)
            }else if(usefulData && usefulData.length > 0){ 
                const date = moment(usefulData[usefulData.length-1].date).add(1,'day').format('MM/DD/YYYY')
                setMinDate(date)
                setMaxDate(moment(leaseDraftData.terminalLeaseEndDate).format('MM/DD/YYYY'))
                validUseful.setFieldValue('date', date)
                validUseful.setFieldValue('endDate', date)
            }else if(leaseDetails && leaseDetails.status == 'Modified'){
                const date = moment(leaseDetails.modification_date, "YYYY-MM-DD").add(1,'day').format('MM/DD/YYYY')
                setMinDate(date)
                setMaxDate(moment(leaseDraftData.terminalLeaseEndDate).format('MM/DD/YYYY'))
                validUseful.setFieldValue('date', date)
                validUseful.setFieldValue('endDate', date)
            }else{
                const date = moment(leaseDetails.commencement, "YYYY-MM-DD").add(1,'day').format('MM/DD/YYYY')
                setMinDate(date)
                setMaxDate(moment(leaseDraftData.terminalLeaseEndDate).format('MM/DD/YYYY'))
                validUseful.setFieldValue('date', date)
                validUseful.setFieldValue('endDate', date)
            } 
            setLoadingUseful(false) 
        }
    }, [leaseDraftData,leaseDetails])

    const getClientData = async () => {
        setLoadingUseful(true)
        try{
            let value = await get(`${AppConfig.baseUrl}/user_client/get_user_client?id=${leaseDraftData.user_client_id}&customer_id=${userData.cust_info._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            let clientData = value.userData.client_id
            setClientName(clientData.client)   
        }catch(err){
            console.log(err)
        }        
     }

    const editLease = async (data) => {
        setLoadingUseful(true)
        let newData = { ...data, lease_id: leaseId, clientId: leaseDraftData.clientId }
        let value = await newPost(`${AppConfig.baseUrl}/lease_draft/editLease`, newData, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if (value.error === false) {
            reloadProcessingStatus()
            let data = { activity: 'useful_life', lease_id: value.data.lease_id, client_id: leaseDraftData.clientId, client_name: clientName, mode: mode ? mode : 'lessee' }
            await newPost(`${AppConfig.baseUrl}/activity/add_activity`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if(window.location.href.includes(`/leases/details/${leaseId}`)){
                window.location.href = `/${localStorage.getItem('company')}/leases/details/${value.data.lease_id}/refresh`
            }else{
                navigate(`/${localStorage.getItem('company')}/leases/details/${value.data.lease_id}/refresh`);
            }
        } else {
            toast.error(value.title || 'Lease not saved');
        }
        setUsefulLife(!usefulLife)
        setLoadingUseful(false)
    }

    const modifyLease = async (d) => {
        setLoadingUseful(true)
        const { data: revertData } = await axios.post(`${AppConfig.baseUrl}/lease_draft/revertLease`, { lease_id: leaseId, mode: mode ? mode : 'lessee' }, {
            headers: { token: localStorage.getItem("token") }
        });
        if (revertData.error === false) {
            let newData = { ...d, lease_id: revertData.parentData._id, clientId: leaseDraftData.clientId }
            let value = await newPost(`${AppConfig.baseUrl}/lease_draft/modifyLease`, newData, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (value.error === false) {
                reloadProcessingStatus()
                let data = { activity: 'useful_life', lease_id: value.data._id, client_id: leaseDraftData.clientId, client_name: clientName, mode: mode ? mode : 'lessee' }
                await newPost(`${AppConfig.baseUrl}/activity/add_activity`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    }
                })
                if(window.location.href.includes(`/leases/details/${leaseId}`)){
                    window.location.href = `/${localStorage.getItem('company')}/leases/details/${value.data._id}/refresh`
                }else{
                    navigate(`/${localStorage.getItem('company')}/leases/details/${value.data._id}/refresh`);
                }
            } else {
                toast.error(value.title || 'Lease not saved');
            }
        }
        setUsefulLife(!usefulLife)
        setLoadingUseful(false)
    }

    const includeExcludeUseful = async()=>{      
        let payment = [...usefulLifeData].filter( e => !(e.deletable))
        leaseDraftData.usefulLifes = payment
        if(leaseDetails && leaseDetails.status == 'Modified'){
            modifyLease(leaseDraftData)
        }else{
            editLease(leaseDraftData)
        }  
    }

    const checkAll = () => {
        const data = [...usefulLifeData].filter(v => v.deletable )
        return data.length == usefulLifeData.length
    }

    const handleClear = () => {
        setUsefulLifeData(leaseDraftData && leaseDraftData.usefulLifes && leaseDraftData.usefulLifes.length > 0 ? leaseDraftData.usefulLifes : []);
        setActiveTab(leaseDraftData && leaseDraftData.usefulLifes && leaseDraftData.usefulLifes.length > 0 ? "1" : "2")
    }

    return (        
        <Modal
            isOpen={usefulLife}
            toggle={() => {
                toggleUseful();
                handleClear()
            }}
            size="lg"
            centered
        >
        <div className="modal-header">
            <h5 className="modal-title mt-0">Useful Life</h5>
            <button
                type="button"
                onClick={() => {
                    setUsefulLife(false)
                    handleClear()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <Card>            
                <CardBody>
                    <Row>
                        <Nav tabs>
                            {    usefulLifeData && usefulLifeData.length > 0 && 
                                <NavItem style={{ textAlign: 'left' }}>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "1",
                                        })}
                                        onClick={() => {
                                            setActiveTab("1");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="far fa-envelope"></i>
                                        </span>
                                        <span className="d-none d-sm-block font-size-16">View Useful Life</span>
                                    </NavLink>
                                </NavItem>
                            }                                
                            <NavItem style={{ textAlign: 'left' }}>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeTab === "2",
                                    })}
                                    onClick={() => {
                                        setActiveTab("2");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="far fa-envelope"></i>
                                    </span>
                                    <span className="d-none d-sm-block font-size-16">Add useful life</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent
                            activeTab={activeTab}
                            className="text-muted"
                        >
                            <TabPane tabId="1">
                                {
                                    loadingUseful ?
                                        <div className="text-center mt-4"><Spinner color="primary" className="text-lbusers" /></div>
                                        :
                                        usefulLifeData && usefulLifeData.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <div className="table-responsive">
                                                        <Table className="table mb-0 customTable">
                                                            <thead className="table-light tableHead">
                                                                <tr>
                                                                    <th>Start Date</th>
                                                                    <th>End Date</th>
                                                                    <th>
                                                                        <Input
                                                                            className="form-check-input"
                                                                            name="deleteAll"
                                                                            type="checkbox"
                                                                            checked={checkAll()}
                                                                            onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    const newArr = [...usefulLifeData].map(v => {
                                                                                        v.deletable = true
                                                                                        return v;
                                                                                    })
                                                                                    setUsefulLifeData(newArr)
                                                                                } else {
                                                                                    const newArr = [...usefulLifeData].map(v => {
                                                                                        v.deletable = false
                                                                                        return v;
                                                                                    })
                                                                                    setUsefulLifeData(newArr)
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label
                                                                            className="form-check-label ms-2"
                                                                            htmlFor="delete"
                                                                        >
                                                                            Delete
                                                                        </label>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="tableBody" style={{minHeight:'200px', maxHeight:'500px', height: 'auto'}}>
                                                                {   usefulLifeData && usefulLifeData.length > 0 && usefulLifeData.map((data, i) => {
                                                                    return <tr key={data.type + i} style={{ textDecoration: data.deletable ? 'line-through' : 'none', textDecorationColor: data.deletable ? 'red' : '' }}>
                                                                        <td>{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                        <td>{moment(data.endDate).format('MM/DD/YYYY')}</td>
                                                                        <td>
                                                                            <div className="mb-3 ms-2">
                                                                                <Input
                                                                                    className="form-check-input"
                                                                                    name="includePayment"
                                                                                    id={i}
                                                                                    type="checkbox"
                                                                                    checked={data.deletable}
                                                                                    onClick={() => {
                                                                                        let newArr = [...usefulLifeData];
                                                                                        newArr[i].deletable = !newArr[i].deletable
                                                                                        setUsefulLifeData(newArr)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>                                                
                                                    <div className="modal-footer mt-3">
                                                        <button type="submit" className="btn btn-lbusers" disabled={loadingUseful} onClick={includeExcludeUseful}> 
                                                            {
                                                                loadingUseful && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                            }
                                                            SUBMIT
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                }
                            </TabPane>
                            <TabPane tabId="2">
                                {
                                    loadingUseful ?
                                    <div className="text-center mt-4"><Spinner color="primary" className="text-lbusers" /></div>
                                    :
                                    <Form className="form-horizontal mt-4" onSubmit={validUseful.handleSubmit}>
                                        <Row className='justify-content-center'>
                                            <Col md="12" lg="12" xl="12" xxl="12">
                                                <h6>Date of processing</h6>
                                                <InputGroup>
                                                    <Flatpickr
                                                        className="form-control d-block bg-white"
                                                        placeholder="Lease Start"
                                                        id='date'
                                                        type='date'
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "m/d/Y",
                                                            dateFormat: "m/d/Y",
                                                            allowInput: true,
                                                            minDate: minDate,
                                                            maxDate: maxDate,
                                                        }}
                                                        onClose={(e) =>{
                                                            if(e.length == 0) {
                                                                validUseful.setFieldValue(`date`, (moment(e[0]).format('MM/DD/YYYY')))
                                                            } else {                                                              
                                                                validUseful.setFieldValue(`date`, (moment(e[0]).format('MM/DD/YYYY')))
                                                                let a = moment(e[0]).add(Number(validUseful.values.howLong),validUseful.values.typeStep).subtract(1,'days').format();
                                                                validUseful.setFieldValue(`endDate`, (moment(a).format('MM/DD/YYYY')))
                                                            }
                                                        }}
                                                        onBlur={() => validUseful.setFieldTouched("date")}
                                                        value={validUseful.values.date}
                                                    />  
                                                    <div className="input-group-append">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                            disabled
                                                        >
                                                            <i
                                                                className="fa fa-calendar"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </InputGroup>
                                                {validUseful.touched.date && validUseful.errors.date && (
                                                    <span className='text-danger'>{validUseful.touched.date && validUseful.errors.date}</span>
                                                )}
                                            </Col>
                                            <Col md="12">
                                                <h6 className="mt-4">New useful life</h6>
                                                <InputGroup>
                                                    <div className="input-group-append" style={{width:'81px'}}>
                                                        <select className="form-control arrows" id='typeStep'
                                                        onChange={(e)=> {
                                                            validUseful.setFieldValue(`typeStep`, e.target.value)
                                                            let a;
                                                            if(e.target.value == 'days' || e.target.value == 'weeks') {
                                                                a = moment(moment(validUseful.values.date).format()).add(Number(validUseful.values.howLong), e.target.value).format();
                                                            } else {
                                                                a = moment(moment(validUseful.values.date).format()).add(Number(validUseful.values.howLong), e.target.value).subtract(1,'days').format();
                                                            }

                                                            if(leaseDraftData && leaseDraftData.classify != 'financing' && moment(moment(a).format('YYYY/MM/DD')).isAfter(moment(moment(leaseDraftData.terminalLeaseEndDate).format('YYYY/MM/DD')))){
                                                                validUseful.setFieldError('howLong','Please select valid number')
                                                                validUseful.setFieldValue(`endDate`, '')
                                                            }else{
                                                                validUseful.setFieldValue(`endDate`, moment(a).format('MM/DD/YYYY'))
                                                            }
                                                        }}
                                                        value={validUseful.values.typeStep} 
                                                        >
                                                            <option value='days'>Days</option>
                                                            <option value='weeks'>Weeks</option>
                                                            <option value='months'>Months</option>
                                                            <option value='years'>Years</option>
                                                        </select>
                                                    </div>
                                                    <Input
                                                        type="number"
                                                        className="form-control"
                                                        id="howLong"
                                                        value={validUseful.values.howLong}
                                                        onChange={(e) =>{
                                                            validUseful.setFieldValue(`howLong`, (e.target.value))
                                                            let type = validUseful.values.typeStep;
                                                            let val = e.target.value.length > 0 ? e.target.value : 0;
                                                            let a = moment(moment(validUseful.values.date).format()).add(Number(val), type).subtract(1,'days').format();
                                                            
                                                            validUseful.setFieldValue(`endDate`, moment(a).format('MM/DD/YYYY'))
                                                            if(leaseDraftData && leaseDraftData.classify != 'financing' && moment(moment(a).format('YYYY/MM/DD')).isAfter(moment(moment(leaseDraftData.terminalLeaseEndDate).format('YYYY/MM/DD')))){
                                                                validUseful.setFieldError('howLong','Please select valid number')
                                                                validUseful.setFieldValue(`endDate`, '')
                                                            }else{
                                                                validUseful.setFieldValue(`endDate`, moment(a).format('MM/DD/YYYY'))
                                                            }
                                                        }}
                                                    />
                                                </InputGroup>
                                                {validUseful.errors.howLong && (
                                                    <span className='text-danger'>{validUseful.errors.howLong}</span>
                                                )}
                                            </Col>
                                            <Col md="12">
                                                <h6 className="mt-4">End date</h6>
                                                <InputGroup>
                                                    <Flatpickr
                                                        className="form-control d-block bg-white"
                                                        placeholder="Lease End"
                                                        id='endDate'
                                                        type='date'
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "m/d/Y",
                                                            dateFormat: "m/d/Y",
                                                            allowInput: true,
                                                            minDate: moment(validUseful.values.date).format('MM/DD/YYYY'),
                                                            maxDate: leaseDetails && leaseDetails.classification != 'financing' ? maxDate : '',
                                                        }}
                                                        onClose={(e) =>{
                                                            if(e.length == 0) {
                                                                validUseful.setFieldValue(`endDate`, '')
                                                            } else {                                                              
                                                                let start = moment(new Date(validUseful.values.date)).endOf('day').format('MM/DD/YYYY')
                                                                let end = moment(e[0])
                                                                let a = moment(e[0]).format()
                                                                let days = end.diff(start, 'days');
                                                                validUseful.setFieldValue(`howLong`, days+1)
                                                                validUseful.setFieldValue(`typeStep`, 'days')
                                                                validUseful.setFieldValue(`endDate`, (moment(a).format('MM/DD/YYYY')))
                                                            }
                                                        }}
                                                        onBlur={() => validUseful.setFieldTouched("endDate")}
                                                        value={validUseful.values.endDate}
                                                    />  
                                                    <div className="input-group-append">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                            disabled
                                                        >
                                                            <i
                                                                className="fa fa-calendar"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </InputGroup>
                                                {validUseful.errors.endDate && (
                                                    <span className='text-danger'>{validUseful.errors.endDate}</span>
                                                )}
                                            </Col>
                                        </Row>
                                        <div className="modal-footer mt-3">
                                            <button type="button" onClick={() => {setUsefulLife(false)}} className="btn bg-theme-color w-md ms-3" disabled={loadingUseful} >
                                                {
                                                    loadingUseful && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                }
                                                CANCEL
                                            </button>
                                            <button type="submit" className="btn bg-theme-color w-md ms-3" disabled={loadingUseful} >
                                                {
                                                    loadingUseful && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                }
                                                SUBMIT
                                            </button>
                                        </div>
                                    </Form>
                            }
                            </TabPane>
                        </TabContent>
                    </Row>
                </CardBody>
            </Card>            
        </div>
    </Modal> 
    )
}

export default UsefulLife