import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col } from "reactstrap"

const Breadcrumb = prop => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{prop.breadcrumbItem}</h4>
            <button
              type="button"
              className="btn btn-lbusers"
              style={{visibility:'hidden'}}
            >
              ADD NEW
            </button>
            {prop.type === 'quickrecentlease'?
              <div className="page-title-right font-size-13">
                <Link to={`/${localStorage.getItem('company')}/leases`} className="txt-color" style={{fontWeight:'bold'}}>VIEW ALL</Link>
              </div>
            :prop.type === 'quickrecentclients'?
              <div className="page-title-right font-size-13">
                <Link to={`/${localStorage.getItem('company')}/clients`} className="txt-color" style={{fontWeight:'bold'}}>VIEW ALL</Link>
              </div>
            : ''
            }
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
