import React from "react"
import { Row } from "reactstrap"
import { map } from "lodash";
import CardGridC from "../../components/client/cardgrid";
import CardGridL from "../../components/lease/cardgrid";


const QuickAcessGridView = prop => {
    const FlaggedLeaseData = prop.FlaggedLeaseData;
    const RecentLeaseData = prop.RecentLeaseData;
    const RecentClientData = prop.RecentClientData;
    const clientAccess = prop.clientAccess;
    const getQuickAccessData = prop.getQuickAccessData;
    return (
        <React.Fragment>
            { prop.type === 'flaggedleaseGridView' ?
                <Row style={{marginTop:'-1%'}}>
                    {map(FlaggedLeaseData, (user) => (
                        <CardGridL lease={user} type="flaggedleaseCardGrid" key={"_fl_" + user._id} clientAccess={clientAccess} getLeaseData={getQuickAccessData}/>
                    ))}
                </Row>
            : prop.type === 'recentleaseGridView'?
                <Row style={{marginTop:'-1%'}}>
                {map(RecentLeaseData, (user) => (
                    <CardGridL lease={user} type="recentLeaseCardGrid" clientAccess={clientAccess} getLeaseData={getQuickAccessData}/> // key={"_user_" + key}
                ))}
                </Row>
            : prop.type === 'recentClientGridView' ?
                <Row style={{marginTop:'-1%'}}>
                {map(RecentClientData, (user) => (
                    <CardGridC user={user} type="recentClientCardGrid" getClientsData={prop.getClientsData}/> // key={"_user_" + key}
                ))}
                </Row>
            :''
            }
        </React.Fragment>
    )
}

export default QuickAcessGridView
