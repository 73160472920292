import React,{useEffect, useState, useContext} from "react"
import {Modal,Input,Row,Col,InputGroup, Spinner} from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { AuthContext } from "context/authContext";
import { get} from 'helpers/axios_helper';
import AppConfig from 'constants/config';
import toast from "react-hot-toast";

const ModifyDate = (prop) => {
    const {modifyOpen, toggleModify, setModifyOpen , modifyL, lease, termiDate} = prop;
    const [modiDate, setModiDate] = useState('');
    const [decreaseModify, setDecreaseModify] = useState("no");
    const [approach, setApproach] = useState('liability_approach')
    const [percentage, setPercentage] = useState('');
    const [leaseDraftData, setLeaseDraftData] = useState('')
    const { mode, userData } = useContext(AuthContext);
    const [impairDate, setImpairDate] = useState('');
    const [loading, setLoading] = useState(false)
    const [minDate, setMinDate] = useState('');
    const [type, setType] = useState('')

    const getDraftData = async()=>{
        setLoading(true)
        try {
            let {data} = await get(`${AppConfig.baseUrl}/lease_draft/get_draft?id=${lease._id}&mode=${ mode ? mode : 'lessee' }&customer_id=${userData.cust_info._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if(data && data.lease) {
                setLeaseDraftData(data.lease)
            }
        } catch (err) {
            console.log(err)
        }
        setLoading(false)
    }

    useEffect(() => {
        if(lease && lease._id){
            getDraftData()
        }    
    },[lease])

    useEffect(() => {
        if(leaseDraftData && Object.keys(leaseDraftData).length > 0){ 
            const impairData = leaseDraftData && leaseDraftData.impairments ? leaseDraftData.impairments : []
            const usefulData = leaseDraftData && leaseDraftData.usefulLifes ? leaseDraftData.usefulLifes : []
            if(impairData && impairData.length > 0 || usefulData && usefulData.length > 0 || lease && lease.is_modified){
                let filterArr = [...impairData, ...usefulData].sort(function (a, b) {
                    return new Date(a.date) - new Date(b.date);
                })
                const data = filterArr[filterArr.length - 1]
                if(lease && lease.is_modified && moment(lease.modification_date).isAfter(moment(data && data.date ? data.date : lease.commencement))){
                    setType('modify')
                    setImpairDate(moment(lease.modification_date, "YYYY-MM-DD").format('MM/DD/YYYY'))
                } else if(data && data.endDate){
                    setType('use_ful')
                    setImpairDate(data.date)
                }else{
                    setType('impairment')
                    setImpairDate(data && data.date)
                }
            }
            if(usefulData && usefulData.length > 0 && impairData && impairData.length > 0){
                if(moment(impairData[impairData.length-1].date).isSameOrAfter(moment(usefulData[usefulData.length-1].date))){  
                    const date = moment(impairData[impairData.length-1].date).add(1,'day').format('MM/DD/YYYY')                  
                    setModiDate(date)
                    setMinDate(date)
                }else{
                    const date = moment(usefulData[usefulData.length-1].date).add(1,'day').format('MM/DD/YYYY')
                    setModiDate(date)
                    setMinDate(date)
                }                    
            }else if(impairData && impairData.length > 0){
                const date = moment(impairData[impairData.length-1].date).add(1,'day').format('MM/DD/YYYY')
                setModiDate(date)
                setMinDate(date)
            }else if(usefulData && usefulData.length > 0){ 
                const date = moment(usefulData[usefulData.length-1].date).add(1,'day').format('MM/DD/YYYY')
                setModiDate(date)
                setMinDate(date)
            }else if(lease && lease.status == 'Modified'){
                const date = moment(lease.modification_date, "YYYY-MM-DD").add(1,'day').format('MM/DD/YYYY')
                setModiDate(date)
                setMinDate(date)
            }else{
                const date = moment(leaseDraftData && leaseDraftData.transitionDate ? leaseDraftData.transitionDate :lease.commencement).add(1,'day').format('MM/DD/YYYY')
                setModiDate(date)
                setMinDate(date)
            } 
        }  
    },[lease,leaseDraftData])

    useEffect(()=>{
        setModiDate(termiDate)
    },[termiDate])

    return (
        <Modal
            isOpen={modifyOpen}
            toggle={() => {
                toggleModify();
            }}
            centered
            size="lg"
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Modification Date</h5>
                <button
                    type="button"
                    onClick={() => {
                        setModifyOpen(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form>{
                    loading ?
                    <div className="text-center mt-4"><Spinner color="primary" className="text-lbusers" /></div>
                    :
                    <>
                    <div className="mb-3">
                    <Row>
                        <Col md="6" className="mt-4">
                            <h6>Lease modification date <span className="text-danger">*</span></h6>
                            <InputGroup>
                                <Flatpickr
                                    className="form-control d-block bg-white"
                                    placeholder="Lease Start"
                                    id='commencementDate'
                                    type='date'
                                    options={{
                                        altInput: true,
                                        altFormat: "m/d/Y",
                                        dateFormat: "m/d/Y",
                                        allowInput: true,
                                        minDate: minDate,
                                        maxDate: moment(lease.terminal_end_date).format('MM/DD/YYYY')
                                    }}
                                    onClose={(e) => {
                                        if(e && e[0]){
                                            let a = moment(e[0]).format('MM/DD/YYYY')
                                            setModiDate(a)
                                        }else{
                                            setModiDate('')
                                        }
                                    }}
                                    value={modiDate}
                                />   
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                        disabled
                                    >
                                        <i
                                            className="fa fa-calendar"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </InputGroup>
                        </Col>
                        {impairDate && <span>Note - Last <q>{type == 'modify' ? 'Modify' : type == 'use_ful' ? 'Change in Useful Life' : 'Impairment'}</q> date is {moment(impairDate).format('MM/DD/YYYY')}, please delete this {type == 'modify' ? 'Modify' : type == 'use_ful' ? 'Change in Useful Life' : 'Impairment'} to be able to impairment this lease on a prior date. </span>}
                    </Row>
                    <Row>
                        <Col md="12" className="mt-4">
                            <h6>Is this modification a partial termination? In other words - does the modification decrease lessee&apos;s scope of Right of Use? Note -<span style={{fontWeight:'normal'}}> a decrease in lease term is not considered a decrease in the lessee&apos;s scope of Right of Use</span></h6>
                            <select
                            value={decreaseModify}
                            onChange={(e) => {
                                setDecreaseModify(e.target.value)
                            }}
                            id="formrow-InputState"
                            style = {{width:'120px',height:'30px'}}
                            className="form-control form-control-sm arrows"
                            >
                                <option name="yes" value="yes">Yes</option>
                                <option name="no" value="no">No</option>
                            </select>
                        </Col>
                    </Row>
                    {
                        decreaseModify == "yes" &&
                        <>
                        <Row>
                            <Col md="12" className="mt-4"> 
                                <h6 className="me-4">Partial termination approach </h6>                       
                                <select
                                value={approach}
                                onChange={(e) => {
                                    setApproach(e.target.value)
                                }}
                                id="formrow-InputState"
                                style = {{width:'170px',height:'30px'}}
                                className="form-control form-control-sm arrows"
                                >
                                    <option name="liability_approach" value="liability_approach">Liability Approach</option>
                                    <option name="rou_approach" value="rou_approach">ROU Asset Approach</option>
                                </select>
                            </Col>
                        </Row>
                        {
                            approach == 'rou_approach' &&
                            <Row>                        
                                <Col md="12" className="mt-4">
                                        <h6 className="me-4">What is the scope decrease in the ROU Asset as a percentage? </h6>
                                        <Input
                                        type="number"
                                        className="form-control form-control-sm"
                                        value={percentage}
                                        style = {{width:'120px',height:'30px'}}
                                        onChange={(e) =>{
                                            setPercentage(e.target.value)
                                        }}
                                        />
                                </Col>
                            </Row>
                        }
                        </>
                    }                    
                    </div>
                    <div className="modal-footer mt-3">
                        <button type="button" className="btn btn-lbusers" disabled={ !modiDate } onClick={()=>{ setModifyOpen(false);modifyL({date:modiDate, lease_id: lease._id, client_id: lease.client_id._id, mod_type: decreaseModify == "yes" && approach == 'liability_approach' ? 'liability_decreasing' : decreaseModify == "yes" && approach == 'rou_approach' ? 'rou_decreasing' : 'increasing' ,percentage: percentage})}}>
                            Submit
                        </button>  
                    </div>
                    </>
                    }
                </form>
            </div>
        </Modal>
    )
}

export default ModifyDate