import React, { useState, useEffect, useContext, useRef } from "react"
import {Helmet} from "react-helmet";
import { Card,CardBody,Col, Container, NavItem,NavLink,Row,TabContent,TabPane,Modal,Form, Spinner, Table, Nav,Input,} from "reactstrap"
import classnames from "classnames"
import { useParams, useLocation, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumbclientdetails"
import AddLeaseStepOne from "./addLeaseStepOne";
import AddLeaseStepTwo from "./addLeaseStepTwo";
import AddLeaseStepThree from "./addLeaseStepThree";
import AddLeaseStepFour from "./addLeaseStepFour";
import AddLeaseStepFive from "./addLeaseStepFive";
import AddLeaseStepSix from "./addLeaseStepSix";
import { object, string, number, date, array, boolean } from "yup";
import { useFormik } from "formik";
import moment from 'moment';
import { newPost, get } from 'helpers/axios_helper';
import AppConfig from 'constants/config';
import toast from 'react-hot-toast';
import { AuthContext } from "context/authContext"
import UploadFiles from "./uploadFiles"
import SaveAsDraft from "Modules/AuditCategory/saveAsDraft"
import { commaSeperated } from "helpers/formatters"
import Layout from "components/VerticalLayout";

const AddAccount = () => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [activeTab, setactiveTab] = useState(1)
    const [activeTab1, setActiveTab1] = useState("2");
    const [addFile, setAddFile] = useState(false)
    const [passedSteps, setPassedSteps] = useState([1])
    const [clientName, setClientName] = useState('')
    const isLastStep = activeTab === 6;
    const { userData, reloadProcessingStatus, mode } = useContext(AuthContext);
    const [leaseDraftData, setLeaseDraftData] = useState('')
    const [batchData, setBatchData] = useState('')
    const [loadingData, setLoadingData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [clientDataFromApi, setClientDataFromApi] = useState(false)
    const [selectedFiles, setselectedFiles] = useState([])
    const [nextPreviewOpen, setNextPreviewOpen] = useState(false);
    const [sortedPayments, setSortedPayments] = useState([]);
    const [termEndPayments, setTermEndPayments] = useState([]);
    const [refundableDeposit, setRefundableDeposit] = useState([]);
    const [totalVal, setTotalVal] = useState(0);
    const [totalVal1, setTotalVal1] = useState(0);
    const [totalVal2, setTotalVal2] = useState(0);
    const [clientData, setClientData] = useState({});
    const [totalVal3, setTotalVal3] = useState(0);
    const [variablePayments, setVariablePayments] = useState([]);
    const [ selectedImpairUsefuls, setSelectedImpairUsefuls] = useState([])
    const [ showImpairUseful, setShowImpairUseful ] = useState(false)
    const [ impairUseful, setImpairUseful ] = useState([])
    const [shortTermOpen, setShortTermOpen] = useState(false);

    const queryString = location.search;
    const sp = new URLSearchParams(queryString);
    let path = location && location.pathname.split('/')
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 6) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }
    const toggleModel = () => {
        setAddFile(!addFile)
    }
    const toggleNextPreview = () => {
        setActiveTab1('2')
        setNextPreviewOpen(!nextPreviewOpen);
    }

    const step1Schema = object().shape({
        leaseName: string().required("Please enter Lease name"),
        state: string().required("Please select your state"),
        type: string().required("Please select your type"),
        zipcode: string().length(5, 'Please enter valid zipcode')
    });
    const step2Schema = object().shape({
        commencementDate: date().required("Please enter commencement date"),
        howLong: number().min(0, 'Initial term must be greater than or equal to 0').required("Please enter numeric value"),
        leaseEndDate: date().required("Please enter lease end date"),
        extendForTheLessee: array().of(object().shape({
            howLong: number().min(0),
            notify: number().min(0),
        })),
        extendForTheLessor: array().of(object().shape({
            howLong: number().min(0),
            notify: number().min(0),
        }))
    });
    const step3Schema = object().shape({
        discountRate: number().min(0, 'Must be greater than 0').max(100, 'Must be less than 100').required("Please enter discount rate"),

        periodicLeasePayments: array().of(object().shape({
            amount: number().min(0, 'Must be greater than 0'),
            annualFixedAmountPer: number().min(0, 'Must be greater than 0').max(100, 'Must be less than 100'),
            annualFixedAmount: number().min(0, 'Must be greater than 0'),
        })),

        refundableDepositAmount: number().min(0, 'Must be greater than 0'),
        refundableDepositPaymentDate: date().when('refundableDepositAmount', {
            is: (val) => val > 0,
            then: (schema) => schema.required("Payment date is required"),
            otherwise: (schema) => schema,
        }),

        paymentsBeforeCommencement: array().of(object().shape({
            amount: number().min(0, 'Must be greater than 0'),
        })),

        probableOwedUnderRVGTotalAmount: number().min(0, 'Must be greater than 0'),
        probableOwedUnderRVGAmount: number().min(0, 'Must be greater than 0'),

        incentives: array().of(object().shape({
            amount: number().max(0, 'Must be a negative number'),
        })),

        paymentsForPurchaseReason: boolean(),
        paymentsForPurchaseAmount: number().when('paymentsForPurchaseReason', {
            is: true,
            then: (schema) => schema.min(0, 'Must be greater than 0').required("Amount is required"),
            otherwise: (schema) => schema.min(0, 'Must be greater than 0'),
        }),

        penaltyForTerminatingReason: boolean(),
        penaltyForTerminatingAmount: number().when('penaltyForTerminatingReason', {
            is: true,
            then: (schema) => schema.min(0, 'Must be greater than 0').required("Amount is required"),
            otherwise: (schema) => schema.min(0, 'Must be greater than 0'),
        }),

        initialDirectCostAmount: number().min(0, 'Must be greater than 0'),
    });
    const step4Schema = object().shape({
        transitionDate: date(),
        prepaidRent: number().positive().min(0),
        unamortizedInitialDirectCost: number().positive().min(0),
        leaseIncentives: number().max(-1),
        topic420Liability: number().max(-1),
        otherAccounts: number(),
    });
    const step5Schema = object().shape({
        transferOwnership: boolean(),
        certainToExercise: boolean(),
        usefulEconomicLife: number().when(['transferOwnership', 'certainToExercise', 'classify'], {
            is: (transferOwnership, certainToExercise, classify) => ((certainToExercise === true || transferOwnership === true) && (classify == 'financing' || classify == 'determine')),
            then: (schema) => schema.min(1, 'Must be greater than 0').required(`This field is required`),
            otherwise: (schema) => schema.min(1, 'Must be greater than 0')
        }, [['transferOwnership', 'certainToExercise', 'classify']]),
        amountForOptionToPurchase: number().when('certainToExercise', {
            is: true,
            then: (schema) => schema.min(0, 'Must be greater than 0').required(`This field is required`),
            otherwise: (schema) => schema.min(0, 'Must be greater than 0')
        }),
        fairValue: number().when(['classify', 'specializedNature', 'transferOwnership', 'certainToExercise', 'economicLifeOfUnderlyingAsset'], {
            is: (classify, transferOwnership, certainToExercise, specializedNature, economicLifeOfUnderlyingAsset) => (classify == 'determine' && specializedNature === false && certainToExercise === false && transferOwnership === false && economicLifeOfUnderlyingAsset === false),
            then: (schema) => schema.min(0, 'Must be greater than 0').required(`This field is required`),
            otherwise: (schema) => schema.min(0, 'Must be greater than 0')
        }, [['classify', 'specializedNature', 'transferOwnership', 'certainToExercise', 'economicLifeOfUnderlyingAsset']])
    });

    const step6Schema = object().shape({
        leasingComponentAmount: number().positive().min(0),
        nonLeasingComponent: array().of(object().shape({
            amount: number().min(0, 'Must be greater than 0'),
        })),
    });

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            // step 1
            leaseName: leaseDraftData ? (path[3] == 'clone') ? leaseDraftData.leaseName + ' (Copy)' : path[3] == 'edit' || path[3] == 'add_bulk_lease' ? leaseDraftData.leaseName : '' : '',
            type: leaseDraftData ? leaseDraftData.type : 'Office',
            assetDisc: leaseDraftData ? leaseDraftData.assetDisc : '',
            assetTypeValue: leaseDraftData ? leaseDraftData.assetTypeValue : '',
            assetId: leaseDraftData ? leaseDraftData.assetId : '',
            address1: leaseDraftData ? leaseDraftData.address1 : '',
            address2: leaseDraftData ? leaseDraftData.address2 : '',
            city: leaseDraftData ? leaseDraftData.city : '',
            state: leaseDraftData ? leaseDraftData.state : '',
            zipcode: leaseDraftData ? leaseDraftData.zipcode : '',
            reporting_standard: leaseDraftData && leaseDraftData.reporting_standard ? leaseDraftData.reporting_standard : clientData ? clientData.reporting_standard : 'FASB ASC 842',
            lessee_or_lessor_name: leaseDraftData ? leaseDraftData.lessee_or_lessor_name : '',
            lessee_or_lessor_email: leaseDraftData ? leaseDraftData.lessee_or_lessor_email : '',
            lessee_or_lessor_address: leaseDraftData ? leaseDraftData.lessee_or_lessor_address : '',
            //   step 2
            commencementDate: leaseDraftData ? leaseDraftData.commencementDate : moment(new Date()).format('MM/DD/YYYY'),
            leaseEndDate: leaseDraftData ? leaseDraftData.leaseEndDate : moment(new Date()).format('MM/DD/YYYY'),
            typeStep2: leaseDraftData ? leaseDraftData.typeStep2 : 'months',
            howLong: leaseDraftData ? leaseDraftData.howLong : 0,
            initialTerm: leaseDraftData ? leaseDraftData.initialTerm : '',
            totalLeaseTerm: leaseDraftData ? leaseDraftData.totalLeaseTerm : '',
            terminalLeaseEndDate: leaseDraftData ? leaseDraftData.terminalLeaseEndDate : moment(new Date()).format('MM/DD/YYYY'),
            lastExtensionDate: leaseDraftData && leaseDraftData.lastExtensionDate ? leaseDraftData.lastExtensionDate
                : leaseDraftData && leaseDraftData.extendForTheLessee && leaseDraftData.extendForTheLessee.length > 0 && leaseDraftData.extendForTheLessee[leaseDraftData.extendForTheLessee.length - 1].extendTheLease ? leaseDraftData.extendForTheLessee[leaseDraftData.extendForTheLessee.length - 1].endDate
                    : leaseDraftData && leaseDraftData.terminalLeaseEndDate ? leaseDraftData.terminalLeaseEndDate
                        : moment(new Date()).format('MM/DD/YYYY'),
            extendForTheLessee: leaseDraftData ? leaseDraftData.extendForTheLessee : [{
                extendTheLease: false,
                certainToExercise: false,
                startDate: moment(new Date()).add(1, 'days').format('MM/DD/YYYY'),
                howLong: 0,
                type: 'months',
                endDate: moment(new Date()).add(1, 'days').format('MM/DD/YYYY'),
                notify: 0,
                notifyType: 'months',
                notifyDate: moment(new Date()).format('MM/DD/YYYY')
            }],
            extendForTheLessor: leaseDraftData ? leaseDraftData.extendForTheLessor : [{
                extendTheLease: false,
                certainToExercise: false,
                startDate: moment(new Date()).add(1, 'days').format('MM/DD/YYYY'),
                type: 'months',
                howLong: 0,
                endDate: moment(new Date()).add(1, 'days').format('MM/DD/YYYY'),
                notifyDate: moment().format('MM/DD/YYYY'),
                notify: 0,
                notifyType: 'months'
            }],
            is_short_term: false,

            //   step 3
            discountRate: leaseDraftData ? leaseDraftData.discountRate : '',
            interest_rate_method: leaseDraftData && leaseDraftData.interest_rate_method ? leaseDraftData.interest_rate_method : clientData ? clientData.interest_rate_method : 'true_annual',
            calculation_type: leaseDraftData && leaseDraftData.calculation_type ? leaseDraftData.calculation_type : clientData && clientData.calculation_type ? clientData.calculation_type : 'monthly',
            currency: leaseDraftData ? leaseDraftData.currency : 'USD',
            paymentPreview: leaseDraftData ? leaseDraftData.paymentPreview : '',
            oldPaymentPreview: leaseDraftData ? leaseDraftData.oldPaymentPreview : '',
            extensionPreview: leaseDraftData ? leaseDraftData.extensionPreview : '',
            oldExtensionPreview: leaseDraftData ? leaseDraftData.oldExtensionPreview : '',
            variablePreview: leaseDraftData ? leaseDraftData.variablePreview : '',
            oldVariablePreview: leaseDraftData ? leaseDraftData.oldVariablePreview : '',
            periodicLeasePayments: leaseDraftData ? leaseDraftData.periodicLeasePayments : [{
                amount: 0,
                frequency: 'Monthly',
                paymentDue: 'Beginning of the period',
                paymentDate: '',
                paymentEndDate: '',
                annualFixedAmountPer: 0,
                annualFixedAmount: 0,
                description: ''
            }],

            refundableDepositAmount: leaseDraftData ? leaseDraftData.refundableDepositAmount : 0,
            refundableDepositPaymentDate: leaseDraftData ? leaseDraftData.refundableDepositPaymentDate : '',
            refundableDepositNotificationDate: leaseDraftData ? leaseDraftData.refundableDepositNotificationDate : '',
            refundableDepositReceiptDate: leaseDraftData ? leaseDraftData.refundableDepositReceiptDate : '',
            refundableDepositReceiptAmount: leaseDraftData ? leaseDraftData.refundableDepositReceiptAmount : '',
            refundableDepositSummary: leaseDraftData ? leaseDraftData.refundableDepositSummary : '',

            paymentsBeforeCommencement: leaseDraftData ? leaseDraftData.paymentsBeforeCommencement : [{
                amount: 0,
                paymentDate: '',
                description: ''
            }],

            probableOwedUnderRVGTotalAmount: leaseDraftData ? leaseDraftData.probableOwedUnderRVGTotalAmount : 0,
            probableOwedUnderRVGPaymentDate: leaseDraftData ? leaseDraftData.probableOwedUnderRVGPaymentDate : '',
            probableOwedUnderRVGAmount: leaseDraftData ? leaseDraftData.probableOwedUnderRVGAmount : 0,
            probableOwedUnderRVGTotalAmountDesc: leaseDraftData ? leaseDraftData.probableOwedUnderRVGTotalAmountDesc : '',

            incentives: leaseDraftData ? leaseDraftData.incentives : [{
                amount: 0,
                receiptDate: '',
                description: ''
            }],

            paymentsForPurchaseReason: leaseDraftData ? leaseDraftData.paymentsForPurchaseReason : false,
            paymentsForPurchaseAmount: leaseDraftData ? leaseDraftData.paymentsForPurchaseAmount : '',
            paymentsForPurchasePaymentDate: leaseDraftData ? leaseDraftData.paymentsForPurchasePaymentDate : '',
            paymentsForPurchaseDescription: leaseDraftData ? leaseDraftData.paymentsForPurchaseDescription : '',

            penaltyForTerminatingReason: leaseDraftData ? leaseDraftData.penaltyForTerminatingReason : false,
            penaltyForTerminatingAmount: leaseDraftData ? leaseDraftData.penaltyForTerminatingAmount : '',
            penaltyForTerminatingPaymentDate: leaseDraftData ? leaseDraftData.penaltyForTerminatingPaymentDate : '',
            penaltyForTerminatingDescription: leaseDraftData ? leaseDraftData.penaltyForTerminatingDescription : '',

            initialDirectCostAmount: leaseDraftData ? leaseDraftData.initialDirectCostAmount : '',
            initialDirectCostAmountPaymentDate: leaseDraftData ? leaseDraftData.initialDirectCostAmountPaymentDate : '',
            initialDirectCostDescription: leaseDraftData ? leaseDraftData.initialDirectCostDescription : '',

            variableLeasePayments: leaseDraftData ? leaseDraftData.variableLeasePayments : [{
                category: 'new_category',
                new_category: '',
                amount: 0,
                frequency: 'Monthly',
                paymentDue: 'Beginning of the period',
                paymentDate: '',
                paymentEndDate: '',
                annualFixedAmountPer: 0,
                annualFixedAmount: 0
            }],

            //   step 4
            transitionDate: leaseDraftData ? leaseDraftData.transitionDate : '',
            acquisitionRelated: leaseDraftData ? leaseDraftData.acquisitionRelated : '',
            prepaidRent: leaseDraftData ? leaseDraftData.prepaidRent : 0,
            unamortizedInitialDirectCost: leaseDraftData ? leaseDraftData.unamortizedInitialDirectCost : 0,
            leaseIncentives: leaseDraftData && Number(leaseDraftData.leaseIncentives) < 0 ? leaseDraftData.leaseIncentives : '',
            deferredRent: leaseDraftData ? leaseDraftData.deferredRent : '',
            topic420Liability: leaseDraftData && Number(leaseDraftData.topic420Liability) < 0 ? leaseDraftData.topic420Liability : '',
            otherAccounts: leaseDraftData ? leaseDraftData.otherAccounts : '',
            description: leaseDraftData ? leaseDraftData.description : '',

            // step 5
            classify: clientData && (clientData.reporting_standard == "GASB 87" || clientData.reporting_standard == "IFRS 16") ? 'financing' : leaseDraftData && leaseDraftData.paymentsForPurchaseReason && leaseDraftData.classify !== 'financing' ? 'determine' : leaseDraftData && leaseDraftData.classify ? leaseDraftData.classify : 'operating',
            typeStep5: leaseDraftData ? leaseDraftData.typeStep5 : 'months',
            transferOwnership: leaseDraftData ? leaseDraftData.transferOwnership : false,
            certainToExercise: leaseDraftData ? leaseDraftData.certainToExercise : false,
            amountForOptionToPurchase: leaseDraftData && leaseDraftData.classify === 'financing' && leaseDraftData.paymentsForPurchaseAmount ? leaseDraftData.paymentsForPurchaseAmount : leaseDraftData && leaseDraftData.classify !== 'financing' && leaseDraftData.amountForOptionToPurchase ? leaseDraftData.amountForOptionToPurchase : '',
            usefulEconomicLife: leaseDraftData ? leaseDraftData.usefulEconomicLife : '',
            fairValue: leaseDraftData ? leaseDraftData.fairValue : '',
            specializedNature: leaseDraftData ? leaseDraftData.specializedNature : false,
            economicLifeOfUnderlyingAsset: leaseDraftData && leaseDraftData.economicLifeOfUnderlyingAsset === true ? true : false,
            substantiallyAllOfTheFairValue: leaseDraftData ? leaseDraftData.substantiallyAllOfTheFairValue : false,

            // step 6
            leasingComponentLabel: leaseDraftData ? leaseDraftData.leasingComponentLabel : '',
            leasingComponentAmount: leaseDraftData && leaseDraftData.leasingComponentAmount ? Number(Number(leaseDraftData.leasingComponentAmount).toFixed(2)) : 0,
            leasingPer: leaseDraftData ? leaseDraftData.leasingPer : 100,
            nonLeasingPer: leaseDraftData ? leaseDraftData.nonLeasingPer : '0',
            nonLeasingComponent: leaseDraftData ? leaseDraftData.nonLeasingComponent : [{
                label: '',
                amount: 0
            }]

        },
        validationSchema: activeTab === 1 ? step1Schema : activeTab === 2 ? step2Schema : activeTab === 3 ? step3Schema :
            activeTab === 4 ? step4Schema : activeTab === 5 ? step5Schema : activeTab === 6 ? step6Schema : '',
        onSubmit: async (values) => {
            values.uploaded_files = [...selectedFiles]
            values.mode = mode
            if (activeTab == 6) {
                if (values.transitionDate == '') {
                    values.acquisitionRelated = 0;
                    values.prepaidRent = 0;
                    values.unamortizedInitialDirectCost = 0;
                    values.leaseIncentives = 0;
                    values.deferredRent = 0;
                    values.topic420Liability = 0;
                    values.otherAccounts = 0;
                }
                if (path[3] == 'clone' || path[3] == "edit") {
                    if((leaseDraftData && leaseDraftData.impairments && leaseDraftData.impairments.length > 0) || (leaseDraftData && leaseDraftData.usefulLifes && leaseDraftData.usefulLifes.length > 0) && mode != 'lessor'){
                        setShowImpairUseful(true)
                    }else{
                        if (path[3] == "edit") {
                            editLease(values);
                        } else {
                            hitAxios(values);
                        }
                    }
                } else {
                    hitAxios(values);
                }
            } else {
                if (activeTab == 1) {
                    setLoading(true)
                    let id = (path[3] == 'clone' || path[3] == 'edit') ? sp.get('client_id') : path[3] == 'add_bulk_lease' ? batchData.client_id : params.id;
                    let value = path[3] == 'edit' ? await get(`${AppConfig.baseUrl}/lease/unique_name_edit?name=${validation.values.leaseName}&mode=${mode ? mode : 'lessee'}&client_id=${id}&lease_id=${sp.get('lease_id')}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('token')
                        }
                    })
                        :
                        await get(`${AppConfig.baseUrl}/lease/unique_name?name=${validation.values.leaseName}&client_id=${id}&mode=${mode ? mode : 'lessee'}`, {
                            headers: {
                                'Content-Type': 'application/json',
                                'token': localStorage.getItem('token')
                            }
                        })
                    if (!value.error) {
                        toggleTab(activeTab + 1)
                    } else {
                        validation.setFieldError('leaseName', 'Lease name already exists');
                    }
                    setLoading(false)
                } else if (activeTab == 2) {
                    if (validation.values.lastExtensionDate && validation.values.commencementDate) {
                        validation.setFieldValue(`refundableDepositPaymentDate`, validation.values.refundableDepositPaymentDate ? validation.values.refundableDepositPaymentDate : (moment(validation.values.commencementDate).subtract(1, 'days').format('MM/DD/YYYY')))
                        validation.setFieldValue(`paymentsBeforeCommencement[0].paymentDate`, validation.values.paymentsBeforeCommencement[0] && validation.values.paymentsBeforeCommencement[0].paymentDate ? validation.values.paymentsBeforeCommencement[0].paymentDate : (moment(validation.values.commencementDate).subtract(1, 'days').format('MM/DD/YYYY')))
                        validation.setFieldValue(`probableOwedUnderRVGPaymentDate`, validation.values.probableOwedUnderRVGPaymentDate ? validation.values.probableOwedUnderRVGPaymentDate : (moment(validation.values.lastExtensionDate).format('MM/DD/YYYY')))
                        validation.setFieldValue(`paymentsForPurchasePaymentDate`, validation.values.paymentsForPurchasePaymentDate ? validation.values.paymentsForPurchasePaymentDate : (moment(validation.values.lastExtensionDate).format('MM/DD/YYYY')))
                        validation.setFieldValue(`penaltyForTerminatingPaymentDate`, validation.values.penaltyForTerminatingPaymentDate ? validation.values.penaltyForTerminatingPaymentDate : (moment(validation.values.lastExtensionDate).format('MM/DD/YYYY')))
                        validation.setFieldValue(`initialDirectCostAmountPaymentDate`, validation.values.initialDirectCostAmountPaymentDate ? validation.values.initialDirectCostAmountPaymentDate : (moment(validation.values.commencementDate).subtract(1, 'days').format('MM/DD/YYYY')))
                        validation.setFieldValue(`periodicLeasePayments[0].paymentDate`, validation.values.periodicLeasePayments[0] && validation.values.periodicLeasePayments[0].paymentDate ? validation.values.periodicLeasePayments[0].paymentDate : moment(validation.values.commencementDate).format('MM/DD/YYYY'))
                        validation.setFieldValue(`periodicLeasePayments[0].paymentEndDate`, validation.values.periodicLeasePayments[0] && validation.values.periodicLeasePayments[0].paymentEndDate ? validation.values.periodicLeasePayments[0].paymentEndDate : moment(validation.values.commencementDate).subtract(1, 'day').add(12, 'months').format('MM/DD/YYYY'))
                        validation.setFieldValue(`incentives[0].receiptDate`, validation.values.incentives[0] && validation.values.incentives[0].receiptDate ? validation.values.incentives[0].receiptDate : moment(validation.values.commencementDate).subtract(1, 'day').format('MM/DD/YYYY'))
                    }
                    let month = moment(validation.values.terminalLeaseEndDate).diff(moment(validation.values.commencementDate),'months')
                    if(month < 12 && mode != 'lessor'){
                        setShortTermOpen(true)
                    }else{
                        validation.setFieldValue(`is_short_term`,false)
                        toggleTab(activeTab + 1) 
                    }
                } else if (activeTab == 3) {
                    if (!values.oldPaymentPreview || (values.oldPaymentPreview && values.oldPaymentPreview.length <= 0)) {
                        toast.error(`Please make sure you have added your payments and saved them.`);
                    } else {
                        setActiveTab1('2')
                        let data = values.oldPaymentPreview.sort(function (a, b) {
                            return new Date(a.date) - new Date(b.date);
                        });
                        let extendData = values.oldExtensionPreview.sort(function (a, b) {
                            return new Date(a.date) - new Date(b.date);
                        });
                        let variableData = values.oldVariablePreview.sort(function (a, b) {
                            return new Date(a.date) - new Date(b.date);
                        });
                        let allPayments = [...data, ...extendData, ...variableData].sort(function (a, b) {
                            return new Date(a.date) - new Date(b.date);
                        });
                        let wrongDate = false
                        for (const i of data) {
                            if (i.type == 'Periodic lease payments' && (moment(i.date).isBefore(moment(validation.values.commencementDate)) || moment(i.date).isAfter(moment(validation.values.terminalLeaseEndDate)))) {
                                wrongDate = true
                            }
                        }
                        let wrongDate1 = false
                        for (const i of allPayments) {
                            if (i.type == 'Periodic lease payments' && (moment(i.date).isBefore(moment(validation.values.commencementDate)) || moment(i.date).isAfter(moment(validation.values.lastExtensionDate)))) {
                                wrongDate1 = true
                            } else if (i.type == 'Payments before commencement' && moment(i.date).isSameOrAfter(moment(validation.values.commencementDate))) {
                                wrongDate1 = true
                            } else if (i.type == 'Initial direct cost' && moment(i.date).isAfter(moment(validation.values.lastExtensionDate))) {
                                wrongDate1 = true
                            } else if (i.type == 'Penalty for terminating' && moment(i.date).isBefore(moment(validation.values.commencementDate))) {
                                wrongDate1 = true
                            } else if (i.type == 'Payments for purchase option' && moment(i.date).isBefore(moment(validation.values.commencementDate))) {
                                wrongDate1 = true
                            } else if (i.type == 'variable lease payments' && (moment(i.date).isBefore(moment(validation.values.commencementDate)) || moment(i.date).isAfter(moment(validation.values.lastExtensionDate)))) {
                                wrongDate1 = true
                            }
                        }
                        let wrongDate2 = false
                        for (const i of extendData) {
                            if (i.type == 'Periodic lease payments' && (moment(i.date).isBefore(moment(validation.values.terminalLeaseEndDate)))) {
                                wrongDate2 = true
                            }
                        }
                        if (wrongDate1) {
                            toast.error(`Please select valid date.`);
                        } else if (wrongDate || wrongDate2) {
                            toast.error(`Please save periodic lease payments again.`);
                        } else {
                            let val = values.oldPaymentPreview.reduce((acc, current) => acc + Number(current.amount), 0)
                            let val1 = values.oldExtensionPreview.reduce((acc, current) => acc + Number(current.amount), 0)
                            let refund = allPayments.filter(e => e.type == 'Refundable Deposit')
                            let val2 = refund.reduce((acc, current) => acc + Number(current.amount), 0)
                            let val3 = values.oldVariablePreview.reduce((acc, current) => acc + Number(current.amount), 0)
                            setTotalVal(val - val2);
                            setTotalVal1(val1);
                            setTotalVal2(val2);
                            setTotalVal3(val3);
                            setRefundableDeposit(refund)
                            setTermEndPayments(extendData)
                            setSortedPayments(data)
                            setVariablePayments(variableData)
                            setNextPreviewOpen(true);
                        }
                    }
                } else {
                    toggleTab(activeTab + 1)
                }
            }
        }
    });
    const hitAxios = async (data) => {
        setLoading(true)
        let leaseId = params.id;
        let newData = { ...data, lease_id: leaseId, old_lease_id: sp.get('lease_id'), clientId: path[3] == 'edit' ? leaseDraftData.clientId : path[3] == 'clone' ? sp.get('client_id') : leaseId,lease_type : path[3] }//lease_type for clone 
        if (path[3] == 'add_bulk_lease') {
            newData = { ...newData, batch_id: batchData.batch_id, batch_no: batchData.batch_no, leaseType: 'bulk', bulkLeaseId: batchData._id, clientId: batchData.client_id }
        }
        let value = await newPost(`${AppConfig.baseUrl}/lease_draft/addDraft`, newData, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if (value.error === false) {
            reloadProcessingStatus()
            let avti = (path[3] == 'clone') ? 'clone' : (path[3] == 'edit') ? 'edit' : 'add';
            let data = { activity: avti, lease_id: value.data.lease_id, client_id: (path[3] == 'clone' || path[3] == 'edit') ? leaseDraftData.clientId : leaseId, client_name: clientName, mode: mode ? mode : 'lessee' }
            newPost(`${AppConfig.baseUrl}/activity/add_activity`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            navigate(`/${localStorage.getItem('company')}/leases/details/${value.data.lease_id}/refresh`)
        } else {
            toast.error(value.title || 'Lease not saved');
        }
        setLoading(false)
    }
    const editLease = async (data) => {
        setLoading(true)
        let newData = { ...data, lease_id: sp.get('lease_id'), clientId: leaseDraftData.clientId ? leaseDraftData.clientId : sp.get('client_id'),lease_type : path[3] }
        let value = await newPost(`${AppConfig.baseUrl}/lease_draft/editLease`, newData, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if (value.error === false) {
            reloadProcessingStatus()
            let avti = 'edit';
            let data = { activity: avti, lease_id: value.data.lease_id, client_id: leaseDraftData.clientId ? leaseDraftData.clientId : sp.get('client_id'), client_name: clientName, mode: mode ? mode : 'lessee' }
            newPost(`${AppConfig.baseUrl}/activity/add_activity`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            navigate(`/${localStorage.getItem('company')}/leases/details/${value.data.lease_id}/refresh`)
        } else {
            toast.error(value.title || 'Lease not saved');
        }
        setLoading(false)
    }

    const getClientName = async () => {        
        let value = await get(`${AppConfig.baseUrl}/user_client/get_user_client?id=${leaseDraftData.user_client_id}&customer_id=${userData.cust_info._id}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        let clientData = value.userData.client_id
        setClientName(clientData.client)
    }

    useEffect( () => {
        if (path[3] === 'add_bulk_lease' && leaseDraftData && leaseDraftData.user_client_id) {
            getClientName();
            if (leaseDraftData.transitionDate) {
                validation.setFieldValue(`transitionDate`, (moment.utc(leaseDraftData.transitionDate).format('MM/DD/YYYY')))
            } else if (moment(clientData.transition_date).isAfter(moment(validation.values.commencementDate))) {
                validation.setFieldValue(`transitionDate`, (moment.utc(clientData.transition_date).format('MM/DD/YYYY')))
            } else {
                validation.setFieldValue(`transitionDate`, (''))
            }
        }
    }, [leaseDraftData, validation.values.commencementDate])


    const getClientData = async () => { // get client transition date
        let value = await get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}&customer_id=${userData.cust_info._id}&access_type=full_access`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        let id = (path[3] == 'clone' || path[3] == 'edit') ? sp.get('client_id') : params.id;
        let clientData = value.clientData.filter((a) => a.client_id._id == id)
        setClientData(clientData[0].client_id)
        setClientName(clientData[0].client_id.client)
        if (clientData.length > 0) {
            let client = clientData[0].client_id;
            setClientDataFromApi(client)
            if (path[3] == 'clone' || path[3] == 'edit') {
                validation.setFieldValue(`transitionDate`, leaseDraftData.transitionDate ? (moment.utc(leaseDraftData.transitionDate).format('MM/DD/YYYY')) : '')
            } else if (moment(client.transition_date).isAfter(moment(validation.values.commencementDate))) {
                validation.setFieldValue(`transitionDate`, (moment.utc(client.transition_date).format('MM/DD/YYYY')))
            } else {
                validation.setFieldValue(`transitionDate`, (''))
            }
        }
    }

    useEffect( () => { // get client transition date
        if (userData && userData.id && path[3] !== 'add_bulk_lease') {
            getClientData();
        }
    }, [userData, leaseDraftData, validation.values.commencementDate])

    useEffect(() => {
        if (validation.values.commencementDate && validation.values.terminalLeaseEndDate) {
            validation.setFieldValue("transitionDate", '')
        }
    }, [validation.values.commencementDate, validation.values.terminalLeaseEndDate])

    const getDraftData = async () => {
        if ((path[3] == 'clone' || path[3] == 'edit') && sp.get('lease_id')) {
            setLoadingData(true)
            try {
                let { data } = await get(`${AppConfig.baseUrl}/lease_draft/get_draft?id=${sp.get('lease_id')}&mode=${mode ? mode : 'lessee'}&customer_id=${userData.cust_info._id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    }
                })
                if (data && data.lease){
                    setLeaseDraftData(data.lease)
                }
            } catch (err) {

            }
            setLoadingData(false)
        }
        else if (path[3] == 'add_bulk_lease' && sp.get('bulk_lease_id')) {
            setLoadingData(true)
            try {
                let { data } = await get(`${AppConfig.baseUrl}/bulk_lease/get_bulk_lease_data?id=${sp.get('bulk_lease_id')}&mode=${mode ? mode : 'lessee'}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    }
                })
                if (data){
                    setLeaseDraftData(data.lease ? data.lease : {});
                    setBatchData(data);
                }
            } catch (err) {

            }
            setLoadingData(false)
        }
    }

    useEffect(() => {
        if (leaseDraftData && Object.keys(leaseDraftData).length > 0) {
            if (path[3] != 'clone') {
                setselectedFiles(leaseDraftData.uploaded_files ? leaseDraftData.uploaded_files : [])
            }

            leaseDraftData.extendForTheLessee.map((d, i) => {
                if (d) {
                    let elem = document.getElementById(`extendForTheLessee[${i}].certainToExercise`);
                    if (elem) {
                        elem.checked = d.certainToExercise;
                    }
                }
            })
            let elem2 = document.getElementById(`paymentsForPurchaseReason`);
            elem2 && setTimeout(() => elem2.checked = leaseDraftData.paymentsForPurchaseReason, 500)
            let elem5 = document.getElementById('transferOwnership');
            elem5 && setTimeout(() => elem5.checked = leaseDraftData.transferOwnership)
            let elem6 = document.getElementById('specializedNature');
            elem6 && setTimeout(() => elem6.checked = leaseDraftData.specializedNature)
            let elem7 = document.getElementById('substantiallyAllOfTheFairValue');
            elem7 && setTimeout(() => elem7.checked = leaseDraftData.substantiallyAllOfTheFairValue)
            let elem8 = document.getElementById('periodicLeasePayments[0].frequency');
            elem8 && setTimeout(() => elem8.value = leaseDraftData.periodicLeasePayments[0].frequency)
            validation.setFieldValue(`oldPaymentPreview`, leaseDraftData.oldPaymentPreview)
            let val = leaseDraftData.paymentPreview ? leaseDraftData.paymentPreview.reduce((acc, current) => acc + Number(current.amount), 0) : 0;
            validation.setFieldValue(`leasingComponentAmount`, (val))
            setTermEndPayments(leaseDraftData && leaseDraftData.oldExtensionPreview)
            validation.setFieldValue(`oldExtensionPreview`, leaseDraftData.oldExtensionPreview)
            setVariablePayments(leaseDraftData && leaseDraftData.oldVariablePreview)
            validation.setFieldValue(`oldVariablePreview`, leaseDraftData.oldVariablePreview)            
        }
    }, [leaseDraftData])

    useEffect(() => {
        if (leaseDraftData && Object.keys(leaseDraftData).length > 0) {
            let impairData = leaseDraftData && leaseDraftData.impairments ? leaseDraftData.impairments : []
            let usefulData = leaseDraftData && leaseDraftData.usefulLifes ? leaseDraftData.usefulLifes : []
            impairData = impairData.filter(e => moment(e.date).isSameOrAfter(moment(validation.values.commencementDate)) && moment(e.date).isSameOrBefore(moment(validation.values.terminalLeaseEndDate)))
            usefulData = usefulData.filter(e => moment(e.date).isSameOrAfter(moment(validation.values.commencementDate)) && moment(e.date).isSameOrBefore(moment(validation.values.terminalLeaseEndDate)))
            const allpayments = [...impairData,...usefulData].sort(function(a, b) {  return new Date(a.date) - new Date(b.date);})
            setImpairUseful(allpayments)
            setSelectedImpairUsefuls(allpayments)
        }
    }, [validation.values.commencementDate, validation.values.terminalLeaseEndDate, leaseDraftData])

    useEffect(() => {
        if ((leaseDraftData && Object.keys(leaseDraftData).length > 0) || (clientData && Object.keys(clientData).length > 0)) {
            validation.setFieldValue('reporting_standard', validation.values.reporting_standard ? validation.values.reporting_standard : path[3] == 'add' && clientData.reporting_standard ? clientData.reporting_standard : leaseDraftData && leaseDraftData.reporting_standard ? leaseDraftData.reporting_standard : "FASB ASC 842")
            validation.setFieldValue('interest_rate_method', (path[3] == 'add' || path[3] == 'add_bulk_lease') && clientData && clientData.interest_rate_method ? clientData.interest_rate_method : leaseDraftData && leaseDraftData.interest_rate_method ? leaseDraftData.interest_rate_method : "true_annual")
            validation.setFieldValue('classify', (validation.values.reporting_standard == "GASB 87" || validation.values.reporting_standard == "IFRS 16") ? "financing" : path[3] == 'add' && (clientData.reporting_standard == "GASB 87" || clientData.reporting_standard == "IFRS 16") ? "financing" : leaseDraftData && leaseDraftData.paymentsForPurchaseReason && leaseDraftData.classify !== 'financing' ? 'determine' : leaseDraftData && leaseDraftData.classify ? leaseDraftData.classify : 'operating')
        }
    }, [leaseDraftData, clientData,validation.values.reporting_standard])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeTab])

    useEffect(() => {
        getDraftData();
        if (mode && clientData && clientData.client_type && mode != clientData.client_type) {
            window.location.href = `/${localStorage.getItem('company')}/leases`
        }
    }, [mode])

    const handleClose = () => {
        setShowImpairUseful(false);
    }

    const includeExcludeImpairment = ()=>{
        let payments = selectedImpairUsefuls
        let useful = payments.filter(v => v.endDate)
        let impair = payments.filter(v => !v.endDate)
        validation.values.impairments = impair
        validation.values.usefulLifes = useful
        if (path[3] == "edit") {
            editLease(validation.values);
        } else {
            hitAxios(validation.values);
        }
    }

    const showCheck = (data) => {
        let a = selectedImpairUsefuls.filter((e) => e == data);
        return a.length > 0 ? true : false
    }

    const checkArr = (data) => {
        let newArr = [...selectedImpairUsefuls];
        let index = newArr.findIndex((e) => e == data);
        if (index > -1) {
            newArr.splice(index, 1);
            setSelectedImpairUsefuls(newArr);
        } else {
            newArr.push(data);
            setSelectedImpairUsefuls(newArr);
        }
    }

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Add New Lease</title>
                </Helmet>
                <Container fluid={true}>
                    <Breadcrumbs title="Forms" name={clientName} breadcrumbItem={`${path[3] == 'clone' ? 'Clone' : path[3] == 'edit' ? 'Edit' : 'Add New'} Lease`}
                        dates={`${moment(new Date(validation.values.commencementDate)).format('MM/DD/YYYY')} to
                     ${Number(validation.values.howLong) >= 0 ? moment(new Date(validation.values.terminalLeaseEndDate)).format('MM/DD/YYYY') : moment(new Date(validation.values.commencementDate)).format('MM/DD/YYYY')}`} />
                    {
                        loadingData ?
                            <div className="text-center"><Spinner color="primary" className="text-lbusers" /></div>
                            :
                            <Row>
                                <Col lg="12" sm="12">
                                    <Card>
                                        <CardBody style={{ padding: 0 }}>
                                            <div className="wizard clearfix">
                                                <div className="steps clearfix">
                                                    <ul>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 1 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ current: activeTab === 1 })}
                                                                disabled={!(passedSteps || []).includes(1)}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">1</div>
                                                                    <div className="client">General</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 2 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 2 })}
                                                                disabled={!(passedSteps || []).includes(2)}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">2</div>
                                                                    <div className="client">Lease Term</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 3 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 3 })}
                                                                disabled={!(passedSteps || []).includes(3)}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">3</div>
                                                                    <div className="client">Payments</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 4 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 4 })}
                                                                disabled={!(passedSteps || []).includes(4)}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">4</div>
                                                                    <div className="client">Transition Data</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 5 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 5 })}
                                                                disabled={!(passedSteps || []).includes(5)}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">5</div>
                                                                    <div className="client">Classification</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 6 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 6 })}
                                                                disabled={!(passedSteps || []).includes(6)}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number ">6</div>
                                                                    <div className="client">Allocations</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </div>
                                                <Form
                                                    className="form-horizontal"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                >
                                                    <div className="content clearfix mt-4">
                                                        <TabContent activeTab={activeTab}>
                                                            <TabPane tabId={1}>
                                                                <AddLeaseStepOne formik={validation} clientData={clientData} leaseDraftData={leaseDraftData} mode={mode} />
                                                            </TabPane>
                                                            <TabPane tabId={2}>
                                                                <AddLeaseStepTwo formik={validation} clientDataFromApi={clientDataFromApi} leaseDraftData={leaseDraftData} />
                                                            </TabPane>
                                                            <TabPane tabId={3}>
                                                                <AddLeaseStepThree formik={validation} clientData={clientData} leaseDraftData={leaseDraftData} termEndPayments={termEndPayments} setTermEndPayments={setTermEndPayments} refundableDeposit={refundableDeposit} setRefundableDeposit={setRefundableDeposit} variablePayments={variablePayments} setVariablePayments={setVariablePayments} tab={activeTab} />
                                                            </TabPane>
                                                            <TabPane tabId={4}>
                                                                <AddLeaseStepFour formik={validation} />
                                                            </TabPane>
                                                            <TabPane tabId={5}>
                                                                <AddLeaseStepFive formik={validation} mode={mode} />
                                                            </TabPane>
                                                            <TabPane tabId={6}>
                                                                <AddLeaseStepSix formik={validation} per={leaseDraftData} mode={mode} />
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                    <div className="row actions clearfix mt-4 mb-4" style={{ paddingRight: '15px' }}>
                                                        <div style={{ listStyleType: 'none', display: 'flex', justifyContent: 'end' }}>
                                                            {
                                                                activeTab < 2 &&
                                                                <div className="me-auto ms-3">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => setAddFile(true)}
                                                                        className="btn bg-theme-color"
                                                                    >
                                                                        File Upload
                                                                    </button>
                                                                </div>
                                                            }
                                                            <SaveAsDraft validation={validation} batchData={batchData} clientData={clientData} leaseDraftData={leaseDraftData} clientName={clientName} mode={mode} />
                                                            {
                                                                activeTab < 7 && activeTab != 1 &&
                                                                <div
                                                                    className={
                                                                        activeTab === 1 ? "previous disabled" : "previous ms-3"
                                                                    }
                                                                >
                                                                    <button className="btn bg-theme-color w-md" type='button' onClick={() => { toggleTab(activeTab - 1) }} disabled={loading} >
                                                                        {
                                                                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                                        }
                                                                        PREVIOUS
                                                                    </button>
                                                                </div>
                                                            }
                                                            {

                                                                activeTab < 6 &&
                                                                <div
                                                                    className={activeTab === 6 ? "next disabled ms-3" : "next ms-3"}
                                                                >
                                                                    <button type="submit" className="btn bg-theme-color w-md" disabled={loading} >
                                                                        {
                                                                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                                        }
                                                                        NEXT
                                                                    </button>
                                                                </div>
                                                            }
                                                            {
                                                                activeTab === 6 &&
                                                                <div
                                                                    className={
                                                                        activeTab === 1 ? "previous disabled" : "previous"
                                                                    }
                                                                >
                                                                    <button type="submit" className="btn bg-theme-color w-md ms-3" disabled={loading} >
                                                                        {
                                                                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                                        }
                                                                        SUBMIT
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </Container>
            </div>
            <UploadFiles selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} addFile={addFile} toggleModel={toggleModel} />
            <Modal
                size="lg"
                isOpen={nextPreviewOpen}
                toggle={() => {
                    toggleNextPreview();
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        Preview
                    </h5>
                    <button
                        onClick={() => {
                            setNextPreviewOpen(false);
                            setActiveTab1('2')
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Card>
                        <CardBody>
                            <Row>
                                <Nav tabs>
                                    {refundableDeposit && refundableDeposit.length > 0 &&
                                        <NavItem style={{ textAlign: 'left' }}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab1 === "1",
                                                })}
                                                onClick={() => {
                                                    setActiveTab1("1");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block font-size-16">Refundable Deposit</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                    <NavItem style={{ textAlign: 'left' }}>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab1 === "2",
                                            })}
                                            onClick={() => {
                                                setActiveTab1("2");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                            </span>
                                            <span className="d-none d-sm-block font-size-16">Lease Payments</span>
                                        </NavLink>
                                    </NavItem>
                                    {termEndPayments && termEndPayments.length > 0 &&
                                        <NavItem style={{ textAlign: 'left' }}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab1 === "3",
                                                })}
                                                onClick={() => {
                                                    setActiveTab1("3");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block font-size-16">Extension Payments</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                    {variablePayments && variablePayments.length > 0 &&
                                        <NavItem style={{ textAlign: 'left' }}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab1 === "4",
                                                })}
                                                onClick={() => {
                                                    setActiveTab1("4");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block font-size-16">Variable Lease Payments</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                </Nav>
                                <TabContent
                                    activeTab={activeTab1}
                                    className="p-3 text-muted"
                                >
                                    <TabPane tabId="1">
                                        {refundableDeposit && refundableDeposit.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th>Amount</th>
                                                                            <th className="text-center">Payment due date</th>
                                                                            <th style={{ textAlign: 'left', width: '35%' }}>Payment Type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {refundableDeposit && refundableDeposit.length > 0 && refundableDeposit.map((data, i) => (
                                                                            <tr key={data.type + i}>
                                                                                <td scope="row">$ {data.amount != '' ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                <td className="text-align-left">{data.type}</td>
                                                                            </tr>
                                                                        ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal2)).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {sortedPayments && sortedPayments.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th>Amount</th>
                                                                            <th className="text-center">Payment due date</th>
                                                                            <th style={{ textAlign: 'left', width: '35%' }}>Payment Type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {sortedPayments && sortedPayments.length > 0 && sortedPayments.map((data, i) => {
                                                                            if (data.type != 'Refundable Deposit') {
                                                                                return <tr key={data.type + i}>
                                                                                    <td scope="row">$ {data.amount != '' ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                    <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                    <td className="text-align-left">{data.type}</td>
                                                                                </tr>
                                                                            }
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal)).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="3">
                                        {termEndPayments && termEndPayments.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th>Amount</th>
                                                                            <th className="text-center">Payment due date</th>
                                                                            <th style={{ textAlign: 'left', width: '35%' }}>Payment Type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {termEndPayments && termEndPayments.length > 0 && termEndPayments.map((data, i) => (
                                                                            <tr key={data.type + i}>
                                                                                <td scope="row">$ {data.amount != '' ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                <td className="text-align-left">{data.type}</td>
                                                                            </tr>
                                                                        ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal1)).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="4">
                                        {variablePayments && variablePayments.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th className="text-center">Date</th>
                                                                            <th className="text-center">Category</th>
                                                                            <th>Estimated Amount</th>
                                                                            {path[3] != 'add' && <th>Actual Amount</th>}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {variablePayments && variablePayments.length > 0 && variablePayments.map((data, i) => (
                                                                            <tr key={data.type + i}>
                                                                                <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                <td className="text-center">{data.category ? data.category : ''}</td>
                                                                                <td scope="row">$ {data.amount ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                {path[3] != 'add' && <td scope="row">{data.actual_amount && data.is_processed ? `$${(Number(data.actual_amount)).toFixed(2)}` : ''}</td>}
                                                                            </tr>
                                                                        ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal3)).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                </TabContent>
                            </Row>
                            {/* <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {(Number(totalVal)).toFixed(2)}</h5> */}
                            <div style={{ float: 'right' }} className="next ms-3">
                                <button type="button" className="btn bg-theme-color w-md" disabled={loading} onClick={() => {
                                    setNextPreviewOpen(false);
                                    toggleTab(activeTab + 1);
                                }} >
                                    {
                                        loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    }
                                    CONFIRM PAYMENTS
                                </button>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            <Modal
                isOpen={showImpairUseful}
                toggle={() => {
                    handleClose();
                }}
                centered
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Include / Exclude Impairments / Change in Useful Life</h5>
                    <button
                        type="button"
                        onClick={handleClose}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="table-responsive">
                        <Table className="table mb-0 customTable">
                            <thead className="table-light tableHead">
                                <tr>
                                    <th>Date</th>
                                    <th>End Date</th>
                                    <th>Amount</th>
                                    <th>Type</th>
                                    <th>
                                        <div className="ms-2">
                                            <Input
                                                className="form-check-input"
                                                name="selectAll"
                                                type="checkbox"
                                                checked={impairUseful && selectedImpairUsefuls && impairUseful.length == selectedImpairUsefuls.length}
                                                onClick={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedImpairUsefuls(impairUseful)
                                                    } else {
                                                        setSelectedImpairUsefuls([])
                                                    }
                                                }}
                                            />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="tableBody" style={{minHeight:'200px', maxHeight:'500px', height: 'auto'}}>
                                {impairUseful && impairUseful.length > 0 && impairUseful.map((data, i) => (
                                    <tr key={data.type + i}>
                                    <td>{moment(data.date).format('MM/DD/YYYY')}</td>
                                    <td>{data.endDate ? moment(data.endDate).format('MM/DD/YYYY') : ''}</td>
                                    <td>{data.amount && !data.endDate ? commaSeperated((Number(data.amount || 0)).toFixed(2)) : ''}</td>
                                    <td style={{width:'160px'}}>{data.endDate ? 'Change in Useful Life' : 'Impairment'}</td>
                                    <td>
                                        <div className="mb-3 ms-2">
                                            <Input
                                                className="form-check-input"
                                                name="includePayment"
                                                id={i}
                                                type="checkbox"
                                                checked={showCheck(data)}
                                                onClick={() => checkArr(data)}
                                            />
                                        </div>
                                    </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div className="modal-footer mt-3">
                        <button type="submit" className="btn btn-lbusers" disabled={loading} onClick={includeExcludeImpairment}>
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            }
                            SUBMIT
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={shortTermOpen}
                toggle={() => {
                    setShortTermOpen(false);
                }}
                centered
            >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Short Term</h5>
                <button
                    type="button"
                    onClick={()=>{setShortTermOpen(false)}}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3">
                    Do you want to mark this lease as short term lease ?
                </div>
                <div className="modal-footer mt-3">
                    <button type="button" className="btn btn-lbusers" onClick={()=>{
                        validation.setFieldValue(`is_short_term`,false)
                        setactiveTab(3)
                        setShortTermOpen(false);
                    }}>
                        No
                    </button>
                    <button type="submit" className="btn btn-lbusers" onClick={()=>{
                        validation.setFieldValue(`is_short_term`,true)
                        validation.setFieldValue(`classify`,'operating')
                        setactiveTab(3)
                        setShortTermOpen(false);
                    }}>
                        Yes
                    </button>
                </div>
            </div>
            </Modal>
        </Layout>
    )
}

export default AddAccount
