import React, {  useState, useContext } from "react"
import PropTypes from 'prop-types'
import { Row, Col, Modal, Label, Input } from "reactstrap"
import AddClient from "pages/Myclients/addClient";
import AddClientToLease from "../../pages/Leases/addClientToLease";
import { AuthContext } from "context/authContext"

const BreadcrumbManual = (prop) => {
  const { getClientsData } = prop;
  const { userData } = useContext(AuthContext)
  const [openPopup, setOpenPopup] = useState(false);
  const [addClientLeasePopup,setAddClientLeasePopup]=useState(false)
  const [isGridViewOpen, setIsGridViewOpen] = useState(false);

  const onToggleView = () => {
    setIsGridViewOpen(!isGridViewOpen)
  }
  // toggle add new account modal
  const toggleModel = () => {
    setOpenPopup(!openPopup);
  }

  const AddClientLeaseToggle = () =>{
    setAddClientLeasePopup(!addClientLeasePopup)
  }

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{prop.breadcrumbItem}</h4>
          <div className="mb-0">
            <button
              type="button"
              className="btn btn-light gridbtn-m"
              onClick={() => {
                prop.handleViewChange();
                onToggleView()
              }}
            >
              {isGridViewOpen ? <i className='bx bx-list-ul'></i> : <i className='bx bxs-grid grid'></i>}
            </button>
            {
              userData.main_user_type === 'third_party' && prop.type === 'client' ? "" :
              <>
                  <button
                    type="button"
                    className="btn btn-lbusers"
                    onClick={() => {
                      if (prop.type === 'lease') {
                        AddClientLeaseToggle()
                      }
                      else {
                        toggleModel();
                      }
                    }}
                  >
                    ADD NEW
                  </button>
              </>
            }
            {

              prop.type === 'client' ?
                <AddClient addOpen={openPopup} toggleAdd={toggleModel} setOpenPopup={setOpenPopup} getClientsData={getClientsData} />
                : prop.type === 'lease'?
                   <AddClientToLease addOpen={addClientLeasePopup} toggleAdd={AddClientLeaseToggle} setOpenPopup={setAddClientLeasePopup} />
                :
                <Modal
                  isOpen={openPopup}
                  toggle={() => {
                    toggleModel();
                  }}
                  centered
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">Add New Account</h5>
                    <button
                      type="button"
                      onClick={() => {
                        setOpenPopup(false);
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="mb-3">
                        <Label for="name" className="col-form-label">
                          Name:
                        </Label>
                        <Input
                          type="text"
                          id="name"
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3">
                        <Label for="email1" className="col-form-label">
                          Email:
                        </Label>
                        <Input
                          type="text"
                          id="email1"
                          className="form-control"
                        />
                      </div>
                    </form>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() =>
                          setOpenPopup(false)
                        }
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-lbusers">
                        Save
                      </button>
                    </div>
                  </div>
                </Modal>
            }
          </div>
        </div>
      </Col>
    </Row>
  )
}

BreadcrumbManual.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default BreadcrumbManual
