import React, { useEffect, useState } from "react"
import {
    Card,
    Col,
    Row,
    Modal,
    Form
} from "reactstrap"
import Dropzone from "react-dropzone"

const UploadFiles = (prop) => {
    const {selectedFiles, setselectedFiles, addFile, toggleModel, submitFiles, type} = prop;
    const [dummyFiles, setDummyFiles] = useState([]);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    async function handleAcceptedFiles(files) {
        let updatedFiles = [];
        for(const file of files){
            updatedFiles.push({
                ...file, 
                preview: await toBase64(file),
                formattedSize: formatBytes(file.size),
                name: file.name,
                type: file.type
            })
        }
        setDummyFiles([...dummyFiles, ...updatedFiles])
    }

    const removeFile = async(file) => {
        const updatedFiles = await dummyFiles.filter(v => v.preview != file.preview);
        setDummyFiles(updatedFiles);
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const handleSave = () => {
        setselectedFiles(dummyFiles)
        toggleModel()
        if(type == 'details'){
            submitFiles(dummyFiles, "new")
        }
    }

    useEffect(() => {
        setDummyFiles(selectedFiles ? selectedFiles : [])
    },[selectedFiles])

    return (
        <React.Fragment>
            <Modal
                isOpen={addFile}
                toggle={() => {
                    setDummyFiles(selectedFiles);
                    toggleModel();
                }}
                centered
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Upload File</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setDummyFiles(selectedFiles);
                            toggleModel();
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Form>
                        <Row>
                            <Col md='6'>
                                <Dropzone
                                    onDrop={acceptedFiles => {
                                        handleAcceptedFiles(acceptedFiles)
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                            <div
                                                className="dz-message needsclick mt-2"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Drop files here or click to upload.</h4>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </Col>
                            <Col md='6' style={{height: '220px', overflowY:'scroll'}}>
                                <div className="dropzone-previews mt-3" id="file-previews">
                                    {dummyFiles.map((f, i) => {
                                        return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                    <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                            {
                                                                f.type === 'application/pdf' ?
                                                                    <i className='bx bxs-file-pdf' style={{fontSize: '30px'}}></i>
                                                                : <i className='bx bxs-file-doc' style={{fontSize: '30px'}}></i>
                                                            }
                                                        </Col>
                                                        <Col className="d-flex justify-content-between">
                                                            <div>
                                                                <p className="text-muted font-weight-bold mb-0">
                                                                    {f.name}
                                                                </p>
                                                                <p className="mb-0">
                                                                    <strong>{f.formattedSize}</strong>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <i className='bx bx-x' style={{fontSize: '20px', color: 'red', cursor:'pointer'}} onClick={() => removeFile(f)}></i>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                        <div className="mt-3 text-right">
                             <button type="button"  className="btn bg-theme-color w-md" style={{float: 'right'}} onClick={handleSave}>
                                SAVE
                            </button>
                        </div>
                       
                    </Form>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default UploadFiles
