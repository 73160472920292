import React from "react"
import {Modal} from "reactstrap"
import "../../assets/scss/manual/manual.scss";
const RevertLease = (prop) => {
    const {deleteOpen, toggleDelete, setDeleteOpen , deleteL, lease, loading} = prop;
    return (
        <Modal
            isOpen={deleteOpen}
            toggle={() => {
                toggleDelete();
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Revert Lease</h5>
                <button
                    type="button"
                    onClick={() => {
                        setDeleteOpen(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form>
                    <div className="mb-3">
                        Are you sure you want to revert this lease ?
                    </div>
                    <div className="modal-footer mt-3">
                        <button
                            type="button"
                            className="btn btn-lbusers"
                            onClick={() =>
                                setDeleteOpen(false)
                            }
                            disabled={loading} 
                        >
                            {
                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            No
                        </button>
                        <button type="button" className="btn btn-lbusers" onClick={() => deleteL(lease._id)} disabled={loading} >
                            {
                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            Yes
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default RevertLease