import React, { useState, useEffect, useContext } from "react"
import { Row, Col, Modal, Spinner } from "reactstrap"
import "./datatables.scss"
import "../../assets/scss/manual/manual.scss"
import { AuthContext } from "context/authContext"
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "../../constants/config";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Select from "react-select";

const Template = (prop) => {
  const {glmapModal, setGlmapModal, id, getGlData, setSelectedGroup} = prop;
  const { mode } = useContext(AuthContext)
  const [glData, setGlData] = useState(mode == 'lessor' ? 
  [{ 'jecolumn': 'Receipt Suspense Account Before Commencement', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Rent Deposit', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Rent Receipt Suspense Account', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': "Deferred inflow of resources", "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': "Receivable" , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': "ST Lease Asset-Liability" , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Interest Income', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Lease Revenue' , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Financing Variable Lease Revenue' , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Modification Loss or Gain', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Transition', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }] : 
  [{ 'jecolumn': 'Payment Suspense Account Before Commencement' , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Rent Deposit', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Rent Payment Suspense Account', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn':  "ROU Asset", "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': "Lease Liability" , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': "ST Lease Asset-Liability" , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Rent Expense' , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Interest Expense', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Amortization' , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Operating Variable Lease Expense', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Financing Variable Lease Expense', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Modification Loss or Gain', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Impairment', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Transition', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }]
  );
  const [loading, setLoading] = useState(false);
  const [index,setIndex] = useState('')
  const [editRow, setEditRow] = useState(false)
  const [deleteRow, setDeleteRow] = useState(false)
  const [rowData, setRowData] = useState({})
  const [updateRow, setUpdateRow] = useState(false)
  const [glAccounts, setGlAccounts] = useState([])
  const [allocations, setAllocations] = useState([])
  const [allocationData, setAllocationData] = useState([])

  const toggleModal = () => {
    setGlmapModal(!glmapModal);
    setSelectedGroup({})
    removeBodyCss();
}

function removeBodyCss() {
    document.body.classList.add("no_padding");
}

  useEffect(() => {
    if(id){
      getGlmapData()
    }
  },[id])

  const getGlmapData = async()=>{
    setLoading(true)
    try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/gl_template/get_gl_template_details?mode=${mode ? mode : 'lessee'}&id=${id}`,{
            headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
          setGlAccounts(data.glAccounts || []);
          setAllocations(data.allocations|| []);
          setAllocationData(data.allocationData || []);          
          if(data.glGroupData && data.glGroupData.template && data.glGroupData.template.length > 0){
            const index = data.glGroupData.template.findLastIndex(v=>v.jecolumn == 'Lease liability' || v.jecolumn == 'Lease Liability' || v.jecolumn == 'Receivable')
            const stIndex = data.glGroupData.template.findLastIndex(v=>v.jecolumn == 'ST Lease Asset-Liability')
            const value13 = data && data.glAccounts && data.glAccounts.length > 0 && data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test("ST Lease Asset-Liability"))
            let stLiability = value13 && value13[0]
            if(stIndex < 0)data.glGroupData.template.splice(index+1, 0, { 'jecolumn': "ST Lease Asset-Liability" , "assigned_glaccount": stLiability && stLiability.preview ? stLiability.preview : '', "ac_number": stLiability && stLiability.ac_number ? stLiability.ac_number : '', "glaccount_description": stLiability && stLiability.glaccount_description ? stLiability.glaccount_description : 0, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 });
            setGlData(data.glGroupData.template)
          }else{
            let payment = '',rent = '',rentPayment='',rou = '',liability = '',rentExpense='', interest='',amort = '',operating = '',financing = '',mod = '',impairment = '',transition = '',stLiability = ''
            if(data && data.glAccounts && data.glAccounts.length > 0){
                const value = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test("Receipt Suspense Account Before Commencement") || new RegExp(a.jecolumn, 'ig').test("Payment Suspense Account Before Commencement"))
                payment = value && value[0]
                const value1 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test("Rent Deposit"))
                rent = value1 && value1[0]
                const value2 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test("Rent Receipt Suspense Account") || new RegExp(a.jecolumn, 'ig').test("Rent Payment Suspense Account"))
                rentPayment = value2 && value2[0]
                const value3 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test('Deferred inflow of resources') || new RegExp(a.jecolumn, 'ig').test('ROU Asset') || new RegExp(a.jecolumn, 'ig').test('Rou Asset'))
                rou = value3 && value3[0]
                const value4 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test("Receivable") || new RegExp(a.jecolumn, 'ig').test("Lease Liability") || new RegExp(a.jecolumn, 'ig').test("Lease liability"))
                liability = value4 && value4[0]
                const value5 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test("Rent Expense"))
                rentExpense = value5 && value5[0]
                const value6 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test('Interest Income') || new RegExp(a.jecolumn, 'ig').test('Interest Expense'))
                interest  = value6 && value6[0]
                const value7 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test('Lease Revenue') || new RegExp(a.jecolumn, 'ig').test('Amortization'))
                amort = value7 && value7[0]
                const value8 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test('Operating Variable Lease Revenue') || new RegExp(a.jecolumn, 'ig').test('Operating Variable Lease Expense'))
                operating = value8 && value8[0]
                const value9 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test('Financing Variable Lease Revenue') || new RegExp(a.jecolumn, 'ig').test('Financing Variable Lease Expense'))
                financing = value9 && value9[0]
                const value10 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test("Modification Loss or Gain"))
                mod = value10 && value10[0] 
                const value11 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test("Impairment"))
                impairment = value11 && value11[0] 
                const value12 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test("Transition"))
                transition = value12 && value12[0] 
                const value13 = data.glAccounts.filter(a => new RegExp(a.jecolumn, 'ig').test("ST Lease Asset-Liability"))
                stLiability = value13 && value13[0] 
            }
            const glValue = mode == 'lessor' ? 
              [{ 'jecolumn': 'Receipt Suspense Account Before Commencement', "assigned_glaccount": payment.preview, "ac_number": payment.ac_number, "glaccount_description": payment.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Rent Deposit', "assigned_glaccount": rent.preview, "ac_number": rent.ac_number, "glaccount_description": rent.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Rent Receipt Suspense Account', "assigned_glaccount": rentPayment.preview, "ac_number": rentPayment.ac_number, "glaccount_description": rentPayment.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': "Deferred inflow of resources", "assigned_glaccount": rou.preview, "ac_number": rou.ac_number, "glaccount_description": rou.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': "Receivable" , "assigned_glaccount": liability.preview, "ac_number": liability.ac_number, "glaccount_description": liability.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': "ST Lease Asset-Liability" , "assigned_glaccount": stLiability && stLiability.preview ? stLiability.preview : '', "ac_number": stLiability && stLiability.ac_number ? stLiability.ac_number : '', "glaccount_description": stLiability && stLiability.glaccount_description ? stLiability.glaccount_description : 0, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Interest Income', "assigned_glaccount": interest.preview, "ac_number": interest.ac_number, "glaccount_description": interest.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Lease Revenue' , "assigned_glaccount": amort.preview, "ac_number": amort.ac_number, "glaccount_description": amort.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Financing Variable Lease Revenue' , "assigned_glaccount": financing.preview, "ac_number": financing.ac_number, "glaccount_description": financing.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Modification Loss or Gain', "assigned_glaccount": mod.preview, "ac_number": mod.ac_number, "glaccount_description": mod.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Transition', "assigned_glaccount": transition.preview, "ac_number": transition.ac_number, "glaccount_description": transition.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }]
               : 
              [{ 'jecolumn': 'Payment Suspense Account Before Commencement' , "assigned_glaccount": payment.preview, "ac_number": payment.ac_number, "glaccount_description": payment.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Rent Deposit', "assigned_glaccount": rent.preview, "ac_number": rent.ac_number, "glaccount_description": rent.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Rent Payment Suspense Account', "assigned_glaccount": rentPayment.preview, "ac_number": rentPayment.ac_number, "glaccount_description": rentPayment.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn':  "ROU Asset", "assigned_glaccount": rou.preview, "ac_number": rou.ac_number, "glaccount_description": rou.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': "Lease Liability" , "assigned_glaccount": liability.preview, "ac_number": liability.ac_number, "glaccount_description": liability.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': "ST Lease Asset-Liability" , "assigned_glaccount": stLiability && stLiability.preview ? stLiability.preview : '', "ac_number": stLiability && stLiability.ac_number ? stLiability.ac_number : '', "glaccount_description": stLiability && stLiability.glaccount_description ? stLiability.glaccount_description : 0, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Rent Expense' , "assigned_glaccount": rentExpense.preview, "ac_number": rentExpense.ac_number, "glaccount_description": rentExpense.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Interest Expense', "assigned_glaccount": interest.preview, "ac_number": interest.ac_number, "glaccount_description": interest.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Amortization' , "assigned_glaccount": amort.preview, "ac_number": amort.ac_number, "glaccount_description": amort.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Operating Variable Lease Expense', "assigned_glaccount": operating.preview, "ac_number": operating.ac_number, "glaccount_description": operating.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Financing Variable Lease Expense', "assigned_glaccount": financing.preview, "ac_number": financing.ac_number, "glaccount_description": financing.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Modification Loss or Gain', "assigned_glaccount": mod.preview, "ac_number": mod.ac_number, "glaccount_description": mod.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Impairment', "assigned_glaccount": impairment.preview, "ac_number": impairment.ac_number, "glaccount_description": impairment.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, 
              { 'jecolumn': 'Transition', "assigned_glaccount": transition.preview, "ac_number": transition.ac_number, "glaccount_description": transition.glaccount_description, "allocation_number": '', "allocation_description": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }]
            setGlData(glValue)
          }
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  const columns = [
    {
      name: "jecolumn",
      label: "Financial statement line item",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '200px',height: '40px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '200px',height:'50px'}}),
        customBodyRenderLite:(dataIndex,rowIndex)=>(
          <div style={{fontSize:'13px',display:'flex',width:'200px', justifyContent:'space-between'}}>
            <div style={{ marginTop:'5px'}}>
              {glData && glData[dataIndex] && glData[dataIndex].jecolumn}
            </div>
            <div style={{display:'flex'}}>
              { 
                glData && glData[dataIndex] && glData[dataIndex].inputValue && 
                <>
                  <button type="button"
                    style={{ backgroundColor: 'transparent', borderColor: 'transparent', paddingLeft:'8px'}}
                    onClick={()=>{ setIndex(rowIndex);setDeleteRow(true)}}
                    > 
                    <i className="fas fa-minus-circle" style={{fontSize: '20px',color: "#CC0000" }}></i>
                  </button>
                </>
              }
              <button type="button"
              style={{ backgroundColor: 'transparent', borderColor: 'transparent', padding:glData && glData[dataIndex] && glData[dataIndex].inputValue ? '2px 2px 2px 0px' : '2px 2px 2px 8px'}}
              onClick={()=>{ setIndex(rowIndex);setEditRow(true)}}
              > 
              <i className="fas fa-plus-circle" style={{fontSize: '20px',color: "#38761D" }}></i>
              </button>
            </div>
          </div>
        ),
      }
    },
    {
      name: "assigned_glaccount",
      label: "Assigned G/L Account",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '200px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
        customBodyRenderLite:(dataIndex,rowIndex)=>{
        return <Editable value={glData && glData[dataIndex] && glData[dataIndex].assigned_glaccount} inputData={{preview:glData && glData[dataIndex] && glData[dataIndex].assigned_glaccount,ac_number:glData && glData[dataIndex] && glData[dataIndex].ac_number,glaccount_description:glData && glData[dataIndex] && glData[dataIndex].glaccount_description}} rowIndex={dataIndex} data={glData && glData[dataIndex]} setRowData={setRowData} setUpdateRow={setUpdateRow} column={'assigned_glaccount'} glAccount={glAccounts || []} allocationType={allocations || []} allocations={allocationData || []} key={Math.random()}/>
        }
      },
    },
    {
      name: "allocation_type",
      label: "Allocation Type",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '150px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
        customBodyRenderLite:(dataIndex,rowIndex)=>{
          return <Editable value={glData && glData[dataIndex] && glData[dataIndex].allocation_type} rowIndex={dataIndex} data={glData && glData[dataIndex]} setRowData={setRowData} setUpdateRow={setUpdateRow} column={'allocation_type'} glAccount={glAccounts || []} allocationType={allocations || []} allocations={allocationData || []} key={Math.random()}/>
        }
      },
    },
    {
      name: "allocations",
      label: "Allocations",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '250px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
        customBodyRenderLite:(dataIndex,rowIndex)=>{
          return <Editable value={glData && glData[dataIndex] && glData[dataIndex].allocations} inputData={{preview:glData && glData[dataIndex] && glData[dataIndex].allocations,allocation_number:glData && glData[dataIndex] && glData[dataIndex].allocation_number,allocation_description:glData && glData[dataIndex] && glData[dataIndex].allocation_description}} rowIndex={dataIndex} data={glData && glData[dataIndex]} setRowData={setRowData} setUpdateRow={setUpdateRow} column={'allocations'} glAccount={glAccounts || []} allocationType={allocations || []} allocations={allocationData || []} key={Math.random()}/>
        },
      }
    },
    {
      name: "allocation_percentage",
      label: "Allocation Percentage",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        customBodyRenderLite:(dataIndex,rowIndex)=>{
          let beforeData = {...glData[dataIndex - 1]}
          let afterData = {...glData[dataIndex + 1]}          
          let jecolumn = glData && glData[dataIndex] && glData[dataIndex].jecolumn
          if((beforeData && beforeData.jecolumn && beforeData.jecolumn == jecolumn) || (afterData && afterData.jecolumn && afterData.jecolumn == jecolumn)){
            return <Editable value={glData && glData[dataIndex] && glData[dataIndex].allocation_percentage} rowIndex={dataIndex} data={glData && glData[dataIndex]} setRowData={setRowData} setUpdateRow={setUpdateRow} column={'allocation_percentage'} key={Math.random()}/>
          } else{
            return ''
          }
        }, 
      }     
    },
  ]

  const saveGlmapping = async() => {
    setLoading(true)
    try{
      let values = {id,glData,mode}
      let paymentTotal = 0,rentTotal = 0,rentPaymentTotal = 0,rouTotal = 0,liabilityTotal = 0,expenseTotal = 0,transitionTotal = 0,interestTotal = 0,amortTotal = 0,operatingTotal = 0,financingTotal = 0,modTotal = 0,total = 0, stLiabilityTotal=0
      for(const i of glData){
        if(new RegExp(i.jecolumn, 'ig').test("Receipt Suspense Account Before Commencement") || new RegExp(i.jecolumn, 'ig').test("Payment Suspense Account Before Commencement")){
          paymentTotal = paymentTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test("Rent Deposit")){
          rentTotal = rentTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test("Rent Receipt Suspense Account") || new RegExp(i.jecolumn, 'ig').test("Rent Payment Suspense Account")){
          rentPaymentTotal = rentPaymentTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test('Deferred inflow of resources') || new RegExp(i.jecolumn, 'ig').test('ROU Asset') || new RegExp(i.jecolumn, 'ig').test('Rou Asset')){
          rouTotal = rouTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test("Receivable") || new RegExp(i.jecolumn, 'ig').test("Lease Liability")|| new RegExp(i.jecolumn, 'ig').test("Lease liability")){
          liabilityTotal = liabilityTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test("ST Lease Asset-Liability")){
          stLiabilityTotal = stLiabilityTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test("Rent Expense")){
          expenseTotal = expenseTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test('Interest Income') || new RegExp(i.jecolumn, 'ig').test('Interest Expense')){
          interestTotal = interestTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test('Lease Revenue') || new RegExp(i.jecolumn, 'ig').test('Amortization')){
          amortTotal = amortTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test('Operating Variable Lease Revenue') || new RegExp(i.jecolumn, 'ig').test('Operating Variable Lease Expense')){
          operatingTotal = operatingTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test('Financing Variable Lease Revenue') || new RegExp(i.jecolumn, 'ig').test('Financing Variable Lease Expense')){
          financingTotal = financingTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test("Modification Loss or Gain")){
          modTotal = modTotal + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test("Impairment")){
          total = total + Number(i.allocation_percentage || 0)
        }else if(new RegExp(i.jecolumn, 'ig').test("Transition")){
          transitionTotal = transitionTotal + Number(i.allocation_percentage || 0)
        }
      }
      if(paymentTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? "Receipt Suspense Account Before Commencement" : "Payment Suspense Account Before Commencement"} must be 100`);
      if(rentTotal != 100) throw new Error("Total of allocation percentage for Rent Deposit must be 100");
      if(rentPaymentTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? "Rent Receipt Suspense Account" : "Rent Payment Suspense Account"} must be 100`);
      if(rouTotal != 100) throw new Error(`Total of allocation percentage for ${ mode == 'lessor' ? 'Deferred inflow of resources' : 'ROU Asset'} must be 100`);
      if(liabilityTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? "Receivable" : "Lease Liability"} must be 100`);
      if(stLiabilityTotal != 100) throw new Error("Total of allocation percentage for ST Lease Asset-Liability must be 100");
      if(expenseTotal != 100 && mode != 'lessor') throw new Error("Total of allocation percentage for Rent Expense must be 100");
      if(interestTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? "Interest Income" : "Interest Expense"} must be 100`);
      if(amortTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? 'Lease Revenue' : 'Amortization'} must be 100`);
      if(operatingTotal != 100 && mode != 'lessor') throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? 'Operating Variable Lease Revenue' : 'Operating Variable Lease Expense'} must be 100`);
      if(financingTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'} must be 100`);
      if(modTotal != 100) throw new Error("Total of allocation percentage for Modification Loss or Gain must be 100");
      if(total != 100 && mode != 'lessor') throw new Error("Total of allocation percentage for Impairment must be 100");
      if(transitionTotal != 100) throw new Error("Total of allocation percentage for Transition must be 100");

      const {data} = await axios.post(`${AppConfig.baseUrl}/gl_template/update_gl_template`,values,{
          headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
          getGlData()
          toast.success(data.message || 'Template added successfully');          
      }
      else{
          throw new Error(data.title);
      }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  useEffect(()=>{
    if(editRow){
      let updatedData = [...glData]
      let data = {...updatedData[index]}
      data.inputValue = true
      updatedData.splice((index + 1),0, data)
      setGlData(updatedData)
      setEditRow(false)
    }
  },[editRow])

  useEffect(()=>{
    if(deleteRow){
      let updatedData = [...glData]
      let data = {...updatedData[index]}
      let filter = updatedData.filter(v => v.jecolumn == data.jecolumn)
      if(filter.length == 2){
        updatedData[index-1].allocation_type = ''
        updatedData[index-1].allocations = ''
        updatedData[index-1].allocation_percentage = 100
      }
      data.inputValue = false
      updatedData.splice(index,1)
      setGlData(updatedData)
      setDeleteRow(false)
    }
  },[deleteRow])

  useEffect(()=>{
    if(updateRow){
      const updatedData = [...glData]
      if(rowData.column == 'allocation_type'){
        updatedData[rowData.rowIndex][rowData.column] = rowData.inputValue.value
        updatedData[rowData.rowIndex].allocations = ''
        updatedData[rowData.rowIndex].allocation_number = ''
        updatedData[rowData.rowIndex].allocation_description = ''
      }else if (rowData.column == 'assigned_glaccount') {
        updatedData[rowData.rowIndex][rowData.column] = rowData.inputValue.value
        updatedData[rowData.rowIndex].ac_number = rowData.dataValue.ac_number
        updatedData[rowData.rowIndex].glaccount_description = rowData.dataValue.glaccount_description
      }else if (rowData.column == 'allocations') {
        updatedData[rowData.rowIndex][rowData.column] = rowData.inputValue.value
        updatedData[rowData.rowIndex].allocation_number = rowData.dataValue.allocation_number
        updatedData[rowData.rowIndex].allocation_description = rowData.dataValue.allocation_description
      }else{
        updatedData[rowData.rowIndex][rowData.column] = rowData.inputValue
      }
      setGlData(updatedData)
      setUpdateRow(false)
    }
  },[updateRow])

  return (
    <React.Fragment>
        <Modal
            size="xl"
            isOpen={glmapModal}
            toggle={() => {
                toggleModal();
            }}
        >
          <div className="modal-header">
              <h5
                  className="modal-title mt-0"
                  id="myLargeModalLabel"
              >
                  G/L Mapping
              </h5>
              <button
                  onClick={() => {
                    setGlmapModal(false);
                    setSelectedGroup({})
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
              >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col className="col-12 mt-4">
                {
                  loading ?
                    <div className="text-center"><Spinner color="primary" className="text-lbusers" /></div>
                  :
                  <ThemeProvider theme={createTheme({components:getMuiTheme()})}>
                    <MUIDataTable
                      title=""
                      data={glData}
                      columns={columns}
                      options={{ 
                        rowsPerPage:10, 
                        print:false, 
                        download:false, 
                        filter:false, 
                        viewColumns: false,
                        selectableRows:"none", 
                        search:false, 
                        pagination:false,
                        rowsPerPageOptions:[], 
                        responsive:'scroll',
                        textLabels: {
                          viewColumns: {
                            title: "",
                          },
                        } 
                      }}
                    />
                  </ThemeProvider>
                }
              </Col>
            </Row>
            <Row className="row mt-3">
              <Col md='3'>
                  <button
                      type="button"
                      className="btn btn-lbusers"
                      onClick={saveGlmapping}
                      disabled={loading}
                  >
                      SAVE
                  </button>
              </Col>
            </Row>
          </div>
        </Modal>
  </React.Fragment>
  )
}

const Editable = (prop) => {
  const {value, rowIndex, data, column, glAccount, setRowData, setUpdateRow, allocations, allocationType, inputData } = prop;
  const [inputValue, setInputValue] = useState(column == "allocation_percentage" ? value : {label: value, value: value});
  const [allocationData, setAllocationData] = useState([]);
  const [glaccountOptions, setGlaccountOptions] = useState([])
  const [allocationTypeOptions, setAllocationTypeOptions] = useState([])
  const [allocationOptions, setAllocationOptions] = useState([])
  const [dataValue, setDataValue] = useState(inputData)
  const [isValid, setIsValid] = useState(true)

  const handleInputChange = (e) => {
    if(column != 'allocation_percentage')setDataValue(e.data)
    setInputValue(column == "allocation_percentage" ? e.target.value : e);
  };

  const handleBlur = () => {
    setRowData({rowIndex, column, inputValue, dataValue})
    setUpdateRow(true)
  };

  useEffect(()=>{
    if(data && data.allocation_type && allocationType){
      const value = allocationType.filter(a => a.title == data.allocation_type)
      const allocation = allocations.filter(a => a.allocation_id == value[0]._id)
      setAllocationData(allocation)
    }
  },[data,allocationType])

  useEffect(()=>{
    if(allocationType){
      let alc = [{value:'',label:'Select allocation type'}]
      allocationType.map(a => alc.push({value:a.title,label:a.title}))
      setAllocationTypeOptions(alc)
    }
  },[allocationType])

  useEffect(()=>{
    if(allocationData){
      let data = [{value:'',label:'Select allocation',data:{preview:'',allocation_number:'',allocation_description:''}}]
      allocationData.map(a => data.push({value:a.preview,label:a.preview,data:{preview:a.preview,allocation_number:a.allocation_number,allocation_description:a.allocation_description}}))
      setAllocationOptions(data)
    }
  },[allocationData])

  useEffect(()=>{
    if(glAccount && glAccount.length > 0){
      let value = [{label:'',options:[{label:'Select G/L account',value:'',data:{preview:'',ac_number:'',glaccount_description:''}}]}]
      for(const i of glAccount){        
          const data = glAccount.filter(v =>v.jecolumn == i.jecolumn)
          const filter = value.filter(v => v.label == i.jecolumn)
          if(filter.length == 0) value.push({label:i.jecolumn,options:data.map(v =>{ return{label:v.preview,value:v.preview,data:{preview:v.preview,ac_number:v.ac_number,glaccount_description:v.glaccount_description}}})})
      }
      setGlaccountOptions(value)
    }
  },[glAccount])

  useEffect(() => {
    if (glaccountOptions && glaccountOptions.length > 0 && column == 'assigned_glaccount') {
      let isPresent = false
      for (const group of glaccountOptions) {
        for (const option of group.options) {
          if (option.value === inputValue.value || !inputValue.value) {
            isPresent = true
          }
        }
      }
      setIsValid(isPresent);
    }else if((!glaccountOptions || glaccountOptions.length < 1 )&& column == 'assigned_glaccount'){
      setIsValid(true);
    }
  }, [glaccountOptions, inputValue])

  useEffect(() => {
    if (allocationTypeOptions && allocationTypeOptions.length > 0 && column == 'allocation_type') {
      let isPresent = false
      for (const allocation of allocationTypeOptions) {
        if (allocation.value === inputValue.value || !inputValue.value) {
          isPresent = true
        }
      }
      setIsValid(isPresent);
    }else if((!allocationTypeOptions || allocationTypeOptions.length < 1 )&& column == 'allocation_type'){
      setIsValid(true);
    }
  }, [allocationTypeOptions, inputValue])

  useEffect(() => {
    if (allocationOptions && allocationOptions.length > 0 && column == 'allocations') {
      let isPresent = false
      for (const allocation of allocationOptions) {
        if (allocation.value === inputValue.value || !inputValue.value) {
          isPresent = true
        }
      }
      setIsValid(isPresent);
    }else if((!allocationOptions || allocationOptions.length < 1 )&& column == 'allocations'){
      if(inputValue && inputValue.value){
        setIsValid(false);
      }else{
        setIsValid(true);
      }
    }
  }, [allocationOptions, inputValue])

  return (
    <>
    {
      column == 'assigned_glaccount' ? 
      <Select
        name="assigned_glaccount"
        value={inputValue}
        options={glaccountOptions}
        onChange={handleInputChange} 
        onBlur={handleBlur}
        isValid={isValid}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (baseStyles, state) => {
            return ({
              ...baseStyles,
              borderColor: state.selectProps && !state.selectProps.isValid ? 'rgb(0, 86, 145)' : 'hsl(0, 0%, 80%)',
            })
          }
        }}
        menuPortalTarget={document.body}
        />
      : column == 'allocation_type' ? 
        <Select
        name="allocation_type"
        value={inputValue}
        options={allocationTypeOptions}
        onChange={handleInputChange} 
        onBlur={handleBlur}
        isValid={isValid}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (baseStyles, state) => {
            return ({
              ...baseStyles,
              borderColor: state.selectProps && !state.selectProps.isValid ? 'rgb(0, 86, 145)' : 'hsl(0, 0%, 80%)',
            })
          }
        }}
        menuPortalTarget={document.body}
      />
      : column == 'allocations' ? 
        <Select
        name="allocation"
        value={inputValue}
        options={allocationOptions}
        onChange={handleInputChange} 
        onBlur={handleBlur}
        isValid={isValid}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (baseStyles, state) => {
            return ({
              ...baseStyles,
              borderColor: state.selectProps && !state.selectProps.isValid ? 'rgb(0, 86, 145)' : 'hsl(0, 0%, 80%)',
            })
          }
        }}
        menuPortalTarget={document.body}
      />
      :
      <input
        type="number"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        className={`form-control`} 
        style={{width:'100px'}}
      />
    }
    </>
  );
}

export default Template
