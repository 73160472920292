import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { Link, useLocation } from "react-router-dom"
import classname from "classnames";

const SidebarContent = props => {
  const location = useLocation();
  const ref = useRef();
  useEffect(() => {
    const pathName = location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        const pathArr = items[i].pathname.split('/');
        if (pathName.includes(pathArr[2])) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100 sidebar-bg-color" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li>
              <Link to="/dashboard">
                <i className="bx bxs-dashboard"></i>
                <span className="sidebar-menu-font">Dashboard</span>
              </Link>
            </li> */}
            <li>
              <Link to={`/${localStorage.getItem('company')}/quickaccess`}>
                <i className="bx bxs-zap"></i>
                <span className="sidebar-menu-font">Quick Access</span>
              </Link>
            </li>
            <li>
              <Link to={`/${localStorage.getItem('company')}/clients`}>
                <i className="bx bxs-user-badge"></i>
                <span className="sidebar-menu-font">My Clients</span>
              </Link>
            </li>
            <li>
              <Link to={`/${localStorage.getItem('company')}/leases`}>
                <i className="bx bx-file"></i>
                <span className="sidebar-menu-font">Leases</span>
              </Link>
            </li>
            <li>
              <Link to="/#">
                <i className="bx bxs-book"></i>
                <span className="sidebar-menu-font">Journal Entries</span>
                <div className="arrow-down"></div>
              </Link>
              <ul>
                <li>
                  <Link to={`/${localStorage.getItem('company')}/journal`} onClick={() => window.location.href = `/${localStorage.getItem('company')}/journal`}>
                  <span className="sidebar-menu-font">FSLI - Column view</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/${localStorage.getItem('company')}/gl_mapped`} onClick={() => window.location.href = `/${localStorage.getItem('company')}/gl_mapped`}>
                  <span className="sidebar-menu-font">G/L mapped Dr / Cr View</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to={`/${localStorage.getItem('company')}/disclosures`} onClick={() => window.location.href = `/${localStorage.getItem('company')}/disclosures`}>
                <i className="bx bx-folder-open"></i>
                <span className="sidebar-menu-font">Disclosures</span>
              </Link>
            </li>
            <li>
              <Link to={`/${localStorage.getItem('company')}/reports`} onClick={() => window.location.href = `/${localStorage.getItem('company')}/reports`}>
                <i className="bx bxs-report"></i>
                <span className="sidebar-menu-font">Reports</span>
              </Link>
            </li>
            <li>
              <Link to={`/${localStorage.getItem('company')}/bulk_upload`}>
              <i className='bx bx-upload'></i>
                <span className="sidebar-menu-font">Bulk Upload</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default SidebarContent
