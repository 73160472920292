import React, { useState, useEffect, useContext } from "react"
import { Card, CardBody, Col, Modal, Table, Input, Label, InputGroup, Row, TabPane, NavLink, NavItem, TabContent, Nav, Form, Spinner } from "reactstrap"
import classnames from "classnames";
import "../../assets/scss/manual/manual.scss";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { FieldArray, FormikProvider } from 'formik';
import { string, number, array, object } from "yup";
import { useFormik } from "formik";
import toast, { useToaster } from 'react-hot-toast';
import moment from 'moment';
import axios from "axios";
import AppConfig from 'constants/config';
import { AuthContext } from "context/authContext";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import { useLocation, useParams } from "react-router-dom"
import { commaSeperated } from "helpers/formatters";

const AddVariablePayment = (prop) => {
    const params = useParams();
    const location = useLocation();
    const queryString = location.search;
    const sp = new URLSearchParams(queryString);
    const { variablePaymentOpen, toggleVariablePayment, setVariablePaymentOpen, path, validation, save, type, variablePayments, setVariablePayments, hitApi, lease_id, changeVariablePayments, tab } = prop;
    const [activeTab, setActiveTab] = useState(type == 'step3' && path == 'add' ? "2" : "1");
    const [totalVal, setTotalVal] = useState('');
    const [loading, setLoading] = useState(false)
    const [leaseDraftData, setLeaseDraftData] = useState({});
    const { mode,reloadProcessingStatus, userData } = useContext(AuthContext);
    const [tableData, setTableData] = useState([])
    const [remainingData, setRemainingData] = useState([])
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState('')
    const [process, setProcess] = useState('')
    const [dateFilterType, setDateFilterType] = useState('date')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [variableCategories, setvariableCategories] = useState([])
    const [paymentsUpdated, setPaymentsUpdated] = useState(false)

    useEffect(() => {
        if (variablePayments && variablePayments.length > 0) {
            const cat = [];
            for (const val of variablePayments) {
                if (!cat.includes(val.category)) {
                    cat.push(val.category)
                }
            }
            setCategories(cat)
        } else {
            setCategories([])
        }
    }, [variablePayments])

    const getDraftData = async() =>{
        setLoading(true)
        let { data } = await axios.get(`${AppConfig.baseUrl}/lease_draft/get_draft?id=${lease_id}&mode=${mode ? mode : 'lessee'}&customer_id=${userData.cust_info._id}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if (data && !data.error) {
            setLeaseDraftData(data.data.lease)
            let payments = []
            if (type == 'step3' && variablePayments && variablePayments.length > 0) {
                payments = variablePayments.filter(e => moment(e.date).isSameOrAfter(validation.values.commencementDate) && moment(e.date).isSameOrBefore(validation.values.lastExtensionDate))
            } else if (type == 'step3' && data.data && data.data.lease && data.data.lease.oldVariablePreview && data.data.lease.oldVariablePreview.length > 0) {
                payments = data.data.lease.oldVariablePreview.filter(e => moment(e.date).isSameOrAfter(validation.values.commencementDate) && moment(e.date).isSameOrBefore(validation.values.lastExtensionDate))
            } else if (data.data && data.data.lease && data.data.lease.oldVariablePreview && data.data.lease.oldVariablePreview.length > 0) {
                payments = data.data.lease.oldVariablePreview
            } else {
                payments = []
            }
            let newPayments = []
            for (let payment of payments) {
                const pay = { ...payment, old_processed: payment.is_processed }
                newPayments.push(pay)
            }

            setVariablePayments(newPayments)
            setTableData(newPayments)
            setRemainingData([])
            if (type == 'step3' && tab == 3 && !paymentsUpdated) {
                setPaymentsUpdated(true)
                changeVariablePayments(newPayments)
            }
            let val = newPayments.reduce((acc, current) => acc + Number(current.amount), 0)
            setTotalVal(val)
        } else {
            toast.error(data.title);
        }
        setLoading(false)
    }

    useEffect(() => {
        if (lease_id && (variablePaymentOpen || (type == 'step3' && tab == 3))) {
            getDraftData()
        }
    }, [lease_id, variablePaymentOpen, type, tab, validation && validation.values && validation.values.commencementDate, validation && validation.values && validation.values.lastExtensionDate])

    useEffect(() => {
        if (tab < 3 && paymentsUpdated) {
            setPaymentsUpdated(false)
        }
    }, [validation && validation.values && validation.values.commencementDate, validation && validation.values && validation.values.lastExtensionDate])

    const getVariableCategories = async() =>{
        const client_id = type == 'step3' && (path == 'clone' || path == 'edit') ? sp.get('client_id') : type == 'step3' && path == 'add' ? params.id : leaseDraftData && leaseDraftData.clientId ? leaseDraftData.clientId : ''
        if (client_id) {
            setLoading(true)
            let { data } = await axios.get(`${AppConfig.baseUrl}/lease_draft/variable_categories?client_id=${client_id}&mode=${mode ? mode : 'lessee'}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            setvariableCategories(data.categoryData || [])
            setLoading(false)
        } else {
            setvariableCategories([])
        }
    }

    useEffect(() => {
        if (variablePaymentOpen) {
            getVariableCategories()
        }
    }, [leaseDraftData, sp.get('client_id'), path, variablePaymentOpen])

    const handleSubmit = async (values) => {
        setLoading(true)
        if (type == 'step3') {
            validation.setFieldValue(`variableLeasePayments`, values.variableLeasePayments)
            await save(values, 'Variable lease payments')
            handleClear()
        } else {
            try {
                let newArr = []
                let wrongDate = false
                let wrongCategory = false
                let wrongNewCategory = false
                let newCategories = []
                newArr = leaseDraftData && leaseDraftData.oldVariablePreview && leaseDraftData.oldVariablePreview.length > 0 ? leaseDraftData.oldVariablePreview : []
                const commenceDate = moment(type == 'step3' ? validation.values.commencementDate : leaseDraftData.commencementDate, "MM/DD/YYYY").format("YYYY-MM-DD")
                const terminalDate = moment(type == 'step3' ? validation.values.lastExtensionDate : leaseDraftData.lastExtensionDate ? leaseDraftData.lastExtensionDate : leaseDraftData.terminalLeaseEndDate, "MM/DD/YYYY").format("YYYY-MM-DD")
                values.variableLeasePayments.map((d, i) => {

                    if (d.category == 'new_category') {
                        let category = (d.new_category).trim()
                        if(category.length > 0){
                            newCategories.push(d.new_category)
                        }else{
                            wrongNewCategory = true
                        }
                    }
                    if (!d.category || (d.category == 'new_category' && !d.new_category)) {
                        wrongCategory = true
                    }
                    if ((d.frequency == 'One-time' && moment(d.paymentDate).isSameOrAfter(moment(commenceDate)) && moment(d.paymentDate).isSameOrBefore(moment(terminalDate))) || (d.frequency != 'One-time' && moment(d.paymentDate).isSameOrAfter(moment(commenceDate)) && moment(d.paymentDate).isSameOrBefore(moment(terminalDate)) && moment(d.paymentEndDate).isSameOrAfter(moment(commenceDate)) && moment(d.paymentEndDate).isSameOrBefore(moment(terminalDate)))) {
                        let obj1 = {}
                        let type = (d.frequency == 'Monthly' || d.frequency == 'Quarterly' || d.frequency == 'Semi-annual')
                            ? 'months' : 'years';
                        let add = d.frequency == 'Monthly' ? 1 : d.frequency == 'Quarterly' ? 3 : d.frequency == 'Semi-annual' ? 6 : 1;
                        if (d.frequency == 'One-time') {
                            obj1.category = d.category && d.category == 'new_category' ? d.new_category : d.category;
                            obj1.amount = d.amount;
                            obj1.date = d.paymentDate instanceof Array ? moment(d.paymentDate[0]).format('YYYY-MM-DD') : moment(d.paymentDate).format('YYYY-MM-DD');
                            obj1.type = 'Variable lease payments';
                            obj1.actual_amount = d.amount;
                            obj1.is_processed = false
                            if (Object.keys(obj1).length > 0) newArr.push(obj1);
                        } else {
                            let start = d.paymentDate instanceof Array ? moment(new Date(d.paymentDate[0])).format('') : moment(d.paymentDate).format('');
                            let end = d.paymentEndDate instanceof Array ? moment(d.paymentEndDate[0]).format('') : moment(d.paymentEndDate).format('');
                            let newEnd = moment(end);

                            let days = newEnd.diff(start, type);
                            let thisYear = moment(start).format('YYYY')
                            let startMonth = moment(start).month() + 1;
                            let count = 0;
                            let compoundAmount = Number(d.amount);
                            for (let a = 0; a <= days; a++) {
                                let obj2 = {};
                                if (new Date(moment(start).format('YYYY-MM-DD')).getTime() <= new Date(moment(newEnd).format('YYYY-MM-DD')).getTime()) {
                                    obj2.date = moment(start).format('YYYY-MM-DD')
                                    obj2.type = 'Variable lease payments';
                                    obj2.category = d.category && d.category == 'new_category' ? d.new_category : d.category;
                                    obj2.is_processed = false
                                    let yearVal = moment(start).year() - Number(thisYear)
                                    let currentMonth = moment(start).month() + 1;
                                    if (currentMonth == startMonth && a != 0) {
                                        count += 1;
                                        if (Number(d.annualFixedAmountPer) > 0) {
                                            compoundAmount = Number(compoundAmount) + ((Number(compoundAmount)) * (Number(d.annualFixedAmountPer) / 100));
                                        }
                                    }
                                    if (Number(d.annualFixedAmount) > 0) {
                                        if (count > 0) {
                                            obj2.amount = Number(d.amount) + (Number(d.annualFixedAmount) * (count));
                                        } else {
                                            obj2.amount = Number(d.amount);
                                        }
                                    } else if (Number(d.annualFixedAmountPer) > 0) {
                                        if (count > 0) {
                                            obj2.amount = compoundAmount;
                                            // compoundAmount + ((compoundAmount * (count)) * (Number(d.annualFixedAmountPer)/100));
                                        } else {
                                            obj2.amount = Number(d.amount);
                                        }
                                    } else {
                                        obj2.amount = Number(d.amount)
                                    }
                                    obj2.actual_amount = obj2.amount
                                    if (Object.keys(obj2).length > 0) newArr.push(obj2);
                                    if (String(moment(start).format('MM/DD/YYYY')) == String(moment(start).endOf('month').format('MM/DD/YYYY'))) {
                                        start = moment(start).add(add, type).endOf('month')
                                    }
                                    else {
                                        start = moment(start).add(add, type)
                                    }
                                }
                            }
                        }
                    } else {
                        wrongDate = true
                    }
                })
                if (wrongDate) {
                    setLoading(false)
                    return toast.error('Please select valid dates')
                }
                if (wrongCategory) {
                    setLoading(false)
                    return toast.error('Please select valid category')
                }
                if (wrongNewCategory) {
                    setLoading(false)
                    return toast.error('Please enter valid new category')
                }
                let data = newArr.sort(function (a, b) {
                    return new Date(a.date) - new Date(b.date);
                });
                let variablePreview = [];
                data.map(value => {
                    if (moment(value.date).isSameOrBefore(moment(terminalDate))) {
                        let filter = data.filter(e => {
                            if (new Date(e.date).getTime() == new Date(value.date).getTime()) {
                                return e
                            }
                        })
                        if (filter.length > 0) {
                            let type = filter.map(val => val.type).join(', ')
                            let amt = filter.reduce((acc, curr) => acc + Number(curr.amount), 0);
                            let actual_amount = filter.reduce((acc, curr) => acc + Number(curr.actual_amount || 0), 0);
                            let index = variablePreview.findIndex(e => new Date(e.date).getTime() == new Date(value.date).getTime());
                            if (index > -1) {
                                variablePreview.splice(index, 0)
                            } else {
                                variablePreview.push({ type: type, amount: amt, date: value.date, actual_amount })
                            }
                        } else if (filter.length == 0) {
                            variablePreview.push({ type: value.type, amount: Number(value.amount), date: value.date, actual_amount: 0 })
                        }
                    }
                })

                let oldVariablePreview = data.filter(e => e.type == 'Variable lease payments')

                let variableLeasePayments = leaseDraftData && leaseDraftData.variableLeasePayments ? leaseDraftData.variableLeasePayments : []
                variableLeasePayments = [...variableLeasePayments, ...values.variableLeasePayments]

                let newData = { variablePreview, oldVariablePreview, lease_id, mode: leaseDraftData.mode, variableLeasePayments, newCategories, client_id: type == 'step3' ? sp.get('client_id') : leaseDraftData && leaseDraftData.clientId ? leaseDraftData.clientId : '' }
                let { data: value } = await axios.post(`${AppConfig.baseUrl}/lease_draft/add_variable_payment`, newData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    }
                })
                if (!value.error) {
                    hitApi()
                    toast.success('Variable payment added successfully');
                    setVariablePaymentOpen(false);
                    handleClear();
                    reloadProcessingStatus()
                    formik.resetForm()
                } else {
                    toast.error(value.title);
                }
            } catch (error) {
                console.log("Error in adding variable payments", error)
            }

        }
        setLoading(false)
    }

    const submitPayments = async () => {
        setLoading(true)
        const allPayments = [...variablePayments].filter(v => !v.deletable)
        const sortedPayments = allPayments.sort((a, b) => (new Date(a.date) - new Date(b.date)))
        let data = sortedPayments.filter(v => v.is_processed && !v.actual_amount)
        if (data.length > 0) {
            setLoading(false)
            return
        }
        setVariablePayments(sortedPayments)
        if (type == 'step3') {
            await changeVariablePayments(sortedPayments)
            if (formik.values.variableLeasePayments[0].amount) {
                await save(formik.values, 'Variable lease payments')
            } else {
                toast.success('Data saved successfully');
                setVariablePaymentOpen(false);
            }
            handleClear()
        } else {
            let variablePaymentData = [];
            sortedPayments.map(value => {
                if (moment(value.date).isSameOrBefore(moment(moment(leaseDraftData.lastExtensionDate ? leaseDraftData.lastExtensionDate : leaseDraftData.terminalLeaseEndDate, "MM/DD/YYYY").format('YYYY-MM-DD')))) {
                    let filter = sortedPayments.filter(e => {
                        if (new Date(e.date).getTime() == new Date(value.date).getTime()) {
                            return e
                        }
                    })
                    if (filter.length > 0) {
                        let type = filter.map(val => val.type).join(', ')
                        let amt = filter.reduce((acc, curr) => acc + Number(curr.amount), 0);
                        let actual_amount = filter.reduce((acc, curr) => acc + Number(curr.actual_amount || 0), 0);
                        let index = variablePaymentData.findIndex(e => new Date(e.date).getTime() == new Date(value.date).getTime());
                        if (index > -1) {
                            variablePaymentData.splice(index, 0)
                        } else {
                            variablePaymentData.push({ type: type, amount: amt, date: value.date, actual_amount })
                        }
                    } else if (filter.length == 0) {
                        variablePaymentData.push({ type: value.type, amount: Number(value.amount), date: value.date, actual_amount: 0 })
                    }
                }
            })

            let newData = { variablePreview: variablePaymentData, oldVariablePreview: sortedPayments, mode: leaseDraftData.mode, lease_id }
            let { data: value } = await axios.post(`${AppConfig.baseUrl}/lease_draft/add_variable_paymentJE`, newData, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (!value.error) {
                hitApi()
                toast.success("Variable payment updated successfully");
                setVariablePaymentOpen(false);
                handleClear();
                reloadProcessingStatus()
            } else {
                toast.error(value.title);
            }
        }
        setLoading(false)
    }

    const checkVariableDates = (type, e, key) => {
        if (type == 'start') {
            formik.setFieldValue(`variableLeasePayments[${key}].paymentDate`, moment(e[0]).format('MM/DD/YYYY'))
            formik.setFieldValue(`variableLeasePayments[${key}].paymentEndDate`, formik.values.variableLeasePayments[key].frequency == 'One-time' ? '' : moment(e[0]).add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY'))
            let endDate = formik.values.variableLeasePayments[key].frequency == 'One-time' ? moment(e[0]).format('MM/DD/YYYY') : moment(e[0]).add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY')
            formik.values.variableLeasePayments.map((v, i) => {
                if (i > key) {
                    formik.setFieldValue(`variableLeasePayments[${i}].paymentDate`, moment(endDate).add(1, 'day').format('MM/DD/YYYY'))
                    formik.setFieldValue(`variableLeasePayments[${i}].paymentEndDate`, formik.values.variableLeasePayments[i].frequency == 'One-time' ? '' : moment(endDate).add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY'))
                    endDate = formik.values.variableLeasePayments[i].frequency == 'One-time' ? moment(endDate).add(1, 'day') : moment(endDate).add(12, 'months')
                }
            })
        } else if (type == 'frequency') {
            formik.setFieldValue(`variableLeasePayments[${key}].frequency`, e.target.value)
            let endDate = e.target.value == 'One-time' ? moment(formik.values.variableLeasePayments[key].paymentDate) : moment(formik.values.variableLeasePayments[key].paymentDate).add(12, 'months').subtract(1, 'day')
            formik.values.variableLeasePayments.map((v, i) => {
                if (i > key) {
                    formik.setFieldValue(`variableLeasePayments[${i}].paymentDate`, endDate.add(1, 'day').format('MM/DD/YYYY'))
                    formik.setFieldValue(`variableLeasePayments[${i}].paymentEndDate`, formik.values.variableLeasePayments[i].frequency == 'One-time' ? '' : endDate.add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY'))
                    endDate = formik.values.variableLeasePayments[i].frequency == 'One-time' ? endDate.add(1, 'day') : endDate.add(12, 'months')
                }
            })
        }
        else if (type == 'end') {
            formik.setFieldValue(`variableLeasePayments[${key}].paymentEndDate`, moment(e[0]).format('MM/DD/YYYY'))
            let endDate = moment(e[0])
            formik.values.variableLeasePayments.map((v, i) => {
                if (i > key) {
                    formik.setFieldValue(`variableLeasePayments[${i}].paymentDate`, endDate.add(1, 'day').format('MM/DD/YYYY'))
                    formik.setFieldValue(`variableLeasePayments[${i}].paymentEndDate`, formik.values.variableLeasePayments[i].frequency == 'One-time' ? '' : endDate.add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY'))
                    endDate = formik.values.variableLeasePayments[i].frequency == 'One-time' ? endDate.add(1, 'day') : endDate.add(12, 'months')
                }
            })
        }
    }

    const setEndDate = (val, key, index) => {
        let type = (val == 'Monthly' || val == 'Quarterly' || val == 'Semi-annual')
            ? 'months' : 'years';
        let add = (val == 'Monthly' || val == 'Quarterly' || val == 'Semi-annual') ? 12 : 1;
        let a;
        if (key == 'paymentDate') {
            a = moment(new Date(val)).add(add, type).subtract(1, 'day').format('MM/DD/YYYY')
        } else {
            a = moment(new Date(formik.values.variableLeasePayments[index].paymentDate)).add(add, type).subtract(1, 'day').format('MM/DD/YYYY')
        }
        formik.setFieldValue(`variableLeasePayments[${index}].paymentEndDate`, a)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            variableLeasePayments: [{
                category: 'new_category',
                new_category: '',
                amount: 0,
                frequency: 'Monthly',
                paymentDue: 'Beginning of the period',
                paymentDate: '',
                paymentEndDate: '',
                annualFixedAmountPer: 0,
                annualFixedAmount: 0
            }]
        },
        validationSchema: object().shape({
            variableLeasePayments: array().of(object().shape({
                category: string().required("Please enter category"),
                amount: number().min(0, 'Must be greater than 0'),
                annualFixedAmountPer: number().min(0, 'Must be greater than 0').max(100, 'Must be less than 100'),
                annualFixedAmount: number().min(0, 'Must be greater than 0')
            }))
        }),
        onSubmit: handleSubmit
    });

    const checkAll = (type) => {
        const data = tableData.filter(v => type == "delete" ? v.deletable : v.is_processed)
        return data.length == tableData.length
    }

    const filterPayments = () => {
        if ((fromDate && !toDate) || (toDate && !fromDate)) {
            toast.error(`Please select ${fromDate ? 'to date' : 'from date'} also`);
        }
        let finalData = [...variablePayments]
        let remData = []
        if (category) {
            finalData = finalData.filter(v => {
                if (v.category == category) {
                    return true
                } else {
                    remData.push(v)
                    return false;
                }
            })
        }
        if (process) {
            finalData = finalData.filter(v => {
                if (v.old_processed == (process == 'processed')) {
                    return true
                } else {
                    remData.push(v)
                    return false
                }
            })
        }
        if (fromDate && toDate) {
            finalData = finalData.filter(v => {
                if ((moment(v.date).isSameOrAfter(moment(fromDate, 'MM/DD/YYYY')) && moment(v.date).isSameOrBefore(moment(toDate, "MM/DD/YYYY")))) {
                    return true
                } else {
                    remData.push(v)
                    return false
                }
            })
        }
        const sortedRemData = remData.sort((a, b) => (new Date(a.date) - new Date(b.date)))
        const sortedTableData = finalData.sort((a, b) => (new Date(a.date) - new Date(b.date)))
        setRemainingData(sortedRemData)
        setTableData(sortedTableData)
    }

    const handleClear = () => {
        const allPayments = [...variablePayments].map(v => {
            v.deletable = false
            return v
        })
        const sortedPayments = [...allPayments].sort((a, b) => (new Date(a.date) - new Date(b.date)))
        setTableData(sortedPayments);
        setRemainingData([])
        setCategory('')
        setProcess('')
        setFromDate('')
        setToDate('')
        setDateFilterType('date')
        setActiveTab(path == 'add' ? "2" : "1")
        if (type != 'step3') {
            formik.setFieldValue("variableLeasePayments", [{
                category: 'new_category',
                new_category: '',
                amount: 0,
                frequency: 'Monthly',
                paymentDue: 'Beginning of the period',
                paymentDate: '',
                paymentEndDate: '',
                annualFixedAmountPer: 0,
                annualFixedAmount: 0
            }])
        }
    }

    const tableToCSV = () => {
        let csv_data = [];
        csv_data.push(' Date, Category,Estimated Amount, Actual Amount, Processed')
        for (const s of tableData) {
            csv_data.push(`\"${moment(s.date).format('MM/DD/YYYY')}\",${s.category ? s.category : ''},${s.amount},${s.is_processed ? s.actual_amount : ''},${s.is_processed}`)
        }
        csv_data = csv_data.join('\n');
        downloadCSVFile(csv_data);
    }

    const downloadCSVFile = (csv_data) => {
        let CSVFile = new Blob([csv_data], {
            type: "text/csv"
        });
        var temp_link = document.createElement('a');
        temp_link.download = "variable_payments.csv";
        var url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;
        temp_link.style.display = "none";
        document.body.appendChild(temp_link);
        temp_link.click();
        document.body.removeChild(temp_link);
    }

    return (
        <React.Fragment>
            <Modal
                size="xl"
                isOpen={variablePaymentOpen}
                toggle={() => {
                    handleClear();
                    toggleVariablePayment();
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        Variable Lease Payment (experiment)
                    </h5>
                    <button
                        onClick={() => {
                            setVariablePaymentOpen(false);
                            handleClear();
                            setActiveTab('1')
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Card>
                        <h4 className="font-size-18 mb-0" style={{ textAlign: 'right' }}>{`${moment(new Date(type == 'step3' && validation ? validation.values.commencementDate : leaseDraftData.commencementDate)).format('MM/DD/YYYY')} to ${type == 'step3' && validation ? moment(new Date(Number(validation.values.howLong) >= 0 ? validation.values.terminalLeaseEndDate : validation.values.commencementDate)).format('MM/DD/YYYY') : moment(new Date(Number(leaseDraftData.howLong) >= 0 ? leaseDraftData.lastExtensionDate ? leaseDraftData.lastExtensionDate : leaseDraftData.terminalLeaseEndDate : leaseDraftData.commencementDate)).format('MM/DD/YYYY')}`}</h4>
                        <CardBody>
                            <Row>
                                <Nav tabs>
                                    {path != 'add' &&
                                        <NavItem style={{ textAlign: 'left' }}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab === "1",
                                                })}
                                                onClick={() => {
                                                    setActiveTab("1");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block font-size-16">View Payments</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                    <NavItem style={{ textAlign: 'left' }}>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                setActiveTab("2");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                            </span>
                                            <span className="d-none d-sm-block font-size-16">Add Variable Payments</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent
                                    activeTab={activeTab}
                                    className="text-muted"
                                >
                                    <TabPane tabId="1">
                                        <div className="mt-4 ms-3 me-3">
                                            <Row>
                                                <Col md="4">
                                                    <select value={category} className="form-control arrows" onChange={(e) => setCategory(e.target.value)}>
                                                        <option value=''>Filter by category</option>
                                                        {
                                                            categories.map(v => <option key={v} value={v}>{v}</option>)
                                                        }
                                                    </select>
                                                </Col>
                                                <Col md="4">
                                                    <select value={process} className="form-control arrows" onChange={(e) => setProcess(e.target.value)}>
                                                        <option value=''>Show all</option>
                                                        <option value='processed'>Show processed</option>
                                                        <option value='unprocessed'>Show unprocessed</option>
                                                    </select>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <select value={dateFilterType} className="form-control arrows mt-2" onChange={(e) => { setFromDate(''); setToDate(''); setDateFilterType(e.target.value); }}>
                                                        <option value='date'>Filter by date range</option>
                                                        <option value='month'>Filter by month</option>
                                                    </select>
                                                </Col>
                                                <Col md="4">
                                                    {
                                                        dateFilterType == 'month' &&
                                                        <InputGroup>
                                                            <Flatpickr
                                                                onClose={([date]) => {
                                                                    if (date) {
                                                                        setFromDate(moment(date).startOf('M').format('MM/DD/YYYY'));
                                                                        setToDate(moment(date).endOf('M').format('MM/DD/YYYY'));
                                                                    }
                                                                    else {
                                                                        setFromDate('');
                                                                        setToDate('')
                                                                    }
                                                                }}
                                                                value={fromDate ? moment(fromDate, 'MM/DD/YYYY').format('MMMM YYYY') : ''}
                                                                className="form-control d-block bg-white mt-2"
                                                                placeholder="From month & year"
                                                                options={{
                                                                    plugins: [
                                                                        new monthSelectPlugin({
                                                                            shorthand: true,
                                                                            dateFormat: "F Y",
                                                                            altFormat: "F Y",
                                                                        })
                                                                    ],
                                                                    minDate: new Date(type == 'step3' ? validation.values.commencementDate : leaseDraftData.commencementDate),
                                                                    maxDate: new Date(type == 'step3' ? validation.values.lastExtensionDate : leaseDraftData.lastExtensionDate ? leaseDraftData.lastExtensionDate : leaseDraftData.terminalLeaseEndDate),
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-secondary docs-datepicker-trigger mt-2"
                                                                    disabled
                                                                >
                                                                    <i
                                                                        className="fa fa-calendar"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </InputGroup>
                                                    }
                                                    {
                                                        dateFilterType != 'month' &&
                                                        <InputGroup>
                                                            <Flatpickr
                                                                onClose={([date]) => {
                                                                    if (date) {
                                                                        setFromDate(moment(date).format('MM/DD/YYYY'));
                                                                        setToDate(moment(date).endOf('month').format('MM/DD/YYYY'))
                                                                    }
                                                                    else {
                                                                        setFromDate('');
                                                                        setToDate('')
                                                                    }
                                                                }}
                                                                value={fromDate}
                                                                className="form-control d-block bg-white mt-2"
                                                                placeholder="From date"
                                                                options={{
                                                                    altInput: true,
                                                                    altFormat: "m/d/Y",
                                                                    dateFormat: "m/d/Y",
                                                                    allowInput: true,
                                                                    minDate: new Date(type == 'step3' ? validation.values.commencementDate : leaseDraftData.commencementDate),
                                                                    maxDate: new Date(type == 'step3' ? validation.values.lastExtensionDate : leaseDraftData.lastExtensionDate ? leaseDraftData.lastExtensionDate : leaseDraftData.terminalLeaseEndDate),
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-secondary docs-datepicker-trigger mt-2"
                                                                    disabled
                                                                >
                                                                    <i
                                                                        className="fa fa-calendar"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </InputGroup>
                                                    }
                                                </Col>
                                                <Col md="4">
                                                    {
                                                        dateFilterType == 'month' &&
                                                        <InputGroup>
                                                            <Flatpickr
                                                                onClose={([date]) => {
                                                                    if (date) {
                                                                        setToDate(moment(date).endOf('M').format('MM/DD/YYYY'));
                                                                    }
                                                                    else {
                                                                        setToDate('');
                                                                    }
                                                                }}
                                                                value={toDate ? moment(toDate, 'MM/DD/YYYY').format('MMMM YYYY') : ''}
                                                                className="form-control d-block bg-white mt-2"
                                                                placeholder="To month & year"
                                                                options={{
                                                                    plugins: [
                                                                        new monthSelectPlugin({
                                                                            shorthand: true,
                                                                            dateFormat: "F Y",
                                                                            altFormat: "F Y",
                                                                        })
                                                                    ],
                                                                    disable:[(date) => moment(date).isBefore(moment(fromDate ? fromDate : new Date(type == 'step3' ? validation.values.commencementDate : leaseDraftData.commencementDate)))],
                                                                    minDate: fromDate ? fromDate : new Date(type == 'step3' ? validation.values.commencementDate : leaseDraftData.commencementDate),
                                                                    maxDate: new Date(type == 'step3' ? validation.values.lastExtensionDate : leaseDraftData.lastExtensionDate ? leaseDraftData.lastExtensionDate : leaseDraftData.terminalLeaseEndDate),
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-secondary docs-datepicker-trigger mt-2"
                                                                    disabled
                                                                >
                                                                    <i
                                                                        className="fa fa-calendar"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </InputGroup>
                                                    }
                                                    {
                                                        dateFilterType != 'month' &&
                                                        <InputGroup>
                                                            <Flatpickr
                                                                onClose={([date]) => {
                                                                    if (date) {
                                                                        setToDate(moment(date).format('MM/DD/YYYY'));
                                                                    }
                                                                    else {
                                                                        setToDate('');
                                                                    }
                                                                }}
                                                                value={toDate}
                                                                className="form-control d-block bg-white mt-2"
                                                                placeholder="To date"
                                                                options={{
                                                                    altInput: true,
                                                                    altFormat: "m/d/Y",
                                                                    dateFormat: "m/d/Y",
                                                                    minDate: fromDate ? fromDate : new Date(type == 'step3' ? validation.values.commencementDate : leaseDraftData.commencementDate),
                                                                    maxDate: new Date(type == 'step3' ? validation.values.lastExtensionDate : leaseDraftData.lastExtensionDate ? leaseDraftData.lastExtensionDate : leaseDraftData.terminalLeaseEndDate),
                                                                    allowInput: true,
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-secondary docs-datepicker-trigger mt-2"
                                                                    disabled
                                                                >
                                                                    <i
                                                                        className="fa fa-calendar"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </InputGroup>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md='6'>
                                                    <button type="button" onClick={filterPayments} className="btn btn-lbusers mt-2">
                                                        Apply filters
                                                    </button>
                                                    <button type="button" onClick={handleClear} className="btn btn-lbusers mt-2 ms-2">
                                                        Reset filters
                                                    </button>
                                                    <button type="button" onClick={tableToCSV} className="btn btn-lbusers mt-2 ms-2">
                                                        Export
                                                    </button>
                                                </Col>
                                                <Col md='6'>
                                                    <div className="d-flex justify-content-end">
                                                        <button type="button" className="btn btn-lbusers mt-2" onClick={submitPayments} disabled={loading}>
                                                            {
                                                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                            }
                                                            Process
                                                        </button>
                                                    </div>
                                                    <h6 className="text-end mt-2">Process to save changes </h6>
                                                </Col>
                                            </Row>
                                        </div>
                                        {
                                            loading ?
                                                <div className="text-center"><Spinner color="primary" className="text-lbusers" /></div>
                                                :
                                                tableData && tableData.length < 1 ?
                                                    <>
                                                        <div className="text-center mt-4">No data found</div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="modal-body">
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr style={{paddingRight:'15px'}}>
                                                                            <th style={{width:'150px'}}>
                                                                                <div className="mt-4">
                                                                                    <Input
                                                                                        className="form-check-input"
                                                                                        name="selectAll"
                                                                                        type="checkbox"
                                                                                        checked={checkAll()}
                                                                                        onClick={(e) => {
                                                                                            if (e.target.checked) {
                                                                                                const newArr = tableData.map(v => {
                                                                                                    v.is_processed = true
                                                                                                    return v;
                                                                                                })
                                                                                                const all = [...newArr, ...remainingData].sort((a, b) => (new Date(a.date) - new Date(b.date)))
                                                                                                setVariablePayments(all)
                                                                                            } else {
                                                                                                const newArr = tableData.map(v => {
                                                                                                    v.is_processed = false
                                                                                                    return v;
                                                                                                })
                                                                                                const all = [...newArr, ...remainingData].sort((a, b) => (new Date(a.date) - new Date(b.date)))
                                                                                                setVariablePayments(all)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label ms-2"
                                                                                        htmlFor="select"
                                                                                    >
                                                                                        Select
                                                                                    </label>
                                                                                </div>
                                                                            </th>
                                                                            <th>Date</th>
                                                                            <th>Category</th>
                                                                            <th>Estimated Amount</th>
                                                                            <th>Actual Amount</th>
                                                                            <th>
                                                                                <Input
                                                                                    className="form-check-input"
                                                                                    name="selectAllDelete"
                                                                                    type="checkbox"
                                                                                    checked={checkAll("delete")}
                                                                                    onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            const newArr = tableData.map(v => {
                                                                                                v.deletable = true
                                                                                                return v;
                                                                                            })
                                                                                            const all = [...newArr, ...remainingData].sort((a, b) => (new Date(a.date) - new Date(b.date)))
                                                                                            setVariablePayments(all)
                                                                                        } else {
                                                                                            const newArr = tableData.map(v => {
                                                                                                v.deletable = false
                                                                                                return v;
                                                                                            })
                                                                                            const all = [...newArr, ...remainingData].sort((a, b) => (new Date(a.date) - new Date(b.date)))
                                                                                            setVariablePayments(all)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label ms-2"
                                                                                    htmlFor="delete"
                                                                                >
                                                                                    Delete
                                                                                </label>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {tableData && tableData.length > 0 && tableData.map((data, i) => {
                                                                            return <tr key={data.type + i} style={{ textDecoration: data.deletable ? 'line-through' : 'none', textDecorationColor: data.deletable ? 'red' : '' }} >
                                                                                <td className="text-center" style={{ width: '150px' }}>
                                                                                    <div className="mt-2">
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            name="includePayment"
                                                                                            id={i}
                                                                                            type="checkbox"
                                                                                            disabled={data.deletable}
                                                                                            checked={data.is_processed}
                                                                                            onClick={() => {
                                                                                                let newArr = [...tableData];
                                                                                                newArr[i].is_processed = !newArr[i].is_processed
                                                                                                const all = [...newArr, ...remainingData].sort((a, b) => (new Date(a.date) - new Date(b.date)))
                                                                                                setVariablePayments(all)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td><div className="mt-2">{moment(data.date).format('MM/DD/YYYY')}</div></td>
                                                                                <td><div className="mt-2">{data.category ? data.category : ''}</div></td>
                                                                                <td><div className="mt-2">$ {data.amount ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</div></td>
                                                                                <td>
                                                                                    <Input
                                                                                        type="number"
                                                                                        className={`ms-3 form-control ${data.is_processed && !data.actual_amount ? 'border border-danger' : ''}`}
                                                                                        id="amount"
                                                                                        value={data.actual_amount}
                                                                                        disabled={!data.is_processed || data.deletable}
                                                                                        onChange={(e) => {
                                                                                            let newArr = [...tableData]
                                                                                            newArr[i].actual_amount = Number(e.target.value)
                                                                                            const all = [...newArr, ...remainingData].sort((a, b) => (new Date(a.date) - new Date(b.date)))
                                                                                            setVariablePayments(all)
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <div className="mt-2 ps-4">
                                                                                        <Input
                                                                                            className="form-check-input"
                                                                                            name="includePayment"
                                                                                            id={i}
                                                                                            type="checkbox"
                                                                                            checked={data.deletable}
                                                                                            onClick={() => {
                                                                                                let newArr = [...tableData];
                                                                                                newArr[i].deletable = !newArr[i].deletable
                                                                                                const all = [...newArr, ...remainingData].sort((a, b) => (new Date(a.date) - new Date(b.date)))
                                                                                                setVariablePayments(all)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </>
                                        }
                                        {
                                            Number(totalVal) ?
                                                <div className="next ms-3">
                                                    <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal)).toFixed(2))}</h5>
                                                </div> : ''
                                        }
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Form>
                                            <FormikProvider value={formik}>
                                                <div className="accordion-body">
                                                    <Row style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                                        <FieldArray
                                                            name="variableLeasePayments"
                                                            id="variableLeasePayments"
                                                            validateOnChange={false}
                                                            render={arrayHelpers => {
                                                                return <>
                                                                    {(formik.values.variableLeasePayments || []).map((formRow, key) => (
                                                                        <React.Fragment key={key}>
                                                                            {
                                                                                formik.values.variableLeasePayments.length > 1 && key > 0 ?
                                                                                    <div className="mt-4 d-flex">
                                                                                        <hr style={{ flexGrow: '1' }} />
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger gridbtn-m"
                                                                                            style={{ marginRight: '0', marginLeft: 'auto' }}
                                                                                            onClick={() => {
                                                                                                arrayHelpers.remove(key)
                                                                                                let arr = ['One-time', 'Monthly', 'Quarterly', 'Semi-annual', 'Annual'];
                                                                                                formik.values.variableLeasePayments.map((d, i) => {
                                                                                                    if (i + 1 < formik.values.variableLeasePayments.length && i != 0) {
                                                                                                        let index = arr.findIndex(e => e == formik.values.variableLeasePayments[i + 1].frequency)
                                                                                                        document.getElementById(`variableLeasePayments[${i}].frequency`).selectedIndex = index;
                                                                                                    }
                                                                                                })


                                                                                            }}
                                                                                        >
                                                                                            <i className='bx bx-x'></i>
                                                                                        </button>
                                                                                    </div>
                                                                                    : ""
                                                                            }
                                                                            <div key={key} style={{ padding: '20px 30px 0px 30px' }}>
                                                                                <Row key={key}>
                                                                                    <Col md='6'>
                                                                                        <div className="mt-2">
                                                                                            <Label for="Amount">
                                                                                                Estimated Amount
                                                                                            </Label>
                                                                                            <Input
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                name={`variableLeasePayments[${key}].amount`}
                                                                                                id="amount"
                                                                                                onBlur={() => formik.setFieldTouched(`variableLeasePayments[${key}].amount`)}
                                                                                                value={formik.values.variableLeasePayments[key].amount}
                                                                                                onChange={formik.handleChange}
                                                                                            />
                                                                                        </div>
                                                                                        {formik.errors && formik.errors.variableLeasePayments && formik.errors.variableLeasePayments.length > 0 && formik.errors.variableLeasePayments[key] != null && formik.errors.variableLeasePayments[key].amount && (
                                                                                            <span className='text-danger'>{'Must be greater than or equal to 0'}</span>
                                                                                        )}
                                                                                        <Row className='mt-4'>
                                                                                            <Col md='12'>
                                                                                                <div >
                                                                                                    <Label for="Start date">
                                                                                                        {formik.values.variableLeasePayments[key].frequency == 'One-time' ? 'Payment date' : 'Payment Start date'}
                                                                                                    </Label>
                                                                                                    <InputGroup>
                                                                                                        <Flatpickr
                                                                                                            className="form-control d-block bg-white"
                                                                                                            options={{
                                                                                                                altInput: true,
                                                                                                                altFormat: "m/d/Y",
                                                                                                                dateFormat: "m/d/Y",
                                                                                                                minDate: moment(new Date(type == 'step3' ? validation.values.commencementDate : leaseDraftData.commencementDate)).format('MM/DD/YYYY'),
                                                                                                                maxDate: moment(new Date(type == 'step3' ? validation.values.lastExtensionDate : leaseDraftData.lastExtensionDate ? leaseDraftData.lastExtensionDate : leaseDraftData.terminalLeaseEndDate)).format('MM/DD/YYYY'),
                                                                                                                allowInput: true
                                                                                                            }}
                                                                                                            value={formik.values.variableLeasePayments[key].paymentDate}
                                                                                                            onClose={(e) => {
                                                                                                                formik.setFieldValue(`variableLeasePayments[${key}].paymentDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                                                                formik.setFieldValue(`variableLeasePayments[${key}].paymentEndDate`, formik.values.variableLeasePayments[key].frequency == 'One-time' ? '' : moment(e[0]).add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY'))
                                                                                                                checkVariableDates('start', e, key)
                                                                                                            }}
                                                                                                        />
                                                                                                        <div className="input-group-append">
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                                disabled
                                                                                                            >
                                                                                                                <i
                                                                                                                    className="fa fa-calendar"
                                                                                                                    aria-hidden="true"
                                                                                                                />
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </InputGroup>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md='6'>
                                                                                        <div className="mt-2">
                                                                                            <Label for="Amount">
                                                                                                Payment frequency
                                                                                            </Label>
                                                                                            <div className="input-group-append" >
                                                                                                <select className="form-control arrows" id={`variableLeasePayments[${key}].frequency`}
                                                                                                    name={`variableLeasePayments[${key}].frequency`}
                                                                                                    onChange={(e) => {
                                                                                                        formik.setFieldValue(`variableLeasePayments[${key}].frequency`, e.target.value)
                                                                                                        setEndDate(e.target.value, 'frequency', key)
                                                                                                        checkVariableDates('frequency', e, key)
                                                                                                    }}
                                                                                                    value={formik.values.variableLeasePayments[key].frequency}
                                                                                                    onBlur={() => formik.setFieldTouched(`variableLeasePayments[${key}].frequency`)} >
                                                                                                    <option value='One-time'>One-time</option>
                                                                                                    <option value='Monthly'>Monthly</option>
                                                                                                    <option value='Quarterly'>Quarterly</option>
                                                                                                    <option value='Semi-annual'>Semi-annual</option>
                                                                                                    <option value='Annual'>Annual</option>
                                                                                                </select>
                                                                                            </div>
                                                                                            <Row className='mt-4'>
                                                                                                <Col md='12'>
                                                                                                    {formik.values.variableLeasePayments[key].frequency != 'One-time' &&
                                                                                                        <div>
                                                                                                            <Label for="Start date">
                                                                                                                Payment End date
                                                                                                            </Label>
                                                                                                            <InputGroup>
                                                                                                                <Flatpickr
                                                                                                                    options={{
                                                                                                                        altInput: true,
                                                                                                                        altFormat: "m/d/Y",
                                                                                                                        dateFormat: "m/d/Y",
                                                                                                                        minDate: moment(new Date(formik.values.variableLeasePayments[key].paymentDate)).format('MM/DD/YYYY'),
                                                                                                                        maxDate: moment(new Date(type == 'step3' ? validation.values.lastExtensionDate : leaseDraftData.lastExtensionDate ? leaseDraftData.lastExtensionDate : leaseDraftData.terminalLeaseEndDate)).format('MM/DD/YYYY'),
                                                                                                                        allowInput: true
                                                                                                                    }}
                                                                                                                    value={formik.values.variableLeasePayments[key].paymentEndDate}
                                                                                                                    onClose={(e) => {
                                                                                                                        formik.setFieldValue(`variableLeasePayments[${key}].paymentEndDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                                                                        checkVariableDates('end', e, key)
                                                                                                                    }}
                                                                                                                />

                                                                                                                <div className="input-group-append">
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <i
                                                                                                                            className="fa fa-calendar"
                                                                                                                            aria-hidden="true"
                                                                                                                        />
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </InputGroup>
                                                                                                        </div>
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col md='6'>
                                                                                        <div className="mt-4">
                                                                                            <Label for="Amount">
                                                                                                Estimated Annual Fixed amount % increment
                                                                                            </Label>
                                                                                            <Input
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                id="annualFixedAmountPer"
                                                                                                value={formik.values.variableLeasePayments[key].annualFixedAmountPer}
                                                                                                onBlur={() => formik.setFieldTouched(`variableLeasePayments[${key}].annualFixedAmountPer`)}
                                                                                                disabled={(Number(formik.values.variableLeasePayments[key].annualFixedAmount || 0) > 0 || formik.values.variableLeasePayments[key].frequency == 'One-time') ? true : false}
                                                                                                onChange={(e) => {
                                                                                                    formik.setFieldValue(`variableLeasePayments[${key}].annualFixedAmountPer`, (e.target.value))
                                                                                                    formik.setFieldValue(`variableLeasePayments[${key}].annualFixedAmount`, (0))
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        {formik.errors && formik.errors.variableLeasePayments && formik.errors.variableLeasePayments.length > 0 && formik.errors.variableLeasePayments[key] != null && formik.errors.variableLeasePayments[key].annualFixedAmountPer && (
                                                                                            <span className='text-danger'>{formik.errors.variableLeasePayments[key].annualFixedAmountPer}</span>
                                                                                        )}
                                                                                    </Col>
                                                                                    <Col md='6'>
                                                                                        <div className="mt-4">
                                                                                            <Label for="Amount">
                                                                                                Estimated Annual Fixed amount $ increment
                                                                                            </Label>
                                                                                            <Input
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                id="annualFixedAmount"
                                                                                                onBlur={() => formik.setFieldTouched(`variableLeasePayments[${key}].annualFixedAmount`)}
                                                                                                disabled={(Number(formik.values.variableLeasePayments[key].annualFixedAmountPer || 0) > 0 || formik.values.variableLeasePayments[key].frequency == 'One-time') ? true : false}
                                                                                                value={formik.values.variableLeasePayments[key].annualFixedAmount}
                                                                                                onChange={(e) => {
                                                                                                    formik.setFieldValue(`variableLeasePayments[${key}].annualFixedAmount`, (e.target.value))
                                                                                                    formik.setFieldValue(`variableLeasePayments[${key}].annualFixedAmountPer`, (0))
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        {formik.errors && formik.errors.variableLeasePayments && formik.errors.variableLeasePayments.length > 0 && formik.errors.variableLeasePayments[key] != null && formik.errors.variableLeasePayments[key].annualFixedAmount && (
                                                                                            <span className='text-danger'>{'Must be greater than or equal to 0'}</span>
                                                                                        )}
                                                                                    </Col>
                                                                                    <Col md='6'>
                                                                                        <div className="mt-4">
                                                                                            <Label for="Title">
                                                                                                Category
                                                                                            </Label>
                                                                                            <div className="input-group-append" >
                                                                                                <select className="form-control arrows" id={`variableLeasePayments[${key}].category`}
                                                                                                    name={`variableLeasePayments[${key}].category`}
                                                                                                    onChange={(e) => {
                                                                                                        formik.setFieldValue(`variableLeasePayments[${key}].category`, e.target.value)
                                                                                                    }}
                                                                                                    value={formik.values.variableLeasePayments[key].category}
                                                                                                    onBlur={() => formik.setFieldTouched(`variableLeasePayments[${key}].category`)} >
                                                                                                    <option value='new_category'>New category</option>
                                                                                                    {
                                                                                                        variableCategories.map(v => <option key={v.name} value={v.name}>{v.name}</option>)
                                                                                                    }
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                    {
                                                                                        formik.values.variableLeasePayments[key].category == 'new_category' &&
                                                                                        <Col md='6'>
                                                                                            <div className="mt-4">
                                                                                                <Label for="New category">
                                                                                                    New category
                                                                                                </Label>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    id="new_category"
                                                                                                    value={formik.values.variableLeasePayments[key].new_category}
                                                                                                    onChange={(e) => {
                                                                                                        formik.setFieldValue(`variableLeasePayments[${key}].new_category`, (e.target.value))
                                                                                                    }}

                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    }
                                                                                </Row>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ))}
                                                                    <div className="mt-4 d-flex">
                                                                        <hr style={{ flexGrow: '1' }} />
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-success gridbtn-m"
                                                                            style={{ marginRight: '0', marginLeft: 'auto' }}
                                                                            onClick={() =>
                                                                                arrayHelpers.insert(formik.values.variableLeasePayments.length, {
                                                                                    amount: 0,
                                                                                    frequency: formik.values.variableLeasePayments[formik.values.variableLeasePayments.length - 1].frequency,
                                                                                    paymentDue: 'Beginning of the period',
                                                                                    paymentDate: moment(new Date(formik.values.variableLeasePayments[formik.values.variableLeasePayments.length - 1].paymentEndDate)).add(1, 'days').format('MM/DD/YYYY'),
                                                                                    paymentEndDate: moment(new Date(formik.values.variableLeasePayments[formik.values.variableLeasePayments.length - 1].paymentEndDate)).add(12, 'months').format('MM/DD/YYYY'),
                                                                                    annualFixedAmountPer: 0,
                                                                                    annualFixedAmount: 0,
                                                                                    category: 'new_category',
                                                                                    new_category: ''
                                                                                })}
                                                                        >
                                                                            <i className='bx bx-plus text-white'></i>
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            }}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-lbusers mt-4"
                                                                    disabled={loading}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        formik.handleSubmit();
                                                                        return false;
                                                                    }}>
                                                                    {
                                                                        loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                                    }
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </FormikProvider>
                                        </Form>
                                    </TabPane>
                                </TabContent>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </React.Fragment >
    )
}

export default AddVariablePayment