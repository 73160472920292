import PropTypes from "prop-types"
import React,{useEffect, useContext} from "react"
import { AuthContext } from "context/authContext";

const Logout = () => {
	const { onLogout } = useContext(AuthContext);
  useEffect(() => {
    onLogout();
  },[])
  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default Logout
