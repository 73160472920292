/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Badge } from "reactstrap"
import { useLocation } from "react-router-dom";
import EditTemplate from "pages/GLsetup/editGlTemplate"
import DeleteTemplate from "pages/GLsetup/deleteGlTemplate";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "../../constants/config";
import { AuthContext } from "context/authContext"
import moment from "moment";
import AddGlTemplate from "pages/GLsetup/addGlTemplate";
import Template from "pages/GLsetup/template";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const GlGroup = (prop) => {
  const {setactiveTab} = prop;
  const location = useLocation();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [glGroups, setGlGroups] = useState([])
  const { mode } = useContext(AuthContext)
  const [id, setId] = useState('');
  const [selectedGroup, setSelectedGroup] = useState({})
  const [openPopup, setOpenPopup] = useState(false);
  const [modal, setModal] = useState(false);
  const [glmapModal, setGlmapModal] = useState(false);

  const toggleOpen = () => {
    setOpenPopup(!openPopup);
  }

  const columns = [
    {
      label: "Title",
      name: "title",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({style: {minWidth: '100px',height: '70px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px',height: '50px'}}),
        customBodyRenderLite:(dataIndex)=>(
          <div className="table-data-margin">
            {glGroups && glGroups[dataIndex] && glGroups[dataIndex].title}
          </div>
        ),
      }
    },
    {
      name: "is_gl_mapped",
      label: "Is G/L mapped",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
        <div className="table-data-margin">
          {
            glGroups && glGroups[dataIndex] && glGroups[dataIndex].title == "Unassigned" ? 'NA': 
            <Badge
              className={"font-size-14 badge-soft-" + glGroups && glGroups[dataIndex] && glGroups[dataIndex].is_gl_mapped ? 'success' : 'danger'}
              color={glGroups && glGroups[dataIndex] && glGroups[dataIndex].is_gl_mapped ? 'success' : 'danger'}
              pill
              style={{padding:'5px 10px'}}
            >
              {glGroups && glGroups[dataIndex] && glGroups[dataIndex].is_gl_mapped ? 'Yes' : 'No'}
            </Badge>
          }
      </div>
      )}
    },
    {
      name: "last_updated",
      label: "Last updated",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>{
          return <div className="table-data-margin">
            { glGroups && glGroups[dataIndex] && glGroups[dataIndex].title == "Unassigned" ? 'NA' : moment(glGroups && glGroups[dataIndex] && glGroups[dataIndex].updatedAt).format('MM/DD/YYYY HH:mm')}
          </div>
        }
      }
    },
    {
      name: "created_by",
      label: "Created by",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite:(dataIndex)=>(
        <div className="table-data-margin">
          { glGroups && glGroups[dataIndex] && glGroups[dataIndex].title == "Unassigned" ? 'NA' : glGroups && glGroups[dataIndex] && glGroups[dataIndex].user_id ? glGroups[dataIndex].user_id.name : ''}
        </div>
      )}
    },
    {
      name: "menu",
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite:(dataIndex)=>(
        <div className="mt-2">
            <>
              <button
                  type="button"
                  className="btn btn-sm btn-lbusers me-2"
                  onClick={() => {setGlmapModal(true); setSelectedGroup(glGroups[dataIndex]) }}
                  disabled={loading}
              > 
                  G/L MAPPING
              </button>
              <button
                  type="button"
                  className="btn btn-sm btn-lbusers me-2"
                  onClick={() => {setEditOpen(true); setSelectedGroup(glGroups[dataIndex])}}
                  disabled={loading}
              >
                  RENAME
              </button>
              <button
                  type="button"
                  className="btn btn-sm btn-lbusers"
                  onClick={() => {setDeleteOpen(true); setSelectedGroup(glGroups[dataIndex])}}
                  disabled={loading}
              >
                  DELETE
              </button>
            </>
        </div>
      )},
    },
  ];

  // toggle edit account modal
  const toggleEdit = () => {
    setEditOpen(!editOpen);
    setSelectedGroup({})
  }
  // toggle delete account modal
  const toggleDelete = () => {
    setDeleteOpen(!deleteOpen);
    setSelectedGroup({})
  }

  useEffect(() => {
    setId((location.search).replace('?', ''));
  }, [])

  useEffect(()=>{
    if(mode && id){
      getGlData(id)
    }
  },[mode,id])

  const getGlData = async() => {
    setLoading(true)
    setGlGroups([])
    try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/gl_template/list_gl_template?mode=${mode ? mode : 'lessee'}&client_id=${id}`,{
            headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
          const arr = data.glGroupData || []
          setGlGroups(arr);
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }  

  return (
    <React.Fragment>
          <Row>
            <Col className="col-12">
              <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                  MUIDataTableToolbar:{
                    styleOverrides:{
                      actions: {
                        display: 'none'
                      }
                    }
                  }
                  }})}>
                <MUIDataTable
                  title={
                    <Row className="row">
                      <Col md='9'></Col>
                      <Col md='3' style={{ textAlign: 'end' }}>
                        <button type="button" className="btn btn-lbusers me-2" onClick={() => {toggleOpen();}}>ADD NEW</button> 
                      </Col>
                    </Row>
                  }
                  data={glGroups}
                  columns={columns}
                  options={{ 
                    rowsPerPage:10, 
                    print:false, 
                    download:false, 
                    filter:false, 
                    viewColumns: false,
                    selectableRows:"none", 
                    search:false, 
                    pagination:false,
                    rowsPerPageOptions:[], 
                    responsive:'scroll',
                    textLabels: {
                      viewColumns: {
                        title: "",
                      },
                    } 
                  }}
                />
              </ThemeProvider>
              <Row className="row mb-2 mt-3">
                <Col md='9'></Col>
                <Col md='3' style={{ textAlign: 'end' }}>
                    <button
                        type="button"
                        className="btn btn-lbusers me-2"
                        onClick={()=>setactiveTab(2)}
                        disabled={loading}
                    >
                        PREVIOUS
                    </button>
                </Col>
              </Row>
            <EditTemplate editOpen={editOpen} toggleEdit={toggleEdit} setEditOpen={setEditOpen} getGlData={getGlData} glgroup={selectedGroup} setSelectedGroup={setSelectedGroup} />
            <DeleteTemplate deleteOpen={deleteOpen} toggleDelete={toggleDelete} setDeleteOpen={setDeleteOpen} getGlData={getGlData} glgroup={selectedGroup} setSelectedGroup={setSelectedGroup} />
            <AddGlTemplate openPopup={openPopup} toggleOpen={toggleOpen} setOpenPopup={setOpenPopup} getGlData={getGlData}/>
            <Template glmapModal={glmapModal} setGlmapModal={setGlmapModal} id={selectedGroup && selectedGroup._id} setSelectedGroup={setSelectedGroup} getGlData={getGlData}/>
            </Col>
          </Row>
    </React.Fragment>
    )
}

export default GlGroup