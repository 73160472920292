/* eslint-disable react/no-unknown-property */
import React, { useState } from "react"
import { ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import { capitalize } from "lodash"
import { useNavigate } from 'react-router-dom'
import toast from "react-hot-toast";
import axios from "axios";
import AppConfig from "constants/config";

const AuditCategoryDropdown = (prop) => {
    const { reviewAccess, leaseDetails, clientAccess, type, refreshData, mode } = prop
    const [drpCategoryOpen, setDrpCategoryOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleCategoryChange = async (category) => {
        setLoading(true)
        try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/lease_draft/changeAuditCategory`, { lease_id: leaseDetails._id, audit_category: category, comment: `Audit category changed to ${category.replace(/_/g, ' ')}`, mode: mode ? mode : 'lessee' }, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                toast.success("Category changed successfully")
                if (type == 'details' && category == 'drafted') {
                    navigate(`/${localStorage.getItem('company')}/leases`);
                } else if (type == 'details') {
                    refreshData('', 'refresh')
                } else {
                    refreshData({})
                }
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong')
        }
        setLoading(false)
    }

    return (
        <>
            {
                leaseDetails && leaseDetails.client_id && leaseDetails.client_id.modules && leaseDetails.client_id.modules.includes('audit_category') && (type == 'list' || (type == 'details' && leaseDetails.status != "Terminated" && clientAccess && clientAccess[leaseDetails.client_id.client] === 'full_access')) ?
                    <div className={`${type == 'list' ? '' : 'mb-1 mt-4'}`}>
                        <span className="d-inline-block" dataToggle="tooltip" dataPlacement="top" data-custom-class="tooltip-danger" title={leaseDetails.audit_category == "drafted" ? "Drafted" : leaseDetails.audit_category == "reviewed" ? "Reviewed" : leaseDetails.audit_category == "submitted" ? "Submitted" : "Created"}>
                            <div className="btn-group dropstart">
                                <ButtonDropdown
                                    isOpen={drpCategoryOpen}
                                    toggle={() => setDrpCategoryOpen(!drpCategoryOpen)}
                                >
                                    <DropdownToggle
                                        caret
                                        className={`btn btn-sm drp btn-${leaseDetails.audit_category === 'reviewed' ? 'success' : leaseDetails.audit_category === 'submitted' ? 'lbusers' : 'warning'}`}
                                        disabled={loading || leaseDetails.audit_category == "drafted" || leaseDetails.status == "Terminated" || clientAccess[leaseDetails.client_id.client] === "read_only"}
                                    >
                                        {capitalize(leaseDetails.audit_category ? leaseDetails.audit_category : 'created')} <i className="mdi mdi-chevron-down" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => { handleCategoryChange('drafted') }}> Drafted </DropdownItem>
                                        {leaseDetails.audit_category != 'submitted' && leaseDetails.audit_category != 'reviewed' && <DropdownItem onClick={() => { handleCategoryChange('created') }}> Created </DropdownItem>}
                                        <DropdownItem onClick={() => { handleCategoryChange('submitted') }}> Submitted</DropdownItem>
                                        {(reviewAccess[leaseDetails.client_id.client] === true) && <DropdownItem onClick={() => { handleCategoryChange('reviewed') }}> Reviewed </DropdownItem>}
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                        </span>
                    </div>
                    : type == 'list' ?
                        <div>
                            NA
                        </div>
                        : <></>
            }
        </>
    )
}

export default AuditCategoryDropdown