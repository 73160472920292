import React, {useState } from "react"
import PropTypes from "prop-types"
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,InputGroup,
    Row,
    TabContent,
    TabPane,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
// Form Mask
import InputMask from "react-input-mask";
import moment from 'moment';

const AddLeaseStepFour = (prop) => {
    let { formik } = prop;
    const [maskValue , setMaskValue] = useState('')
    
    const onChange = ({ target: { value } }) =>{
        setMaskValue(value)
    }

    let beforeMaskedValueChange = (newState, oldState, userInput) => {
        let { value } = newState;

        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;
    
        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !maskValue.endsWith('-')) {
        if (cursorPosition === value.length) {
            cursorPosition--;
            selection = { start: cursorPosition, end: cursorPosition };
        }
        value = value.slice(0, -1);
        }
    
        return {
        value,
        selection
        };
      }

    const Currency = (prop) => (
        <InputMask
          mask="- "
          maskChar={null}
          value={prop.value}
          className="form-control input-color"
          onChange={prop.onChange}
          beforeMaskedValueChange={beforeMaskedValueChange}
        >
        </InputMask>
      )
    return (
        <React.Fragment>
            <Form>
                <Row>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="TransitionDate">
                                Transition/Acquisition Date
                            </Label>
                            <InputGroup>
                                <Flatpickr
                                    // value={Date.parse(pick_date)}
                                    className="form-control d-block bg-white"
                                    options={{
                                        altInput: true,
                                        altFormat: "m/d/Y",
                                        dateFormat: "m/d/Y",
                                        minDate: moment(new Date(formik.values.commencementDate)).add(1,'day').format('MM/DD/YYYY'),
                                        maxDate: moment( new Date(formik.values.terminalLeaseEndDate)).format('MM/DD/YYYY'),
                                        allowInput: true
                                    }}
                                    id='transitionDate'
                                    onClose={([date]) => {
                                        if(date) {
                                            formik.setFieldValue(`transitionDate`, moment(date).format('MM/DD/YYYY'))
                                        } else {
                                            formik.setFieldValue(`transitionDate`, '')
                                        }
                                    }}
                                    onBlur={() => formik.setFieldTouched("transitionDate")}
                                    value={formik.values.transitionDate}
                                    // value={moment.utc(formik.values.transitionDate).format('MM/DD/YYYY')}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                        disabled
                                    >
                                        <i
                                            className="fa fa-calendar"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </InputGroup>
                            {formik.touched.transitionDate && formik.errors.transitionDate && (
                                <span className='text-danger'>{formik.touched.transitionDate && formik.errors.transitionDate}</span>
                            )}
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="Acquisition">
                                Acquisition Related - Off Market Lease
                            </Label>
                            <Input
                                type="number"
                                className="form-control"
                                id="acquisitionRelated"
                                onChange={formik.handleChange}
                                disabled={!formik.values.transitionDate}
                                onBlur={() => formik.setFieldTouched("acquisitionRelated")}
                                value={formik.values.acquisitionRelated}
                            />
                        </div>
                        <span>Note: Unfavorable lease should be a negative number<br/>Favorable lease should be a positive number</span>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="prepaidRent">
                                Prepaid rent
                            </Label>
                            <Input
                                type="number"
                                className="form-control"
                                id="prepaidRent"
                                disabled={!formik.values.transitionDate}
                                onChange={formik.handleChange}
                                onBlur={() => formik.setFieldTouched("prepaidRent")}
                                value={formik.values.prepaidRent}
                            /> 
                        </div>
                        {formik.touched.prepaidRent && formik.errors.prepaidRent && (
                            <span className='text-danger'>Prepaid rent must be greater than or equal to 0</span>
                            // <span className='text-danger'>{formik.touched.prepaidRent && formik.errors.prepaidRent}</span>
                        )}
                    </Col>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="initDirectCost">
                                Unamortized initial direct cost
                            </Label>
                            <Input
                                type="number"
                                className="form-control"
                                id="unamortizedInitialDirectCost"
                                disabled={!formik.values.transitionDate}
                                onChange={formik.handleChange}
                                onBlur={() => formik.setFieldTouched("unamortizedInitialDirectCost")}
                                value={formik.values.unamortizedInitialDirectCost}
                            />
                        </div>
                        {formik.touched.unamortizedInitialDirectCost && formik.errors.unamortizedInitialDirectCost && (
                            <span className='text-danger'>Unamortized Initial Direct Cost must be greater than or equal to 0</span>
                            // <span className='text-danger'>{formik.touched.unamortizedInitialDirectCost && formik.errors.unamortizedInitialDirectCost}</span>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="leaseIncentive">
                                Lease incentives
                            </Label>
                            <Input
                                type="number"
                                className="form-control"
                                id="leaseIncentives"
                                disabled={!formik.values.transitionDate}
                                onChange={formik.handleChange}
                                onBlur={() => formik.setFieldTouched("leaseIncentives")}
                                value={formik.values.leaseIncentives}
                            />                           
                            {formik.touched.leaseIncentives && formik.errors.leaseIncentives && (
                                <span className='text-danger'>Lease Incentives must be a negative number</span>
                            )}
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mt-4">
                            <Label>
                                Deferred rent
                            </Label>
                            {/* <Currency value={maskValue} onChange={onChange} /> */}
                            <Input
                                type="number"
                                className="form-control"
                                id="deferredRent"
                                disabled={!formik.values.transitionDate}
                                onChange={formik.handleChange}
                                onBlur={() => formik.setFieldTouched("deferredRent")}
                                value={formik.values.deferredRent}
                            />                           
                            {formik.touched.deferredRent && formik.errors.deferredRent && (
                                <span className='text-danger'>{formik.touched.deferredRent && formik.errors.deferredRent}</span>
                            )}
                        </div>
                        <span>Note: Deferred Rent Liability lease should be a negative number Deferred Rent<br/>Asset lease should be a positive number</span>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="420liability">
                                Topic 420 liability
                            </Label>
                            {/* <Currency value={maskValue} onChange={onChange} /> */}
                            <Input
                                type="number"
                                className="form-control"
                                id="topic420Liability"
                                disabled={formik.values.reporting_standard == "GASB 87" || formik.values.reporting_standard == "IFRS 16" || !formik.values.transitionDate}
                                onChange={formik.handleChange}
                                onBlur={() => formik.setFieldTouched("topic420Liability")}
                                value={formik.values.topic420Liability}
                            />                           
                            {formik.touched.topic420Liability && formik.errors.topic420Liability && (
                                <span className='text-danger'>Topic 420 Liability must be a negative number</span>
                            )}
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="otherAcc">
                                Other accounts
                            </Label>
                            <Input
                                type="number"
                                className="form-control"
                                id="otherAccounts"
                                disabled={!formik.values.transitionDate}
                                onChange={formik.handleChange}
                                onBlur={() => formik.setFieldTouched("otherAccounts")}
                                value={formik.values.otherAccounts}
                            />                           
                            {formik.touched.otherAccounts && formik.errors.otherAccounts && (
                                <span className='text-danger'>{formik.touched.otherAccounts && formik.errors.otherAccounts}</span>
                            )}
                        </div>
                        <span>Note: Liability lease should be a negative number<br/>Asset lease should be a positive number</span>
                    </Col>
                    <Col md='6'>
                        <div className="mt-4">
                            <Label for="Amount">
                                Description
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="description"
                                value={formik.values.description}
                                onChange={(e) =>{ 
                                    formik.setFieldValue(`description`, (e.target.value))
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

AddLeaseStepFour.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func
}

export default AddLeaseStepFour