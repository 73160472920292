/* eslint-disable react/no-unknown-property */
import React, { useState } from "react"
import { useNavigate } from 'react-router-dom'
import toast from "react-hot-toast";
import axios from "axios";
import AppConfig from "constants/config";

const AuditCategoryDropdown = (prop) => {
    const { reviewAccess, leaseDetails, clientAccess, type, refreshData, mode } = prop
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleCategoryChange = async (category) => {
        setLoading(true)
        try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/lease_draft/changeAuditCategory`, { lease_id: leaseDetails._id, audit_category: category, comment: `Audit category changed to ${category.replace(/_/g, ' ')}`, mode: mode ? mode : 'lessee' }, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                toast.success("Category changed successfully")
                if (type == 'details' && category == 'drafted') {
                    navigate(`/${localStorage.getItem('company')}/leases`);
                } else if (type == 'details') {
                    refreshData('', 'refresh')
                } else {
                    refreshData({})
                }
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong')
        }
        setLoading(false)
    }

    return (
        <>
            {
                leaseDetails && leaseDetails.client_id && leaseDetails.client_id.modules && leaseDetails.client_id.modules.includes('audit_category') && (type == 'list' || (type == 'details' && leaseDetails.status != "Terminated" && clientAccess && clientAccess[leaseDetails.client_id.client] === 'full_access')) ?
                    <div className={`${type == 'list' ? '' : 'mb-1 mt-4'}`}>
                        <select
                            onChange={(e) => handleCategoryChange(e.target.value)}
                            value={leaseDetails.audit_category}
                            className={`btn btn-sm drp btn-${leaseDetails.audit_category === 'reviewed' ? 'success' : leaseDetails.audit_category === 'submitted' ? 'lbusers' : 'warning'}`}
                            disabled={loading || leaseDetails.audit_category == "drafted" || leaseDetails.status == "Terminated" || clientAccess[leaseDetails.client_id.client] === "read_only"}
                        >
                            <option value="drafted">Drafted</option>
                            {leaseDetails.audit_category != 'submitted' && leaseDetails.audit_category != 'reviewed' && <option value="created">Created</option>}
                            <option value="submitted">Submitted</option>
                            {(reviewAccess[leaseDetails.client_id.client] === true) && <option value="reviewed">Reviewed</option>}
                        </select>
                    </div>
                    : type == 'list' ?
                        <div>
                            NA
                        </div>
                        : <></>
            }
        </>
    )
}

export default AuditCategoryDropdown