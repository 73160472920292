import React,{ useState,useContext} from "react"
import {Modal,Row,Col} from "reactstrap"
import "../../assets/scss/manual/manual.scss";

const BrowserSettingPopup = (prop) => {
    const {settingOpen, toggleSetting, setSettingOpen, downloadTemplate} = prop;
    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDeleteClose = () => {
        setSettingOpen(false);
    }

    return (
        <Modal
            isOpen={settingOpen}
            toggle={() => {
                toggleSetting();
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Browser Setting</h5>
                <button
                    type="button"
                    onClick={handleDeleteClose}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3">
                    Please make sure that multiple download settings are on for your browser.
                </div>
                <Row>
                    <Col>
                        <div className="d-flex">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="Setting"
                                    onChange={
                                        (e) =>{setCheck(e.target.checked)}
                                    }
                                    checked = {check}
                                />
                            </div>
                            <label
                                className="form-check-label ms-2"
                                htmlFor="Setting"
                            >
                                Don&apos;t show this popup again
                            </label>
                        </div>
                    </Col>
                </Row>
                <div className="modal-footer mt-3">
                    <button
                        type="button"
                        className="btn btn-lbusers"
                        onClick={handleDeleteClose}
                        disabled={loading} 
                    >
                        {
                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                        }
                        Close
                    </button>
                    <button 
                        type="submit" 
                        className="btn btn-lbusers" 
                        disabled={loading} 
                        onClick={() => {
                            downloadTemplate(check)
                            handleDeleteClose()
                        }} 
                    >
                        {
                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                        }
                        DOWNLOAD
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default BrowserSettingPopup