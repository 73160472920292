import React,{ useState} from "react"
import {Modal} from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import axios from "axios";
import AppConfig from "../../constants/config";
import toast from 'react-hot-toast';

const DeleteAllocation = (prop) => {
    const {deleteOpen, setDeleteOpen, getAllocations, id, allocation, setSelectedAllocation} = prop;
    const [loading, setLoading] = useState(false);

    const handleDeleteClose = () => {
        setSelectedAllocation({})
        setDeleteOpen(false);
    }

    const handleSubmit = async(e) => {
        setLoading(true)
        try {
            e.preventDefault();
            const {data} = await axios.post(`${AppConfig.baseUrl}/allocation/remove_allocation`, {id: allocation._id, client_id: id},{
                headers:{token: localStorage.getItem('token')}
            });
            if(!data.error){
                toast.success("Allocation removed successfully");
                getAllocations({});
                handleDeleteClose();
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            handleDeleteClose();
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    return (
        <Modal
            isOpen={deleteOpen}
            toggle={() => {
                setDeleteOpen(false);
                setSelectedAllocation({})
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Remove Allocation</h5>
                <button
                    type="button"
                    onClick={handleDeleteClose}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        Are you sure you want to remove allocation for this client ?
                    </div>
                    <div className="modal-footer mt-3">
                        <button
                            type="button"
                            className="btn btn-lbusers"
                            onClick={handleDeleteClose}
                            disabled={loading} 
                        >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            No
                        </button>
                        <button type="submit" className="btn btn-lbusers" disabled={loading} >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            Yes
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default DeleteAllocation