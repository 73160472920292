import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Row, Col, Tooltip } from "reactstrap"
import fb from "../../assets/images/clients/fb-small.png"
import avatar from "../../assets/images/users/avatar-1.jpg";


const BreadcrumbClientDetail = (prop) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  return (
    <Row>
      <Col xs="12" style={{ padding: '0', display: 'flex', justifyContent: 'space-between' }}>
        <div className="page-title-box d-flex align-items-center ms-3">
          {
            prop.type === "leaseDeatails" ?
              <button
                type="button"
                className="btn rounded-circle avatar-md bg-lbusers mb-2 text-align-center"
              >
                { 
                  prop.leaseType === 'Vehicle' ? <i className='bx bx-car text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                  : (prop.leaseType === 'Building' || prop.leaseType === 'Office') ? <i className='bx bx-buildings text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                  : prop.leaseType === 'Land' ? <i className='bx bxs-landscape text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                  : prop.leaseType === 'Equipment' ? <i className='bx bx-list-ol text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                  : prop.leaseType === 'Others' ? <i className='bx bx-customize text-white' style={{ fontSize: '250%', padding: '0' }}></i> : ''
                }
              </button>
            : ''
          }&nbsp;
          { 
            prop.type === "disclosures" || prop.type === 'journal' || prop.type === 'reports'?
              <div className="client">
                <h4 className="font-size-18 mb-0 ">{prop.name1}</h4>
              </div>
            :''
          }
          <div>
            {
              prop.type === "leaseDeatails" && prop.name.length > 40 ? 
              <>
                <span onMouseOver={() => setTooltipOpen(true)} id="TooltipExample">
                  <div className="client">
                    <h4 className="font-size-18 mb-0 lease-name">{prop.name} {prop.breadcrumbItem ? `- ${prop.breadcrumbItem}` : ""}</h4>
                  </div>
                </span>
                <Tooltip placement="top" isOpen={tooltipOpen} target="TooltipExample" style={{backgroundColor: '#005691'}} toggle={() => setTooltipOpen(false)}>
                  {prop.name} {prop.breadcrumbItem ? `- ${prop.breadcrumbItem}` : ""}
                </Tooltip>
              </>
              : prop.type === "activitylog" ? 
              <div className="client">
                <h4 className="font-size-18 mb-0 ">{prop.breadcrumbItem ? `${prop.breadcrumbItem}` : ""}</h4>
              </div>
              : 
              <div className="client">
                <h4 className="font-size-18 mb-0 ">{prop.name} {prop.breadcrumbItem ? `- ${prop.breadcrumbItem}` : ""}</h4>
              </div>
            }
          </div>
        </div>
        { prop.dates && <div className="page-title-box d-flex align-items-center ms-0">
            <h4 className="font-size-18 mb-0 " style={{marginRight:'15px'}}>{prop.dates}</h4>
        </div>}
      </Col>
    </Row>
  )
}

BreadcrumbClientDetail.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default BreadcrumbClientDetail
