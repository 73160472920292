import React, { useEffect, useState } from "react"
import { Table,Collapse,Row,Col } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import moment from 'moment';
import { capitalize } from "lodash";
import {commaSeperated} from "helpers/formatters"
import classnames from "classnames";

const LeaseSummary = (prop) => {
    const {leaseDetails, leaseDraft, refundAmount, currency, paymentsData, clientData, rouArrData, useful, impairments } = prop
    const [rouDate, setRouDate] = useState('');
    const [type, setType] = useState('');
    const [terminateDate, setTerminateDate] = useState('');
    const rouData = leaseDetails && leaseDetails.modification_date && leaseDetails.rouAsset && leaseDetails.rouAsset[0] ? leaseDetails.rouAsset.filter(r => moment(r.date).isSame(moment(leaseDetails.modification_date))) : leaseDetails && leaseDetails.rouAsset ? leaseDetails.rouAsset : []
    const totalAmort = (paymentsData ? Number(paymentsData.total_lease_payment) : 0) - (paymentsData ? Number(paymentsData.total_present_value) : 0) + (rouData && rouData[0] ? rouData[0].beginning :0)
    const totalTransitionAmt = leaseDraft ? 
    ((leaseDraft.acquisitionRelated ? Number(leaseDraft.acquisitionRelated) : 0 )
    + (leaseDraft.prepaidRent ? Number(leaseDraft.prepaidRent) : 0)
    + (leaseDraft.unamortizedInitialDirectCost ? Number(leaseDraft.unamortizedInitialDirectCost) : 0)
    + (leaseDraft.leaseIncentives ? Number(leaseDraft.leaseIncentives) : 0 )
    + (leaseDraft.deferredRent ? Number(leaseDraft.deferredRent) : 0)
    + (leaseDraft.topic420Liability ? Number(leaseDraft.topic420Liability) : 0)
    + (leaseDraft.otherAccounts ? Number(leaseDraft.otherAccounts) : 0)) 
    : 0;

    useEffect(()=>{
        if(rouArrData && rouArrData.rou_assets && rouArrData.rou_assets.length > 0){ 
            let rou = rouArrData && rouArrData.rou_assets ? rouArrData.rou_assets : []
            let lastRou = rou[rou.length - 1]
            setRouDate(lastRou.date)
            if(leaseDetails.is_terminated){
                setTerminateDate(lastRou.date)
            }
        }
    },[rouArrData,leaseDetails])

    const handleModeClick = (modeName) => {
        if (type === modeName) {
            setType('')
        }
        else {
            setType(modeName)
        }
    }
    
return(
    <React.Fragment>
        <div>
            <table style={{width:'100%'}}>
                <tbody>
                    <tr style={{backgroundColor:leaseDetails && leaseDetails.status == "Modified" ? '#fff' : '#eeeeee' }}>
                        <td style={{padding:'10px'}}><h6 className="mt-2">Client Name:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{clientData && clientData.client ? clientData.client : ''}</td>
                    </tr>
                    <tr style={{backgroundColor:leaseDetails && leaseDetails.status == "Modified" ? '#eeeeee' : '#fff'}}>
                        <td style={{padding:'10px'}}><h6 className="mt-2">GAAP - Lease Commencement Date:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{leaseDetails && leaseDetails.commencement ? moment.utc(leaseDetails.commencement,'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</td>
                    </tr>
                    {
                        leaseDetails && leaseDetails.status == "Modified" &&
                        <tr>
                            <td style={{padding:'10px'}}><h6 className="mt-2">Modification Date:</h6></td>
                            <td style={{textAlign: 'right',paddingRight: '10px'}}>{leaseDetails && leaseDetails.modification_date ? moment.utc(leaseDetails.modification_date).format('MM/DD/YYYY') : ''}</td>
                        </tr>
                    }
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td style={{padding:'10px'}}><h6 className="mt-2">Transition Date:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{leaseDraft && leaseDraft.transitionDate ? moment.utc(leaseDraft.transitionDate).format('MM/DD/YYYY') : ''}</td>
                    </tr>
                    <tr>
                        <td style={{padding:'10px'}}><h6 className="mt-2">GAAP - Lease End Date:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{leaseDetails && leaseDetails.terminal_end_date ? moment.utc(leaseDetails.terminal_end_date).format('MM/DD/YYYY') : ''}</td>
                    </tr>
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td style={{padding:'10px'}}><h6 className="mt-2">GAAP - Lease Classification:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}><span style={{textTransform: 'capitalize'}}>{leaseDetails && leaseDetails?.classification}</span></td>
                    </tr>

                    <tr>
                        <td style={{padding:'10px'}}><h6 className="mt-2">Useful Life of Underlying Asset:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{leaseDetails && leaseDetails.useful_eco_life ? commaSeperated(leaseDetails.useful_eco_life) : ''}</td>
                    </tr>
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td style={{padding:'10px'}}><h6 className="mt-2">Discount Rate:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{leaseDetails && leaseDetails.discount_rate ? leaseDetails.discount_rate + " %" : ''} ({leaseDetails && leaseDetails.interest_rate_method && leaseDetails.interest_rate_method == 'monthly_compound' ?  'Monthly compounded rate' : 'True annual rate' })</td>
                    </tr>
                    <tr>
                        <td style={{padding:'10px'}}><h6 className="mt-2">Lease Type:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{leaseDetails && leaseDetails.calculation_type ? capitalize(leaseDetails.calculation_type) : 'Daily'}</td>
                    </tr>
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td style={{padding:'10px'}}><h6 className="mt-2">Deposit Amount:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{currency+" "}{Number(refundAmount) > 0 ? commaSeperated(refundAmount) : 0}</td>
                    </tr>
                    <tr>
                        <td style={{padding:'10px'}}><h6 className="mt-2">Transition Amount:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{currency+" "}{totalTransitionAmt && commaSeperated(totalTransitionAmt)}</td>
                    </tr>
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td style={{padding:'10px'}}><h6 className="mt-2">Undiscounted {leaseDetails && leaseDetails.mode && leaseDetails.mode == 'lessor' ? 'Receivable' : 'Lease Liability'} at Lease { leaseDetails && leaseDetails.status == 'Modified' ? 'Modification' : 'Commencement'}:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{currency+" "}{paymentsData && paymentsData.total_lease_payment && commaSeperated(Number(paymentsData.total_lease_payment))}</td>
                    </tr>
                    <tr>
                        <td style={{padding:'10px'}}><h6 className="mt-2">PV of {leaseDetails && leaseDetails.mode && leaseDetails.mode == 'lessor' ? 'Receivable' : 'Lease Liability'} at Lease { leaseDetails && leaseDetails.status == 'Modified' ? 'Modification' : 'Commencement'}:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{currency+" "}{paymentsData && paymentsData.total_present_value && commaSeperated(Number(paymentsData.total_present_value))}</td>
                    </tr>
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td style={{padding:'10px'}}><h6 className="mt-2">{leaseDetails && leaseDetails.mode && leaseDetails.mode == 'lessor' ? 'Deferred inflow of resources' : 'Rou Asset'} at Lease { leaseDetails && leaseDetails.status == 'Modified' ? 'Modification' : 'Commencement' }:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{currency+" "}{rouData && rouData[0] && rouData[0].beginning && commaSeperated(rouData[0].beginning)}</td>
                    </tr>
                    <tr>
                        <td style={{padding:'10px'}}><h6 className="mt-2">Total Interest and {leaseDetails && leaseDetails.mode && leaseDetails.mode == 'lessor' ? 'Lease Revenue' : 'Amortization'}:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{currency+" "}{totalAmort && commaSeperated(totalAmort)}</td>
                    </tr>
                   { impairments && impairments.length > 0 && 
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td colSpan={2} style={{padding:'10px'}}>
                            <div className="Accordion" id="accordion" >
                                <div className="accordion-item" style={{border:'none'}}>
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className={classnames(
                                                "accordion-button",
                                                "fw-medium",
                                                { collapsed: type === 'impairment' }
                                            )}
                                            type="button"
                                            onClick={() => handleModeClick('impairment')}
                                            style={{ cursor: "pointer",display:'block',boxShadow:'none', padding:'8px', color:'#000',width:"100%",backgroundColor:'#eeeeee'}}
                                        >
                                            <Row>
                                                <Col xl="6">Most Recent Impairment Date <i className='bx bx-chevron-down'></i></Col>
                                                <Col xl="6" style={{textAlign:'right'}}>{moment(impairments[0].date).format('MM/DD/YYYY')}</Col>
                                            </Row>
                                        </button>
                                    </h2>
                                    <Collapse isOpen={type === 'impairment'} className="accordion-collapse">
                                        <div className="accordion-body" style={{backgroundColor:'#fff'}}>
                                            <Table className="table mb-0 customTable mt-2">
                                                <thead className="table-light tableHead">
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Amount</th>                                    
                                                    </tr>
                                                </thead>
                                                <tbody className="tableBody" style={{minHeight:'200px', maxHeight:'500px', height: 'auto'}}>
                                                    {impairments && impairments.length > 0 && impairments.map((data, i) => (
                                                        <tr key={data.type + i}>
                                                            <td>{moment(data.date).format('MM/DD/YYYY')}</td>
                                                            <td>{data.amount ? commaSeperated((Number(data.amount || 0)).toFixed(2)) : ''}</td>                                        
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </td>
                    </tr>
                    }
                    { (useful && useful.length > 0) ?
                    <tr style={{backgroundColor:impairments && impairments.length > 0 ? '#fff' : '#eeeeee' }}>
                        <td colSpan={2} style={{padding:'10px'}}>
                            <div className="Accordion" id="accordion" >
                                <div className="accordion-item" style={{border:'none'}}>
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className={classnames(
                                                "accordion-button",
                                                "fw-medium",
                                                { collapsed: type === 'useful' }
                                            )}
                                            type="button"
                                            onClick={() => handleModeClick('useful')}
                                            style={{ cursor: "pointer",display:'block', boxShadow: 'none', padding:'8px', color:'#000', width:"100%",backgroundColor: impairments && impairments.length > 0 ? '#fff' : '#eeeeee' }}
                                        >
                                            <Row>
                                                <Col xl="6">ROU Asset End Date <i className='bx bx-chevron-down'></i></Col>
                                                <Col xl="6" style={{textAlign:'right'}}>{moment(useful[0].endDate).format('MM/DD/YYYY')}</Col>
                                            </Row>
                                        </button>
                                    </h2>
                                    <Collapse isOpen={type === 'useful'} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <Table className="table mb-0 customTable mt-2">
                                                <thead className="table-light tableHead">
                                                    <tr>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>  
                                                        <th>Description</th>                                    
                                                    </tr>
                                                </thead>
                                                <tbody className="tableBody" style={{minHeight:'200px', maxHeight:'500px', height: 'auto'}}>
                                                    {useful && useful.length > 0 && useful.map((data, i) => (
                                                        <tr key={data.type + i}>
                                                            <td>{moment(data.date).format('MM/DD/YYYY')}</td>
                                                            <td>{moment(data.endDate).format('MM/DD/YYYY')}</td>   
                                                            <td>{data.description ? data.description : 'Change in useful life'}</td>                                     
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </td>
                    </tr>
                    : 
                    !(moment(rouDate).isSame(moment(leaseDetails.terminal_end_date))) ?
                    <tr style={{backgroundColor:impairments && impairments.length > 0 ? '#fff' : '#eeeeee' }}>
                        <td style={{padding:'10px'}}><h6 className="mt-2">ROU Asset End Date:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{moment.utc(rouDate).format('MM/DD/YYYY')}</td>
                    </tr>:''
                    }
                    { leaseDetails.is_terminated && 
                    <tr style={{backgroundColor: (impairments && impairments.length > 0) && !(moment(rouDate).isSame(moment(leaseDetails.terminal_end_date))) ? '#eeeeee' : (impairments && impairments.length > 0) || (!(moment(rouDate).isSame(moment(leaseDetails.terminal_end_date)))) ? '#fff' : '#eeeeee'}}>
                        <td style={{padding:'10px'}}><h6 className="mt-2">Date of Termination:</h6></td>
                        <td style={{textAlign: 'right',paddingRight: '10px'}}>{terminateDate ? moment.utc(terminateDate).format('MM/DD/YYYY') : ''}</td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    </React.Fragment>
)
}

export default LeaseSummary