import React, { useEffect, useState, useContext } from "react"
import { Modal, Label, Input } from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import axios from "axios";
import AppConfig from "../../constants/config";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from 'react-hot-toast';
import { AuthContext } from "context/authContext"

const EditGlTemplate = (prop) => {
    const { editOpen, toggleEdit, setEditOpen, getGlData, glgroup, setSelectedGroup } = prop
    const [loading, setLoading] = useState(false);
    const { mode } = useContext(AuthContext)


    const handleSubmit = async(values) => {
        setLoading(true)
        try {
            values.mode = mode
            values.id = glgroup._id
            const {data} = await axios.post(`${AppConfig.baseUrl}/gl_template/edit_gl_template`,values,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                toast.success(data.message || 'G/L template edited successfully');
                getGlData()
                formik.resetForm();
                setEditOpen(false);
                setSelectedGroup({})
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: '',
        },
        validationSchema: Yup.object({            
            title: Yup.string().trim().required("Please Enter Title"),          
        }),
        onSubmit: handleSubmit
    });

    useEffect(()=>{
        if(glgroup){
            formik.setValues({
                title: glgroup.title || ''
            })
        }
    },[glgroup])
    
    return (
        <Modal
        isOpen={editOpen}
        toggle={() => {
          toggleEdit();
          formik.resetForm();
        }}
        centered
        >
            <div className="modal-header">
            <h5 className="modal-title mt-0">Edit G/L template</h5>
            <button
                type="button"
                onClick={() => {
                setEditOpen(false);
                formik.resetForm();
                setSelectedGroup({})
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-3">
                    <Label for="name" className="col-form-label">
                        Title:
                    </Label>
                    <Input
                        type="text"
                        id="title"
                        name="title"
                        className="form-control"
                        onChange={(e) => formik.setFieldValue('title',(e.target.value).replace(/\s+/g,' '))}
                        onBlur={formik.handleBlur}
                        value={formik.values.title || ""}
                    />
                    {formik.touched.title && formik.errors.title &&
                        <div className="text-danger">{formik.errors.title}</div>
                    }
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-lbusers" disabled={loading}>
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default EditGlTemplate