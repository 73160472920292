import React, { useContext, useEffect, useState } from "react"
import { Row, Col, CardBody } from "reactstrap"
import {Helmet} from "react-helmet";
import BreadcrumbManual from "../../components/Common/BreadcrumbManual"
import "../Myclients/datatables.scss"
import "../../assets/scss/manual/manual.scss"
import LeaseListView from "components/lease/list";
import LeaseGridView from "components/lease/grid";
import { newPost, get } from 'helpers/axios_helper';
import AppConfig from 'constants/config';
// import axios from "axios";
import toast from "react-hot-toast";
import { AuthContext } from "context/authContext";
import Layout from "components/VerticalLayout";

const Leases = () => {
  const { userData, mode } = useContext(AuthContext)
  const [isListView, setIsListView] = useState(true);
  const [leaseData, setLeaseData] = useState([]);
  // const [leaseCount, setLeaseCount] = useState({})
  const [loading, setLoading] = useState(false);
  const [clientAccess, setClientAccess] = useState({});
  const [review, setReview] = useState({});
  const [lesseeClient, setLesseeClient] = useState([]);
  const [lessorClient, setLessorClient] = useState([]);

  const handleViewChange = () => {
    setIsListView(!isListView);
  }

  const getLeaseData = async (data) => {
    setLeaseData([])
    setLoading(true)
    try {
      let value = await newPost(`${AppConfig.baseUrl}/lease/listing`, data, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      })
      if (value) {
        setLeaseData(value.data)
      } else {
        setLeaseData([])
      }
    } catch (err) {
      console.log('error in listing lease ->', err)
    }
    setLoading(false)
  }

  const getUserClientAccess = async () => {
    try {
      let value = await get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}&customer_id=${userData.cust_info._id}`, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      })
      if (value.error) {
        toast.error(value.error.message || 'Something went wrong');
      }
      else {
        let access = {}
        let review = {}
        let lessee = [];
        let lessor = [];
        for (const d of value.clientData) {
          if (d && d.client_id) {
            access = { ...access, [d.client_id.client]: d.access_type }
            review = { ...review, [d.client_id.client]: d.can_review || false }
            if (d.client_id.client_type == 'lessor') {
              lessor.push({ label: d.client_id.client, value: d.client_id._id })
            } else {
              lessee.push({ label: d.client_id.client, value: d.client_id._id })
            }
          }
        }
        setLesseeClient(lessee)
        setLessorClient(lessor)
        setClientAccess(access)
        setReview(review)
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
  }

  useEffect(() => {
    if (userData && userData.id) {
      getUserClientAccess();
    }
    else {
      setClientAccess({})
      setReview({})
    }
  }, [userData])

  return (
    <Layout>
      <div className="page-content">
        <Helmet>
          <title>LeaseJava | Leases</title>
        </Helmet>
        <div className="container-fluid">
          <BreadcrumbManual title="Tables" breadcrumbItem="leases" handleViewChange={handleViewChange} type="lease" />
          <Row>
            <Col className="col-12">
              {
                isListView ?
                  <LeaseListView InitialData={leaseData} loading={loading} getLeaseData={getLeaseData} clientAccess={clientAccess} reviewAccess={review} clientData={mode == 'lessor' ? lessorClient : lesseeClient} />
                  :
                  <CardBody className="p-0">
                    <LeaseGridView leaseData={leaseData} setLeaseData={setLeaseData} getLeaseData={getLeaseData} loading={loading} clientAccess={clientAccess} clientData={mode == 'lessor' ? lessorClient : lesseeClient} />
                  </CardBody>
              }
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default Leases
