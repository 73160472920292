import React, { useState } from "react"
import {newPost} from 'helpers/axios_helper';
import AppConfig from "constants/config";
import toast from 'react-hot-toast';
import { useNavigate, useLocation, useParams } from "react-router-dom"

const SaveAsDraft = (prop) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {validation, clientData, leaseDraftData, clientName, mode, batchData } = prop
    const params = useParams();
    const [loading, setLoading] = useState(false)

    const queryString = location.search;
    const sp = new URLSearchParams(queryString);
    let path = location && location.pathname.split('/')

    const saveAsDraft = async() => {
        setLoading(true)
        try {
            if(!validation.values.leaseName){
                validation.setFieldTouched('leaseName', true)
            }else{
                const bodyData = validation.values
                let leaseId = params.id;
                bodyData.lease_id= path[3] == 'edit' ? sp.get('lease_id') : leaseId; 
                bodyData.clientId= path[3] == 'edit' ? leaseDraftData.clientId : path[3] == 'clone' ? sp.get('client_id') : leaseId 
                bodyData.mode = mode ? mode : 'lessee'
                if(path[3] == 'add_bulk_lease'){
                    bodyData = {...bodyData, batch_id: batchData.batch_id, batch_no: batchData.batch_no, leaseType: 'bulk', bulkLeaseId: batchData._id, clientId: batchData.client_id}
                }
                bodyData.draftType = path[3] == 'edit' ? 'edit' : 'add';
                let value = await newPost(`${AppConfig.baseUrl}/lease_draft/saveAsDraft`, bodyData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    }
                })
                if(!value.error){
                    let avti = 'draft';
                    let data = { activity: avti, lease_id: value.data.lease_id, client_id: value.data.lease.clientId, client_name: clientName, mode: mode ? mode : 'lessee' }
                    newPost(`${AppConfig.baseUrl}/activity/add_activity`, data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': localStorage.getItem('token')
                        }
                    })
                    toast.success("Lease saved as draft successfully")
                    navigate(`/${localStorage.getItem('company')}/leases`)
                }else{
                    throw new Error(value.title)
                }
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong')
        }
        setLoading(false)
    }

    return (
        <>
            {
                clientData.modules && clientData.modules.includes('audit_category') && 
                <div className="previous ms-3">
                    <button className="btn bg-theme-color w-md" type='button' onClick={saveAsDraft} disabled={loading} >
                        {
                        loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                        }
                        SAVE AS DRAFT
                    </button>
                </div>
            }
        </>
    )
}

export default SaveAsDraft