import React, { useState, useEffect, useContext } from "react";
import { Collapse, Col, Row, TabPane, NavLink, NavItem, TabContent, Nav, Spinner} from "reactstrap";
import classnames from "classnames";
import ExcelJS from "exceljs";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {commaSeperated} from "helpers/formatters"
import moment from "moment";
import { capitalize } from "lodash"
import { AuthContext } from "context/authContext";

const AccountingRollforward = (prop) => {
    const { financingRollforward, operatingRollforward, shortLeaseRollforward, loadingReport,searchResult } = prop;
    const [activeTab, setActiveTab] = useState("1");
    const { mode } = useContext(AuthContext)
 
    useEffect(() => {
        if(operatingRollforward && Object.keys(operatingRollforward).length > 0){
            setActiveTab("1")
        }else if(financingRollforward && Object.keys(financingRollforward).length > 0){
            setActiveTab("2")
        }else if(shortLeaseRollforward && Object.keys(shortLeaseRollforward).length > 0){
            setActiveTab("3")
        }else{
            setActiveTab("4")
        }
    },[operatingRollforward, financingRollforward, shortLeaseRollforward])

    const downloadCSVFile = (type) => {
        var ExcelJSWorkbook = new ExcelJS.Workbook();
        var worksheet1 = ExcelJSWorkbook.addWorksheet("Report criteria");
        worksheet1.getColumn("A").width = 40
        worksheet1.getColumn("B").width = 40
        worksheet1.getCell("A1").value = `Report Type`
        worksheet1.getCell("B1").value = 'Accounting rollforward'
        worksheet1.getCell("A2").value = `Start Date`
        worksheet1.getCell("B2").value = new Date(searchResult.startDate)
        worksheet1.getCell("B2").numFmt = 'mm/dd/yyyy'
        worksheet1.getCell("A3").value = `End Date`
        worksheet1.getCell("B3").value = new Date(searchResult.endDate)
        worksheet1.getCell("B3").numFmt = 'mm/dd/yyyy'

        var worksheet = ExcelJSWorkbook.addWorksheet("Accounting rollforward");
        worksheet.getColumn("A").width = 40
        worksheet.getColumn("B").width = 40
        worksheet.getColumn("C").width = 40
        worksheet.getColumn("D").width = 20
        worksheet.getColumn("E").width = 20
        worksheet.getColumn("F").width = 20
        worksheet.getColumn("G").width = 20

        worksheet.getColumn("H").width = 20
        worksheet.getColumn("I").width = 20
        worksheet.getColumn("J").width = 20
        worksheet.getColumn("K").width = 20
        worksheet.getColumn("L").width = 20
        worksheet.getColumn("M").width = 20

        worksheet.getColumn("N").width = 20
        worksheet.getColumn("O").width = 20
        worksheet.getColumn("P").width = 20
        worksheet.getColumn("Q").width = 20
        worksheet.getColumn("R").width = 20
        if(mode != 'lessor')worksheet.getColumn("S").width = 20

        worksheet.mergeCells("A1:F1");
        worksheet.getCell("A1").value = `Lease Information`
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell("A2").value = `Client Name`
        worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell("B2").value = `Lease Name`
        worksheet.getCell('B2').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell("C2").value = `Date of initial recognition/latest modification`
        worksheet.getCell('C2').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell("D2").value = `Lease end date`
        worksheet.getCell('D2').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell("E2").value = type == 'operating' ? 'Reclass from financing' : 'Reclass from operating'
        worksheet.getCell(type == 'st_lease' ? "E2" : "F2").value = `St.line rent`
        worksheet.getCell(type == 'st_lease' ? "F2" : "G2").value = `Variable payments`

        if(type == 'st_lease'){
            worksheet.mergeCells("G1:K1");
            worksheet.getCell("G1").value = `ST Balance (Negative = liability)`
            worksheet.getCell('G1').alignment = { vertical: 'middle', horizontal: 'center' };
        }else{
            worksheet.mergeCells("H1:M1");
            worksheet.getCell("H1").value = `${ mode == "lessor" ? "Receivable" : "Liability"}`
            worksheet.getCell('H1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.mergeCells(mode != 'lessor' ? "N1:S1" : "N1:R1");
            worksheet.getCell("N1").value = `${ mode == "lessor" ? "Deferred inflow of resources" : "ROU"}`
            worksheet.getCell('N1').alignment = { vertical: 'middle', horizontal: 'center' };
        }

        worksheet.getCell(type == 'st_lease' ? "G2" : "H2").value = `Beginning`
        worksheet.getCell(type == 'st_lease' ? "H2" : "I2").value = type == 'st_lease' ? 'Expense' : `Initial Recognition`
        worksheet.getCell(type == 'st_lease' ? "I2" : "J2").value = type == 'st_lease' ? 'Payment' : `Interest`
        worksheet.getCell(type == 'st_lease' ? "J2" : "K2").value = type == 'st_lease' ? 'transfer' : `Payment`
        worksheet.getCell(type == 'st_lease' ? "K2" : "L2").value = type == 'st_lease' ? 'Ending' : `Modification Adj`
        if(type != 'st_lease') worksheet.getCell("M2").value = `Ending`

        if(type != 'st_lease') worksheet.getCell("N2").value = `Beginning`
        if(type != 'st_lease') worksheet.getCell("O2").value = `Initial Recognition`
        if(type != 'st_lease') worksheet.getCell("P2").value = `${mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`
        if(type != 'st_lease') worksheet.getCell("Q2").value = `Modification Adj`
        if(mode != 'lessor' || type != 'st_lease') worksheet.getCell("R2").value = `Impairment`
        if(type != 'st_lease') worksheet.getCell(mode != 'lessor' ? "S2" : "R2").value = `Ending`
        {   
            type == 'st_lease' ?
            shortLeaseRollforward && shortLeaseRollforward.length > 0 && shortLeaseRollforward.map((value, i) => {
                worksheet.addRow([value.client,  value.name, new Date(moment(value.initial_date,'MM/DD/YYYY').format('YYYY-MM-DD')), new Date(moment(value.end_date,'MM/DD/YYYY').format('YYYY-MM-DD')), value.straight_line_rent ? value.straight_line_rent : 0, value.variable_lease_payment ? value.variable_lease_payment : '', (value.beginningRou || value.initial_recognitionRou || 0) - (value.beginning || value.initial_recognition || 0), -((value.interest || 0) + (value.amort || 0)), value.payment, value.transfer, !value.transfer && (value.endingRou) - (value.ending) ? (value.endingRou) - (value.ending) : 0]);
            })
            :
            type == 'operating' ?
            operatingRollforward && operatingRollforward.length > 0 && operatingRollforward.map((value, i) => {
                worksheet.addRow([value.client,  value.name, new Date(moment(value.initial_date,'MM/DD/YYYY').format('YYYY-MM-DD')), new Date(moment(value.end_date,'MM/DD/YYYY').format('YYYY-MM-DD')), value.reclass_from , value.straight_line_rent ? value.straight_line_rent : 0, value.variable_lease_payment ? value.variable_lease_payment : '', value.beginning ? value.beginning : '', value.initial_recognition ? value.initial_recognition : '', value.interest ? value.interest : 0, value.payment, value.modification_adjustment ? value.modification_adjustment : 0, value.ending ? value.ending : 0, value.beginningRou ? value.beginningRou : '', value.initial_recognitionRou ? value.initial_recognitionRou : '', value.amort ? value.amort : 0, value.modification_adjustmentRou ? value.modification_adjustmentRou : 0, value.impairment ? value.impairment : 0, value.endingRou ? value.endingRou : 0]);
            })
            :
            financingRollforward && financingRollforward.length > 0 && financingRollforward.map((value, i) => {
                mode != 'lessor' ?
                worksheet.addRow([value.client,  value.name, new Date(moment(value.initial_date,'MM/DD/YYYY').format('YYYY-MM-DD')), new Date(moment(value.end_date,'MM/DD/YYYY').format('YYYY-MM-DD')), value.reclass_from , value.straight_line_rent ? value.straight_line_rent : 0, value.variable_lease_payment ? value.variable_lease_payment : '', value.beginning ? value.beginning : '', value.initial_recognition ? value.initial_recognition : '', value.interest ? value.interest : 0, value.payment, value.modification_adjustment ? value.modification_adjustment : 0, value.ending ? value.ending : 0, value.beginningRou ? value.beginningRou : '', value.initial_recognitionRou ? value.initial_recognitionRou : '', value.amort ? value.amort : 0, value.modification_adjustmentRou ? value.modification_adjustmentRou : 0, value.impairment ? value.impairment : 0, value.endingRou ? value.endingRou : 0]) : 
                worksheet.addRow([value.client,  value.name, new Date(moment(value.initial_date,'MM/DD/YYYY').format('YYYY-MM-DD')), new Date(moment(value.end_date,'MM/DD/YYYY').format('YYYY-MM-DD')), value.reclass_from , value.straight_line_rent ? value.straight_line_rent : 0, value.variable_lease_payment ? value.variable_lease_payment : '', value.beginning ? value.beginning : '', value.initial_recognition ? value.initial_recognition : '', value.interest ? value.interest : 0, value.payment, value.modification_adjustment ? value.modification_adjustment : 0, value.ending ? value.ending : 0, value.beginningRou ? value.beginningRou : '', value.initial_recognitionRou ? value.initial_recognitionRou : '', value.amort ? value.amort : 0, value.modification_adjustmentRou ? value.modification_adjustmentRou : 0, value.endingRou ? value.endingRou : 0]);
            }) 
        }
        worksheet.getColumn(3).numFmt = 'mm/dd/yyyy'
        worksheet.getColumn(4).numFmt = 'mm/dd/yyyy'
        worksheet.getColumn(6).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(7).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(8).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(9).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(10).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(11).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(12).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(13).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(14).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(15).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(16).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(17).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(18).numFmt = '##,##,##,##,##0.00'
        mode != 'lessor' && (worksheet.getColumn(19).numFmt = '##,##,##,##,##0.00')

        ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
            var csv_file, download_link;
            csv_file = new Blob([buffer], { type: "application/octet-stream" });
            download_link = document.createElement("a");
            download_link.download = type == 'operating' ? "accounting_rollforward_operating.xlsx" : "accounting_rollforward_financing.xlsx" ;
            download_link.href = window.URL.createObjectURL(csv_file);
            download_link.style.display = "none";
            document.body.appendChild(download_link);
            download_link.click();
        });
    }

    return (
        <React.Fragment> 
            <Nav tabs className="nav-tabs-custom nav-justified">
            {
                operatingRollforward && Object.keys(operatingRollforward).length > 0 &&
                <NavItem style={{textAlign: 'left'}}>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            active: activeTab === "1",
                        })}
                        onClick={() => {
                            setActiveTab("1");
                        }}
                    >
                        <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block font-size-16">OPERATING LEASES</span>
                    </NavLink>
                </NavItem>
            }
            {
                financingRollforward && Object.keys(financingRollforward).length > 0 &&
                <NavItem style={{textAlign: 'left'}}>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            active: activeTab === "2",
                        })}
                        onClick={() => {
                        setActiveTab("2");
                        }}
                    >
                        <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block font-size-16">FINANCING LEASES</span>
                    </NavLink>
                </NavItem>
            }
            {
                shortLeaseRollforward && Object.keys(shortLeaseRollforward).length > 0 &&
                <NavItem style={{textAlign: 'left'}}>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            active: activeTab === "3",
                        })}
                        onClick={() => {
                        setActiveTab("3");
                        }}
                    >
                        <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block font-size-16">ST LEASES</span>
                    </NavLink>
                </NavItem>
            }
            {
                operatingRollforward && Object.keys(operatingRollforward).length < 1 && financingRollforward && Object.keys(financingRollforward).length < 1 && shortLeaseRollforward && Object.keys(shortLeaseRollforward).length < 1 && 
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            active: activeTab === "4",
                        })}
                        onClick={() => {
                        setActiveTab("4");
                        }}
                    >
                        <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                        </span>
                    </NavLink>
                </NavItem>
            }
            </Nav>
            <TabContent
                activeTab={activeTab}
                className="text-muted"
            >
                <TabPane tabId="1">
                    <div className="d-md-flex justify-content-md-end">
                        <button type="button" onClick={()=>{downloadCSVFile('operating')}} className="btn btn-lbusers mt-2 ms-2">
                            Export
                        </button>
                    </div>
                    <Row className="mt-3" >
                        <Col lg={12}>
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0">
                                    <Table className="table text-center rollforward_table">
                                        <Thead>
                                            <Tr>
                                                <Th colSpan={7}>Lease Information</Th>
                                                <Th colSpan={6}>{ mode == "lessor" ? "Receivable" : "Liability"}</Th>
                                                <Th colSpan={6}>{ mode == "lessor" ? "Deferred inflow of resources" : "ROU"}</Th>
                                            </Tr>
                                        </Thead>
                                        <Thead>
                                            <Tr>
                                                <Th>Client Name</Th>
                                                <Th>Lease Name</Th>
                                                <Th>Date of initial recognition/latest modification</Th>
                                                <Th>Lease end date</Th>
                                                <Th>Reclass from</Th>
                                                <Th>St.line rent</Th>
                                                <Th>Variable payments</Th>
                                                <Th>Beginning</Th>
                                                <Th>Initial Recognition</Th>
                                                <Th>Interest</Th>
                                                <Th>Payment</Th>
                                                <Th>Modification Adj</Th>
                                                <Th>Ending</Th>
                                                <Th>Beginning</Th>
                                                <Th>Initial Recognition</Th>
                                                <Th>{mode == 'lessor' ? 'Lease Revenue' : 'Amort'}</Th>
                                                <Th>Modification Adj</Th>
                                                {mode != 'lessor' && <Th>Impairment</Th>}
                                                <Th>Ending</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {operatingRollforward && operatingRollforward.length > 0 && operatingRollforward.map((value, i) => {
                                            return <Tr key={value.type + i} >
                                                <Td>{value.client}</Td>
                                                <Td>{value.name}</Td>
                                                <Td>{value.initial_date}</Td>
                                                <Td>{value.end_date}</Td>
                                                <Td>{capitalize(value.reclass_from)}</Td>
                                                <Td>{value.straight_line_rent && Number(Number(value.straight_line_rent).toFixed(2)) != 0 ? commaSeperated((Number(value.straight_line_rent)).toFixed(2)) : ''}</Td>
                                                <Td>{value.variable_lease_payment && Number(Number(value.variable_lease_payment).toFixed(2)) != 0 ? commaSeperated(value.variable_lease_payment) : ''}</Td>
                                                <Td>{value.beginning && Number(Number(value.beginning).toFixed(2)) != 0 ? commaSeperated((Number(value.beginning)).toFixed(2)) : ''}</Td>
                                                <Td>{value.initial_recognition && Number(Number(value.initial_recognition).toFixed(2)) != 0 ? commaSeperated((Number(value.initial_recognition)).toFixed(2)) : ''}</Td>
                                                <Td>{value.interest && Number(Number(value.interest).toFixed(2)) != 0 ? commaSeperated((Number(value.interest)).toFixed(2)) : ''}</Td>
                                                <Td>{Number(Number(value.interest).toFixed(2)) != 0 ? commaSeperated(value.payment) :''}</Td>
                                                <Td>{value.modification_adjustment && Number(Number(value.modification_adjustment).toFixed(2)) != 0 ? commaSeperated((Number(value.modification_adjustment)).toFixed(2)) : ''}</Td>
                                                <Td>{value.ending && Number(Number(value.ending).toFixed(2)) != 0 ? commaSeperated((Number(value.ending)).toFixed(2)) : ''}</Td>
                                                <Td>{value.beginningRou && Number(Number(value.beginningRou).toFixed(2)) != 0 ? commaSeperated((Number(value.beginningRou)).toFixed(2)) : ''}</Td>
                                                <Td>{value.initial_recognitionRou && Number(Number(value.initial_recognitionRou).toFixed(2)) != 0 ? commaSeperated((Number(value.initial_recognitionRou)).toFixed(2)) : ''}</Td>
                                                <Td>{value.amort && Number(Number(value.amort).toFixed(2)) != 0 ? commaSeperated((Number(value.amort)).toFixed(2)) : ''}</Td>
                                                <Td>{value.modification_adjustmentRou && Number(Number(value.modification_adjustmentRou).toFixed(2)) != 0 ? commaSeperated((Number(value.modification_adjustmentRou)).toFixed(2)) : ''}</Td>
                                                {mode != 'lessor' && <Td>{value.impairment && Number(Number(value.impairment).toFixed(2)) != 0 ? commaSeperated((Number(value.impairment)).toFixed(2)) : ''}</Td>}
                                                <Td>{value.endingRou && Number(Number(value.endingRoue).toFixed(2)) != 0 ? commaSeperated((Number(value.endingRou)).toFixed(2)) : ''}</Td>
                                            </Tr>
                                            })}
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <div className="d-md-flex justify-content-md-end">
                        <button type="button" onClick={()=>{downloadCSVFile('financing')}} className="btn btn-lbusers mt-2 ms-2">
                            Export
                        </button>
                    </div>
                    <Row className="mt-3">
                        <Col lg={12}>
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0">
                                    <Table className="table text-center rollforward_table">
                                        <Thead>
                                            <Tr>
                                                <Th colSpan={7}>Lease Information</Th>
                                                <Th colSpan={6}>{ mode == "lessor" ? "Receivable" : "Liability"}</Th>
                                                <Th colSpan={6}>{ mode == "lessor" ? "Deferred inflow of resources" : "ROU"}</Th>
                                            </Tr>
                                        </Thead>
                                        <Thead>
                                            <Tr>
                                                <Th>Client Name</Th>
                                                <Th>Lease Name</Th>
                                                <Th>Date of initial recognition/latest modification</Th>
                                                <Th>Lease end date</Th>
                                                <Th>Reclass from</Th>
                                                <Th>St.line rent</Th>
                                                <Th>Variable payments</Th>
                                                <Th>Beginning</Th>
                                                <Th>Initial Recognition</Th>
                                                <Th>Interest</Th>
                                                <Th>Payment</Th>
                                                <Th>Modification Adj</Th>
                                                <Th>Ending</Th>
                                                <Th>Beginning</Th>
                                                <Th>Initial Recognition</Th>
                                                <Th>{mode == 'lessor' ? 'Lease Revenue' : 'Amort'}</Th>
                                                <Th>Modification Adj</Th>
                                                {mode != 'lessor' && <Th>Impairment</Th>}
                                                <Th>Ending</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {financingRollforward && financingRollforward.length > 0 && financingRollforward.map((value, i) => {
                                            return <Tr key={value.type + i} >
                                                <Td>{value.client}</Td>
                                                <Td>{value.name}</Td>
                                                <Td>{value.initial_date}</Td>
                                                <Td>{value.end_date}</Td>
                                                <Td>{capitalize(value.reclass_from)}</Td>
                                                <Td>{value.straight_line_rent && Number(Number(value.straight_line_rent).toFixed(2)) != 0 ? commaSeperated((Number(value.straight_line_rent)).toFixed(2)) : ''}</Td>
                                                <Td>{value.variable_lease_payment && Number(Number(value.variable_lease_payment).toFixed(2)) != 0 ? commaSeperated(value.variable_lease_payment) : ''}</Td>
                                                <Td>{value.beginning && Number(Number(value.beginning).toFixed(2)) != 0 ? commaSeperated((Number(value.beginning)).toFixed(2)) : ''}</Td>
                                                <Td>{value.initial_recognition && Number(Number(value.initial_recognition).toFixed(2)) != 0 ? commaSeperated((Number(value.initial_recognition)).toFixed(2)) : ''}</Td>
                                                <Td>{value.interest && Number(Number(value.interest).toFixed(2)) != 0 ? commaSeperated((Number(value.interest)).toFixed(2)) : ''}</Td>
                                                <Td>{Number(Number(value.payment).toFixed(2)) != 0 ? commaSeperated(value.payment) : ''}</Td>
                                                <Td>{value.modification_adjustment && Number(Number(value.modification_adjustment).toFixed(2)) != 0 ? commaSeperated((Number(value.modification_adjustment)).toFixed(2)) : ''}</Td>
                                                <Td>{value.ending && Number(Number(value.ending).toFixed(2)) != 0 ? commaSeperated((Number(value.ending)).toFixed(2)) : ''}</Td>
                                                <Td>{value.beginningRou && Number(Number(value.beginningRou).toFixed(2)) != 0 ? commaSeperated((Number(value.beginningRou)).toFixed(2)) : ''}</Td>
                                                <Td>{value.initial_recognitionRou && Number(Number(value.initial_recognitionRou).toFixed(2)) != 0 ? commaSeperated((Number(value.initial_recognitionRou)).toFixed(2)) : ''}</Td>
                                                <Td>{value.amort && Number(Number(value.amort).toFixed(2)) != 0 ? commaSeperated((Number(value.amort)).toFixed(2)) : ''}</Td>
                                                <Td>{value.modification_adjustmentRou && Number(Number(value.modification_adjustmentRou).toFixed(2)) != 0 ? commaSeperated((Number(value.modification_adjustmentRou)).toFixed(2)) : ''}</Td>
                                                {mode != 'lessor' && <Td>{value.impairment && Number(Number(value.impairment).toFixed(2)) != 0 ? commaSeperated((Number(value.impairment)).toFixed(2)) : ''}</Td>}
                                                <Td>{value.endingRou && Number(Number(value.endingRou).toFixed(2)) != 0 ? commaSeperated((Number(value.endingRou)).toFixed(2)) : ''}</Td>
                                            </Tr>
                                            })}
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    <div className="d-md-flex justify-content-md-end">
                        <button type="button" onClick={()=>{downloadCSVFile('st_lease')}} className="btn btn-lbusers mt-2 ms-2">
                            Export
                        </button>
                    </div>
                    <Row className="mt-3">
                        <Col lg={12}>
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0">
                                    <Table className="table text-center rollforward_table">
                                        <Thead>
                                            <Tr>
                                                <Th colSpan={7}>Lease Information</Th>
                                                <Th colSpan={5}>ST balance (Negative = liability)</Th>
                                            </Tr>
                                        </Thead>
                                        <Thead>
                                            <Tr>
                                                <Th>Client Name</Th>
                                                <Th>Lease Name</Th>
                                                <Th>Date of initial recognition/latest modification</Th>
                                                <Th>Lease end date</Th>
                                                <Th>Reclass to</Th>
                                                <Th>St.line rent</Th>
                                                <Th>Variable payments</Th>
                                                <Th>Beginning</Th>
                                                <Th>Expense</Th>
                                                <Th>Payment</Th>
                                                <Th>Transfer</Th>
                                                <Th>Ending</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {shortLeaseRollforward && shortLeaseRollforward.length > 0 && shortLeaseRollforward.map((value, i) => {
                                            return <Tr key={value.type + i} >
                                                <Td>{value.client}</Td>
                                                <Td>{value.name}</Td>
                                                <Td>{value.initial_date}</Td>
                                                <Td>{value.end_date}</Td>
                                                <Td>{capitalize(value.reclass_from)}</Td>
                                                <Td>{value.straight_line_rent && Number(Number(value.straight_line_rent).toFixed(2)) != 0 ? commaSeperated((Number(value.straight_line_rent)).toFixed(2)) : ''}</Td>
                                                <Td>{value.variable_lease_payment && Number(Number(value.variable_lease_payment).toFixed(2)) != 0 ? commaSeperated(value.variable_lease_payment) : ''}</Td>                                                
                                                <Td>{Number(Number((value.beginningRou || value.initial_recognitionRou || 0) - (value.beginning || value.initial_recognition || 0)).toFixed(2)) != 0 ? commaSeperated((Number((value.beginningRou || value.initial_recognitionRou || 0) - (value.beginning || value.initial_recognition || 0))).toFixed(2)) : ''}</Td>
                                                <Td>{Number(Number((value.interest || 0) + (value.amort || 0)).toFixed(2)) != 0 ? commaSeperated((-Number((value.interest || 0) + (value.amort || 0))).toFixed(2)) : ''}</Td>
                                                <Td>{Number(Number(value.payment).toFixed(2)) != 0 ? commaSeperated(value.payment) : ''}</Td>
                                                <Td>{Number(Number(value.transfer).toFixed(2)) != 0 ? commaSeperated(value.transfer) : ''}</Td>
                                                <Td>{Number(Number((value.endingRou || 0) - (value.ending || 0)).toFixed(2)) != 0 && !(Number(value.transfer).toFixed(2)) ? commaSeperated((Number((value.endingRou || 0) - (value.ending || 0))).toFixed(2)) : ''}</Td>
                                            </Tr>
                                            })}
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="4">
                    <div className="text-center mt-4">
                        {
                            loadingReport ? <Spinner color="primary" className="text-lbusers" /> : !loadingReport && searchResult && searchResult.is_processing ? <div><h6>Your data is being processed</h6><Spinner color="primary" className="text-lbusers" /></div> : "No data found"
                        }
                    </div>
                </TabPane>
            </TabContent> 
        </React.Fragment>
    )
}

export default AccountingRollforward