import React, { useState, useContext, useEffect } from "react"
import { Row, Col, Modal } from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import "flatpickr/dist/themes/material_blue.css";
import { NavLink } from "react-router-dom";
import { get} from 'helpers/axios_helper';
import AppConfig from 'constants/config';
import { AuthContext } from "context/authContext"

const AddClientToLease = (prop) => {
    const { addOpen, toggleAdd, setOpenPopup, type, lease_id } = prop;
    const { userData,mode } = useContext(AuthContext)
    const [clientId, setClientId] = useState('');
    const [clientData, setClientData] = useState('');
    
    const callFunction = (e) => {
        if(e){
            setClientId(e)
        } else {
            setClientId('')
        }
    }

    const getUserClient = async() =>{
        let value = await get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}&customer_id=${userData.cust_info._id}&access_type=full_access&client_type=${mode || 'lessee'}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
            })
            setClientData(value.clientData)
    }

    useEffect(()=>{
        if (userData) {
            getUserClient()
        }
    },[userData,mode])

    return (
        <Modal
            isOpen={addOpen}
            toggle={() => {
                toggleAdd();
            }}
            size="md"
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Select Client</h5>
                <button
                    type="button"
                    onClick={() => {
                        setOpenPopup(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form>
                    <Row>
                        <Col md="12">
                            <select className="form-control arrows" id="clientDataList" placeholder="Type to search..." type='select' onChange={(e) => callFunction(e.target.value)}>
                                <option value=''>Select Client</option>
                                { clientData && clientData.map((data,i)=>
                                    <option key={i+data.client_id.client} value={data.client_id._id} >{data.client_id.client}</option>
                                )}
                            </select>

                        </Col>
                        
                    </Row>
                    <div className="modal-footer mt-3">
                        { clientId == '' ?
                            <button type="button" className="btn btn-lbusers" disabled>
                                SUBMIT
                            </button> :
                            <NavLink to={ type == 'clone' ? `/${localStorage.getItem('company')}/leases/clone?lease_id=${lease_id}&client_id=${clientId}` : `/${localStorage.getItem('company')}/leases/add/${clientId}`}>
                                <button type="button" className="btn btn-lbusers">
                                    SUBMIT
                                </button>
                            </NavLink>
                        }
                    </div>
                 </form>
            </div>
        </Modal>
    )
}

export default AddClientToLease