import React,{useContext, useEffect, useState} from "react"
import {Modal} from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import { AuthContext } from "context/authContext"
import toast from "react-hot-toast";
import axios from 'axios'
import AppConfig from 'constants/config'

const ShortTermLease = (prop) => {
    const {shortTermOpen, setShortTermOpen, lease, refreshData} = prop;
    const [loading, setLoading] = useState(false)
    const { mode } = useContext(AuthContext)

  const shortTermL = async (d) => {
    setLoading(true)
    try {
      const { data } = await axios.post(`${AppConfig.baseUrl}/lease/short_lease`, { lease_id: d && d.id, mode: mode ? mode : 'lessee',shortTerm:d && d.shortTerm }, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {        
        toast.success(data.title || 'Lease updated successfully');
        refreshData()
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
    }
    setShortTermOpen(false);
    setLoading(false);
  }

    return (
        <Modal
            isOpen={shortTermOpen}
            toggle={() => {
                setShortTermOpen(false);
            }}
            centered
        >
        <div className="modal-header">
            <h5 className="modal-title mt-0">Short Term</h5>
            <button
                type="button"
                onClick={()=>{setShortTermOpen(false)}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <div className="mb-3">
                Do you want to mark this lease as short term lease ?
            </div>
            <div className="modal-footer mt-3">
                <button type="button" className="btn btn-lbusers" disabled={loading} onClick={()=>{
                    setShortTermOpen(false);
                }}>
                    No
                </button>
                <button type="submit" className="btn btn-lbusers" onClick={() => shortTermL({id:lease._id,shortTerm: lease.is_short_term ? false : true})} disabled={loading}>
                    Yes
                </button>
            </div>
        </div>
        </Modal>
    )
}

export default ShortTermLease