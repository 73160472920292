import React, { useState } from "react"
import {Modal} from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropImage = (prop) => {
    const { cropOpen, setCropOpen, image, setImage } = prop;
    const [cropper, setCropper] = useState("");

    const handleCropClose = () => {
        setCropper("");
        setCropOpen(false);
    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setImage(cropper.getCroppedCanvas().toDataURL());
            handleCropClose()
        }
    };

    return (
        <Modal
            isOpen={cropOpen}
            toggle={() => {
                setCropOpen(!cropOpen);
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Crop Image</h5>
                <button
                    type="button"
                    onClick={handleCropClose}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3">
                    <Cropper
                        style={{ height: 200, width: "100%" }}
                        zoomTo={0.5}
                        aspectRatio={1}
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
                </div>
                <button className="btn btn-lbusers" onClick={getCropData} type="button">
                    Crop Image
                </button>
            </div>
        </Modal>
    )
}

export default CropImage