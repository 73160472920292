import React, { useState, useEffect, useContext} from "react"
import {Helmet} from "react-helmet";
import { Card,CardBody, Container,Row, Col, Modal, Spinner, Label, Input } from "reactstrap"
import Breadcrumbs from "components/Common/BreadcrumbBulkUpload";
import { AuthContext } from "context/authContext";
import toast from "react-hot-toast";
import axios from "axios";
import AppConfig from "constants/config";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";

const BulkUpload = () => {
    const navigate = useNavigate()
    const { userData, mode, reloadProcessingBatches } = useContext(AuthContext)
    const [batchData, setBatchData] = useState([{}]);
    const [loadingBatches, setLoadingBatches] = useState(true);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState("");
    const [localFile, setLocalFile] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState({});
    const [checked, setChecked] = useState(false)
    const [leaseCount, setLeaseCount] = useState([])
  
    const columns = [
        {
          label: 'Sr. No.',
          name: 'sr_no',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '20px',height: '40px',paddingLeft: '15px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '20px',height: '50px',paddingLeft: '15px'}}),
            customBodyRender:(value, tableMeta, updateValue)=> (tableMeta && tableMeta.rowIndex) + 1,
          }
        },
        {
          label: 'Date Time',
          name: 'date',
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite:(dataIndex)=> moment(batchData && batchData[dataIndex] && batchData[dataIndex].createdAt).format('MM/DD/YYYY HH:mm'),
          }
        },
        {
          label: 'Batch ID',
          name: 'batch_no',
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite:(dataIndex)=>(batchData && batchData[dataIndex] && batchData[dataIndex].batch_no)
          }
        },
        {
            label: 'Leases Uploaded',
            name: 'leases',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    let succedded = leaseCount.filter(v => v._id == (batchData && batchData[dataIndex] && batchData[dataIndex]._id))
                    let count = succedded && succedded.length > 0 ? succedded[0].total : '0'
                    return <div>{batchData && batchData[dataIndex] ? `${count ? count : batchData[dataIndex].lease_succeed}/${batchData[dataIndex].total_leases}` : ''}</div>
                }
            }
        },
        {
          label: 'Actions',
          name: 'actions',
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite:(dataIndex)=>(
                <>
                    <button
                        type="button"
                        className="btn btn-lbusers btn-sm"
                        onClick={() => {
                            navigate(`/${localStorage.getItem('company')}/bulk_upload/details/${batchData && batchData[dataIndex] && batchData[dataIndex]._id}`)
                        }}
                        disabled={loading} 
                    >
                        {
                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                        }
                        Details
                    </button>
                    <button
                        type="button"
                        className="btn btn-lbusers btn-sm ms-1"
                        onClick={() => {
                            setDeleteOpen(true);
                            setSelectedBatch(batchData && batchData[dataIndex]);
                        }}
                        disabled={loading} 
                    >
                        {
                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                        }
                        Delete
                    </button>
                </>
            ),
          }
        },
    ]
  
    const getBatchData = async() =>{
        setLoadingBatches(true);
        setBatchData([]);
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/bulk_lease/get_batches?mode=${mode ? mode : 'lessee'}`, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setBatchData(data.batchData);
                setLeaseCount(data.succeddedLeases)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoadingBatches(false)
    }
  
    useEffect(() => {
        if(userData && userData.id){
            getBatchData();
        }
    }, [userData,mode])

    const uploadBulkData = async(e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const dataArray = new FormData();
            dataArray.append('type', 'lease')
            dataArray.append('file', file)
            dataArray.append('mode',mode ? mode : 'lessee' )
            const {data} = await axios.post(`${AppConfig.baseUrl}/bulk_lease/bulk_add`, dataArray ,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                toast.success(data.title);
                reloadProcessingBatches()
            }
            // else{
            //     throw new Error(data.title);
            // }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
        setFile("");
        setLocalFile("");
        getBatchData();
    }

    const handleDeleteBatch = async(e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const {data} = await axios.post(`${AppConfig.baseUrl}/bulk_lease/delete_batch`, {batch_id: selectedBatch._id,mode: mode ? mode : 'lessee', lease_delete: checked } ,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                toast.success('Batch deleted successfully');
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
        getBatchData()
        setSelectedBatch({})
        setDeleteOpen(false)
        setChecked(false);
    }

    const handleChangeFile = (e) => {
        e.preventDefault();
        setLocalFile(e.target.value)
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        setFile(files[0])
    };

    const downloadTemplate = async(type) => {
        setDownloading(true);
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/bulk_lease/get_template?type=${type}` ,{
                headers: { token: localStorage.getItem("token") },
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${type}_template.xlsx`);
            document.body.appendChild(link);
            link.click();
            toast.success('Template downloaded successfully');
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setDownloading(false)
    }

    const toggleDelete = () => {
        setDeleteOpen(false);
        setSelectedBatch({});
        setChecked(false);
    }

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Bulk Upload</title>
                </Helmet>
                <Container fluid>
                    <Breadcrumbs title="bulk_upload"
                        breadcrumbItem="Bulk Upload"
                        type="bulk_upload"
                    />
                    <Card>
                        <CardBody>
                            <h5 className="card-title mb-4">Upload Leases</h5>
                            <form onSubmit={uploadBulkData}>
                                <Row className="mt-4">
                                    <Col sm="4">
                                        <div>
                                            <input 
                                                className="form-control" 
                                                id="formFileLg" 
                                                type="file" 
                                                value={localFile || ""}
                                                accept=".xlsx" 
                                                onChange={handleChangeFile}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="2">
                                        <button type="submit" className="btn btn-lbusers" disabled={loading || !file} >
                                            {
                                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                            }
                                            Submit
                                        </button>
                                    </Col>
                                    <Col sm="6" className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-lbusers me-2" disabled={downloading} onClick={() => {downloadTemplate('lease')}} >
                                            {
                                                downloading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                            }
                                            Download Lease Template
                                        </button>
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <h5 className="card-title mb-4">Batches</h5>
                            <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                                MUIDataTableToolbar:{
                                    styleOverrides:{
                                    actions: {
                                        display: 'contents'
                                    }
                                    }
                                }
                            }})}>
                            <MUIDataTable
                                title=""
                                data={batchData}
                                columns={columns}
                                options={{ 
                                rowsPerPage:10, 
                                print:false, 
                                download:false, 
                                viewColumns:false,
                                filter:false, 
                                selectableRows:"none", 
                                search:false, 
                                pagination:false,
                                rowsPerPageOptions:[], 
                                responsive:'scroll',
                                textLabels: {
                                    body: {
                                      noMatch: loadingBatches ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                    },
                                    viewColumns: {
                                    title: "",
                                    },
                                } 
                                }}
                            />
                            </ThemeProvider>
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal
                isOpen={deleteOpen}
                toggle={() => {
                    toggleDelete();
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Delete Batch</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setDeleteOpen(false);
                            setSelectedBatch({});
                            setChecked(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleDeleteBatch}>
                        <div className="mb-3">
                        Are you sure you want to delete this batch? You can choose to either delete the batch only and keep the leases, or delete both the batch and all leases within it.
                        </div>
                        <div>
                            <Input
                                name="lease_deleted"
                                id="lease_deleted"
                                type="checkbox"
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                            />
                            <Label htmlFor="lease_deleted" className="ms-2">Delete leases with the batch</Label>
                        </div>
                        {checked && <span>Note: Any leases in the batch that were modified will not be deleted.</span>}                                        
                        <div className="modal-footer mt-3">
                            <button
                                type="button"
                                className="btn btn-lbusers"
                                onClick={() => {
                                    setDeleteOpen(false);
                                    setSelectedBatch({});
                                }}
                                disabled={loading} 
                            >
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                No
                            </button>
                            <button type="submit" className="btn btn-lbusers" disabled={loading} >
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                Yes
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </Layout>
    )
}

export default BulkUpload