/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Input, Spinner } from "reactstrap"
import EditClient from "pages/Myclients/editClient"
import DeleteClient from "pages/Myclients/deleteClient";
import ButtonDropdownClient from './ButtonDropdown';
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const ClientListView = (prop) => {
  const { clientData, getClientsData, leaseData, loading } = prop;
  const [selectedOptions, setSelectedOption] = useState("");
  const [finalClientData, setFinalClientData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [search, setSearch] = useState("");

  const columns = [
    {
      name: "client",
      label: "Client",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px',height: '70px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px',height: '50px'}}),
        customBodyRenderLite:(dataIndex)=>(
          <>
            {!finalClientData[dataIndex].logo ? (
              <div style={{ display: 'flex', flexDirection: 'row', minWidth: '300px' }}>
                <div className="avatar-sm">
                  <button type="button" className="btn rounded-circle avatar-sm bg-lbusers text-white title" >
                    {finalClientData[dataIndex].client.charAt(0)}
                  </button>
                </div>
                <div className="client">
                  <div>{finalClientData[dataIndex].client}</div>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row', minWidth: '300px' }}>
                <div className="avatar-sm">
                  <img
                    className="rounded-circle avatar-sm"
                    src={finalClientData[dataIndex].logo}
                    alt=""
                  />
                </div>
                <div className="client">
                  <div>{finalClientData[dataIndex].client}</div>
                </div>
              </div>
            )}
          </>
        ),
      },
    },
    {
      label: "Contact",
      name: "contact_person",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
          <div>
            {finalClientData[dataIndex].contact_person}
          </div>
        ),
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
          <div>
            {finalClientData[dataIndex].email}
          </div>
        ),
      }
    },
    {
      name: "no_of_leases",
      label: "# Of Leases",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
          <div
            tabIndex="0" dataToggle="tooltip"
            title={`Active Leases: ${finalClientData[dataIndex].leases.activeLeases || 0} \nTerminated Leases: ${finalClientData[dataIndex].leases.terminatedLeases || 0} \nInactive Leases: ${finalClientData[dataIndex].leases.expiredLeases || 0} \nDrafted Leases: ${finalClientData[dataIndex].leases.draftedLeases || 0}`}
          >
            {finalClientData[dataIndex].no_of_leases}
          </div>
        ), 
      },   
      
    },
    {
      name: "access_type",
      label: "Access Type",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
          <div className={`drp text-${finalClientData[dataIndex].access_type === 'full_access' ? 'success' : 'warning'}`}
            style={{ fontWeight: 'bold' }}
          >
            {(finalClientData[dataIndex].access_type).replace("_", " ").toUpperCase()}
          </div>
        ), 
      }
    },
    {
      name: "menu",
      label: 'Actions',
      options:{
        filter: false,
        sort: false,
        customBodyRenderLite:(dataIndex)=>(
          <div className="mt-2">
            <ButtonDropdownClient setEditOpen={setEditOpen} setDeleteOpen={setDeleteOpen} client={finalClientData[dataIndex].all_data.client_id} setSelectedClient={setSelectedClient} type="client" access={finalClientData[dataIndex].access_type} />
          </div>
        ),
      }
    },
  ];

  const handleChangeAccessType = (e) => {
    setSelectedOption(e.target.value)
    getClientsData({ access_type: e.target.value })
  }

  const handleSearch = (value) => {
    const data = originalData.filter(v => v.client.match(new RegExp(value,"i")) || v.contact_person.match(new RegExp(value,"i")))
    setFinalClientData(data)
  }


  useEffect(() => {
    let data = []
    for (const val of clientData) {
      const clientLeases = leaseData && leaseData[0] ? leaseData.filter(v => String(v._id) == String(val.client_id._id)) : []
      const no_of_leases = clientLeases && clientLeases[0] ? Number(clientLeases[0].activeLeases || 0) + ((Number(clientLeases[0].terminatedLeases || 0) + Number(clientLeases[0].expiredLeases || 0) + Number(clientLeases[0].draftedLeases || 0)) / 4) : 0
      data.push({
        client: val.client_id.client,
        logo: val.client_id.logo,
        contact_person: val.client_id.contact_person,
        email: val.client_id.email,
        no_of_leases: no_of_leases || 0,
        access_type: val.access_type,
        all_data: val,
        leases: clientLeases && clientLeases[0] ? clientLeases[0] : {}
      })
    }
    const data1 = data.filter(v => v.client.match(new RegExp(search,"i")) || v.contact_person.toString().match(new RegExp(search,"i")))
    setFinalClientData(data1)
    setOriginalData(data)
  }, [clientData, leaseData]);

  // toggle edit account modal
  const toggleEdit = () => {
    setEditOpen(!editOpen);
  }
  // toggle delete account modal
  const toggleDelete = () => {
    setDeleteOpen(!deleteOpen);
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
            <MUIDataTable
              title={
                <Row className="row">
                  <Col md="4" className="search-p">
                    <div className="me-2 mb-3 mt-3">
                      <div className="position-relative sw">
                      <Input
                          placeholder="Search"
                          type="text"
                          value={search}
                          onChange={(e) => {handleSearch(e.target.value);setSearch(e.target.value)}}
                      />
                      </div>
                    </div>
                  </Col>
                  <Col md="4" className="dropdown-p">
                    <div className="mb-3 mt-3">
                      <select
                        value={selectedOptions}
                        onChange={handleChangeAccessType}
                        id="formrow-InputState"
                        className="form-control"
                      >
                        <option name="accesstype" value="">Access Type</option>
                        <option name="read_only" value="read_only">Read Only</option>
                        <option name="full_acess" value="full_access">Full Access</option>
                      </select>
                    </div>
                  </Col>
                </Row>
              }
              data={finalClientData}
              columns={columns}
              options={{ 
                rowsPerPage:10, 
                print:false, 
                download:false, 
                filter:false, 
                selectableRows:"none", 
                search:false, 
                rowsPerPageOptions:[], 
                responsive:'scroll',
                textLabels: {
                  body: {
                    noMatch: loading || finalClientData.length != clientData.length ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                  },
                  viewColumns: {
                    title: "",
                  },
                } 
              }}
            />
          </ThemeProvider>
        </CardBody>
      </Card>
      <EditClient editOpen={editOpen} toggleEdit={toggleEdit} setEditOpen={setEditOpen} client={selectedClient} getClientsData={getClientsData} setSelectedClient={setSelectedClient} />
      <DeleteClient deleteOpen={deleteOpen} toggleDelete={toggleDelete} setDeleteOpen={setDeleteOpen} client={selectedClient} getClientsData={getClientsData} setSelectedClient={setSelectedClient} />
    </React.Fragment>);
}

export default ClientListView