import React,{useEffect, useState, useContext} from "react"
import { Modal, Label, Input, Row, Col, InputGroup, Form, Card, CardBody, NavItem, NavLink, TabContent, TabPane, Spinner, Table, Nav } from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { newPost, get } from 'helpers/axios_helper';
import { useFormik } from "formik";
import { object, string, number } from "yup";
import AppConfig from 'constants/config';
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom"
import { AuthContext } from "context/authContext"
import classnames from "classnames"
import { commaSeperated } from "helpers/formatters"
import axios from "axios";

const Impairment = (prop) => {
    const navigate = useNavigate();
    const { reloadProcessingStatus, mode, userData } = useContext(AuthContext);
    const {impairment, toggleImpairment, setImpairment, leaseId, refreshData } = prop;
    const [leaseDetails, setLeaseDetails] = useState({});
    const [rouData, setRouData] = useState({});
    const [amount, setAmount] = useState(0);
    const [validate, setValidate] = useState({ min: '', max: '' });
    const [loadingImpairment, setLoadingImpairment] = useState(false)
    const [isCheck, setIsCheck] = useState(false)
    const [ impairmentData, setImpairmentData ] = useState([])
    const [activeTab, setActiveTab] = useState("1");
    const [leaseDraftData, setLeaseDraftData] = useState('')
    const [clientName, setClientName] = useState('')
    const [impairDate, setImpairDate] = useState('');
    const [type, setType] = useState('')

    const getLeaseData = async() => {
        let value = await get(`${AppConfig.baseUrl}/lease/lease_details?Id=${leaseId}&type=other&mode=${mode || 'lessee'}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        let value1 = await get(`${AppConfig.baseUrl}/rou_asset/get_rou_asset?Id=${leaseId}&mode=${mode || 'lessee'}&customer_id=${userData.cust_info._id}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if(!value1.error){
            setRouData(value1.data)
        }
        if(value.error === false) {
            setLeaseDetails(value.data)
        }
    }

    useEffect(() => {
        if(leaseId){
            getLeaseData()
        }
    },[leaseId])

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: moment().add(1,'days').format('MM/DD/YYYY'),
            amount: 0,
            note: '',
        },
        validationSchema: object().shape({
            date: string().required("Please select impairment date"),
            amount: number().min(0).max(amount ? amount.toFixed(2) : 0).required(),
            note: string()
        }),
        onSubmit: (values) => {
            let rouEndDate = rouData && rouData.rou_assets && rouData.rou_assets.length > 0 && rouData.rou_assets[rouData.rou_assets.length - 1];
            hitAxios(values, rouEndDate)
        }
    });

    const hitAxios = async(data, rouEndDate) => {
        setLoadingImpairment(true)
        let rouDate = rouEndDate ? moment(rouEndDate.date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        let newDate = { lease_id: leaseId, date: moment(data.date).format('YYYY-MM-DD'), endDate: rouDate, amount: isCheck ? Number(amount || 0) : Number(data.amount || 0), note: data.note ,mode: mode ? mode : 'lessee'}
        let value = await newPost(`${AppConfig.baseUrl}/rou_asset/impairment`, newDate, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if(value.error === false) {
            reloadProcessingStatus()
            let data1 = { activity: 'impairment', lease_id: leaseId, client_id: leaseDetails.client_id._id, client_name: leaseDetails.client_id.client, mode: mode ? mode : 'lessee' }
            await newPost(`${AppConfig.baseUrl}/activity/add_activity`, data1, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            toast.success(value.title);

            if(window.location.href.includes(`/leases/details/${leaseId}`)){
                window.location.href = `/${localStorage.getItem('company')}/leases/details/${leaseId}/refresh`
            }else{
                navigate(`/${localStorage.getItem('company')}/leases/details/${leaseId}/refresh`);
            }
        } else {
            toast.error(value.title);
        }
        setImpairment(!impairment)
        setLoadingImpairment(false)
    }

    useEffect( () => {
        if(leaseDraftData && Object.keys(leaseDraftData).length > 0){ 
            getClientData()
            const impairData = leaseDraftData && leaseDraftData.impairments ? leaseDraftData.impairments : []
            const usefulData = leaseDraftData && leaseDraftData.usefulLifes ? leaseDraftData.usefulLifes : []
            if(impairData && impairData.length > 0 || usefulData && usefulData.length > 0 || leaseDetails && leaseDetails.is_modified){
                let filterArr = [...impairData, ...usefulData].sort(function (a, b) {
                    return new Date(a.date) - new Date(b.date);
                })
                const data = filterArr[filterArr.length - 1]
                if(leaseDetails && leaseDetails.is_modified && moment(leaseDetails.modification_date).isAfter(moment(data && data.date ? data.date : leaseDetails.commencement, "YYYY-MM-DD"))){
                    setType('modify')
                    setImpairDate(moment(leaseDetails.modification_date, "YYYY-MM-DD").format('MM/DD/YYYY'))
                } else if(data.endDate){
                    setType('use_ful')
                    setImpairDate(data.date)
                }else{
                    setType('impairment')
                    setImpairDate(data.date)
                }
            }
            setImpairmentData(impairData)
            if(usefulData && usefulData.length > 0 && impairData && impairData.length > 0){
                if(moment(impairData[impairData.length-1].date).isSameOrAfter(moment(usefulData[usefulData.length-1].date))){  
                    const date = moment(impairData[impairData.length-1].date).add(1,'day').format('MM/DD/YYYY')                  
                    validation.setFieldValue('date', date)
                    setValidate({ 
                        min: date,
                        max: moment(usefulData[usefulData.length-1].endDate).format('MM/DD/YYYY') 
                    })
                }else{
                    const date = moment(usefulData[usefulData.length-1].date).add(1,'day').format('MM/DD/YYYY')
                    validation.setFieldValue('date', date)
                    setValidate({ 
                        min: date,
                        max: moment(usefulData[usefulData.length-1].endDate).format('MM/DD/YYYY') 
                    })
                }                    
            }else if(impairData && impairData.length > 0){
                const date = moment(impairData[impairData.length-1].date).add(1,'day').format('MM/DD/YYYY')
                validation.setFieldValue('date', date)
                setValidate({ 
                    min: date,
                    max: moment(leaseDraftData.terminalLeaseEndDate).format('MM/DD/YYYY') 
                })
            }else if(usefulData && usefulData.length > 0){ 
                const date = moment(usefulData[usefulData.length-1].date).add(1,'day').format('MM/DD/YYYY')
                validation.setFieldValue('date', date)
                setValidate({ 
                    min: date,
                    max: moment(usefulData[usefulData.length-1].endDate).format('MM/DD/YYYY') 
                })
            }else if(leaseDetails && leaseDetails.status == 'Modified'){
                const date = moment(leaseDetails.modification_date, "YYYY-MM-DD").add(1,'day').format('MM/DD/YYYY')
                validation.setFieldValue('date', date)
                setValidate({ 
                    min: date,
                    max: moment(leaseDraftData.terminalLeaseEndDate).format('MM/DD/YYYY') 
                })
            }else{
                const date = moment(leaseDetails.commencement, "YYYY-MM-DD").add(1,'day').format('MM/DD/YYYY')
                validation.setFieldValue('date', date)
                setValidate({ 
                    min: date,
                    max: moment(leaseDraftData.terminalLeaseEndDate).format('MM/DD/YYYY') 
                })
            } 
            setLoadingImpairment(false) 
        }
    }, [leaseDraftData,leaseDetails])

    useEffect(() => {
        if(rouData && rouData.rou_assets && rouData.rou_assets.length > 0) {
            let filter = rouData.rou_assets.filter(e => moment(e.date,'YYYY-MM-DD').isSame(moment(validation.values.date)));
            setAmount(filter && filter[0] ? Number(filter[0].beginning || 0) : 0)
            if(isCheck){
                validation.setFieldValue(`amount`, filter && filter[0] ? Number(filter[0].beginning).toFixed(2) : 0)
            }
        }
    },[rouData, validation.values.date])

    const getdraftData = async() => {
        setLoadingImpairment(true);
        try {
            let { data } = await get(`${AppConfig.baseUrl}/lease_draft/get_draft?id=${leaseId}&mode=${mode ? mode : 'lessee'}&customer_id=${userData.cust_info._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (data && data.lease) {
                setLeaseDraftData(data.lease)
                let value = await get(`${AppConfig.baseUrl}/user_client/get_user_client?id=${data.lease.user_client_id}&customer_id=${userData.cust_info._id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    }
                })
                let clientData = value.userData.client_id
                setClientName(clientData.client)
                let impairData = data.lease && data.lease.impairments ? data.lease.impairments : []
                setImpairmentData(impairData)
                if(impairData && impairData.length < 1) setActiveTab("2")
            }
        } catch (err) {
            console.log(err)
        }
        setLoadingImpairment(false) 
    }

    useEffect(() => {
        getdraftData()
    }, [mode,leaseId])

    const getClientData = async () => {
        setLoadingImpairment(true)
        try{
            if(leaseDraftData && Object.keys(leaseDraftData).length > 0){
                let value = await get(`${AppConfig.baseUrl}/user_client/get_user_client?id=${leaseDraftData.user_client_id}&customer_id=${userData.cust_info._id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    }
                })
                let clientData = value.userData.client_id
                setClientName(clientData.client)   
            }
        }catch(err){
            console.log(err)
        }        
    }

    const editLease = async (data) => {
        setLoadingImpairment(true)
        let newData = { ...data, lease_id: leaseId, clientId: leaseDraftData.clientId }
        let value = await newPost(`${AppConfig.baseUrl}/lease_draft/editLease`, newData, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if (value.error === false) {
            reloadProcessingStatus()
            let data = { activity: 'impairment', lease_id: value.data.lease_id, client_id: leaseDraftData.clientId, client_name: clientName, mode: mode ? mode : 'lessee' }
            await newPost(`${AppConfig.baseUrl}/activity/add_activity`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if(window.location.href.includes(`/leases/details/${leaseId}`)){
                window.location.href = `/${localStorage.getItem('company')}/leases/details/${value.data.lease_id}/refresh`
            }else{
                navigate(`/${localStorage.getItem('company')}/leases/details/${value.data.lease_id}/refresh`);
            }
        } else {
            toast.error(value.title || 'Lease not saved');
        }
        setImpairment(!impairment)
        setLoadingImpairment(false)
    }

    const modifyLease = async (d) => {
        setLoadingImpairment(true)
        const { data: revertData } = await axios.post(`${AppConfig.baseUrl}/lease_draft/revertLease`, { lease_id: leaseId, mode: mode ? mode : 'lessee' }, {
            headers: { token: localStorage.getItem("token") }
        });
        if (revertData.error === false) {
            let newData = { ...d, lease_id: revertData.parentData._id, clientId: leaseDraftData.clientId }
            let value = await newPost(`${AppConfig.baseUrl}/lease_draft/modifyLease`, newData, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (value.error === false) {
                reloadProcessingStatus()
                let data = { activity: 'impairment', lease_id: value.data._id, client_id: leaseDraftData.clientId, client_name: clientName, mode: mode ? mode : 'lessee' }
                await newPost(`${AppConfig.baseUrl}/activity/add_activity`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    }
                })
                if(window.location.href.includes(`/leases/details/${leaseId}`)){
                    window.location.href = `/${localStorage.getItem('company')}/leases/details/${value.data._id}/refresh`
                }else{
                    navigate(`/${localStorage.getItem('company')}/leases/details/${value.data._id}/refresh`);
                }
            } else {
                toast.error(value.title || 'Lease not saved');
            }
        }
        setImpairment(!impairment)
        setLoadingImpairment(false)
    }

    const includeExcludeImpairment = ()=>{
        let payment = [...impairmentData].filter( e => !(e.deletable))
        leaseDraftData.impairments = payment
        if(leaseDetails && leaseDetails.status == 'Modified'){
            modifyLease(leaseDraftData)
        }else{
            editLease(leaseDraftData)
        }        
    }

    const checkAll = () => {
        const data = [...impairmentData].filter(v => v.deletable )
        return data.length == impairmentData.length
    }

    const handleClear = () => {
        setImpairmentData(leaseDraftData && leaseDraftData.impairments && leaseDraftData.impairments.length > 0 ? leaseDraftData.impairments : []);
        setActiveTab(leaseDraftData && leaseDraftData.impairments && leaseDraftData.impairments.length > 0 ? "1" : "2")
        setImpairDate('')
        setType('')
    }

    return (
        
        <Modal
            isOpen={impairment}
            toggle={() => {
                toggleImpairment();
                handleClear()
            }}
            size="lg"
            centered
        >
        <div className="modal-header">
            <h5 className="modal-title mt-0">New Impairment</h5>
            <button
                type="button"
                onClick={() => {
                    setImpairment(false)
                    handleClear()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <Card>            
                <CardBody>
                    <Row>
                        <Nav tabs>
                            {    impairmentData && impairmentData.length > 0 && 
                                <NavItem style={{ textAlign: 'left' }}>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "1",
                                        })}
                                        onClick={() => {
                                            setActiveTab("1");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="far fa-envelope"></i>
                                        </span>
                                        <span className="d-none d-sm-block font-size-16">View Impairments</span>
                                    </NavLink>
                                </NavItem>
                            }                                
                            <NavItem style={{ textAlign: 'left' }}>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeTab === "2",
                                    })}
                                    onClick={() => {
                                        setActiveTab("2");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="fas fa-home"></i>
                                    </span>
                                    <span className="d-none d-sm-block font-size-16">Add Impairment</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent
                            activeTab={activeTab}
                            className="text-muted"
                        >
                            <TabPane tabId="1">
                                {
                                    loadingImpairment ?
                                        <div className="text-center mt-4"><Spinner color="primary" className="text-lbusers" /></div>
                                        :
                                        impairmentData && impairmentData.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <div className="table-responsive">
                                                        <Table className="table mb-0 customTable">
                                                            <thead className="table-light tableHead">
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Amount</th>
                                                                    <th>
                                                                        <Input
                                                                            className="form-check-input"
                                                                            name="deleteAll"
                                                                            type="checkbox"
                                                                            checked={checkAll()}
                                                                            onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    const newArr = [...impairmentData].map(v => {
                                                                                        v.deletable = true
                                                                                        return v;
                                                                                    })
                                                                                    setImpairmentData(newArr)
                                                                                } else {
                                                                                    const newArr = [...impairmentData].map(v => {
                                                                                        v.deletable = false
                                                                                        return v;
                                                                                    })
                                                                                    setImpairmentData(newArr)
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label
                                                                            className="form-check-label ms-2"
                                                                            htmlFor="delete"
                                                                        >
                                                                            Delete
                                                                        </label>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="tableBody" style={{minHeight:'200px', maxHeight:'500px', height: 'auto'}}>
                                                                {   impairmentData && impairmentData.length > 0 && impairmentData.map((data, i) => {
                                                                    return <tr key={data.type + i} style={{ textDecoration: data.deletable ? 'line-through' : 'none', textDecorationColor: data.deletable ? 'red' : '' }}>
                                                                        <td>{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                        <td>{data.amount != '' ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                        <td>
                                                                            <div className="mb-3 ms-2">
                                                                                <Input
                                                                                    className="form-check-input"
                                                                                    name="includePayment"
                                                                                    id={i}
                                                                                    type="checkbox"
                                                                                    checked={data.deletable}
                                                                                    onClick={() => {
                                                                                        let newArr = [...impairmentData];
                                                                                        newArr[i].deletable = !newArr[i].deletable
                                                                                        setImpairmentData(newArr)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>                                                
                                                    <div className="modal-footer mt-3">
                                                        <button type="submit" className="btn btn-lbusers" disabled={loadingImpairment} onClick={includeExcludeImpairment}> 
                                                            {
                                                                loadingImpairment && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                            }
                                                            SUBMIT
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                }
                            </TabPane>
                            <TabPane tabId="2">
                                {
                                loadingImpairment ?
                                <div className="text-center mt-4"><Spinner color="primary" className="text-lbusers" /></div>
                                :
                                <Form className="form-horizontal mt-4" onSubmit={validation.handleSubmit}>
                                    <Row className='justify-content-center'>
                                    <Col md="12" lg="12" xl="12" xxl="12">
                                        <Label for="optAmount">
                                            Date
                                        </Label>
                                        <InputGroup>
                                            <Flatpickr
                                                className="form-control d-block bg-white"
                                                placeholder="Lease Start"
                                                id='date'
                                                type='date'
                                                options={{
                                                    altInput: true,
                                                    altFormat: "m/d/Y",
                                                    dateFormat: "m/d/Y",
                                                    minDate: validate.min,
                                                    maxDate: validate.max,
                                                    allowInput: true,
                                                }}
                                                onClose={(e) =>{
                                                    validation.setFieldValue(`date`, (e[0]))
                                                }}
                                                onBlur={() => validation.setFieldTouched("date")}
                                                value={validation.values.date}
                                            />                           
                                            {validation.touched.date && validation.errors.date && (
                                                <span className='text-danger'>{validation.touched.date && validation.errors.date}</span>
                                            )}
                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                                    disabled
                                                >
                                                    <i
                                                        className="fa fa-calendar"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </InputGroup>
                                        {validation.touched.date && validation.errors.date && (
                                            <span className='text-danger'>{validation.touched.date && validation.errors.date}</span>
                                        )}
                                        {impairDate && <span>Note - Last <q>{type == 'modify' ? 'Modify' : type == 'use_ful' ? 'Change in Useful Life' : 'Impairment'}</q> date is {moment(impairDate).format('MM/DD/YYYY')}, please delete this {type == 'modify' ? 'Modify' : type == 'use_ful' ? 'Change in Useful Life' : 'Impairment'} to be able to impairment this lease on a prior date. </span>}                                        
                                    </Col>
                                    <Col md="12">
                                    <div className="mt-4">
                                        <Label for="optAmount">
                                            Amount
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="amount"
                                            disabled={isCheck}
                                            onChange={(e) =>{
                                                validation.setFieldValue(`amount`, (e.target.value))
                                            }}
                                            onBlur={() => validation.setFieldTouched("amount")}
                                            value={validation.values.amount}
                                            />
                                            <span style={{color: validation.errors.amount ? 'red' : 'black'}}>Amount should be greater than 0 and should not exceed ${amount && amount > 0 ? commaSeperated(amount.toFixed(2)) : 0}</span>
                                    </div>
                                    </Col>
                                    <Col md="12">
                                        <h6 className="mt-4">Fully impair ? 
                                            <span className="form-switch ms-2">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id='full_impirment'
                                                    value={isCheck}
                                                    defaultChecked={isCheck}
                                                    onChange={(e) => {
                                                        setIsCheck(e.target.checked)
                                                        if(e.target.checked){
                                                            validation.setFieldValue(`amount`, amount && amount > 0 ? amount.toFixed(2) : 0)
                                                        }
                                                    }}
                                                />
                                            </span>
                                        </h6>
                                    </Col>
                                    <Col md='12'>
                                        <div className="mt-2">
                                            <Label for="Amount">
                                                Note
                                            </Label>
                                            <Input
                                                type="textarea"
                                                className="form-control"
                                                id="note"
                                                onChange={(e) =>{ 
                                                    validation.setFieldValue(`note`, (e.target.value))
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    </Row>
                                    <div className="modal-footer mt-3">
                                        <button onClick={() => {setImpairment(false)}} className="btn bg-theme-color w-md ms-3" disabled={loadingImpairment} >
                                            {
                                                loadingImpairment && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                            }
                                            CANCEL
                                        </button>
                                        <button type="submit" className="btn bg-theme-color w-md ms-3" disabled={loadingImpairment} >
                                            {
                                                loadingImpairment && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                            }
                                            SUBMIT
                                        </button>
                                    </div>
                                </Form>
                                }
                            </TabPane>
                        </TabContent>
                    </Row>
                </CardBody>
            </Card>            
        </div>
    </Modal> 
    )
}

export default Impairment