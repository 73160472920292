import React, { useEffect, useState, useRef } from "react"
import {
    Col,
    Input,
    Row,
    InputGroup,
    Card,
    CardBody
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { FieldArray, Field, FormikProvider } from 'formik';
import moment from 'moment';
import { useLocation } from "react-router-dom"

const AddLeaseStepTwo = (prop) => {
    let { formik, clientDataFromApi, leaseDetails, leaseDraftData } = prop;
    const location = useLocation();
    let path = location && location.pathname.split('/')
    const [impairDate, setImpairDate] = useState('');
   
   useEffect(()=>{
    let impairment = leaseDraftData && leaseDraftData.impairments
    if(impairment && impairment.length > 0 && path[3] == 'modify'){
        const date = impairment[impairment.length-1].date
        setImpairDate(date)
    }
   },[leaseDraftData])
   
    const checkDates = (formik, e, key) => {
        if(key == 'commencementDate' && (path[3] == 'modify' || path[3] == 'edit_modify_lease')){
            let a;
            if(formik.values.leaseEndDate){
                let day = moment(formik.values.leaseEndDate,'MM/DD/YYYY').diff(moment(moment(e[0]).format('MM/DD/YYYY'),'MM/DD/YYYY'),'days')
                formik.setFieldValue(`howLong`, day+1)
                formik.setFieldValue(`typeStep2`, 'days')                
            } else {
                formik.setFieldValue(`howLong`, 0)
                formik.setFieldValue(`typeStep2`, 'days')
                formik.setFieldValue(`leaseEndDate`,(moment(e[0]).format('MM/DD/YYYY')))
                formik.setFieldValue(`terminalLeaseEndDate`, (moment(e[0]).format('MM/DD/YYYY'))) 
                formik.setFieldValue(`lastExtensionDate`, (moment(e[0]).format('MM/DD/YYYY'))) 
            }           
            formik.setFieldValue(`commencementDate`, (moment(e[0]).format('MM/DD/YYYY'))) 
        }else if(key == 'commencementDate'){
            let a;
            if(Number(formik.values.howLong) > 0){
                let type = formik.values.typeStep2;
                a = moment(moment(e[0]).format()).add(Number(formik.values.howLong), type).subtract(1,'days')
                formik.setFieldValue(`leaseEndDate`, moment(moment(e[0]).format()).add(Number(formik.values.howLong), type).subtract(1,'days').format('MM/DD/YYYY'))
            } else {
                a = moment(moment(e[0]).format())
                formik.setFieldValue(`leaseEndDate`, moment(e[0]).format('MM/DD/YYYY'))
            }
            if(clientDataFromApi && new Date(clientDataFromApi.transition_date).getTime() > new Date(moment(e[0]).format('YYYY-MM-DD')).getTime()) {
                formik.setFieldValue(`transitionDate`, (moment.utc(clientDataFromApi.transition_date).format('MM/DD/YYYY')))
            } else {
                formik.setFieldValue(`transitionDate`, (''))
            }
            formik.setFieldValue(`terminalLeaseEndDate`, (moment(a).format('MM/DD/YYYY')))            
            formik.setFieldValue(`commencementDate`, (moment(e[0]).format('MM/DD/YYYY')))
            formik.setFieldValue(`lastExtensionDate`, (moment(a).format('MM/DD/YYYY')))  
            setArrayDates(a)
        } else if (key == 'howLong') {
            let type = formik.values.typeStep2;
            let val = e.length > 0 ? e : 0;
            let a = formik.values.commencementDate instanceof Array ? moment(moment(formik.values.commencementDate[0]).format()).add(Number(val), type).subtract(1,'days').format() :
                moment(moment(formik.values.commencementDate).format()).add(Number(val), type).subtract(1,'days').format();
            
            formik.setFieldValue(`leaseEndDate`, moment(a).format('MM/DD/YYYY'))
            if(Number(e) > 0) {
                formik.setFieldValue(`terminalLeaseEndDate`, moment(a).format('MM/DD/YYYY'))
                formik.setFieldValue(`lastExtensionDate`, (moment(a).format('MM/DD/YYYY'))) 
            } else {
                formik.setFieldValue(`terminalLeaseEndDate`, (moment(formik.values.commencementDate).format('MM/DD/YYYY')))
                formik.setFieldValue(`lastExtensionDate`, (moment(formik.values.commencementDate).format('MM/DD/YYYY')))
            }
            setArrayDates(a)
            
        } else if (key == 'leaseEndDate') {
            let start = moment(new Date(formik.values.commencementDate)).endOf('day').format('MM/DD/YYYY')
            let end = moment(e[0])
            let a = moment(e[0]).format()
            let days = end.diff(start, 'days');
            formik.setFieldValue(`howLong`, days+1)
            formik.setFieldValue(`typeStep2`, 'days')
            formik.setFieldValue(`leaseEndDate`, (moment(a).format('MM/DD/YYYY')))
            formik.setFieldValue(`terminalLeaseEndDate`, (moment(a).format('MM/DD/YYYY')))
            formik.setFieldValue(`lastExtensionDate`, (moment(a).format('MM/DD/YYYY'))) 
            setArrayDates(a)
        } else if(key == 'typeStep2') {
           
            let a;
            if(e == 'days' || e == 'weeks') {
                a = formik.values.commencementDate instanceof Array ? moment(moment(formik.values.commencementDate[0]).format()).add(Number(formik.values.howLong), e).format() :
                moment(moment(formik.values.commencementDate).format()).add(Number(formik.values.howLong), e).format();
            } else {
                a = formik.values.commencementDate instanceof Array ? moment(moment(formik.values.commencementDate[0]).format()).add(Number(formik.values.howLong), e).subtract(1,'days').format() :
                moment(moment(formik.values.commencementDate).format()).add(Number(formik.values.howLong), e).subtract(1,'days').format();
            }
            formik.setFieldValue(`leaseEndDate`, moment(a).format('MM/DD/YYYY'))
            formik.setFieldValue(`terminalLeaseEndDate`, (moment(a).format('MM/DD/YYYY')))
            formik.setFieldValue(`lastExtensionDate`, (moment(a).format('MM/DD/YYYY'))) 
            setArrayDates(a)
        }
    }
    const setArrayDates = (a) => {
        document.getElementById(`extendForTheLessee[0].extendTheLease`).checked = false;
        document.getElementById(`extendForTheLessor[0].extendTheLease`).checked = false;
        document.getElementById(`extendForTheLessee[0].certainToExercise`).checked = false;
        document.getElementById(`extendForTheLessor[0].certainToExercise`).checked = false;
        
        formik.setFieldValue('extendForTheLessee',( [{
            extendTheLease: false,
            certainToExercise: false,
            startDate: moment(a).add(1, 'days').format('MM/DD/YYYY'),
            howLong: 0,
            type: 'months',
            endDate: moment(a).add(1, 'days').format('MM/DD/YYYY'),
            notify: 0,
            notifyType: 'months',
            notifyDate: moment(a).add(1, 'days').format('MM/DD/YYYY')
        }]))
        formik.setFieldValue('extendForTheLessor',( [{
            extendTheLease: false,
            certainToExercise: false,
            startDate:  moment(a).add(1, 'days').format('MM/DD/YYYY'),
            type: 'months',
            howLong: 0,
            endDate:  moment(a).add(1, 'days').format('MM/DD/YYYY'),
            notifyDate: moment().add(1, 'days').format('MM/DD/YYYY'),
            notify: 0,
            notifyType: 'months'
        }]))

    }
    const newFunction = (formik, key, updateValue, index, arrayType) => {
        let newEndDate;
        let newStartDate = formik.values.leaseEndDate instanceof Array ? moment(formik.values.leaseEndDate[0]).add(1, 'days').format() : moment(formik.values.leaseEndDate).add(1, 'days').format() ;
        
        if(arrayType == 'lessee'){
            if (key == 'remove') {
                formik.values.extendForTheLessee.splice(index, 1);
            }
            formik.values.extendForTheLessee.map(async (value, i) => {
                if (i != 0) {
                    formik.setFieldValue(`extendForTheLessee[${i}].startDate`, moment(moment(newEndDate).format()).add(1, 'days').format('MM/DD/YYYY'))
                    newStartDate = moment(moment(newEndDate).format()).add(1, 'days').format()
                }
                if (i == index) { // if index is the edited index
                    if (key == 'type') {
                        if(updateValue == 'days' || updateValue == 'weeks') {
                            formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format('MM/DD/YYYY'))
                            newEndDate = moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format()
                            if(value.certainToExercise === true){
                                setTerminalEndDate(moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format('MM/DD/YYYY'))
                            }
                            formik.setFieldValue('lastExtensionDate',moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format('MM/DD/YYYY'))
                        } else {
                            if(Number(value.howLong) > 0) {
                                formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).subtract(1, 'days').format('MM/DD/YYYY'))
                                newEndDate = moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).subtract(1, 'days').format()
                                if(value.certainToExercise === true){
                                    setTerminalEndDate(moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).subtract(1, 'days').format('MM/DD/YYYY'))
                                }
                                formik.setFieldValue('lastExtensionDate',moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).subtract(1, 'days').format('MM/DD/YYYY'))
                            } else {
                                formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format('MM/DD/YYYY'))
                                newEndDate = moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format()
                                if(value.certainToExercise === true){
                                    setTerminalEndDate(moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format('MM/DD/YYYY'))
                                }
                                formik.setFieldValue('lastExtensionDate',moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format('MM/DD/YYYY'))
                            }
                        }
                    } else if (key == 'howLong') {
                        if(value.type == 'days' || value.type == 'weeks') {
                            formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format('MM/DD/YYYY'))
                            newEndDate = moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format()
                            if(value.certainToExercise === true){
                                setTerminalEndDate(moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format('MM/DD/YYYY'))
                            }
                            formik.setFieldValue('lastExtensionDate',moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format('MM/DD/YYYY'))
                        } else {
                            if(Number(updateValue) > 0) {
                                formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(updateValue), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                                newEndDate = moment(moment(value.startDate).format()).add(Number(updateValue), value.type).subtract(1, 'days').format()
                                if(value.certainToExercise === true){
                                    setTerminalEndDate(moment(moment(value.startDate).format()).add(Number(updateValue), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                                }
                                formik.setFieldValue('lastExtensionDate',moment(moment(value.startDate).format()).add(Number(updateValue), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                            } else {
                                formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format('MM/DD/YYYY'))
                                newEndDate = moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format()
                                if(value.certainToExercise === true){
                                    setTerminalEndDate(moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format('MM/DD/YYYY'))
                                }
                                formik.setFieldValue('lastExtensionDate',moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format('MM/DD/YYYY'))
                            }
                        }
                    } else if (key == 'remove') {
                        if(value.type == 'days' || value.type == 'weeks') {
                            formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                            newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format()
                            if(value.certainToExercise === true){
                                setTerminalEndDate(moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                            }
                            formik.setFieldValue('lastExtensionDate',moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                        } else {
                            if(Number(value.howLong) > 0) {
                                formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                                newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format()
                                if(value.certainToExercise === true){
                                    setTerminalEndDate(moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                                }
                                formik.setFieldValue('lastExtensionDate',moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                            } else {
                                formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                                newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format()
                                if(value.certainToExercise === true){
                                    setTerminalEndDate(moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                                }
                                formik.setFieldValue('lastExtensionDate',moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                            }
                        }
                    }
                } else {
                    if(Number(value.howLong) > 0) {
                        if(value.type == 'days' || value.type == 'weeks') {
                            formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                            newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format()
                            if(value.certainToExercise === true){
                                setTerminalEndDate(moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                            }
                            formik.setFieldValue('lastExtensionDate',moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                        } else {
                            formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                            newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format()
                            if(value.certainToExercise === true){
                                setTerminalEndDate(moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                            }
                            formik.setFieldValue('lastExtensionDate',moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                        }
                    } else {
                        formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                        newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format() 
                        if(value.certainToExercise === true){
                            setTerminalEndDate(moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                        }
                        formik.setFieldValue('lastExtensionDate',moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                    }
                }
                if (i == 0) {
                    formik.setFieldValue(`extendForTheLessee[${i}].notifyDate`, moment(new Date(formik.values.leaseEndDate)).subtract(Number(value.notify),value.notifyType).format('MM/DD/YYYY'))
                } else {
                    formik.setFieldValue(`extendForTheLessee[${i}].notifyDate`, moment(new Date(formik.values.extendForTheLessee[i-1].endDate)).subtract(Number(value.notify),value.notifyType).format('MM/DD/YYYY'))
                }
                

            })
        } else {
            if (key == 'remove') {
                formik.values.extendForTheLessor.splice(index, 1);
            }
            formik.values.extendForTheLessor.map(async (value, i) => {
                if (i != 0) {
                    formik.setFieldValue(`extendForTheLessor[${i}].startDate`, moment(moment(newEndDate).format()).add(1, 'days').format('MM/DD/YYYY'))
                    newStartDate = moment(moment(newEndDate).format()).add(1, 'days').format()
                }
                if (i == index) { // if index is the edited index
                    if (key == 'type') {
                        if(updateValue == 'days' || updateValue == 'weeks') {
                            formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format('MM/DD/YYYY'))
                            newEndDate = moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format()
                        } else {
                            if(Number(value.howLong) > 0) {
                                formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).subtract(1, 'days').format('MM/DD/YYYY'))
                                newEndDate = moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).subtract(1, 'days').format()
                            } else {
                                formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format('MM/DD/YYYY'))
                                newEndDate = moment(moment(value.startDate).format()).add(Number(value.howLong), updateValue).format()
                            }
                            
                        }
                    } else if (key == 'howLong') {
                        if(value.type == 'days' || value.type == 'weeks') {
                            formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format('MM/DD/YYYY'))
                            newEndDate = moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format()
                        } else {
                            if(Number(updateValue) > 0) {
                                formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(updateValue), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                                newEndDate = moment(moment(value.startDate).format()).add(Number(updateValue), value.type).subtract(1, 'days').format()
                            } else {
                                formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format('MM/DD/YYYY'))
                                newEndDate = moment(moment(value.startDate).format()).add(Number(updateValue), value.type).format()
                            }
                        }
                    } else if (key == 'remove') {
                        if(value.type == 'days' || value.type == 'weeks') {
                            formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                            newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format()
                        } else {
                            if(Number(value.howLong) > 0) {
                                formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                            newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format()
                            } else {
                                formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                                newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format()
                            }
                        }
                    }
                } else {
                    if(Number(value.howLong) > 0) {
                        if(value.type == 'days' || value.type == 'weeks') {
                            formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                            newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format()
                        } else {
                            formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format('MM/DD/YYYY'))
                            newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).subtract(1, 'days').format()
                        }
                    } else {
                        formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format('MM/DD/YYYY'))
                        newEndDate = moment(moment(newStartDate).format()).add(Number(value.howLong), value.type).format() 
                    }
                }
            })
        }        
    }
    const setTerminalEndDate = (date) => {
        formik.setFieldValue(`terminalLeaseEndDate`, date)
    }
    
    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Row>
                    <Col md="4" lg="4" xl="4" xxl="4">
                        <h6 className="mt-4">{`Lease ${path[3] == 'modify' || path[3] == 'edit_modify_lease' ? 'modification' : 'commencement'} date`} <span className="text-danger">*</span></h6>
                        <InputGroup>
                            <Flatpickr
                                className="form-control d-block bg-white"
                                placeholder="Lease Start"
                                id='commencementDate'
                                type='date'
                                options={{
                                    altInput: true,
                                    altFormat: "m/d/Y",
                                    dateFormat: "m/d/Y",
                                    allowInput: true,
                                    minDate: impairDate && path[3] == 'modify' ? moment(impairDate).add(1, 'day').format('MM/DD/YYYY') : leaseDetails && path[3] == 'modify' && moment(leaseDetails.commencement).add(1, 'day').format('MM/DD/YYYY')
                                }}
                                onClose={(e) =>{
                                    if(e.length == 0) {
                                        formik.setFieldValue(`commencementDate`, '')
                                    } else {
                                        checkDates(formik, e, 'commencementDate')
                                    }
                                }}
                                onBlur={() => formik.setFieldTouched("commencementDate")}
                                value={formik.values.commencementDate}
                            />  
                            <div className="input-group-append">
                                <button
                                    type="button"
                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                    disabled
                                >
                                    <i
                                        className="fa fa-calendar"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </InputGroup>
                        {formik.touched.commencementDate && formik.errors.commencementDate && (
                                <span className='text-danger'>{formik.touched.commencementDate && formik.errors.commencementDate}</span>
                        )}
                    </Col>
                    <Col md="4" lg="4" xl="4" xxl="4">
                        <h6 className="mt-4"> Initial term for how long ?</h6>
                        <InputGroup>
                            <div className="input-group-append" style={{width:'81px'}}>
                                <select className="form-control arrows" id='typeStep2'
                                onChange={(e)=> {
                                    formik.setFieldValue(`typeStep2`, e.target.value)
                                    setTimeout(() => {
                                        checkDates(formik, e.target.value, 'typeStep2')
                                    },100)
                                }}
                                value={formik.values.typeStep2} onBlur={() => formik.setFieldTouched("typeStep2")} >
                                    <option value='days'>Days</option>
                                    <option value='weeks'>Weeks</option>
                                    <option value='months'>Months</option>
                                    <option value='years'>Years</option>
                                </select>
                            </div>
                            <Input
                                type="number"
                                className="form-control"
                                id="howLong"
                                value={formik.values.howLong}
                                onBlur={() => formik.setFieldTouched("howLong")}
                                onChange={(e) =>{
                                    formik.setFieldValue(`howLong`, (e.target.value))
                                    setTimeout(() => {
                                        checkDates(formik, e.target.value, 'howLong')
                                    },100)
                                }}
                            />
                        </InputGroup>
                        {formik.touched.howLong && formik.errors.howLong && (
                            <span className='text-danger'>{formik.touched.howLong && formik.errors.howLong}</span>
                        )}
                    </Col>
                    <Col md="4" lg="4" xl="4" xxl="4">
                        <h6 className="mt-4">Initial lease end date <span className="text-danger">*</span></h6>
                        <InputGroup>
                            <Flatpickr
                                className="form-control d-block bg-white"
                                placeholder="Lease End"
                                id='leaseEndDate'
                                type='date'
                                options={{
                                    altInput: true,
                                    altFormat: "m/d/Y",
                                    dateFormat: "m/d/Y",
                                    allowInput: true,
                                    minDate: moment(formik.values.commencementDate).format('MM/DD/YYYY')
                                }}
                                onClose={(e) =>{
                                    if(e.length == 0) {
                                        formik.setFieldValue(`leaseEndDate`, '')
                                    } else {
                                        checkDates(formik, e, 'leaseEndDate')
                                    }
                                }}
                                onBlur={() => formik.setFieldTouched("leaseEndDate")}
                                value={formik.values.leaseEndDate}
                            />  
                            <div className="input-group-append">
                                <button
                                    type="button"
                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                    disabled
                                >
                                    <i
                                        className="fa fa-calendar"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </InputGroup>
                        {formik.touched.leaseEndDate && formik.errors.leaseEndDate && (
                            <span className='text-danger'>{formik.touched.leaseEndDate && formik.errors.leaseEndDate}</span>
                        )}
                    </Col>
                </Row>
                <Card className="mt-4 mb-0 card-bg">
                    <CardBody>
                        <Row>
                            <h3 className="font-size-18">Option to extend for the lessee</h3>
                        </Row>
                        <Row>
                        <FieldArray
                            name="extendForTheLessee"
                            id="extendForTheLessee"
                            validateOnChange={false}
                            render={arrayHelpers => <>
                            {(formik.values.extendForTheLessee || []).map((formRow, key) => (
                                <React.Fragment key={key}>
                                    {
                                        formik.values.extendForTheLessee.length > 1 && key > 0 ?
                                            <Row>
                                                <Col xs="12">
                                                    <div className="mt-4 d-flex">
                                                        <hr style={{ flexGrow: '1' }} />
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger gridbtn-m"
                                                            style={{ marginRight: '0', marginLeft: 'auto' }}
                                                            onClick={() => {
                                                                arrayHelpers.remove(key)
                                                                setTimeout(()=>{newFunction(formik, 'remove', '', key, 'lessee');}, 10)
                                                            }}
                                                        >
                                                            <i className='bx bx-x'></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            : ""
                                    }
                                    {key == 0 && <Row>
                                        <Col md="6" lg="6" xl="6" xxl="6">
                                            <div className="d-flex mt-4">
                                                <h6>
                                                    Is there an option to extend the lease?
                                                </h6>
                                                <div className="form-check form-switch ms-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={formik.values.extendForTheLessee[key].extendTheLease}
                                                        className="form-check-input"
                                                        id={`extendForTheLessee[${key}].extendTheLease`}
                                                        onClick={(e) =>{ 
                                                            formik.setFieldValue(`extendForTheLessee[${key}].extendTheLease`, (e.target.checked))
                                                            if(key == 0 && e.target.checked == false) {
                                                                formik.setFieldValue(`lastExtensionDate`, moment(new Date(formik.values.leaseEndDate)).format('MM/DD/YYYY'))
                                                                formik.setFieldValue(`terminalLeaseEndDate`, moment(new Date(formik.values.leaseEndDate)).format('MM/DD/YYYY'))
                                                                formik.values.extendForTheLessee.map((d,i)=> {
                                                                    formik.setFieldValue(`extendForTheLessee[${i}].certainToExercise`, (false))
                                                                    formik.setFieldValue(`extendForTheLessee[${i}].type`, 'months')
                                                                    formik.setFieldValue(`extendForTheLessee[${i}].notifyType`, 'months')
                                                                    formik.setFieldValue(`extendForTheLessee[${i}].howLong`, 0)
                                                                    formik.setFieldValue(`extendForTheLessee[${i}].notify`, 0)
                                                                    if(i == 0){
                                                                        formik.setFieldValue(`extendForTheLessee[${i}].startDate`, moment(new Date(formik.values.leaseEndDate)).add(1, 'day').format('MM/DD/YYYY'))
                                                                        formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(new Date(formik.values.leaseEndDate)).add(1, 'day').format('MM/DD/YYYY'))
                                                                        formik.setFieldValue(`extendForTheLessee[${i}].notifyDate`, moment(new Date(formik.values.leaseEndDate)).add(1, 'day').format('MM/DD/YYYY'))
                                                                    }else{
                                                                        formik.setFieldValue(`extendForTheLessee[${i}].startDate`, moment(new Date(formik.values.leaseEndDate)).add(i+1, 'day').format('MM/DD/YYYY'))
                                                                        formik.setFieldValue(`extendForTheLessee[${i}].endDate`, moment(new Date(formik.values.leaseEndDate)).add(i+1, 'day').format('MM/DD/YYYY'))
                                                                        formik.setFieldValue(`extendForTheLessee[${i}].notifyDate`, moment(new Date(formik.values.leaseEndDate)).add(i+1, 'day').format('MM/DD/YYYY'))
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>}
                                    <Row>
                                        <Col md="6" lg="6" xl="6" xxl="6" >
                                            <div className="d-flex mt-4">
                                                <h6>
                                                    Reasonably certain to exercise?
                                                </h6>
                                                <div className="form-check form-switch ms-2" >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`extendForTheLessee[${key}].certainToExercise`}
                                                        name={`extendForTheLessee[${key}].certainToExercise`}
                                                        checked={formik.values.extendForTheLessee[key].certainToExercise}
                                                        disabled={ formik.values.extendForTheLessee[0].extendTheLease === false ? true : key == 0 ? false : (formik.values.extendForTheLessee[key - 1].certainToExercise === true) ? false : true}
                                                        onClick={(e) =>{ 
                                                            formik.setFieldValue(`extendForTheLessee[${key}].certainToExercise`, (e.target.checked))
                                                            
                                                            if(key > 0 && e.target.checked == false){
                                                                formik.setFieldValue(`terminalLeaseEndDate`, (formik.values.extendForTheLessee[key-1].endDate))
                                                                formik.values.extendForTheLessee.map((d,i)=> {
                                                                    if(i > key) {
                                                                        formik.setFieldValue(`extendForTheLessee[${i}].certainToExercise`, (false))
                                                                        let elem = document.getElementById(`extendForTheLessee[${i}].certainToExercise`);
                                                                        elem.checked = false;
                                                                    } 
                                                                })
                                                            } else if(key == 0 && e.target.checked == false) {
                                                                formik.setFieldValue(`terminalLeaseEndDate`, (formik.values.leaseEndDate))
                                                                formik.values.extendForTheLessee.map((d,i)=> {
                                                                    if(i > key) {
                                                                        formik.setFieldValue(`extendForTheLessee[${i}].certainToExercise`, (false))
                                                                        let elem = document.getElementById(`extendForTheLessee[${i}].certainToExercise`);
                                                                        elem.checked = false;
                                                                    } 
                                                                })
                                                            } else if(e.target.checked === true) {
                                                                formik.setFieldValue(`terminalLeaseEndDate`, (formik.values.extendForTheLessee[key].endDate))
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col md="4" lg="4" xl="4" xxl="4">
                                            <h6 className="mt-4">Renew for how long?</h6>
                                            <InputGroup>
                                                <div className="input-group-append" style={{width:'81px'}}>
                                                    <select className="form-control arrows" id='type'  onChange={(e)=>{
                                                        formik.setFieldValue(`extendForTheLessee[${key}].type`, e.target.value)
                                                        let end = moment(new Date(formik.values.extendForTheLessee[key].startDate)).add(Number(formik.values.extendForTheLessee[key].howLong), e.target.value).format('MM/DD/YYYY')
                                                        if(formik.values.extendForTheLessee[key].certainToExercise === true){
                                                            formik.setFieldValue(`terminalLeaseEndDate`, end)                                                         
                                                        }
                                                        setTimeout(()=>{newFunction(formik, 'type', e.target.value, key, 'lessee');}, 10) 
                                                    }}
                                                    disabled={formik.values.extendForTheLessee[0].extendTheLease === false ? true : false}
                                                    value={formik.values.extendForTheLessee[key].type} onBlur={() => formik.setFieldTouched("type")} >
                                                        <option value='days'>Days</option>
                                                        <option value='weeks'>Weeks</option>
                                                        <option value='months'>Months</option>
                                                        <option value='years'>Years</option>
                                                    </select>
                                                </div>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    id="howLong"
                                                    disabled={formik.values.extendForTheLessee[0].extendTheLease === false ? true : false}
                                                    value={formik.values.extendForTheLessee[key].howLong}
                                                    onBlur={() => formik.setFieldTouched(`extendForTheLessee[${key}].howLong`)}
                                                    onChange={(e) =>{
                                                        formik.setFieldValue(`extendForTheLessee[${key}].howLong`, (e.target.value))
                                                        let end = moment(new Date(formik.values.extendForTheLessee[key].startDate)).add(Number(e.target.value),formik.values.extendForTheLessee[key].type).format('MM/DD/YYYY')
                                                        setTimeout(()=>{newFunction(formik, 'howLong', e.target.value, key, 'lessee');}, 10)
                                                        
                                                        if(formik.values.extendForTheLessee[key].certainToExercise === true){
                                                            formik.setFieldValue(`terminalLeaseEndDate`, end)
                                                        }
                                                    }}
                                                />
                                            </InputGroup>
                                            { formik.errors && formik.errors.extendForTheLessee && formik.errors.extendForTheLessee.length > 0 &&  formik.errors.extendForTheLessee[key] != null && formik.errors.extendForTheLessee[key].howLong && (
                                                <span className='text-danger'>{'Must be greater than or equal to 0'}</span>
                                            )}
                                            
                                        </Col>
                                        <Col md="4" lg="4" xl="4" xxl="4">
                                            <div className="mt-4 pt-2">
                                            <h6 className="font-size-14">
                                                <i className="bx bx-calendar me-1" style={{color:'#005691'}}/> Renewal start date
                                            </h6>
                                            <h5 className="text-muted mb-0">{formik.values.extendForTheLessee[key].startDate}</h5>
                                            </div>
                                        </Col>
                                        <Col md="4" lg="4" xl="4" xxl="4">
                                            <div className="mt-4 pt-2">
                                            <h6 className="font-size-14">
                                                <i className="bx bx-calendar-check me-1" style={{color:'#005691'}} /> Renewal end date
                                            </h6>
                                            <h5 className="text-muted mb-0">{formik.values.extendForTheLessee[key].endDate}</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col md="4" lg="4" xl="4" xxl="4">
                                            <h6 className="mt-4">Notify how long in advance?</h6>
                                            <InputGroup>
                                                <div className="input-group-append" style={{width:'81px'}}>
                                                    <select className="form-control arrows" id='notifyType'  onChange={(e)=>{
                                                        formik.setFieldValue(`extendForTheLessee[${key}].notifyType`, e.target.value)
                                                        if (key == 0) {
                                                            formik.setFieldValue(`extendForTheLessee[${key}].notifyDate`, moment(new Date(formik.values.leaseEndDate)).subtract(Number(formik.values.extendForTheLessee[key].notify),e.target.value).format('MM/DD/YYYY'))
                                                        } else {
                                                            formik.setFieldValue(`extendForTheLessee[${key}].notifyDate`, moment(new Date(formik.values.extendForTheLessee[key-1].endDate)).subtract(Number(formik.values.extendForTheLessee[key].notify),e.target.value).format('MM/DD/YYYY'))
                                                        }
                                                    }}
                                                    disabled={formik.values.extendForTheLessee[0].extendTheLease === false ? true : false}
                                                    value={formik.values.extendForTheLessee[key].notifyType} onBlur={() => formik.setFieldTouched("notifyType")} >
                                                        <option value='days'>Days</option>
                                                        <option value='weeks'>Weeks</option>
                                                        <option value='months'>Months</option>
                                                        <option value='years'>Years</option>
                                                    </select>
                                                </div>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    id="notify"
                                                    // min="0"
                                                    disabled={formik.values.extendForTheLessee[0].extendTheLease === false ? true : false}
                                                    onBlur={() => formik.setFieldTouched(`extendForTheLessee[${key}].notify`)}
                                                    value={formik.values.extendForTheLessee[key].notify}
                                                    onChange={(e) =>{
                                                        formik.setFieldValue(`extendForTheLessee[${key}].notify`, (e.target.value))
                                                        if (key == 0) {
                                                            formik.setFieldValue(`extendForTheLessee[${key}].notifyDate`, moment(new Date(formik.values.leaseEndDate)).subtract(Number(e.target.value),formik.values.extendForTheLessee[key].notifyType).format('MM/DD/YYYY'))
                                                        } else {
                                                            formik.setFieldValue(`extendForTheLessee[${key}].notifyDate`, moment(new Date(formik.values.extendForTheLessee[key-1].endDate)).subtract(Number(e.target.value),formik.values.extendForTheLessee[key].notifyType).format('MM/DD/YYYY'))
                                                        }
                                                    }}
                                                />
                                            </InputGroup>
                                            { formik.errors && formik.errors.extendForTheLessee && formik.errors.extendForTheLessee.length > 0 &&  formik.errors.extendForTheLessee[key] != null && formik.errors.extendForTheLessee[key].notify && (
                                                <span className='text-danger'>{'Must be greater than or equal to 0'}</span>
                                            )}
                                        </Col>
                                        <Col md="4" lg="4" xl="4" xxl="4">
                                            <h6 className="mt-4">Deadline to notify Lessor</h6>
                                            <InputGroup>
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    placeholder="Deadline to notify Lessor"
                                                    id='notifyDate'
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "m/d/Y",
                                                        dateFormat: "m/d/Y",
                                                        maxDate: new Date(formik.values.extendForTheLessee[key].notifyDate),
                                                        clickOpens: false
                                                    }}
                                                    value={formik.values.extendForTheLessee[key].notifyDate}
                                                    onClose={(e) =>{
                                                        formik.setFieldValue(`extendForTheLessee[${key}].notifyDate`, (e))

                                                        let start = moment(new Date()).endOf('day').format('MM/DD/YYYY')
                                                        let end = moment(e[0])
                                                        let days = end.diff(start, 'days');
                                                        formik.setFieldValue(`extendForTheLessee[${key}].notify`, days)
                                                        formik.setFieldValue(`extendForTheLessee[${key}].notifyType`, 'days')
                                                    }}
                                                />
                                                <div className="input-group-append">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                        disabled
                                                    >
                                                        <i
                                                            className="fa fa-calendar"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </React.Fragment>

                            ))} 
                            <Row className="mt-4">
                                <Col xs="12">
                                    <div className="mt-4 d-flex">
                                        <hr style={{ flexGrow: '1' }} />
                                        <button
                                            type="button"
                                            className="btn btn-success gridbtn-m"
                                            style={{ marginRight: '0', marginLeft: 'auto' }}
                                            onClick={() => arrayHelpers.insert(formik.values.extendForTheLessee.length, {
                                                extendTheLease: false,
                                                certainToExercise: false,
                                                startDate: moment(new Date(formik.values.extendForTheLessee[formik.values.extendForTheLessee.length - 1].endDate)).add(1, 'days').format('MM/DD/YYYY'),
                                                type: 'months',
                                                howLong: 0,
                                                endDate: moment(new Date(formik.values.extendForTheLessee[formik.values.extendForTheLessee.length - 1].endDate)).add(1, 'days').format('MM/DD/YYYY'),
                                                notifyDate: moment(new Date(formik.values.extendForTheLessee[formik.values.extendForTheLessee.length - 1].endDate)).format('MM/DD/YYYY'),
                                                notify: 0,
                                                notifyType: 'months'
                                            })}
                                        >
                                            <i className='bx bx-plus text-white'></i>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                             </>} />
                            
                        </Row>
                    </CardBody>
                </Card>
                <Card className="mt-4 mb-0 card-bg">
                    <CardBody>
                        <Row>
                            <h3 className="font-size-18">Option to extend for the lessor</h3>
                        </Row>
                        <Row>
                            <FieldArray
                            name="extendForTheLessor"
                            id="extendForTheLessor"
                            validateOnChange={false}
                            render={arrayHelpers => <>
                            {(formik.values.extendForTheLessor || []).map((formRow, key) => (
                                <React.Fragment key={key}>
                                    {
                                        formik.values.extendForTheLessor.length > 1 && key > 0 ?
                                            <Row>
                                                <Col xs="12">
                                                    <div className="mt-4 d-flex">
                                                        <hr style={{ flexGrow: '1' }} />
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger gridbtn-m"
                                                            style={{ marginRight: '0', marginLeft: 'auto' }}
                                                            onClick={() => {
                                                                arrayHelpers.remove(key)
                                                                setTimeout(()=>{newFunction(formik, 'remove', '', key, 'lessor');}, 10)
                                                            }}
                                                        >
                                                            <i className='bx bx-x'></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            : ""
                                    }
                                    { key == 0 && <Row>
                                        <Col md="6" lg="6" xl="6" xxl="6">
                                            <div className="d-flex mt-4">
                                                <h6>
                                                    Is there an option to extend the lease?
                                                </h6>
                                                <div className="form-check form-switch ms-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={formik.values.extendForTheLessor[key].extendTheLease}
                                                        id={`extendForTheLessor[${key}].extendTheLease`}
                                                        onClick={(e) =>{ 
                                                            formik.setFieldValue(`extendForTheLessor[${key}].extendTheLease`, (e.target.checked))
                                                            formik.setFieldValue(`extendForTheLessor[${key}].certainToExercise`, formik.values.reporting_standard == "FASB ASC 842" ? (e.target.checked) : false)
                                                            let elem = document.getElementById(`extendForTheLessor[${0}].certainToExercise`);
                                                            elem.checked = formik.values.reporting_standard == "FASB ASC 842" ? e.target.checked : false;
                                                            formik.values.extendForTheLessor.map((d,i)=> {
                                                                if(e.target.checked === false) {
                                                                    formik.setFieldValue(`extendForTheLessor[${i}].type`, 'months')
                                                                    formik.setFieldValue(`extendForTheLessor[${i}].howLong`, 0)
                                                                    if(i == 0){
                                                                        formik.setFieldValue(`extendForTheLessor[${i}].startDate`, moment(new Date(formik.values.leaseEndDate)).add(1, 'day').format('MM/DD/YYYY'))
                                                                        formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(new Date(formik.values.leaseEndDate)).add(1, 'day').format('MM/DD/YYYY'))
                                                                    }else{
                                                                        formik.setFieldValue(`extendForTheLessor[${i}].startDate`, moment(new Date(formik.values.leaseEndDate)).add(i+1, 'day').format('MM/DD/YYYY'))
                                                                        formik.setFieldValue(`extendForTheLessor[${i}].endDate`, moment(new Date(formik.values.leaseEndDate)).add(i+1, 'day').format('MM/DD/YYYY'))
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>}
                                    <Row>
                                        <Col md="6" lg="6" xl="6" xxl="6" >
                                            <div className="d-flex mt-4">
                                                <h6>
                                                    Reasonably certain to exercise?
                                                </h6>
                                                <div className="form-check form-switch ms-2" >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`extendForTheLessor[${key}].certainToExercise`}
                                                        name={`extendForTheLessor[${key}].certainToExercise`}
                                                        checked={formik.values.extendForTheLessor[key].certainToExercise}
                                                        disabled={ (formik.values.reporting_standard == "GASB 87" || formik.values.reporting_standard == "IFRS 16")  && formik.values.extendForTheLessor[0].extendTheLease === true && key == 0 ? false : (key > 0 && formik.values.extendForTheLessor[key - 1].certainToExercise === true) ? false : true}
                                                        onClick={(e) =>{ 
                                                            formik.setFieldValue(`extendForTheLessor[${key}].certainToExercise`, (e.target.checked))
                                                            
                                                            if(key > 0 && e.target.checked == false){
                                                                formik.values.extendForTheLessor.map((d,i)=> {
                                                                    if(i > key) {
                                                                        formik.setFieldValue(`extendForTheLessor[${i}].certainToExercise`, (false))
                                                                    } 
                                                                })
                                                            } else if(key == 0 && e.target.checked == false) {
                                                                formik.values.extendForTheLessor.map((d,i)=> {
                                                                    if(i > key) {
                                                                        formik.setFieldValue(`extendForTheLessor[${i}].certainToExercise`, (false))
                                                                    } 
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4" lg="4" xl="4" xxl="4">
                                            <h6 className="mt-4">Renew for how long?</h6>
                                            <InputGroup>
                                                <div className="input-group-append" style={{width:'81px'}}>
                                                    <select className="form-control arrows" id='type'  onChange={(e)=>{

                                                        formik.setFieldValue(`extendForTheLessor[${key}].type`, e.target.value)
                                                        formik.setFieldValue(`extendForTheLessor[${key}].endDate`, moment(formik.values.extendForTheLessor[key].startDate).add(Number(formik.values.extendForTheLessor[key].howLong), e.target.value).format('MM/DD/YYYY'))
                                                        let end = moment(new Date(formik.values.extendForTheLessor[key].startDate)).add(Number(formik.values.extendForTheLessor[key].howLong), e.target.value).format('MM/DD/YYYY')
                                                        setTimeout(()=>{newFunction(formik, 'type', e.target.value, key, 'lessor');}, 10)
                                                    }}
                                                    disabled={formik.values.extendForTheLessor[0].extendTheLease === false ? true : false}
                                                    value={formik.values.extendForTheLessor[key].type} onBlur={() => formik.setFieldTouched("type")}>
                                                        <option value='days'>Days</option>
                                                        <option value='weeks'>Weeks</option>
                                                        <option value='months'>Months</option>
                                                        <option value='years'>Years</option>
                                                    </select>
                                                </div>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    id="howLong"
                                                    onBlur={() => formik.setFieldTouched(`extendForTheLessor[${key}].howLong`)}
                                                    value={formik.values.extendForTheLessor[key].howLong}
                                                    disabled={formik.values.extendForTheLessor[0].extendTheLease === false ? true : false}
                                                    onChange={(e) =>{
                                                        
                                                        formik.setFieldValue(`extendForTheLessor[${key}].howLong`, (e.target.value))
                                                        let end = moment(new Date(formik.values.extendForTheLessor[key].startDate)).add(Number(e.target.value),formik.values.extendForTheLessor[key].type).format('MM/DD/YYYY')
                                                        setTimeout(()=>{newFunction(formik, 'howLong', e.target.value, key, 'lessor');}, 10)
                                                    }}
                                                />
                                                
                                            </InputGroup>
                                            { formik.errors && formik.errors.extendForTheLessor && formik.errors.extendForTheLessor.length > 0  &&  formik.errors.extendForTheLessor[key] != null && formik.errors.extendForTheLessor[key].howLong && (
                                                <span className='text-danger'>{'Must be greater than or equal to 0'}</span>
                                            )}
                                        </Col>
                                        <Col md="4" lg="4" xl="4" xxl="4">
                                            <div className="mt-4 pt-2">
                                            <h6 className="font-size-14">
                                                <i className="bx bx-calendar-check me-1" style={{color:'#005691'}} /> Renewal start date
                                            </h6>
                                            <h5 className="text-muted mb-0">{formik.values.extendForTheLessor[key].startDate}</h5>
                                            </div>
                                           
                                        </Col>
                                        <Col md="4" lg="4" xl="4" xxl="4">
                                            <div className="mt-4 pt-2">
                                            <h6 className="font-size-14">
                                                <i className="bx bx-calendar-check me-1" style={{color:'#005691'}} /> Renewal end date
                                            </h6>
                                            <h5 className="text-muted mb-0">{formik.values.extendForTheLessor[key].endDate}</h5>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </React.Fragment>

                            ))}
                            <Row className="mt-4">
                                <Col xs="12">
                                    <div className="mt-4 d-flex">
                                        <hr style={{ flexGrow: '1' }} />
                                        <button
                                            type="button"
                                            className="btn btn-success gridbtn-m"
                                            style={{ marginRight: '0', marginLeft: 'auto' }}
                                            onClick={() => arrayHelpers.insert(formik.values.extendForTheLessor.length, {
                                                extendTheLease: false,
                                                certainToExercise: false,
                                                startDate: moment(formik.values.extendForTheLessor[formik.values.extendForTheLessor.length - 1].endDate).add(1, 'days').format('MM/DD/YYYY'),
                                                type: 'months',
                                                howLong: 0,
                                                endDate: moment(formik.values.extendForTheLessor[formik.values.extendForTheLessor.length - 1].endDate).add(1, 'days').format('MM/DD/YYYY'),
                                                notifyDate: moment().format('MM/DD/YYYY'),
                                                notify: 0,
                                                notifyType: 'months'
                                            })}
                                        >
                                            <i className='bx bx-plus text-white'></i>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            </>}
                            />
                        </Row>
                             
                    </CardBody>
                </Card>
                <Row>
                    <Col md="6">
                        <label
                            htmlFor="termLease"
                            className="col-form-label mt-4"
                        >
                            Total lease term (in months)
                        </label>
                        <input
                            id='totalLeaseTerm'
                            className="form-control"
                            type="text"
                            disabled
                            onChange={formik.handleChange}
                            onBlur={() => formik.setFieldTouched("totalLeaseTerm")}
                            value={formik.values.totalLeaseTerm}
                            />
                    </Col>
                    <Col md="6">
                        <label
                            htmlFor="termLeaseEnd"
                            className="col-form-label mt-4"
                        >
                            Terminal lease end date
                        </label>
                        <input
                            id='terminalLeaseEndDate'
                            name='terminalLeaseEndDate'
                            className="form-control"
                            type="text"
                            disabled
                            onBlur={() => formik.setFieldTouched("terminalLeaseEndDate")}
                            value={Number(formik.values.howLong) >= 0 ? moment(new Date(formik.values.terminalLeaseEndDate)).format('MM/DD/YYYY') :
                            moment(new Date(formik.values.commencementDate)).format('MM/DD/YYYY')}
                            />
                    </Col>
                </Row>
            </FormikProvider>
        </React.Fragment>
    )
}

export default AddLeaseStepTwo