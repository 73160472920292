import React, {useEffect, useState, useContext} from "react";
import {useNavigate,useLocation} from "react-router-dom"
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import { Row, Col, CardBody, Card, Container, Label, Input } from "reactstrap";
import {Helmet} from "react-helmet";
import { AuthContext } from "context/authContext"
import HtmlParse from "html-react-parser"

const TermsAndConditions = (props) => {
    const [terms, setTerms] = useState("");
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [path, setPath] = useState([])
    const { getProcessingStatus, getProcessingSearch, reloadUserData } = useContext(AuthContext)
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let path = location && location.pathname.split('/')
        setPath(path)
    },[])

    useEffect(() => {
        getTerms()
    }, [path])

    const getTerms = async() => {
        setLoading(true)
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/terms/getTermWithType?termType=${path[2] == "view" ? "view" : ''}`,{
                headers: { token: localStorage.getItem("token") ? localStorage.getItem("token") : localStorage.getItem("temp_token") },
            });
            if(!data.error){
                setTerms(data.data ? data.data.text : '')
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    const updateUserTerms = async() => {
        setLoading(true)
        try {
            const {data} = await axios.post(`${AppConfig.baseUrl}/terms/update_user_terms`, null ,{
                headers: { token: localStorage.getItem("temp_token") },
            });
            if(!data.error){
                await localStorage.setItem('token', data.token);
                await localStorage.setItem('company', data.company.toLowerCase());
                reloadUserData()
                getProcessingSearch()
                getProcessingStatus()
                if(data.is_1st_login){
                    navigate("/set_password");
                }
                else{
                    toast.success('Logged in Successfully');
                    window.location.href = `/${localStorage.getItem('company')}/quickaccess`;
                }
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            navigate("/login")
        }
        setLoading(false)
    }

    return(
        <React.Fragment>
        <Helmet>
          <title>LeaseJava | Terms and conditions </title>
        </Helmet>
        <div className="account-pages my-5">
          <Container>
            <Row className="justify-content-center">
                <Col xs={10}>
                    <Card className="overflow-hidden">
                        <div className="bg-lbusers bg-softbg-soft-lbusers login-color">
                            <Row>
                                <Col xs={12}>
                                    <h3 className="text-white" style={{margin: '2%', textAlign: 'center'}}>Terms and conditions</h3>
                                </Col>
                            </Row>
                        </div>
                        <CardBody className="pt-0">
                            {
                                terms ?
                                <>
                                    <div className="mt-4">
                                        {HtmlParse(terms)}
                                    </div>
                                    {
                                        path[2] != "view" &&
                                        <>
                                            <div className="mt-4">
                                                <Input
                                                    name="terms_checked"
                                                    id="terms_checked"
                                                    type="checkbox"
                                                    checked={checked}
                                                    onChange={() => setChecked(!checked)}
                                                />
                                                <Label htmlFor="terms_checked" className="form-label ms-2">I have read and accept the terms and conditions.</Label>
                                            </div>
                                            <div className="mt-4">
                                                <button
                                                    className="btn btn-lbusers login-btn-color btn-block"
                                                    type="button"
                                                    onClick={updateUserTerms}
                                                    disabled={loading || !checked} 
                                                >
                                                    {
                                                        loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                    }
                                                    CONTINUE
                                                </button>
                                            </div>
                                        </>
                                    }
                                </>
                                : ""
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
          </Container>
        </div>
        </React.Fragment>
    )
}

export default TermsAndConditions;