import React, { useState, useEffect, useRef } from "react"
import {
    Card,
    CardBody,
    Col,
    Modal,
    Table,
    Input,
    Label,
    InputGroup,
    Row, TabPane, NavLink, NavItem, TabContent, Nav,
    Collapse,
} from "reactstrap"
import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { FieldArray, FormikProvider } from 'formik';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useLocation } from "react-router-dom"
import './scroll.css'
import AddVariablePayment from '../../pages/Leases/addVariablePayment'
import { commaSeperated } from "helpers/formatters";

const AddLeaseStepThree = (prop) => {
    let { formik, clientData, leaseDraftData, termEndPayments, setTermEndPayments, tab, refundableDeposit, setRefundableDeposit, variablePayments, setVariablePayments } = prop;
    // Periodic lease payments
    // Payments for purchase option
    const location = useLocation();
    let path = location && location.pathname.split('/')
    const queryString = location.search;
    const sp = new URLSearchParams(queryString);
    const [mode, setMode] = useState(path[3] == 'modify' || path[3] == 'edit_modify_lease' ? 'Expected payments from previous lease' : 'Periodic lease payments');
    const [tableData, setTableData] = useState([]);
    const [totalVal, setTotalVal] = useState('');
    const [totalVal1, setTotalVal1] = useState('');
    const [totalVal2, setTotalVal2] = useState('');
    const [totalVal3, setTotalVal3] = useState('');
    const [sortedPayments, setSortedPayments] = useState([]);
    const [activeTab, setActiveTab] = useState("2");
    const [modal, setmodal] = useState(false);
    const penaltyToggle = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [onceOpened, setOnceOpened] = useState(false);
    const [oldPayment, setOldPayment] = useState([])
    const [selectedPayments, setSelectedPayments] = useState([])
    const [saveOldPayments, setSaveOldPayments] = useState([])
    const [variablePaymentOpen, setVariablePaymentOpen] = useState(false)
    const [variablePaymentArr, setVariablePaymentArr] = useState([])

    const save = (value, type) => {
        let obj = {};
        let index = -1;
        let data = [...tableData]
        if (type == 'Initial direct cost' && !formik.values.transitionDate) {
            if (moment(value.initialDirectCostAmountPaymentDate).isSameOrBefore(moment(formik.values.lastExtensionDate))) {
                obj.amount = value.initialDirectCostAmount;
                obj.date = value.initialDirectCostAmountPaymentDate ? moment(value.initialDirectCostAmountPaymentDate).format('YYYY-MM-DD') : moment(new Date(formik.values.commencementDate)).subtract(1, 'days').format('YYYY-MM-DD');
                obj.type = 'Initial direct cost';
                index = data.findIndex(e => e.type == 'Initial direct cost')
            } else {
                return toast.error('Please select valid date')
            }
        } else if (type == 'Penalty for terminating') {
            if (moment(value.penaltyForTerminatingPaymentDate).isSameOrAfter(moment(formik.values.commencementDate))) {
                obj.amount = value.penaltyForTerminatingAmount;
                obj.date = value.penaltyForTerminatingPaymentDate ? moment(value.penaltyForTerminatingPaymentDate).format('YYYY-MM-DD') : moment(new Date(formik.values.leaseEndDate)).format('YYYY-MM-DD');
                obj.type = 'Penalty for terminating';
                index = data.findIndex(e => e.type == 'Penalty for terminating')
            } else {
                return toast.error('Please select valid date')
            }
        } else if (type == 'Payments for purchase option') {
            if (moment(value.paymentsForPurchasePaymentDate).isSameOrAfter(moment(formik.values.commencementDate))) {
                obj.amount = value.paymentsForPurchaseAmount;
                obj.date = value.paymentsForPurchasePaymentDate ? moment(value.paymentsForPurchasePaymentDate).format('YYYY-MM-DD') : moment(new Date(formik.values.lastExtensionDate)).format('YYYY-MM-DD');
                obj.type = 'Payments for purchase option';
                index = data.findIndex(e => e.type == 'Payments for purchase option')
            } else {
                return toast.error('Please select valid date')
            }
        } else if (type == 'Probable owed under RVG') {
            obj.amount = value.probableOwedUnderRVGAmount;
            obj.date = value.probableOwedUnderRVGPaymentDate ? moment(value.probableOwedUnderRVGPaymentDate).format('YYYY-MM-DD') : moment(new Date(formik.values.lastExtensionDate)).format('YYYY-MM-DD');
            obj.type = 'Probable owed under RVG';
            index = data.findIndex(e => e.type == 'Probable owed under RVG')
        } else if (type == 'Refundable Deposit') {
            obj.amount = value.refundableDepositAmount;
            obj.date = value.refundableDepositPaymentDate ? moment(value.refundableDepositPaymentDate).format('YYYY-MM-DD') : moment(new Date(formik.values.commencementDate)).subtract(1, 'days').format('YYYY-MM-DD');
            obj.type = 'Refundable Deposit';
            index = data.findIndex(e => e.type == 'Refundable Deposit')
        } else if (type == 'Incentives') {
            let newArr = data.filter((e) => e.type != 'Incentives');
            newArr = [...newArr, ...saveOldPayments.filter((e) => e.type == 'Incentives')]
            formik.values.incentives.map((d) => {
                let obj1 = {}
                obj1.amount = d.amount;
                obj1.date = d.receiptDate ? moment(d.receiptDate).format('YYYY-MM-DD') : moment(d.receiptDate).format('YYYY-MM-DD');
                obj1.type = 'Incentives';
                newArr.push(obj1);
            })
            setTableData(newArr);
            createSortedTable(newArr)
        } else if (type == 'Payments before commencement') {
            let newArr = data.filter((e) => e.type != 'Payments before commencement');
            newArr = [...newArr, ...saveOldPayments.filter((e) => e.type == 'Payments before commencement')]
            let wrongDate = false
            formik.values.paymentsBeforeCommencement.map((d) => {
                if (moment(d.paymentDate).isBefore(moment(formik.values.commencementDate))) {
                    let obj1 = {}
                    obj1.amount = d.amount;
                    obj1.date = d.paymentDate ? moment(d.paymentDate).format('YYYY-MM-DD') : moment(new Date(formik.values.commencementDate)).subtract(1, 'days').format('YYYY-MM-DD');
                    obj1.type = 'Payments before commencement';
                    if (Object.keys(obj1).length > 0) newArr.push(obj1);
                } else {
                    wrongDate = true
                }
            })
            if (wrongDate) {
                return toast.error('Please select valid date')
            }
            setTableData(newArr);
            createSortedTable(newArr)
        } else if (type == 'Periodic lease payments') {
            let newArr = data.filter((e) => e.type != 'Periodic lease payments');
            newArr = [...newArr, ...saveOldPayments.filter((e) => e.type == 'Periodic lease payments')]
            let wrongDate = false
            formik.values.periodicLeasePayments.map((d, i) => {
                if ((d.frequency == 'One-time' && moment(d.paymentDate).isSameOrAfter(moment(formik.values.commencementDate)) && moment(d.paymentDate).isSameOrBefore(moment(formik.values.lastExtensionDate))) || (d.frequency != 'One-time' && moment(d.paymentDate).isSameOrAfter(moment(formik.values.commencementDate)) && moment(d.paymentDate).isSameOrBefore(moment(formik.values.lastExtensionDate)) && moment(d.paymentEndDate).isSameOrAfter(moment(formik.values.commencementDate)) && moment(d.paymentEndDate).isSameOrBefore(moment(formik.values.lastExtensionDate)))) {
                    let obj1 = {}
                    let type = (d.frequency == 'Monthly' || d.frequency == 'Quarterly' || d.frequency == 'Semi-annual')
                        ? 'months' : 'years';
                    let add = d.frequency == 'Monthly' ? 1 : d.frequency == 'Quarterly' ? 3 : d.frequency == 'Semi-annual' ? 6 : 1;
                    if (d.frequency == 'One-time') {
                        obj1.amount = d.amount;
                        obj1.date = d.paymentDate instanceof Array ? moment(d.paymentDate[0]).format('YYYY-MM-DD') : moment(d.paymentDate).format('YYYY-MM-DD');
                        obj1.type = 'Periodic lease payments';
                        if (Object.keys(obj1).length > 0) newArr.push(obj1);
                    } else {
                        let start = d.paymentDate instanceof Array ? moment(new Date(d.paymentDate[0])).format('') : moment(d.paymentDate).format('');
                        let end = d.paymentEndDate instanceof Array ? moment(d.paymentEndDate[0]).format('') : moment(d.paymentEndDate).format('');
                        let newEnd = moment(end);

                        let days = newEnd.diff(start, type);
                        let thisYear = moment(start).format('YYYY')
                        let startMonth = moment(start).month() + 1;
                        let count = 0;
                        let compoundAmount = Number(d.amount);
                        for (let a = 0; a <= days; a++) {
                            let obj2 = {};
                            if (new Date(moment(start).format('YYYY-MM-DD')).getTime() <= new Date(moment(newEnd).format('YYYY-MM-DD')).getTime()) {
                                obj2.date = moment(start).format('YYYY-MM-DD')
                                obj2.type = 'Periodic lease payments';
                                let yearVal = moment(start).year() - Number(thisYear)
                                let currentMonth = moment(start).month() + 1;
                                if (currentMonth == startMonth && a != 0) {
                                    count += 1;
                                    if (Number(d.annualFixedAmountPer) > 0) {
                                        compoundAmount = Number(compoundAmount) + ((Number(compoundAmount)) * (Number(d.annualFixedAmountPer) / 100));
                                    }
                                }
                                if (Number(d.annualFixedAmount) > 0) {
                                    if (count > 0) {
                                        obj2.amount = Number(d.amount) + (Number(d.annualFixedAmount) * (count));
                                    } else {
                                        obj2.amount = Number(d.amount);
                                    }
                                } else if (Number(d.annualFixedAmountPer) > 0) {
                                    if (count > 0) {
                                        obj2.amount = compoundAmount;
                                        // compoundAmount + ((compoundAmount * (count)) * (Number(d.annualFixedAmountPer)/100));
                                    } else {
                                        obj2.amount = Number(d.amount);
                                    }
                                } else {
                                    obj2.amount = Number(d.amount)
                                }
                                if (Object.keys(obj2).length > 0) newArr.push(obj2);
                                if (String(moment(start).format('MM/DD/YYYY')) == String(moment(start).endOf('month').format('MM/DD/YYYY'))) {
                                    start = moment(start).add(add, type).endOf('month')
                                }
                                else {
                                    start = moment(start).add(add, type)
                                }
                            }
                        }
                    }
                } else {
                    wrongDate = true
                }
            })
            if (wrongDate) {
                return toast.error('Please select valid date')
            }
            setTableData(newArr);
            createSortedTable(newArr)
        } else if (type == 'Variable lease payments') {
            let newArr = data.filter((e) => e.type != 'Variable lease payments');
            let oldData = variablePaymentArr ? variablePaymentArr : []
            newArr = [...newArr, ...oldData]
            let wrongDate = false
            let wrongCategory = false
            let wrongNewCategory = false
            value.variableLeasePayments.map((d, i) => {
                if (d.category == 'new_category') {
                    let category = (d.new_category).trim()
                    if(category.length < 1){
                        wrongNewCategory = true
                    }
                }
                if (!d.category || (d.category == 'new_category' && !d.new_category)) {
                    wrongCategory = true
                }
                if ((d.frequency == 'One-time' && moment(d.paymentDate).isSameOrAfter(moment(formik.values.commencementDate)) && moment(d.paymentDate).isSameOrBefore(moment(formik.values.lastExtensionDate))) || (d.frequency != 'One-time' && moment(d.paymentDate).isSameOrAfter(moment(formik.values.commencementDate)) && moment(d.paymentDate).isSameOrBefore(moment(formik.values.lastExtensionDate)) && moment(d.paymentEndDate).isSameOrAfter(moment(formik.values.commencementDate)) && moment(d.paymentEndDate).isSameOrBefore(moment(formik.values.lastExtensionDate)))) {
                    let obj1 = {}
                    let type = (d.frequency == 'Monthly' || d.frequency == 'Quarterly' || d.frequency == 'Semi-annual')
                        ? 'months' : 'years';
                    let add = d.frequency == 'Monthly' ? 1 : d.frequency == 'Quarterly' ? 3 : d.frequency == 'Semi-annual' ? 6 : 1;
                    if (d.frequency == 'One-time') {
                        obj1.category = d.category && d.category == 'new_category' ? d.new_category : d.category;
                        obj1.amount = d.amount;
                        obj1.date = d.paymentDate instanceof Array ? moment(d.paymentDate[0]).format('YYYY-MM-DD') : moment(d.paymentDate).format('YYYY-MM-DD');
                        obj1.type = 'Variable lease payments';
                        obj1.actual_amount = d.amount;
                        obj1.is_processed = false
                        if (Object.keys(obj1).length > 0) newArr.push(obj1);
                    } else {
                        let start = d.paymentDate instanceof Array ? moment(new Date(d.paymentDate[0])).format('') : moment(d.paymentDate).format('');
                        let end = d.paymentEndDate instanceof Array ? moment(d.paymentEndDate[0]).format('') : moment(d.paymentEndDate).format('');
                        let newEnd = moment(end);

                        let days = newEnd.diff(start, type);
                        let thisYear = moment(start).format('YYYY')
                        let startMonth = moment(start).month() + 1;
                        let count = 0;
                        let compoundAmount = Number(d.amount);
                        for (let a = 0; a <= days; a++) {
                            let obj2 = {};
                            if (new Date(moment(start).format('YYYY-MM-DD')).getTime() <= new Date(moment(newEnd).format('YYYY-MM-DD')).getTime()) {
                                obj2.date = moment(start).format('YYYY-MM-DD')
                                obj2.type = 'Variable lease payments';
                                obj2.category = d.category && d.category == 'new_category' ? d.new_category : d.category;
                                obj2.is_processed = false
                                let yearVal = moment(start).year() - Number(thisYear)
                                let currentMonth = moment(start).month() + 1;
                                if (currentMonth == startMonth && a != 0) {
                                    count += 1;
                                    if (Number(d.annualFixedAmountPer) > 0) {
                                        compoundAmount = Number(compoundAmount) + ((Number(compoundAmount)) * (Number(d.annualFixedAmountPer) / 100));
                                    }
                                }
                                if (Number(d.annualFixedAmount) > 0) {
                                    if (count > 0) {
                                        obj2.amount = Number(d.amount) + (Number(d.annualFixedAmount) * (count));
                                    } else {
                                        obj2.amount = Number(d.amount);
                                    }
                                } else if (Number(d.annualFixedAmountPer) > 0) {
                                    if (count > 0) {
                                        obj2.amount = compoundAmount;
                                        // compoundAmount + ((compoundAmount * (count)) * (Number(d.annualFixedAmountPer)/100));
                                    } else {
                                        obj2.amount = Number(d.amount);
                                    }
                                } else {
                                    obj2.amount = Number(d.amount)
                                }
                                obj2.actual_amount = obj2.amount
                                if (Object.keys(obj2).length > 0) newArr.push(obj2);
                                if (String(moment(start).format('MM/DD/YYYY')) == String(moment(start).endOf('month').format('MM/DD/YYYY'))) {
                                    start = moment(start).add(add, type).endOf('month')
                                }
                                else {
                                    start = moment(start).add(add, type)
                                }
                            }
                        }
                    }
                } else {
                    wrongDate = true
                }
            })
            if (wrongDate) {
                return toast.error('Please select valid date and title')
            }
            if (wrongCategory) {
                return toast.error('Please select valid category')
            }
            if (wrongNewCategory) {
                return toast.error('Please enter valid new category')
            }
            setTableData(newArr);
            createSortedTable(newArr)
            setVariablePaymentOpen(false);
        }

        if (type != 'Incentives' && type != 'Payments before commencement' && type != 'Periodic lease payments' && type != 'Variable lease payments') {
            if (index > -1 && obj && Number(obj.amount)) {
                data.splice(index, 1, obj)
            } else {
                if (obj && Number(obj.amount)) {
                    data.push(obj);
                }
            }
            setTableData(data);
            createSortedTable(data)
        }
        toast.success('Data saved successfully');
    }
    const clear = (type) => {
        let index = -1;
        let data = [...tableData]
        if (type == 'Initial direct cost') {
            formik.setFieldValue('initialDirectCostAmount', '')
            formik.setFieldValue('initialDirectCostAmountPaymentDate', '')
            index = data.findIndex(e => e.type == 'Initial direct cost')
        } else if (type == 'Penalty for terminating') {
            formik.setFieldValue('penaltyForTerminatingReason', false)
            formik.setFieldValue('penaltyForTerminatingAmount', '')
            formik.setFieldValue('penaltyForTerminatingDescription', '')
            formik.setFieldValue('penaltyForTerminatingPaymentDate', '');
            index = data.findIndex(e => e.type == 'Penalty for terminating')
        } else if (type == 'Payments for purchase option') {
            formik.setFieldValue('paymentsForPurchaseReason', false)
            formik.setFieldValue('certainToExercise', false)
            formik.setFieldValue('paymentsForPurchaseAmount', '')
            formik.setFieldValue('amountForOptionToPurchase', '')
            formik.setFieldValue('paymentsForPurchaseDescription', '')
            formik.setFieldValue('paymentsForPurchasePaymentDate', '');
            index = data.findIndex(e => e.type == 'Payments for purchase option')
        } else if (type == 'Probable owed under RVG') {
            formik.setFieldValue('probableOwedUnderRVGTotalAmount', '');
            formik.setFieldValue('probableOwedUnderRVGAmount', '');
            formik.setFieldValue('probableOwedUnderRVGTotalAmountDesc', '')
            formik.setFieldValue('probableOwedUnderRVGPaymentDate', '');
            index = data.findIndex(e => e.type == 'Probable owed under RVG')
        } else if (type == 'Refundable Deposit') {
            formik.setFieldValue('refundableDepositAmount', '');
            formik.setFieldValue('refundableDepositNotificationDate', '')
            formik.setFieldValue('refundableDepositReceiptDate', '')
            formik.setFieldValue('refundableDepositSummary', '')
            formik.setFieldValue('refundableDepositPaymentDate', '');
            index = data.findIndex(e => e.type == 'Refundable Deposit')
        } else if (type == 'Incentives') {
            let newArr = data.filter((e) => e.type != 'Incentives');
            newArr = [...newArr, ...saveOldPayments.filter((e) => e.type == 'Incentives')]
            formik.setFieldValue('incentives', [{
                amount: '',
                receiptDate: '',
                description: ''
            }])
            setTableData(newArr);
            createSortedTable(newArr)
        } else if (type == 'Payments before commencement') {
            let newArr = data.filter((e) => e.type != 'Payments before commencement');
            newArr = [...newArr, ...saveOldPayments.filter((e) => e.type == 'Payments before commencement')]
            formik.setFieldValue('paymentsBeforeCommencement', [{
                amount: '',
                paymentDate: '',
                description: ''
            }])
            setTableData(newArr);
            createSortedTable(newArr)
        } else if (type == 'Periodic lease payments') {
            let newArr = data.filter((e) => e.type != 'Periodic lease payments');
            newArr = [...newArr, ...saveOldPayments.filter((e) => e.type == 'Periodic lease payments')]
            formik.setFieldValue("periodicLeasePayments", [{
                amount: '',
                frequency: 'Monthly',
                paymentDue: 'Beginning of the period',
                paymentDate: '',
                paymentEndDate: '',
                annualFixedAmountPer: '',
                annualFixedAmount: '',
                description: ''
            }])
            setTableData(newArr);
            createSortedTable(newArr)
        } else if (type == 'Variable lease payments') {
            let newArr = data.filter((e) => e.type != 'Variable lease payments');
            newArr = [...newArr, ...saveOldPayments.filter((e) => e.type == 'Variable lease payments')]
            formik.setFieldValue("variableLeasePayments", [{
                amount: '',
                frequency: 'Monthly',
                paymentDue: 'Beginning of the period',
                paymentDate: '',
                paymentEndDate: '',
                annualFixedAmountPer: '',
                annualFixedAmount: '',
                new_category: '',
                category: ''
            }])
            setTableData(newArr);
            createSortedTable(newArr)
        }

        if (type != 'Incentives' && type != 'Payments before commencement' && type != 'Periodic lease payments' && type != 'Variable lease payments' && index > -1) {
            data.splice(index, 1)
            setTableData(data);
            createSortedTable(data)
        }

    }
    const tog_large = () => {
        // createSortedTable()
        setmodal(!modal);
        setActiveTab('2')
        removeBodyCss();
    }
    const changeVariablePayments = async (payments) => {
        let data = [...tableData]
        let newArr = data.filter((e) => e.type != 'Variable lease payments');
        let oldData = payments ? payments : []
        newArr = [...newArr, ...oldData]
        setTableData(newArr);
        createSortedTable(newArr)
    }
    const toggleVariablePayment = () => {
        setVariablePaymentOpen(!variablePaymentOpen)
    }
    const createSortedTable = (d, val) => {
        let data = d.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.date) - new Date(b.date);
        });
        if (val == undefined) {
            let data1 = data.filter(e => (moment(e.date).isSameOrBefore(moment(moment(formik.values.terminalLeaseEndDate).format('YYYY-MM-DD'))) || (e.type != 'Initial direct cost' && e.type != 'Periodic lease payments')) && e.type != 'Variable lease payments')
            setSortedPayments(data1)
        }
        let previewData = [];
        data.map(value => {
            if (moment(value.date).isSameOrBefore(moment(moment(formik.values.terminalLeaseEndDate).format('YYYY-MM-DD'))) && value.type != 'Variable lease payments' || (value.type != 'Initial direct cost' && value.type != 'Periodic lease payments' && value.type != 'Variable lease payments')) {
                let filter = data.filter(e => {
                    if (moment(value.date).isAfter(moment(moment(formik.values.terminalLeaseEndDate).format('YYYY-MM-DD'))) && (value.type == 'Initial direct cost' || value.type == 'Periodic lease payments')) {
                        return e
                    }
                    else if (new Date(e.date).getTime() == new Date(value.date).getTime() && e.type != 'Variable lease payments') {
                        return e
                    }
                })
                if (filter.length > 0) {
                    let type = filter.map(val => val.type).join(', ')
                    let amt = filter.reduce((acc, curr) => acc + Number(curr.amount), 0);
                    let refund_amount = 0, commense_amount = 0;
                    filter.map((acc) => {
                        if (acc.type == 'Refundable Deposit') {
                            refund_amount += Number(acc.amount)
                        }
                        if (acc.type == 'Payments before commencement') {
                            commense_amount += Number(acc.amount)
                        }
                    });
                    let index = previewData.findIndex(e => new Date(e.date).getTime() == new Date(value.date).getTime());
                    if (index > -1) {
                        previewData.splice(index, 0)
                    } else {
                        previewData.push({ type: type, amount: amt, date: value.date, refund_amount, commense_amount })
                    }
                } else if (filter.length == 0) {
                    previewData.push({ type: value.type, amount: Number(value.amount), date: value.date, refund_amount: 0, commense_amount: 0 })
                }
            }
        })
        let extendPreviewData = [];
        data.map(value => {
            if (moment(value.date).isAfter(moment(moment(formik.values.terminalLeaseEndDate).format('YYYY-MM-DD'))) && (value.type == 'Initial direct cost' || value.type == 'Periodic lease payments')) {
                let filter = data.filter(e => {
                    if (new Date(e.date).getTime() == new Date(value.date).getTime() && e.type != 'Variable lease payments' && (value.type == 'Initial direct cost' || value.type == 'Periodic lease payments')) {
                        return e
                    }
                })
                if (filter.length > 0) {
                    let type = filter.map(val => val.type).join(', ')
                    let amt = filter.reduce((acc, curr) => acc + Number(curr.amount), 0);
                    let refund_amount = 0, commense_amount = 0;
                    filter.map((acc) => {
                        if (acc.type == 'Refundable Deposit') {
                            refund_amount += Number(acc.amount)
                        }
                        if (acc.type == 'Payments before commencement') {
                            commense_amount += Number(acc.amount)
                        }
                    });
                    let index = extendPreviewData.findIndex(e => new Date(e.date).getTime() == new Date(value.date).getTime());
                    if (index > -1) {
                        extendPreviewData.splice(index, 0)
                    } else {
                        extendPreviewData.push({ type: type, amount: amt, date: value.date, refund_amount, commense_amount })
                    }
                } else if (filter.length == 0) {
                    extendPreviewData.push({ type: value.type, amount: Number(value.amount), date: value.date, refund_amount: 0, commense_amount: 0 })
                }
            }
        })
        let variablePaymentData = [];
        data.map(value => {
            if (moment(value.date).isSameOrBefore(moment(moment(formik.values.terminalLeaseEndDate).format('YYYY-MM-DD'))) && (value.type == 'Variable lease payments')) {
                let filter = data.filter(e => {
                    if (new Date(e.date).getTime() == new Date(value.date).getTime() && (e.type == 'Variable lease payments')) {
                        return e
                    }
                })
                if (filter.length > 0) {
                    let type = filter.map(val => val.type).join(', ')
                    let amt = filter.reduce((acc, curr) => acc + Number(curr.amount), 0);
                    let actual_amount = filter.reduce((acc, curr) => acc + Number(curr.actual_amount || 0), 0);
                    let index = variablePaymentData.findIndex(e => new Date(e.date).getTime() == new Date(value.date).getTime());
                    if (index > -1) {
                        variablePaymentData.splice(index, 0)
                    } else {
                        variablePaymentData.push({ type: type, amount: amt, date: value.date, actual_amount })
                    }
                } else if (filter.length == 0) {
                    variablePaymentData.push({ type: value.type, amount: Number(value.amount), date: value.date, actual_amount: 0 })
                }
            }
        })
        let data1 = data.filter(e => moment(e.date).isSameOrBefore(moment(moment(formik.values.terminalLeaseEndDate).format('YYYY-MM-DD'))) && e.type != 'Variable lease payments' || (e.type != 'Initial direct cost' && e.type != 'Periodic lease payments' && e.type != 'Variable lease payments'))
        let extendData = data.filter(e => moment(e.date).isAfter(moment(moment(formik.values.terminalLeaseEndDate).format('YYYY-MM-DD'))) && (e.type == 'Initial direct cost' || e.type == 'Periodic lease payments'))
        setTermEndPayments(extendData)
        let refund = data.filter(e => e.type == 'Refundable Deposit')
        setRefundableDeposit(refund)
        let variableData = data.filter(e => e.type == 'Variable lease payments')
        setVariablePayments(variableData)
        getTotalValue(d);
        setTimeout(() => {
            formik.setFieldValue(`paymentPreview`, previewData)
            formik.setFieldValue(`oldPaymentPreview`, data1)
            formik.setFieldValue(`extensionPreview`, extendPreviewData)
            formik.setFieldValue(`oldExtensionPreview`, extendData)
            formik.setFieldValue(`variablePreview`, variablePaymentData)
            formik.setFieldValue(`oldVariablePreview`, variableData)
        }, 500)
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
     
    const handleModeClick = (modeName) => {
        if (mode === modeName) {
            setMode('')
        }
        else {
            setMode(modeName)
        }
    }
    const setEndDate = (val, key, index) => {
        let type = (val == 'Monthly' || val == 'Quarterly' || val == 'Semi-annual')
            ? 'months' : 'years';
        let add = (val == 'Monthly' || val == 'Quarterly' || val == 'Semi-annual') ? 12 : 1;
        let a;
        if (key == 'paymentDate') {
            a = moment(new Date(val)).add(add, type).subtract(1, 'day').format('MM/DD/YYYY')
        } else {
            a = moment(new Date(formik.values.periodicLeasePayments[index].paymentDate)).add(add, type).subtract(1, 'day').format('MM/DD/YYYY')
        }
        formik.setFieldValue(`periodicLeasePayments[${index}].paymentEndDate`, a)
    }

    const getTotalValue = (d) => {
        let data = d.filter(e => moment(e.date).isSameOrBefore(moment(moment(formik.values.terminalLeaseEndDate).format('YYYY-MM-DD'))) && e.type != 'Variable lease payments' || (e.type != 'Initial direct cost' && e.type != 'Periodic lease payments' && e.type != 'Variable lease payments'))
        let refund = d.filter(e => e.type == 'Refundable Deposit')
        let variableData = d.filter(e => e.type == 'Variable lease payments')
        let extendData = d.filter(e => moment(e.date).isAfter(moment(moment(formik.values.terminalLeaseEndDate).format('YYYY-MM-DD'))) && (e.type == 'Initial direct cost' || e.type == 'Periodic lease payments'))
        let val = data.reduce((acc, current) => acc + Number(current.amount), 0)
        let val1 = extendData.reduce((acc, current) => acc + Number(current.amount), 0)
        let val2 = refund.reduce((acc, current) => acc + Number(current.amount), 0)
        let val3 = variableData.reduce((acc, current) => acc + Number(current.amount), 0)
        formik.setFieldValue(`leasingComponentAmount`, Number(val) > 0 ? Number(val).toFixed(2) : 0)
        setTotalVal(val - val2);
        setTotalVal1(val1);
        setTotalVal2(val2);
        setTotalVal3(val3);
    }

    useEffect(() => {
        if (penaltyToggle) penaltyToggle.current.checked = formik.values.penaltyForTerminatingReason

    }, [penaltyToggle])

    useEffect(() => {
        if (Number(formik.values.amountForOptionToPurchase || 0) > 0) {
            let index = -1;
            let data = [...tableData];
            let obj = {}
            obj.amount = Number(formik.values.amountForOptionToPurchase);
            obj.date = moment(new Date(formik.values.paymentsForPurchasePaymentDate)).format('YYYY-MM-DD')
            obj.type = 'Payments for purchase option';
            index = data.findIndex(e => e.type == 'Payments for purchase option')
            if (index > -1) {
                data.splice(index, 1, obj)
            } else {
                data.push(obj);
            }
            setTableData(data);
            setSortedPayments(data)
            createSortedTable(data, 'val')
        } else {
            formik.setFieldValue('paymentsForPurchaseAmount', '')
            formik.setFieldValue('amountForOptionToPurchase', '')
            let index = -1;
            let data = [...tableData];
            index = data.findIndex(e => e.type == 'Payments for purchase option')
            if (index > -1) {
                data.splice(index, 1)
            }
            setTableData(data);
            setSortedPayments(data)
            createSortedTable(data, 'val')
        }
    }, [formik.values.amountForOptionToPurchase, formik.values.paymentsForPurchasePaymentDate])

    useEffect(() => {
        if (leaseDraftData && (leaseDraftData.oldPaymentPreview || leaseDraftData.oldExtensionPreview || leaseDraftData.oldVariablePreview)) {
            let data = leaseDraftData.oldPaymentPreview ? [...leaseDraftData.oldPaymentPreview] : []
            data = leaseDraftData.oldExtensionPreview ? [...data, ...leaseDraftData.oldExtensionPreview] : data
            data = leaseDraftData.oldVariablePreview ? [...data, ...leaseDraftData.oldVariablePreview] : data
            setTableData(data || []);
            createSortedTable(data || [])
        }
    }, [leaseDraftData])

    useEffect(() => {
        if (!formik.values.oldPaymentPreview) {
            clear('Variable lease payments')
            clear('Initial direct cost')
            clear('Penalty for terminating')
            clear('Payments for purchase option')
            clear('Probable owed under RVG')
            clear('Refundable Deposit')
            clear('Incentives')
            clear('Payments before commencement')
            clear('Periodic lease payments')
            setTableData([]);
            createSortedTable([])
        }
    }, [formik.values.oldPaymentPreview])

    const checkDates = (type, e, key) => {
        if (type == 'start') {
            formik.setFieldValue(`periodicLeasePayments[${key}].paymentDate`, moment(e[0]).format('MM/DD/YYYY'))
            formik.setFieldValue(`periodicLeasePayments[${key}].paymentEndDate`, formik.values.periodicLeasePayments[key].frequency == 'One-time' ? '' : moment(e[0]).add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY'))
            let endDate = formik.values.periodicLeasePayments[key].frequency == 'One-time' ? moment(e[0]).format('MM/DD/YYYY') : moment(e[0]).add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY')
            formik.values.periodicLeasePayments.map((v, i) => {
                if (i > key) {
                    formik.setFieldValue(`periodicLeasePayments[${i}].paymentDate`, moment(endDate).add(1, 'day').format('MM/DD/YYYY'))
                    formik.setFieldValue(`periodicLeasePayments[${i}].paymentEndDate`, formik.values.periodicLeasePayments[i].frequency == 'One-time' ? '' : moment(endDate).add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY'))
                    endDate = formik.values.periodicLeasePayments[i].frequency == 'One-time' ? moment(endDate).add(1, 'day') : moment(endDate).add(12, 'months')
                }
            })
        } else if (type == 'frequency') {
            formik.setFieldValue(`periodicLeasePayments[${key}].frequency`, e.target.value)
            let endDate = e.target.value == 'One-time' ? moment(formik.values.periodicLeasePayments[key].paymentDate) : moment(formik.values.periodicLeasePayments[key].paymentDate).add(12, 'months').subtract(1, 'day')
            formik.values.periodicLeasePayments.map((v, i) => {
                if (i > key) {
                    formik.setFieldValue(`periodicLeasePayments[${i}].paymentDate`, endDate.add(1, 'day').format('MM/DD/YYYY'))
                    formik.setFieldValue(`periodicLeasePayments[${i}].paymentEndDate`, formik.values.periodicLeasePayments[i].frequency == 'One-time' ? '' : endDate.add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY'))
                    endDate = formik.values.periodicLeasePayments[i].frequency == 'One-time' ? endDate.add(1, 'day') : endDate.add(12, 'months')
                }
            })
        }
        else if (type == 'end') {
            formik.setFieldValue(`periodicLeasePayments[${key}].paymentEndDate`, moment(e[0]).format('MM/DD/YYYY'))
            let endDate = moment(e[0])
            formik.values.periodicLeasePayments.map((v, i) => {
                if (i > key) {
                    formik.setFieldValue(`periodicLeasePayments[${i}].paymentDate`, endDate.add(1, 'day').format('MM/DD/YYYY'))
                    formik.setFieldValue(`periodicLeasePayments[${i}].paymentEndDate`, formik.values.periodicLeasePayments[i].frequency == 'One-time' ? '' : endDate.add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY'))
                    endDate = formik.values.periodicLeasePayments[i].frequency == 'One-time' ? endDate.add(1, 'day') : endDate.add(12, 'months')
                }
            })
        }
    }

    const handleClose = () => {
        setShowPopup(false);
    }

    const togglePayment = () => {
        setShowPopup(!showPopup);
    }

    useEffect(() => {
        let paymentData = leaseDraftData && leaseDraftData.oldPaymentPreview ? leaseDraftData.oldPaymentPreview : []
        let extendData = leaseDraftData && leaseDraftData.oldExtensionPreview ? leaseDraftData.oldExtensionPreview : []
        let payments = [...paymentData, ...extendData]
        let data = payments && payments.filter(e => (moment(e.date).isSameOrAfter(moment(formik.values.commencementDate)) && moment(e.date).isSameOrBefore(moment(formik.values.lastExtensionDate)) && e.type != 'Refundable Deposit'))
        if (tab === 3 && (path[3] == 'modify' || path[3] == 'edit_modify_lease') && (data && data.length > 0) && !onceOpened) {
            setTimeout(() => {
                setShowPopup(true)
            },500)
            setOnceOpened(true)
        }

    }, [tab, leaseDraftData])

    const addExtensionPayment = () => {
        let paymentData = leaseDraftData && leaseDraftData.oldPaymentPreview ? leaseDraftData.oldPaymentPreview : []
        let extendData = leaseDraftData && leaseDraftData.oldExtensionPreview ? leaseDraftData.oldExtensionPreview : []
        let payments = [...paymentData, ...extendData]
        let exclude = payments.filter(e => (moment(e.date).isSameOrAfter(moment(formik.values.commencementDate)) && moment(e.date).isSameOrBefore(moment(formik.values.lastExtensionDate)) && e.type != 'Refundable Deposit'))
        let variablePayments = leaseDraftData.oldVariablePreview && leaseDraftData.oldVariablePreview.length > 0 ? leaseDraftData.oldVariablePreview.filter(e => moment(e.date).isSameOrAfter(formik.values.commencementDate) && moment(e.date).isSameOrBefore(formik.values.lastExtensionDate)) : []
        setTableData(variablePayments)
        createSortedTable(variablePayments)
        setSaveOldPayments([])
        setOldPayment(exclude)
        setSelectedPayments(exclude)
        setShowPopup(false)
    }

    const removeExcludedPayment = () => {
        let variablePayments = leaseDraftData.oldVariablePreview && leaseDraftData.oldVariablePreview.length > 0 ? leaseDraftData.oldVariablePreview.filter(e => moment(e.date).isSameOrAfter(formik.values.commencementDate) && moment(e.date).isSameOrBefore(formik.values.lastExtensionDate)) : []
        setOldPayment([])
        setSaveOldPayments([])
        setSelectedPayments([])
        setTableData(variablePayments)
        createSortedTable(variablePayments)
        setShowPopup(false);
    }

    const savePayment = async () => {
        let payments = selectedPayments
        let data = [...tableData]
        for (const value of oldPayment) {
            if (payments.includes(value) && !data.includes(value)) {
                data.push(value)
            } else if (!payments.includes(value) && data.includes(value)) {
                let index = data.findIndex((e) => e == value);
                data.splice(index, 1)
            }
        }
        let wrongDate = false
        for (const i of selectedPayments) {
            let error = await checkError(i)
            if (error) {
                wrongDate = error
                break
            }
        }
        if (wrongDate) {
            return toast.error('Please uncheck rows with error')
        } else {
            setSaveOldPayments(selectedPayments)
            setTableData(data)
            createSortedTable(data)
            toast.success('Excluded payment saved successfully');
        }
    }
    const showCheck = (data) => {
        let a = selectedPayments.filter((e) => e == data);
        return a.length > 0 ? true : false
    }
    const checkArr = (data) => {
        let newArr = [...selectedPayments];
        let index = newArr.findIndex((e) => e == data);
        if (index > -1) {
            newArr.splice(index, 1);
            setSelectedPayments(newArr);
        } else {
            newArr.push(data);
            setSelectedPayments(newArr);
        }
    }
    useEffect(() => {
        if (formik.values.transitionDate && tableData && tableData.length > 0) {
            let data = [...tableData]
            let payment = data.filter(e => e.type != 'Initial direct cost')
            createSortedTable(payment)
            setTableData(payment)
        } else if (formik.values.initialDirectCostAmount && Number(formik.values.initialDirectCostAmount) > 0 && tableData && tableData.length > 0) {
            let obj = { amount: formik.values.initialDirectCostAmount, date: formik.values.initialDirectCostAmountPaymentDate ? moment(formik.values.initialDirectCostAmountPaymentDate).format('YYYY-MM-DD') : moment(new Date(formik.values.commencementDate)).subtract(1, 'days').format('YYYY-MM-DD'), type: 'Initial direct cost' }
            let data = [...tableData]
            data.push(obj)
            createSortedTable(data)
            setTableData(data)
        }
    }, [formik.values.transitionDate])

    const checkError = (data) => {
        if (data.type == 'Periodic lease payments' && (moment(data.date).isBefore(moment(formik.values.commencementDate)) || moment(data.date).isAfter(moment(formik.values.lastExtensionDate)))) {
            return true
        } else if (data.type == 'Payments before commencement' && moment(data.date).isSameOrAfter(moment(formik.values.commencementDate))) {
            return true
        } else if (data.type == 'Initial direct cost' && moment(data.date).isAfter(moment(formik.values.lastExtensionDate))) {
            return true
        } else if (data.type == 'Penalty for terminating' && moment(data.date).isBefore(moment(formik.values.commencementDate))) {
            return true
        } else if (data.type == 'Payments for purchase option' && moment(data.date).isBefore(moment(formik.values.commencementDate))) {
            return true
        }
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Row>
                    <Col xs="3">
                        <div className="mt-0">
                            <Label for="Discount rate">
                                Discount rate
                            </Label>
                            <InputGroup>
                                <Input
                                    type="number"
                                    className="form-control"
                                    id="discountRate"
                                    onChange={(e) => {
                                        formik.setFieldValue(`discountRate`, (e.target.value))
                                    }}
                                    onBlur={() => formik.setFieldTouched("discountRate")}
                                    value={formik.values.discountRate}
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary docs-datepicker-trigger text-black"
                                        disabled
                                    >
                                        %
                                    </button>
                                </div>
                            </InputGroup>
                        </div>
                        {formik.touched.discountRate && formik.errors.discountRate && (
                            <span className='text-danger'>{formik.touched.discountRate && formik.errors.discountRate}</span>
                        )}
                    </Col>
                    <Col xs="3">
                        <div className="mt-0">
                            <Label for="interest_rate_method">
                                Interest Rate Method
                            </Label>
                            <select className="form-control arrows" name='interest_rate_method' id="interest_rate_method"
                                onChange={(e) => {
                                    formik.setFieldValue("interest_rate_method", e.target.value)
                                }}
                                value={formik.values.interest_rate_method} onBlur={() => formik.setFieldTouched("interest_rate_method")}
                            >
                                <option value="true_annual">True annual rate</option>
                                <option value="monthly_compound">Monthly compounded rate</option>
                            </select>
                        </div>
                    </Col>
                    {path[3] != 'modify' &&
                        <Col xs="3">
                            <div className="mt-0">
                                <Label for="calculation_type">
                                    Lease Type
                                </Label>
                                <select className="form-control arrows" name='calculation_type' id="calculation_type"
                                    onChange={(e) => {
                                        formik.setFieldValue("calculation_type", e.target.value)
                                    }}
                                    value={formik.values.calculation_type} onBlur={() => formik.setFieldTouched("calculation_type")}
                                    disabled={clientData && clientData.modules && clientData.modules.includes("daily_leases") || leaseDraftData.calculation_type == 'daily' ? false : true}
                                >
                                    <option value="daily">Daily</option>
                                    <option value="monthly">Monthly</option>
                                </select>
                            </div>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col xs='12'>
                        <div className="d-flex justify-content-end">
                            <button type="button" onClick={() => { toggleVariablePayment() }} className="btn btn-lbusers btn-sm mt-2 mb-1 me-2">
                                VARIABLE PAYMENTS
                            </button>
                            <AddVariablePayment
                                save={save}
                                type={'step3'}
                                tab={tab}
                                path={path[3]}
                                lease_id={sp.get('lease_id')}
                                validation={formik}
                                variablePaymentOpen={variablePaymentOpen}
                                toggleVariablePayment={toggleVariablePayment}
                                setVariablePaymentOpen={setVariablePaymentOpen}
                                variablePayments={variablePaymentArr}
                                setVariablePayments={setVariablePaymentArr}
                                changeVariablePayments={changeVariablePayments}
                            />
                            <button type="button" onClick={() => { tog_large(); }} className="btn btn-lbusers btn-sm mt-2 mb-1">
                                PAYMENT PREVIEW
                            </button>
                        </div>
                        <div className="Accordion" id="accordion" >
                            {(path[3] == 'modify' || path[3] == 'edit_modify_lease') && oldPayment && oldPayment.length > 0 &&
                                <div className="collapse-payment">
                                    <h2 className="accordion-header" id="headingSeven">
                                        <button
                                            className={classnames(
                                                "accordion-button",
                                                "fw-medium",
                                                { collapsed: mode === 'Expected payments from previous lease' }
                                            )}
                                            type="button"
                                            onClick={() => handleModeClick('Expected payments from previous lease')}
                                            style={{ backgroundColor: mode === 'Expected payments from previous lease' ? '#e6eef4' : '#fff' }}
                                        >
                                            Expected payments from previous lease
                                        </button>
                                    </h2>
                                    <Collapse isOpen={mode === 'Expected payments from previous lease'} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div style={{ padding: '20px 30px 0px 30px' }}>
                                                <Row style={{ padding: 10, paddingBottom: 20, backgroundColor: mode === 'Expected payments from previous lease' ? "#fff" : "#edf0f5" }}>
                                                    <Col md='12'>
                                                        <div className="table-responsive">
                                                            <Table className="table mb-0 customTable">
                                                                <thead className="table-light tableHead">
                                                                    <tr>
                                                                        <th>Amount</th>
                                                                        <th>Payment due date</th>
                                                                        <th>Payment Type</th>
                                                                        <th>
                                                                            <div className="mt-4">
                                                                                <Input
                                                                                    className="form-check-input"
                                                                                    name="selectAll"
                                                                                    type="checkbox"
                                                                                    checked={selectedPayments && oldPayment && selectedPayments.length == oldPayment.length}
                                                                                    onClick={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setSelectedPayments(oldPayment)
                                                                                        } else {
                                                                                            setSelectedPayments([])
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="tableBody">
                                                                    {oldPayment && oldPayment.length > 0 && oldPayment.map((data, i) => (
                                                                        <tr key={data.type + i} style={{ backgroundColor: checkError(data) ? 'rgb(254, 223, 223)' : '' }}>
                                                                            <td>$ {data.amount != '' ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                            <td>{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                            <td>{data.type}</td>
                                                                            <td>
                                                                                <div className="mb-3 ms-2">
                                                                                    <Input
                                                                                        className="form-check-input"
                                                                                        name="includePayment"
                                                                                        id={i}
                                                                                        type="checkbox"
                                                                                        checked={showCheck(data)}
                                                                                        onClick={(e) => checkArr(data)}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <div style={{ float: 'right' }}>
                                                        <button type="button" onClick={savePayment} className="btn btn-success btn-sm mt-4 mb-4 me-4">
                                                            SAVE
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Collapse>
                                </div>
                            }
                            <div className="collapse-payment">
                                <h2 className="accordion-header" id="headingSeven">
                                    <button
                                        className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            { collapsed: mode === 'Periodic lease payments' }
                                        )}
                                        type="button"
                                        onClick={() => handleModeClick('Periodic lease payments')}
                                        style={{ backgroundColor: mode === 'Periodic lease payments' ? '#e6eef4' : '#fff' }}
                                    >
                                        Periodic lease payments
                                    </button>
                                </h2>
                                <Collapse isOpen={mode === 'Periodic lease payments'} className="accordion-collapse">
                                    <div className="accordion-body">
                                        <Row>
                                            <FieldArray
                                                name="periodicLeasePayments"
                                                id="periodicLeasePayments"
                                                validateOnChange={false}
                                                render={arrayHelpers => <>
                                                    {(formik.values.periodicLeasePayments || []).map((formRow, key) => (
                                                        <React.Fragment key={key}>
                                                            {
                                                                formik.values.periodicLeasePayments.length > 1 && key > 0 ?
                                                                    <div className="mt-4 d-flex">
                                                                        <hr style={{ flexGrow: '1' }} />
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger gridbtn-m me-4"
                                                                            style={{ marginRight: '0', marginLeft: 'auto' }}
                                                                            onClick={() => {
                                                                                arrayHelpers.remove(key)
                                                                                let arr = ['One-time', 'Monthly', 'Quarterly', 'Semi-annual', 'Annual'];
                                                                                formik.values.periodicLeasePayments.map((d, i) => {
                                                                                    if (i + 1 < formik.values.periodicLeasePayments.length && i != 0) {
                                                                                        let index = arr.findIndex(e => e == formik.values.periodicLeasePayments[i + 1].frequency)
                                                                                        document.getElementById(`periodicLeasePayments[${i}].frequency`).selectedIndex = index;
                                                                                    }
                                                                                })


                                                                            }}
                                                                        >
                                                                            <i className='bx bx-x'></i>
                                                                        </button>
                                                                    </div>
                                                                    : ""
                                                            }
                                                            <div key={key} style={{ padding: '20px 30px 0px 30px' }}>
                                                                <Row key={key} style={{ padding: 10, paddingBottom: 20, backgroundColor: key % 2 == 0 ? "#fff" : "#edf0f5", border: (moment(formik.values.periodicLeasePayments[key].paymentDate).isBefore(moment(formik.values.commencementDate)) || moment(formik.values.periodicLeasePayments[key].paymentDate).isAfter(moment(formik.values.lastExtensionDate)) || (formik.values.periodicLeasePayments[key].frequency != 'One-time' && (moment(formik.values.periodicLeasePayments[key].paymentEndDate).isBefore(moment(formik.values.commencementDate)) || moment(formik.values.periodicLeasePayments[key].paymentEndDate).isAfter(moment(formik.values.lastExtensionDate))))) ? '1px solid red' : '' }}>
                                                                    <Col md='6'>
                                                                        <div className="mt-2">
                                                                            <Label for="Amount">
                                                                                Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="amount"
                                                                                onBlur={() => formik.setFieldTouched(`periodicLeasePayments[${key}].amount`)}
                                                                                value={formik.values.periodicLeasePayments[key].amount}
                                                                                onChange={(e) => {
                                                                                    formik.setFieldValue(`periodicLeasePayments[${key}].amount`, (e.target.value))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {formik.errors && formik.errors.periodicLeasePayments && formik.errors.periodicLeasePayments.length > 0 && formik.errors.periodicLeasePayments[key] != null && formik.errors.periodicLeasePayments[key].amount && (
                                                                            <span className='text-danger'>{'Must be greater than or equal to 0'}</span>
                                                                        )}
                                                                        <Row className='mt-4'>
                                                                            <Col md='12'>
                                                                                <div >
                                                                                    <Label for="Start date">
                                                                                        {formik.values.periodicLeasePayments[key].frequency == 'One-time' ? 'Payment date' : 'Payment Start date'}
                                                                                    </Label>
                                                                                    <InputGroup>
                                                                                        <Flatpickr
                                                                                            className="form-control d-block bg-white"
                                                                                            options={{
                                                                                                altInput: true,
                                                                                                altFormat: "m/d/Y",
                                                                                                dateFormat: "m/d/Y",
                                                                                                minDate: moment(new Date(formik.values.commencementDate)).format('MM/DD/YYYY'),
                                                                                                maxDate: moment(new Date(formik.values.lastExtensionDate)).format('MM/DD/YYYY'),
                                                                                                allowInput: true
                                                                                            }}
                                                                                            value={formik.values.periodicLeasePayments[key].paymentDate}
                                                                                            onClose={(e) => {
                                                                                                formik.setFieldValue(`periodicLeasePayments[${key}].paymentDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                                                formik.setFieldValue(`periodicLeasePayments[${key}].paymentEndDate`, formik.values.periodicLeasePayments[key].frequency == 'One-time' ? '' : moment(e[0]).add(12, 'months').subtract(1, 'day').format('MM/DD/YYYY'))
                                                                                                checkDates('start', e, key)
                                                                                            }}
                                                                                        />
                                                                                        <div className="input-group-append">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                disabled
                                                                                            >
                                                                                                <i
                                                                                                    className="fa fa-calendar"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </button>
                                                                                        </div>
                                                                                    </InputGroup>
                                                                                    {(path[3] == 'modify' || path[3] == 'edit_modify_lease') && <span>Should be on or after lease modification date </span>}
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                    </Col>
                                                                    <Col md='6'>
                                                                        <div className="mt-2">
                                                                            <Label for="Amount">
                                                                                Payment frequency
                                                                            </Label>
                                                                            <div className="input-group-append" >
                                                                                <select className="form-control arrows" id={`periodicLeasePayments[${key}].frequency`}
                                                                                    name={`periodicLeasePayments[${key}].frequency`}
                                                                                    onChange={(e) => {
                                                                                        formik.setFieldValue(`periodicLeasePayments[${key}].frequency`, e.target.value)
                                                                                        setEndDate(e.target.value, 'frequency', key)
                                                                                        checkDates('frequency', e, key)
                                                                                    }}
                                                                                    value={formik.values.periodicLeasePayments[key].frequency}
                                                                                    onBlur={() => formik.setFieldTouched(`periodicLeasePayments[${key}].frequency`)} >
                                                                                    <option value='One-time'>One-time</option>
                                                                                    <option value='Monthly'>Monthly</option>
                                                                                    <option value='Quarterly'>Quarterly</option>
                                                                                    <option value='Semi-annual'>Semi-annual</option>
                                                                                    <option value='Annual'>Annual</option>
                                                                                </select>
                                                                            </div>
                                                                            <Row className='mt-4'>
                                                                                <Col md='12'>
                                                                                    {formik.values.periodicLeasePayments[key].frequency != 'One-time' &&
                                                                                        <div>
                                                                                            <Label for="Start date">
                                                                                                Payment End date
                                                                                            </Label>
                                                                                            <InputGroup>
                                                                                                <Flatpickr
                                                                                                    options={{
                                                                                                        altInput: true,
                                                                                                        altFormat: "m/d/Y",
                                                                                                        dateFormat: "m/d/Y",
                                                                                                        minDate: moment(new Date(formik.values.periodicLeasePayments[key].paymentDate)).format('MM/DD/YYYY'),
                                                                                                        maxDate: moment(new Date(formik.values.lastExtensionDate)).format('MM/DD/YYYY'),
                                                                                                        allowInput: true
                                                                                                    }}
                                                                                                    value={formik.values.periodicLeasePayments[key].paymentEndDate}
                                                                                                    onClose={(e) => {
                                                                                                        formik.setFieldValue(`periodicLeasePayments[${key}].paymentEndDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                                                        checkDates('end', e, key)
                                                                                                    }}
                                                                                                />

                                                                                                <div className="input-group-append">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                        disabled
                                                                                                    >
                                                                                                        <i
                                                                                                            className="fa fa-calendar"
                                                                                                            aria-hidden="true"
                                                                                                        />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </InputGroup>
                                                                                        </div>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md='6'>
                                                                        <div className="mt-4">
                                                                            <Label for="Amount">
                                                                                Annual Fixed amount % increment
                                                                            </Label>
                                                                            <Input
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="annualFixedAmountPer"
                                                                                value={formik.values.periodicLeasePayments[key].annualFixedAmountPer}
                                                                                onBlur={() => formik.setFieldTouched(`periodicLeasePayments[${key}].annualFixedAmountPer`)}
                                                                                disabled={(Number(formik.values.periodicLeasePayments[key].annualFixedAmount || 0) > 0 || formik.values.periodicLeasePayments[key].frequency == 'One-time') ? true : false}
                                                                                onChange={(e) => {
                                                                                    formik.setFieldValue(`periodicLeasePayments[${key}].annualFixedAmountPer`, (e.target.value))
                                                                                    formik.setFieldValue(`periodicLeasePayments[${key}].annualFixedAmount`, (0))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {formik.errors && formik.errors.periodicLeasePayments && formik.errors.periodicLeasePayments.length > 0 && formik.errors.periodicLeasePayments[key] != null && formik.errors.periodicLeasePayments[key].annualFixedAmountPer && (
                                                                            <span className='text-danger'>{formik.errors.periodicLeasePayments[key].annualFixedAmountPer}</span>
                                                                        )}
                                                                    </Col>
                                                                    <Col md='6'>
                                                                        <div className="mt-4">
                                                                            <Label for="Amount">
                                                                                Annual Fixed amount $ increment
                                                                            </Label>
                                                                            <Input
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="annualFixedAmount"
                                                                                onBlur={() => formik.setFieldTouched(`periodicLeasePayments[${key}].annualFixedAmount`)}
                                                                                disabled={(Number(formik.values.periodicLeasePayments[key].annualFixedAmountPer || 0) > 0 || formik.values.periodicLeasePayments[key].frequency == 'One-time') ? true : false}
                                                                                value={formik.values.periodicLeasePayments[key].annualFixedAmount}
                                                                                onChange={(e) => {
                                                                                    formik.setFieldValue(`periodicLeasePayments[${key}].annualFixedAmount`, (e.target.value))
                                                                                    formik.setFieldValue(`periodicLeasePayments[${key}].annualFixedAmountPer`, (0))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {formik.errors && formik.errors.periodicLeasePayments && formik.errors.periodicLeasePayments.length > 0 && formik.errors.periodicLeasePayments[key] != null && formik.errors.periodicLeasePayments[key].annualFixedAmount && (
                                                                            <span className='text-danger'>{'Must be greater than or equal to 0'}</span>
                                                                        )}
                                                                    </Col>
                                                                    <Col md='6'>
                                                                        <div className="mt-4">
                                                                            <Label for="Amount">
                                                                                Description
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="description"
                                                                                value={formik.values.periodicLeasePayments[key].description}
                                                                                onChange={(e) => {
                                                                                    formik.setFieldValue(`periodicLeasePayments[${key}].description`, (e.target.value))
                                                                                }}

                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                    <div className="mt-4 d-flex">
                                                        <hr style={{ flexGrow: '1' }} />
                                                        <button
                                                            type="button"
                                                            className="btn btn-success gridbtn-m me-4"
                                                            style={{ marginRight: '0', marginLeft: 'auto' }}
                                                            onClick={() => arrayHelpers.insert(formik.values.periodicLeasePayments.length, {
                                                                amount: 0,
                                                                frequency: formik.values.periodicLeasePayments[formik.values.periodicLeasePayments.length - 1].frequency,
                                                                paymentDue: 'Beginning of the period',
                                                                paymentDate: moment(new Date(formik.values.periodicLeasePayments[formik.values.periodicLeasePayments.length - 1].paymentEndDate)).add(1, 'days').format('MM/DD/YYYY'),
                                                                paymentEndDate: moment(new Date(formik.values.periodicLeasePayments[formik.values.periodicLeasePayments.length - 1].paymentEndDate)).add(12, 'months').format('MM/DD/YYYY'),
                                                                annualFixedAmountPer: 0,
                                                                annualFixedAmount: 0,
                                                                description: ''
                                                            })}
                                                        >
                                                            <i className='bx bx-plus text-white'></i>
                                                        </button>
                                                    </div>
                                                </>}
                                            />
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div style={{ float: 'right' }}>
                                                    <button type="button" onClick={() => { clear('Periodic lease payments'); toast.success('Data cleared successfully'); }} className="btn btn-danger btn-sm mb-4 mt-4 me-2">
                                                        Clear
                                                    </button>
                                                    <button type="button" onClick={() => { save(formik.values, 'Periodic lease payments'); }} className="btn btn-success btn-sm mb-4 mt-4 me-4">
                                                        SAVE
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </div>
                            <div className="collapse-payment">
                                <h2 className="accordion-header" id="headingSix">
                                    <button
                                        className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            { collapsed: mode === 'Refundable Deposit' }
                                        )}
                                        type="button"
                                        onClick={() => handleModeClick('Refundable Deposit')}
                                        style={{ backgroundColor: mode === 'Refundable Deposit' ? '#e6eef4' : '#fff' }}
                                    >
                                        Refundable Deposit
                                    </button>
                                </h2>
                                <Collapse isOpen={mode === 'Refundable Deposit'} className="accordion-collapse">
                                    <div className="accordion-body">
                                        <Row>
                                            <div style={{ padding: '20px 30px 10px 30px' }}>
                                                <div style={{ padding: '10px 20px 20px 20px', border: (!formik.values.refundableDepositPaymentDate && !formik.values.refundableDepositAmount) ? '1px solid red' : '' }}>
                                                    <Row>
                                                        <Col md="6">
                                                            <div className="mt-2">
                                                                <Label for="Amount">
                                                                    Amount
                                                                </Label>
                                                                <Input
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="refundableDepositAmount"
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`refundableDepositAmount`, (e.target.value))
                                                                    }}
                                                                    value={formik.values.refundableDepositAmount}
                                                                />
                                                            </div>
                                                            {formik.errors && formik.errors.refundableDepositAmount && formik.errors.refundableDepositAmount && (
                                                                <span className='text-danger'>{'Must be greater than or equal to 0'}</span>
                                                            )}
                                                        </Col>
                                                        <Col md="6">
                                                            <div className="mt-2">
                                                                <Label for="Payment date">
                                                                    Payment Date
                                                                </Label>
                                                                <InputGroup>
                                                                    <Flatpickr
                                                                        type='date'
                                                                        className="form-control d-block bg-white"
                                                                        options={{
                                                                            altInput: true,
                                                                            altFormat: "m/d/Y",
                                                                            dateFormat: "m/d/Y",
                                                                            allowInput: true
                                                                        }}
                                                                        name='refundableDepositPaymentDate'
                                                                        id='refundableDepositPaymentDate'
                                                                        onClose={(e) => {
                                                                            formik.setFieldValue(`refundableDepositPaymentDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                        }}
                                                                        onBlur={() => formik.setFieldTouched("refundableDepositPaymentDate")}
                                                                        value={formik.values.refundableDepositPaymentDate}
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                            disabled
                                                                        >
                                                                            <i
                                                                                className="fa fa-calendar"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </InputGroup>
                                                            </div>
                                                            {formik.errors && formik.errors.refundableDepositPaymentDate && formik.errors.refundableDepositPaymentDate && (
                                                                <span className='text-danger'>Payment date is required</span>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <div className="mt-3">
                                                                <Label for="Required Notification date">
                                                                    Required Notification Date
                                                                </Label>
                                                                <InputGroup>
                                                                    <Flatpickr
                                                                        className="form-control d-block bg-white"
                                                                        options={{
                                                                            altInput: true,
                                                                            altFormat: "m/d/Y",
                                                                            dateFormat: "m/d/Y",
                                                                            minDate: moment(new Date(formik.values.commencementDate)).format('MM/DD/YYYY'),
                                                                            allowInput: true
                                                                        }}
                                                                        type='date'
                                                                        id='refundableDepositNotificationDate'
                                                                        onClose={(e) => {
                                                                            formik.setFieldValue(`refundableDepositNotificationDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                        }}
                                                                        onBlur={() => formik.setFieldTouched("refundableDepositNotificationDate")}
                                                                        value={formik.values.refundableDepositNotificationDate}
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                            disabled
                                                                        >
                                                                            <i
                                                                                className="fa fa-calendar"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </InputGroup>
                                                            </div>
                                                        </Col>
                                                        <Col md="6">
                                                            <div className="mt-3">
                                                                <Label for="Receipt date">
                                                                    Receipt Date
                                                                </Label>
                                                                <InputGroup>
                                                                    <Flatpickr
                                                                        className="form-control d-block bg-white"
                                                                        options={{
                                                                            altInput: true,
                                                                            altFormat: "m/d/Y",
                                                                            dateFormat: "m/d/Y",
                                                                            minDate: moment(new Date()).format('MM/DD/YYYY'),
                                                                            allowInput: true
                                                                        }}
                                                                        type='date'
                                                                        id='refundableDepositReceiptDate'
                                                                        onClose={(e) => {
                                                                            formik.setFieldValue(`refundableDepositReceiptDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                        }}
                                                                        onBlur={() => formik.setFieldTouched("refundableDepositReceiptDate")}
                                                                        value={formik.values.refundableDepositReceiptDate}
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                            disabled
                                                                        >
                                                                            <i
                                                                                className="fa fa-calendar"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </InputGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="mt-3">
                                                                <Label for="Sodt">
                                                                    Summary of Deposit Terms
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    id="refundableDepositSummary"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.refundableDepositSummary}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <div style={{ float: 'right' }}>
                                                        <button type="button" onClick={() => { clear('Refundable Deposit'); toast.success('Data cleared successfully'); }} className="btn btn-danger btn-sm mt-4 mb-4 me-2">
                                                            Clear
                                                        </button>
                                                        <button type="button" onClick={() => { save(formik.values, 'Refundable Deposit'); }} className="btn btn-success btn-sm mb-4 mt-4">
                                                            SAVE
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </div>
                                </Collapse>
                            </div>
                            {path[3] != 'modify' &&
                                <div className="collapse-payment">
                                    <h2 className="accordion-header" id="headingSix">
                                        <button
                                            className={classnames(
                                                "accordion-button",
                                                "fw-medium",
                                                { collapsed: mode === 'Payments before commencement' }
                                            )}
                                            type="button"
                                            onClick={() => handleModeClick('Payments before commencement')}
                                            style={{ backgroundColor: mode === 'Payments before commencement' ? '#e6eef4' : '#fff' }}
                                        >
                                            Payments before commencement
                                        </button>
                                    </h2>
                                    <Collapse isOpen={mode === 'Payments before commencement'} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <Row>
                                                <FieldArray
                                                    name="paymentsBeforeCommencement"
                                                    id="paymentsBeforeCommencement"
                                                    validateOnChange={false}
                                                    render={arrayHelpers => <>
                                                        {(formik.values.paymentsBeforeCommencement || []).map((formRow, key) => (
                                                            <React.Fragment key={key}>
                                                                {
                                                                    formik.values.paymentsBeforeCommencement.length > 1 && key > 0 ?
                                                                        <Col xs="12" md="12">
                                                                            <div className="mt-4 d-flex">
                                                                                <hr style={{ flexGrow: '1' }} />
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger gridbtn-m me-4"
                                                                                    style={{ marginRight: '0', marginLeft: 'auto' }}
                                                                                    onClick={() => arrayHelpers.remove(key)}
                                                                                >
                                                                                    <i className='bx bx-x'></i>
                                                                                </button>
                                                                            </div>
                                                                        </Col>
                                                                        : ""
                                                                }
                                                                <div key={key} style={{ padding: '20px 30px 0px 30px' }}>
                                                                    <Row key={key} style={{ padding: 10, paddingBottom: 20, backgroundColor: key % 2 == 0 ? "#fff" : "#edf0f5", border: (moment(formik.values.paymentsBeforeCommencement[key].paymentDate).isSameOrAfter(moment(formik.values.commencementDate)) && !formik.values.paymentsBeforeCommencement[key].amount) ? '1px solid red' : '' }}>
                                                                        <Col md="6">
                                                                            <div className="mt-2">
                                                                                <Label for="Amount">
                                                                                    Amount
                                                                                </Label>
                                                                                <Input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    id="amount"
                                                                                    onBlur={() => formik.setFieldTouched(`paymentsBeforeCommencement[${key}].amount`)}
                                                                                    onChange={(e) => {
                                                                                        formik.setFieldValue(`paymentsBeforeCommencement[${key}].amount`, (e.target.value))
                                                                                    }}
                                                                                    value={formik.values.paymentsBeforeCommencement[key].amount}
                                                                                />
                                                                            </div>
                                                                            {formik.errors && formik.errors.paymentsBeforeCommencement && formik.errors.paymentsBeforeCommencement.length > 0 && formik.errors.paymentsBeforeCommencement[key] != null && formik.errors.paymentsBeforeCommencement[key].amount && (
                                                                                <span className='text-danger'>{formik.errors.paymentsBeforeCommencement[key].amount}</span>
                                                                            )}
                                                                        </Col>
                                                                        <Col md="6">
                                                                            <div className="mt-2">
                                                                                <Label for="Receipt Date">
                                                                                    Payment date
                                                                                </Label>
                                                                                <InputGroup>
                                                                                    <Flatpickr
                                                                                        className="form-control d-block bg-white"
                                                                                        options={{
                                                                                            altInput: true,
                                                                                            altFormat: "m/d/Y",
                                                                                            dateFormat: "m/d/Y",
                                                                                            maxDate: moment(new Date(formik.values.commencementDate)).subtract(1, 'day').format('MM/DD/YYYY'),
                                                                                            allowInput: true
                                                                                        }}
                                                                                        id='paymentDate'
                                                                                        value={formik.values.paymentsBeforeCommencement[key].paymentDate}
                                                                                        onClose={(e) => {
                                                                                            formik.setFieldValue(`paymentsBeforeCommencement[${key}].paymentDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                                        }}
                                                                                    />
                                                                                    <div className="input-group-append">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                            disabled
                                                                                        >
                                                                                            <i
                                                                                                className="fa fa-calendar"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        </button>
                                                                                    </div>
                                                                                </InputGroup>
                                                                                <span>Should be less than lease commencement date</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md='6'>
                                                                            <div className="mt-4">
                                                                                <Label for="Amount">
                                                                                    Description
                                                                                </Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="description"
                                                                                    value={formik.values.paymentsBeforeCommencement[key].description}
                                                                                    onChange={(e) => {
                                                                                        formik.setFieldValue(`paymentsBeforeCommencement[${key}].description`, (e.target.value))
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                        <div className="mt-4 d-flex">
                                                            <hr style={{ flexGrow: '1' }} />
                                                            <button
                                                                type="button"
                                                                className="btn btn-success gridbtn-m me-4"
                                                                style={{ marginRight: '0', marginLeft: 'auto' }}
                                                                onClick={() => arrayHelpers.insert(formik.values.paymentsBeforeCommencement.length, {
                                                                    amount: 0,
                                                                    paymentDate: moment(new Date(formik.values.commencementDate)).format('MM/DD/YYYY'),
                                                                    description: ''
                                                                })}
                                                            >
                                                                <i className='bx bx-plus text-white'></i>
                                                            </button>
                                                        </div>
                                                    </>
                                                    } />
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div style={{ float: 'right' }}>
                                                        <button type="button" onClick={() => { clear('Payments before commencement'); toast.success('Data cleared successfully'); }} className="btn btn-danger btn-sm mb-4 mt-4 me-2">
                                                            Clear
                                                        </button>
                                                        <button type="button" onClick={() => { save(formik.values, 'Payments before commencement'); }} className="btn btn-success btn-sm mb-4 me-4 mt-4">
                                                            SAVE
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Collapse>
                                </div>
                            }
                            <div className="collapse-payment">
                                <h2 className="accordion-header" id="headingFive">
                                    <button
                                        className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            { collapsed: mode === 'Probable owed under RVG' }
                                        )}
                                        type="button"
                                        onClick={() => handleModeClick('Probable owed under RVG')}
                                        style={{ backgroundColor: mode === 'Probable owed under RVG' ? '#e6eef4' : '#fff' }}
                                    >
                                        Probable owed under RVG
                                    </button>
                                </h2>
                                <Collapse isOpen={mode === 'Probable owed under RVG'} className="accordion-collapse">
                                    <div className="accordion-body">
                                        <div style={{ padding: '20px 30px 10px 30px' }}>
                                            <Row style={{ padding: 10, paddingBottom: 20, border: (!formik.values.probableOwedUnderRVGPaymentDate && !formik.values.probableOwedUnderRVGTotalAmount) ? '1px solid red' : '' }}>
                                                <Col md="6">
                                                    <div className="mt-2">
                                                        <Label for="Amount">
                                                            Total Amount under RVG
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="probableOwedUnderRVGTotalAmount"
                                                            onBlur={() => formik.setFieldTouched(`probableOwedUnderRVGTotalAmount`)}
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`probableOwedUnderRVGTotalAmount`, (e.target.value))
                                                            }}
                                                            value={formik.values.probableOwedUnderRVGTotalAmount}
                                                        />
                                                    </div>
                                                    {formik.errors && formik.errors.probableOwedUnderRVGTotalAmount && (
                                                        <span className='text-danger'>{formik.errors.probableOwedUnderRVGTotalAmount}</span>
                                                    )}
                                                </Col>
                                                <Col md="6">
                                                    <div className="mt-2">
                                                        <Label for="Receipt Date">
                                                            Payment Date
                                                        </Label>
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block bg-white"
                                                                options={{
                                                                    altInput: true,
                                                                    altFormat: "m/d/Y",
                                                                    dateFormat: "m/d/Y",
                                                                    allowInput: true,
                                                                    minDate: path[3] == 'modify' || path[3] == 'edit_modify_lease' ? moment(new Date(formik.values.commencementDate)).format('MM/DD/YYYY') : ''
                                                                }}
                                                                id='probableOwedUnderRVGPaymentDate'
                                                                onClose={(e) => {
                                                                    formik.setFieldValue(`probableOwedUnderRVGPaymentDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                }}
                                                                onBlur={() => formik.setFieldTouched("probableOwedUnderRVGPaymentDate")}
                                                                value={formik.values.probableOwedUnderRVGPaymentDate}
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                    disabled
                                                                >
                                                                    <i
                                                                        className="fa fa-calendar"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </InputGroup>
                                                        {(path[3] == 'modify' || path[3] == 'edit_modify_lease') && <span>Should be on or after lease modification date </span>}
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mt-2">
                                                        <Label for="Amount">
                                                            Probable amount owed under RVG
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="probableOwedUnderRVGAmount"
                                                            onBlur={() => formik.setFieldTouched(`probableOwedUnderRVGAmount`)}
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`probableOwedUnderRVGAmount`, (e.target.value))
                                                            }}
                                                            value={formik.values.probableOwedUnderRVGAmount}
                                                        />
                                                    </div>
                                                    {formik.errors && formik.errors.probableOwedUnderRVGAmount && (
                                                        <span className='text-danger'>{formik.errors.probableOwedUnderRVGAmount}</span>
                                                    )}
                                                </Col>
                                                <Col md='6'>
                                                    <div className="mt-2">
                                                        <Label for="Amount">
                                                            Description
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="probableOwedUnderRVGTotalAmountDesc"
                                                            value={formik.values.probableOwedUnderRVGTotalAmountDesc}
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`probableOwedUnderRVGTotalAmountDesc`, (e.target.value))
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col>
                                                <div style={{ float: 'right' }}>
                                                    <button type="button" onClick={() => { clear('Probable owed under RVG'); toast.success('Data cleared successfully'); }} className="btn btn-danger btn-sm mb-4 mt-4 me-2">
                                                        Clear
                                                    </button>
                                                    <button type="button" onClick={() => { save(formik.values, 'Probable owed under RVG'); }} className="btn btn-success btn-sm mb-4 me-4 mt-4">
                                                        SAVE
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </div>
                            <div className="collapse-payment">
                                <h2 className="accordion-header" id="headingFour">
                                    <button
                                        className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            { collapsed: mode === 'Incentives' }
                                        )}
                                        type="button"
                                        onClick={() => handleModeClick('Incentives')}
                                        style={{ backgroundColor: mode === 'Incentives' ? '#e6eef4' : '#fff' }}
                                    >
                                        Incentives
                                    </button>
                                </h2>
                                <Collapse isOpen={mode === 'Incentives'} className="accordion-collapse">
                                    <div className="accordion-body">
                                        <Row>
                                            <FieldArray
                                                name="incentives"
                                                id="incentives"
                                                validateOnChange={false}
                                                render={arrayHelpers => <>
                                                    {(formik.values.incentives || []).map((formRow, key) => (
                                                        <React.Fragment key={key}>
                                                            {
                                                                formik.values.incentives.length > 1 && key > 0 ?
                                                                    <Col xs="12">
                                                                        <div className="mt-4 d-flex">
                                                                            <hr style={{ flexGrow: '1' }} />
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger gridbtn-m me-4"
                                                                                style={{ marginRight: '0', marginLeft: 'auto' }}
                                                                                onClick={() => arrayHelpers.remove(key)}
                                                                            >
                                                                                <i className='bx bx-x'></i>
                                                                            </button>
                                                                        </div>
                                                                    </Col>
                                                                    : ""
                                                            }
                                                            <div key={key} style={{ padding: '20px 40px 10px 40px' }}>
                                                                <Row key={key} style={{ padding: 10, paddingBottom: 20, backgroundColor: key % 2 == 0 ? "#fff" : "#edf0f5", border: (!formik.values.incentives[key].receiptDate && !formik.values.incentives[key].amount) ? '1px solid red' : '' }}>
                                                                    <Col md="6">
                                                                        <div className="mt-2">
                                                                            <Label for="Amount">
                                                                                Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="amount"
                                                                                value={formik.values.incentives[key].amount}
                                                                                onBlur={() => formik.setFieldTouched(`incentives[${key}].amount`)}
                                                                                onChange={(e) => {
                                                                                    formik.setFieldValue(`incentives[${key}].amount`, (e.target.value))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {formik.errors && formik.errors.incentives && formik.errors.incentives.length > 0 && formik.errors.incentives[key] != null && formik.errors.incentives[key].amount && (
                                                                            <span className='text-danger'>{formik.errors.incentives[key].amount}</span>
                                                                        )}
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <div className="mt-2">
                                                                            <Label for="Receipt Date">
                                                                                Receipt Date
                                                                            </Label>
                                                                            <InputGroup>
                                                                                <Flatpickr
                                                                                    className="form-control d-block bg-white"
                                                                                    options={{
                                                                                        altInput: true,
                                                                                        altFormat: "m/d/Y",
                                                                                        dateFormat: "m/d/Y",
                                                                                        allowInput: true,
                                                                                        minDate: path[3] == 'modify' || path[3] == 'edit_modify_lease' ? moment(new Date(formik.values.commencementDate)).format('MM/DD/YYYY') : ''
                                                                                    }}
                                                                                    id='receiptDate'
                                                                                    value={formik.values.incentives[key].receiptDate}

                                                                                    onClose={(e) => {
                                                                                        formik.setFieldValue(`incentives[${key}].receiptDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                                    }}
                                                                                />
                                                                                <div className="input-group-append">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                        disabled
                                                                                    >
                                                                                        <i
                                                                                            className="fa fa-calendar"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    </button>
                                                                                </div>
                                                                            </InputGroup>
                                                                            {(path[3] == 'modify' || path[3] == 'edit_modify_lease') && <span>Should be on or after lease modification date </span>}
                                                                        </div>
                                                                    </Col>
                                                                    <Col md='6'>
                                                                        <div className="mt-2">
                                                                            <Label for="Amount">
                                                                                Description
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="description"
                                                                                value={formik.values.incentives[key].description}
                                                                                onChange={(e) => {
                                                                                    formik.setFieldValue(`incentives[${key}].description`, (e.target.value))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                    <div className="mt-4 d-flex">
                                                        <hr style={{ flexGrow: '1' }} />
                                                        <button
                                                            type="button"
                                                            className="btn btn-success gridbtn-m me-4"
                                                            style={{ marginRight: '0', marginLeft: 'auto' }}
                                                            onClick={() => arrayHelpers.insert(formik.values.incentives.length, {
                                                                amount: 0,
                                                                receiptDate: moment(new Date()).format('MM/DD/YYYY'),
                                                                description: ''
                                                            })}
                                                        >
                                                            <i className='bx bx-plus text-white'></i>
                                                        </button>
                                                    </div> </>
                                                } />
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div style={{ float: 'right' }}>
                                                    <button type="button" onClick={() => { clear('Incentives'); toast.success('Data cleared successfully'); }} className="btn btn-danger btn-sm mb-4 mt-4 me-2">
                                                        Clear
                                                    </button>
                                                    <button type="button" onClick={() => { save(formik.values, 'Incentives'); }} className="btn btn-success btn-sm mb-4 me-4 mt-4">
                                                        SAVE
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </div>
                            <div className="collapse-payment">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            { collapsed: mode === 'Payments for purchase option' }
                                        )}
                                        type="button"
                                        onClick={() => handleModeClick('Payments for purchase option')}
                                        style={{ backgroundColor: mode === 'Payments for purchase option' ? '#e6eef4' : '#fff' }}
                                    >
                                        Payments for purchase option
                                    </button>
                                </h2>
                                <Collapse isOpen={mode === 'Payments for purchase option'} className="accordion-collapse">
                                    <div className="accordion-body">
                                        <div style={{ padding: '20px 30px 10px 30px' }}>
                                            <Row style={{ padding: '20px 10px 20px 10px', border: (moment(formik.values.paymentsForPurchasePaymentDate).isBefore(moment(formik.values.commencementDate)) && !formik.values.paymentsForPurchaseAmount) ? '1px solid red' : '' }}>
                                                <Col className="d-flex" md="12">
                                                    <h6 className="me-4">Reasonably certain?</h6>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            color='danger'
                                                            id="paymentsForPurchaseReason"
                                                            name='paymentsForPurchaseReason'
                                                            checked={formik.values.paymentsForPurchaseReason}
                                                            onClick={(e) => {
                                                                formik.setFieldValue(`paymentsForPurchaseReason`, (e.target.checked))
                                                                formik.setFieldValue(`certainToExercise`, (e.target.checked))
                                                                let elem1 = document.getElementById('certainToExercise');
                                                                let elem2 = document.getElementById("financing");
                                                                let elem3 = document.getElementById("operating");
                                                                if (elem1 && e.target.checked === false) {
                                                                    elem1.checked = false;
                                                                } else if (elem1) {
                                                                    elem1.checked = true;
                                                                } else if (e.target.checked === true) {
                                                                    elem2.checked = true;
                                                                    formik.setFieldValue("classify", ('financing'))
                                                                }
                                                            }}
                                                            onBlur={() => formik.setFieldTouched("paymentsForPurchaseReason")}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mt-2">
                                                        <Label for="Amount">
                                                            Amount
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="paymentsForPurchaseAmount"
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`paymentsForPurchaseAmount`, (e.target.value))
                                                                formik.setFieldValue(`amountForOptionToPurchase`, (e.target.value))
                                                            }}
                                                            onBlur={() => formik.setFieldTouched("paymentsForPurchaseAmount")}
                                                            value={formik.values.paymentsForPurchaseAmount}
                                                        />
                                                    </div>
                                                    {formik.errors && formik.errors.paymentsForPurchaseAmount && (
                                                        <span className='text-danger'>{formik.errors.paymentsForPurchaseAmount}</span>
                                                    )}
                                                </Col>
                                                <Col md="6">
                                                    <div className="mt-2">
                                                        <Label for="Receipt date">
                                                            Payment Date
                                                        </Label>
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block bg-white"
                                                                options={{
                                                                    altInput: true,
                                                                    altFormat: "m/d/Y",
                                                                    dateFormat: "m/d/Y",
                                                                    minDate: moment(new Date(formik.values.commencementDate)).format('MM/DD/YYYY'),
                                                                    allowInput: true
                                                                }}
                                                                id='paymentsForPurchasePaymentDate'
                                                                value={formik.values.paymentsForPurchasePaymentDate}
                                                                onBlur={() => formik.setFieldTouched("paymentsForPurchasePaymentDate")}
                                                                onClose={(e) => {
                                                                    formik.setFieldValue(`paymentsForPurchasePaymentDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                    disabled
                                                                >
                                                                    <i
                                                                        className="fa fa-calendar"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </InputGroup>
                                                        {(path[3] == 'modify' || path[3] == 'edit_modify_lease') && <span>Should be on or after lease modification date </span>}
                                                    </div>
                                                    {formik.errors && formik.errors.paymentsForPurchasePaymentDate && (
                                                        <span className='text-danger'>{formik.errors.paymentsForPurchasePaymentDate}</span>
                                                    )}
                                                </Col>
                                                <Col md='6'>
                                                    <div className="mt-2">
                                                        <Label for="Amount">
                                                            Description
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="paymentsForPurchaseDescription"
                                                            value={formik.values.paymentsForPurchaseDescription}
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`paymentsForPurchaseDescription`, (e.target.value))
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col>
                                                <div style={{ float: 'right' }}>
                                                    <button type="button" onClick={() => { clear('Payments for purchase option'); toast.success('Data cleared successfully'); }} className="btn btn-danger btn-sm mb-4 mt-4 me-2">
                                                        Clear
                                                    </button>
                                                    {formik.values.paymentsForPurchaseReason === true ?
                                                        <button type="button" onClick={() => { save(formik.values, 'Payments for purchase option'); }} className="btn btn-success btn-sm mb-4 me-4 mt-4" style={{ float: 'right' }}>
                                                            SAVE
                                                        </button> :
                                                        <button type="button" className="btn btn-secondary btn-sm mb-4 me-4 mt-4" style={{ float: 'right', cursor: 'default' }}>
                                                            SAVE
                                                        </button>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </div>
                            <div className="collapse-payment">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button
                                        className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            { collapsed: mode === 'Penalty for terminating' }
                                        )}
                                        type="button"
                                        onClick={() => handleModeClick('Penalty for terminating')}
                                        style={{ backgroundColor: mode === 'Penalty for terminating' ? '#e6eef4' : '#fff' }}
                                    >
                                        Penalty for terminating
                                    </button>
                                </h2>
                                <Collapse isOpen={mode === 'Penalty for terminating'} className="accordion-collapse">
                                    <div className="accordion-body">
                                        <div style={{ padding: '20px 30px 10px 30px' }}>
                                            <Row style={{ padding: '20px 10px 20px 10px', border: (moment(formik.values.penaltyForTerminatingPaymentDate).isBefore(moment(formik.values.commencementDate)) && !formik.values.penaltyForTerminatingAmount) ? '1px solid red' : '' }}>
                                                <Col className="d-flex" md="12">
                                                    <h6 className="me-4">Reasonably certain?</h6>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            color='danger'
                                                            ref={penaltyToggle}
                                                            id="penaltyForTerminatingReason"
                                                            onChange={formik.handleChange}
                                                            onBlur={() => formik.setFieldTouched("penaltyForTerminatingReason")}
                                                            checked={formik.values.penaltyForTerminatingReason}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mt-2">
                                                        <Label for="Amount">
                                                            Amount
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="Amount"
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`penaltyForTerminatingAmount`, (e.target.value))
                                                            }}
                                                            onBlur={() => formik.setFieldTouched("penaltyForTerminatingAmount")}
                                                            value={formik.values.penaltyForTerminatingAmount}
                                                        />
                                                    </div>
                                                    {formik.errors && formik.errors.penaltyForTerminatingAmount && (
                                                        <span className='text-danger'>{formik.errors.penaltyForTerminatingAmount}</span>
                                                    )}
                                                </Col>
                                                <Col md="6">
                                                    <div className="mt-2">
                                                        <Label for="Receipt date">
                                                            Payment Date
                                                        </Label>
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block bg-white"
                                                                options={{
                                                                    altInput: true,
                                                                    altFormat: "m/d/Y",
                                                                    dateFormat: "m/d/Y",
                                                                    minDate: moment(new Date(formik.values.commencementDate)).format('MM/DD/YYYY'),
                                                                    allowInput: true
                                                                }}
                                                                id='penaltyForTerminatingPaymentDate'
                                                                value={formik.values.penaltyForTerminatingPaymentDate}
                                                                onClose={(e) => {
                                                                    formik.setFieldValue(`penaltyForTerminatingPaymentDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                    disabled
                                                                >
                                                                    <i
                                                                        className="fa fa-calendar"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </InputGroup>
                                                        {(path[3] == 'modify' || path[3] == 'edit_modify_lease') && <span>Should be on or after lease modification date </span>}
                                                    </div>
                                                </Col>
                                                <Col md='6'>
                                                    <div className="mt-2">
                                                        <Label for="Amount">
                                                            Description
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="penaltyForTerminatingDescription"
                                                            value={formik.values.penaltyForTerminatingDescription}
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`penaltyForTerminatingDescription`, (e.target.value))
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col>
                                                <div style={{ float: 'right' }}>
                                                    <button type="button" onClick={() => { clear('Penalty for terminating'); toast.success('Data cleared successfully'); }} className="btn btn-danger btn-sm mb-4 mt-4 me-2">
                                                        Clear
                                                    </button>
                                                    <button type="button" onClick={() => { save(formik.values, 'Penalty for terminating'); }} className="btn btn-success btn-sm mb-4 me-4 mt-4">
                                                        SAVE
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </div>
                            <div className="collapse-payment">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            { collapsed: mode === 'Initial direct cost' }
                                        )}
                                        type="button"
                                        disabled={formik.values.mode == 'lessor' ? true : false}
                                        onClick={() => handleModeClick('Initial direct cost')}
                                        style={{ backgroundColor: formik.values.mode == 'lessor' ? '#f8f9fa' : mode === 'Initial direct cost' ? '#e6eef4' : '#fff' }}
                                    >
                                        Initial direct cost
                                    </button>
                                </h2>
                                <Collapse isOpen={mode === 'Initial direct cost'} className="accordion-collapse">
                                    <div className="accordion-body">
                                        <div style={{ padding: '20px 30px 10px 30px' }}>
                                            <Row style={{ padding: '5px 10px 20px 10px', border: (moment(formik.values.initialDirectCostAmountPaymentDate).isAfter(moment(formik.values.lastExtensionDate)) && !formik.values.initialDirectCostAmount) ? '1px solid red' : '' }}>
                                                <Col md="6">
                                                    <div className="mt-2">
                                                        <Label for="Amount">
                                                            Amount
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="initialDirectCostAmount"
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`initialDirectCostAmount`, (e.target.value))
                                                            }}
                                                            onBlur={() => formik.setFieldTouched("initialDirectCostAmount")}
                                                            value={formik.values.initialDirectCostAmount}
                                                        />
                                                    </div>
                                                    {formik.touched.initialDirectCostAmount && formik.errors.initialDirectCostAmount && (
                                                        <span className='text-danger'>{formik.touched.initialDirectCostAmount && formik.errors.initialDirectCostAmount}</span>
                                                    )}
                                                </Col>
                                                <Col md="6">
                                                    <div className="mt-2">
                                                        <Label for="Receipt date">
                                                            Payment date
                                                        </Label>
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block bg-white"
                                                                options={{
                                                                    altInput: true,
                                                                    altFormat: "m/d/Y",
                                                                    dateFormat: "m/d/Y",
                                                                    minDate: path[3] == 'modify' || path[3] == 'edit_modify_lease' ? moment(new Date(formik.values.commencementDate)).format('MM/DD/YYYY') : '',
                                                                    maxDate: moment(new Date(formik.values.lastExtensionDate)).format('MM/DD/YYYY'),
                                                                    allowInput: true
                                                                }}
                                                                id='initialDirectCostAmountPaymentDate'
                                                                value={formik.values.initialDirectCostAmountPaymentDate}
                                                                onClose={(e) => {
                                                                    formik.setFieldValue(`initialDirectCostAmountPaymentDate`, moment(e[0]).format('MM/DD/YYYY'))
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                    disabled
                                                                >
                                                                    <i
                                                                        className="fa fa-calendar"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </InputGroup>
                                                        <span>{path[3] == 'modify' || path[3] == 'edit_modify_lease' ? `Should be on or after lease modification date` : `Initial direct cost will be ignored if your lease is a transitioned lease.`} </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col>
                                                <div style={{ float: 'right' }}>
                                                    <button type="button" onClick={() => { clear('Initial direct cost'); toast.success('Data cleared successfully'); }} className="btn btn-danger btn-sm mb-4 mt-4 me-2">
                                                        Clear
                                                    </button>
                                                    <button type="button" onClick={() => { save(formik.values, 'Initial direct cost'); }} className="btn btn-success btn-sm mb-4 me-4 mt-4">
                                                        SAVE
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </Col>
                </Row>
            </FormikProvider>
            <Modal
                size="lg"
                isOpen={modal}
                toggle={() => {
                    tog_large();
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        Preview
                    </h5>
                    <button
                        onClick={() => {
                            setmodal(false);
                            setActiveTab('2')
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Card>
                        <CardBody>
                            <Row>
                                <Nav tabs>
                                    {refundableDeposit && refundableDeposit.length > 0 &&
                                        <NavItem style={{ textAlign: 'left' }}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab === "1",
                                                })}
                                                onClick={() => {
                                                    setActiveTab("1");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block font-size-16">Refundable deposit</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                    <NavItem style={{ textAlign: 'left' }}>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                setActiveTab("2");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                            </span>
                                            <span className="d-none d-sm-block font-size-16">Lease Payments</span>
                                        </NavLink>
                                    </NavItem>
                                    {termEndPayments && termEndPayments.length > 0 &&
                                        <NavItem style={{ textAlign: 'left' }}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab === "3",
                                                })}
                                                onClick={() => {
                                                    setActiveTab("3");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block font-size-16">Extension Payments</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                    {variablePayments && variablePayments.length > 0 &&
                                        <NavItem style={{ textAlign: 'left' }}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab === "4",
                                                })}
                                                onClick={() => {
                                                    setActiveTab("4");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block font-size-16">Variable Lease Payments</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                </Nav>
                                <TabContent
                                    activeTab={activeTab}
                                    className="text-muted"
                                >
                                    <TabPane tabId="1">
                                        {refundableDeposit && refundableDeposit.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th>Amount</th>
                                                                            <th className="text-center">Payment due date</th>
                                                                            <th style={{ textAlign: 'left', width: '35%' }}>Payment Type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {refundableDeposit && refundableDeposit.length > 0 && refundableDeposit.map((data, i) => (
                                                                            <tr key={data.type + i}>
                                                                                <td scope="row">$ {data.amount != '' ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                <td className="text-align-left">{data.type}</td>
                                                                            </tr>
                                                                        ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated(Number(totalVal2).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {sortedPayments && sortedPayments.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th>Amount</th>
                                                                            <th className="text-center">Payment due date</th>
                                                                            <th style={{ textAlign: 'left', width: '35%' }}>Payment Type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {sortedPayments && sortedPayments.length > 0 && sortedPayments.map((data, i) => {
                                                                            if (data.type != 'Refundable Deposit') {
                                                                                return <tr key={data.type + i}>
                                                                                    <td scope="row">$ {data.amount != '' ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                    <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                    <td className="text-align-left">{data.type}</td>
                                                                                </tr>
                                                                            }
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal)).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="3">
                                        {termEndPayments && termEndPayments.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th>Amount</th>
                                                                            <th className="text-center">Payment due date</th>
                                                                            <th style={{ textAlign: 'left', width: '35%' }}>Payment Type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {termEndPayments && termEndPayments.length > 0 && termEndPayments.map((data, i) => (
                                                                            <tr key={data.type + i}>
                                                                                <td scope="row">$ {data.amount != '' ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                <td className="text-align-left">{data.type}</td>
                                                                            </tr>
                                                                        ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal1)).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="4">
                                        {variablePayments && variablePayments.length < 1 ?
                                            <>
                                                <div className="text-center mt-4">No data found</div>
                                            </>
                                            :
                                            <>
                                                <div className="modal-body">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0 text-center customTable">
                                                                    <thead className="table-light tableHead">
                                                                        <tr>
                                                                            <th className="text-center">Date</th>
                                                                            <th className="text-center">Category</th>
                                                                            <th>Estimated Amount</th>
                                                                            {path[3] != 'add' && <th>Actual Amount</th>}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tableBody">
                                                                        {variablePayments && variablePayments.length > 0 && variablePayments.map((data, i) => (
                                                                            <tr key={data.type + i}>
                                                                                <td className="text-center">{moment(data.date).format('MM/DD/YYYY')}</td>
                                                                                <td className="text-center">{data.category ? data.category : ''}</td>
                                                                                <td scope="row">$ {data.amount ? commaSeperated((Number(data.amount)).toFixed(2)) : '0'}</td>
                                                                                {path[3] != 'add' && <td scope="row">{data.actual_amount && data.is_processed ? `$${(Number(data.actual_amount)).toFixed(2)}` : ''}</td>}
                                                                            </tr>
                                                                        ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            <h5 style={{ textAlign: 'end' }} className='mt-3 mr-3'>Total Amount: {commaSeperated((Number(totalVal3)).toFixed(2))}</h5>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </>
                                        }
                                    </TabPane>
                                </TabContent>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            <Modal
                isOpen={showPopup}
                toggle={() => {
                    togglePayment();
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Expected Payments</h5>
                    <button
                        type="button"
                        onClick={handleClose}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="mb-3">
                        Would you like to carry over expected payments from previous lease?
                    </div>
                    <div className="modal-footer mt-3">
                        <button
                            type="button"
                            className="btn btn-lbusers"
                            onClick={removeExcludedPayment}
                        >
                            No
                        </button>
                        <button type="submit" className="btn btn-lbusers" onClick={addExtensionPayment}>
                            Yes
                        </button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default AddLeaseStepThree