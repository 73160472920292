import PropTypes from 'prop-types'
import React,{useState} from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
} from "reactstrap"
import EditClient from 'pages/Myclients/editClient'
import DeleteClient from "pages/Myclients/deleteClient"

const CardGrid = prop => {
  const { user, getClientsData } = prop
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  // toggle edit account modal
  const toggleEdit = () => {
    setEditOpen(!editOpen);
  }
  // toggle delete account modal
  const toggleDelete = () => {
    setDeleteOpen(!deleteOpen);
  }
  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>
            {!user.client_id.logo ? (
              <div className="avatar-sm mx-auto mb-4">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft font-size-16"
                  }
                >
                  {user.client_id.client.charAt(0)}
                </span>
              </div>
            ) : (
              <div className="mb-4">
                <img
                  className="rounded-circle avatar-sm"
                  src={user.client_id.logo}
                  alt=""
                />
              </div>
            )}

            <h5 className="font-size-15 mb-1">
              <Link to="#" className="text-dark">
                {user.client_id.client}
              </Link>
            </h5>
            <div className={`drp text-${user.access_type === 'full_access' ? 'success' : 'warning'}`} style={{fontWeight:'bold'}}>
              ({(user.access_type).replace("_", " ").toUpperCase()})
            </div>
            <div className="mt-4">
              <h5 className="font-size-15 mb-1">
                <Link to="#" className="text-dark">
                  {user.client_id.contact_person}
                </Link>
              </h5>
              <div style={{ fontWeight: 'bold' }}>{user.client_id.email}</div>
            </div>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                <Link to={{pathname:`/${localStorage.getItem('company')}/clients/details`, search: user.client_id._id}} user={user}>
                  <h6 className="text-primary text-decoration-underline">View</h6>
                </Link>
              </div>
              {
                user.access_type === 'full_access' && 
                <>
                  <div className="flex-fill">
                    <Link to="#" >
                      <h6 className="text-success text-decoration-underline"
                        onClick={() => {
                          setSelectedClient(user.client_id);
                          setEditOpen(!editOpen)
                        }}>Edit</h6>
                    </Link>
                  </div>
                  <div className="flex-fill">
                    <Link to="#" id={"profile" + user.client_id._id}>
                      <h6 className="text-danger text-decoration-underline"
                        onClick={() => {
                          setSelectedClient(user.client_id);
                          setDeleteOpen(!deleteOpen)
                        }}>Remove Access</h6>
                    </Link>
                  </div>
                </>
              }
              <div className="flex-fill">
                <Link to={{pathname:`/${localStorage.getItem('company')}/clients/glsetup`, search: user.client_id._id}} user={user}>
                  <h6 className="text-warning text-decoration-underline">G/L Setup</h6>
                </Link>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
      <EditClient editOpen={editOpen} toggleEdit={toggleEdit} setEditOpen={setEditOpen} client={selectedClient} getClientsData={getClientsData} setSelectedClient={setSelectedClient} />
      <DeleteClient deleteOpen={deleteOpen} toggleDelete={toggleDelete} setDeleteOpen={setDeleteOpen} client={selectedClient} getClientsData={getClientsData} setSelectedClient={setSelectedClient} />
    </React.Fragment>
  )
}

CardGrid.propTypes = {
  user: PropTypes.object
}

export default CardGrid
