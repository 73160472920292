import React,{useEffect, useState} from "react"
import {Modal,Label,Input,Row,Col,InputGroup} from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const TerminateDate = (prop) => {
    const { terminateOpen, toggleTerminateLease, setTerminateOpen, termiDate, setTermiDate, modifyOpen, toggleModify, setModifyOpen, modifyL, lease, terminateL, loading} = prop;
    const [check, setCheck] = useState(false);
    const [amt, setAmt] = useState('');

    useEffect(() => {
        if (lease) {
            setCheck(false);
        }        
    },[lease])

    return (
        <React.Fragment>
        <Modal
            isOpen={terminateOpen}
            toggle={() => {
                toggleTerminateLease();
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Termination Date</h5>
                <button
                    type="button"
                    onClick={() => {
                        setTerminateOpen(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form>
                    <div className="mb-3">
                    <Row>
                    <Col md="12" lg="12" xl="12" xxl="12">
                        <h6 className="mt-3">Lease termination date <span className="text-danger">*</span></h6>
                        <InputGroup>
                            <Flatpickr
                                className="form-control d-block bg-white"
                                placeholder="Termination date"
                                id='termiDate'
                                type='date'
                                options={{
                                    altInput: true,
                                    altFormat: "m/d/Y",
                                    dateFormat: "m/d/Y",
                                    allowInput: true,
                                    minDate: moment(lease.commencement).utc().add(1, 'day').format('MM/DD/YYYY'),
                                    maxDate: moment(lease.terminal_end_date).utc().format('MM/DD/YYYY')
                                }}
                                onClose={(e) => {
                                    let a = moment(e[0]).format('MM/DD/YYYY')
                                    setTermiDate(a)
                                }}
                                value={termiDate}
                            />  
                            <div className="input-group-append">
                                <button
                                    type="button"
                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                    disabled
                                >
                                    <i
                                        className="fa fa-calendar"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </InputGroup>
                    </Col>
                    <Col md="12" lg="12" xl="12" xxl="12">
                        <h6 className="mt-3 mb-3">Is this a full termination ? 
                            <span className="form-switch ms-2">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`terminateLToggle`}
                                    value={check}
                                    onChange={(e) => {
                                        setCheck(e.target.checked)
                                        if(!e.target.checked){
                                            setAmt('')
                                        }
                                    }}
                                />
                            </span>
                        </h6>
                    </Col>
                    <Col md="12" lg="12" xl="12" xxl="12">
                        <div>
                            <Label for="optAmount">
                                Amount paid or (received)
                            </Label>
                            <Input
                                type="number"
                                className="form-control"
                                id="amount"
                                disabled={!check}
                                value={amt}
                                onChange={(e) =>{
                                    setAmt(e.target.value)
                                }}
                            />
                            <div className="mt-2"><b>Note : </b>If money is received to terminate a lease please input the amount as negative number.</div>
                        </div>
                    </Col>
                    </Row>
                    </div>
                    <div className="modal-footer mt-3">
                        { !check ? 
                            <button type="button" className="btn btn-lbusers" onClick={()=>{
                                setTerminateOpen(false)
                                setTimeout(() => {
                                    setModifyOpen(true)
                                }, 500)
                            }} disabled={loading} >
                                {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                Submit
                            </button> :
                            <button type="button" className="btn btn-lbusers" onClick={()=> terminateL({date: termiDate, lease_id: lease._id, client_id: lease.client_id._id, check: check, amount: amt})} disabled={loading} >
                                {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                Submit
                            </button>
                        }
                    </div>
                </form>
            </div>
        </Modal>
        </React.Fragment>
    )
}

export default TerminateDate