import React, { useState, useEffect } from "react"
import {Helmet} from "react-helmet";
import {
    Card,
    CardBody,
    Container,
    Row,
    Spinner, Col
} from "reactstrap"
import Breadcrumbs from "components/Common/BreadcrumbBulkUpload";
import toast from "react-hot-toast";
import axios from "axios";
import AppConfig from "constants/config";
import { useParams } from "react-router-dom";
import Layout from "components/VerticalLayout";

const Logs = () => {
    const params = useParams()
    const [logsData, setLogsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [batchData, setBatchData] = useState({});
  
    const getLogData = async() =>{
        setLogsData([]);
        setLoading(true);
        try {
            const batchId = params.id;
            const mode = params.mode;
            const {data} = await axios.get(`${AppConfig.baseUrl}/bulk_lease/get_logs_data?id=${batchId}&mode=${mode ? mode : 'lessee'}`, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setLogsData(data.logsData);
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }
  
    const getBatchData = async() =>{
        setBatchData([]);
        try {
            const batchId = params.id;
            const mode = params.mode;
            const {data} = await axios.get(`${AppConfig.baseUrl}/bulk_lease/get_batch_data?id=${batchId}&mode=${mode ? mode : 'lessee'}`, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setBatchData(data.batchData);
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
    }

    useEffect(() => {
        getLogData();
        getBatchData();
    },[params.mode])

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Batches</title>
                </Helmet>
                <Container fluid>
                    <Breadcrumbs title="logs"
                        breadcrumbItem={`Batch Id - ${batchData.batch_no || ''}`}
                        type="logs"
                    />
                    <Card>
                        <CardBody>
                            {
                                loading ?
                                    <div style={{textAlign: 'center', width:'100%'}}><Spinner className="text-lbusers" /></div>
                                :
                                !logsData || !logsData.log || logsData.log.length < 1 ? 
                                    <div className="text-center">No data found</div> 
                                :
                                logsData.log.map((log, key) => {
                                    return <Row key={key}>
                                        <Col>
                                            <div className="d-flex justify-content-start">
                                                <div>
                                                    {
                                                        log.is_succeed ?
                                                        <i className='bx bx-check text-success font-weight-bold' style={{ fontSize: '30px' }}></i>
                                                        :
                                                        <i className='bx bx-x text-danger font-weight-bold' style={{ fontSize: '30px' }}></i>
                                                    }
                                                </div>
                                                <div className="ms-3 mt-1">
                                                    {log.description}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                })
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </Layout>
    )
}

export default Logs