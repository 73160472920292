import React, { useState, useEffect } from "react"
import {
    Col,
    Form,
    Input,
    Label,
    Row,
    Modal
} from "reactstrap";
import axios from "axios";
import AppConfig from "constants/config";
import toast from 'react-hot-toast';

const AddLeaseStepOne = (prop) => {
    let { formik , clientData, leaseDraftData, mode } = prop;
    const [currencyData, setCurrencyData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [reportingStandard, setReportingStandard] = useState(formik.values.reporting_standard)

    const getCurrencyData = async(filter) =>{
        setCurrencyData([]);
        setLoading(true);
        try {
          const {data} = await axios.get(`${AppConfig.baseUrl}/currency/listall`,{
            headers: { token: localStorage.getItem("token") }
          });
          if(!data.error){
            setCurrencyData(data.data || []);
          }
          else{
              throw new Error(data.title);
          }
        } catch (error) {
          toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    useEffect(() => {
        if(currencyData && currencyData.length >0 && formik.values.currency == 'USD'){
            const cdata = currencyData.filter(c => c.code == 'USD')
            formik.setFieldValue("currency",cdata[0]._id)
        }
    }, [currencyData, formik.values.currency])

    useEffect(() => {
        getCurrencyData({});
    }, [])

    return (
        <React.Fragment>
            <Form>
                <Row>
                    <Col md="6">
                        <Row className="mt-4">
                            <Col sm="2">
                                <button
                                    type="button"
                                    className="btn rounded-circle avatar-md bg-lbusers mt-0 ms-0 text-align-center"
                                >
                                    {
                                        formik.values.type === 'Vehicle' ? <i className='bx bx-car text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                                        : (formik.values.type === 'Building' || formik.values.type === 'Office') ? <i className='bx bx-buildings text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                                        : formik.values.type === 'Land' ? <i className='bx bxs-landscape text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                                        : formik.values.type === 'Equipment' ? <i className='bx bx-list-ol text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                                        : formik.values.type === 'Others' ? <i className='bx bx-customize text-white' style={{ fontSize: '250%', padding: '0' }}></i> : ''
                                    }
                                </button>
                            </Col>
                            <Col sm="10">
                                <div>
                                    <Label for="assetType">
                                        Asset type <span className="text-danger">*</span>
                                    </Label>
                                    <select className="form-control arrows" 
                                    // onChange={(e)=>{setSelectedAssetType(e.target.value)}}
                                    onChange={(e)=>{formik.setFieldValue("type", e.target.value);formik.setFieldValue("assetTypeValue", '');}}
                                    value={formik.values.type} onBlur={() => formik.setFieldTouched("type")}>
                                        <option value="Vehicle">Vehicle</option>
                                        <option value="Building">Building</option>
                                        <option value="Land">Land</option>
                                        <option value="Equipment">Equipment</option>
                                        <option value="Office">Office</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="leaseName">
                                Lease name <span className="text-danger">*</span>
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="leaseName"
                                onChange={formik.handleChange}
                                onBlur={() => formik.setFieldTouched("leaseName")}
                                value={formik.values.leaseName}
                            />                           
                            {formik.touched.leaseName && formik.errors.leaseName && (
                                <span className='text-danger'>{formik.touched.leaseName && formik.errors.leaseName}</span>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="assetDisc">
                                Asset description
                            </Label>
                            <Input
                                type="textarea"
                                rows={6}
                                className="form-control"
                                id="assetDisc"
                                onChange={formik.handleChange}
                                value={formik.values.assetDisc}
                            />      
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="assetId">
                                Serial / VIN / Asset ID
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="assetId"
                                onChange={formik.handleChange}
                                value={formik.values.assetId}
                            />
                        </div>
                        <div className="mt-4">
                                <Label for="manufacturer">
                                    { formik.values.type === 'Vehicle' ? 'Manufacturer'
                                        :(formik.values.type === 'Building' || formik.values.type === 'Office') ?'Sq. Footage'
                                        :formik.values.type === 'Equipment' ? 'Manufacturer'
                                        :formik.values.type === 'Land' ? 'Sq. Footage/Acreage'
                                        :formik.values.type === 'Others'? 'Additional Info'
                                        :''
                                    }
                                </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="assetTypeValue"
                                onChange={formik.handleChange}
                                value={formik.values.assetTypeValue}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="street">
                                Address Line 1
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="address1"
                                onChange={formik.handleChange}
                                value={formik.values.address1}
                            />  
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="street">
                                Address Line 2
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="address2"
                                onChange={formik.handleChange}
                                value={formik.values.address2}
                            />  
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="city">
                                City
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="city"
                                onChange={formik.handleChange}
                                value={formik.values.city}
                            />  
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="state">
                                State <span className="text-danger">*</span>
                            </Label>
                            <select className="form-control arrows" id='state' onChange={(e)=>formik.setFieldValue("state", e.target.value)}
                                value={formik.values.state} onBlur={() => formik.setFieldTouched("state")}>
                                    <option value=''>Select state</option>
                                <option value='AL'>AL</option>
                                <option value='AK'>AK</option>
                                <option value='AZ'>AZ</option>
                                <option value='AR'>AR</option>
                                <option value='CA'>CA</option>
                                <option value='CO'>CO</option>
                                <option value='CT'>CT</option>
                                <option value='DE'>DE</option>
                                <option value='DC'>DC</option>
                                <option value='FL'>FL</option>
                                <option value='GA'>GA</option>
                                <option value='HI'>HI</option>
                                <option value='ID'>ID</option>
                                <option value='IL'>IL</option>
                                <option value='IN'>IN</option>
                                <option value='IA'>IA</option>
                                <option value='KS'>KS</option>
                                <option value='KY'>KY</option>
                                <option value='LA'>LA</option>
                                <option value='ME'>ME</option>
                                <option value='MD'>MD</option>
                                <option value='MA'>MA</option>
                                <option value='MI'>MI</option>
                                <option value='MN'>MN</option>
                                <option value='MS'>MS</option>
                                <option value='MO'>MO</option>
                                <option value='MT'>MT</option>
                                <option value='NE'>NE</option>
                                <option value='NV'>NV</option>
                                <option value='NH'>NH</option>
                                <option value='NJ'>NJ</option>
                                <option value='NM'>NM</option>
                                <option value='NY'>NY</option>
                                <option value='NC'>NC</option>
                                <option value='ND'>ND</option>
                                <option value='OH'>OH</option>
                                <option value='OK'>OK</option>
                                <option value='OR'>OR</option>
                                <option value='PA'>PA</option>
                                <option value='PR'>PR</option>
                                <option value='RI'>RI</option>
                                <option value='SC'>SC</option>
                                <option value='SD'>SD</option>
                                <option value='TN'>TN</option>
                                <option value='TX'>TX</option>
                                <option value='UT'>UT</option>
                                <option value='VT'>VT</option>
                                <option value='VA'>VA</option>
                                <option value='VI'>VI</option>
                                <option value='WA'>WA</option>
                                <option value='WV'>WV</option>
                                <option value='WI'>WI</option>
                                <option value='WY'>WY</option>
                            </select>
                            {formik.touched.state && formik.errors.state && (
                                <span className='text-danger'>{formik.touched.state && formik.errors.state}</span>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="zipcode">
                                Zipcode
                            </Label>
                            <Input
                                type="number"
                                className="form-control"
                                id="zipcode"
                                onChange={formik.handleChange}
                                value={formik.values.zipcode}
                            />
                            {formik.touched.zipcode && formik.errors.zipcode && (
                                <span className='text-danger'>{formik.touched.zipcode && formik.errors.zipcode}</span>
                            )}
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="currency">
                                Select currency
                            </Label>
                            <div className="input-group-append">
                                <select className="form-control arrows" id='currency'
                                onChange={(e)=> {
                                    formik.setFieldValue(`currency`, e.target.value)
                                }}
                                value={formik.values.currency} onBlur={() => formik.setFieldTouched("currency")} >
                                    {
                                        currencyData.map(opt => <option key={opt._id} value={opt._id}>{opt.code}</option> )
                                    }
                                </select>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className="mt-4">
                            <Label for="reporting_standard" className="col-form-label">
                                Reporting Standard:
                            </Label>
                            <select className="form-control arrows" 
                            id="reporting_standard"
                            name="reporting_standard"
                            placeholder="Select reporting standard"
                            onChange={(e) => {
                                if((clientData.reporting_standard && e.target.value != clientData.reporting_standard) || (!clientData.reporting_standard && e.target.value != "FASB ASC 842") ){
                                    setReportingStandard(e.target.value)
                                    setModalOpen( true )
                                }else{
                                    formik.setFieldValue("reporting_standard", e.target.value)
                                }
                                if(formik.values.extendForTheLessor[0].extendTheLease === true && e.target.value == "FASB ASC 842"){
                                    formik.setFieldValue('extendForTheLessor[0].certainToExercise', true)
                                    document.getElementById(`extendForTheLessor[0].certainToExercise`).checked = true
                                }
                                formik.setFieldValue("classify", (e.target.value == "GASB 87" || e.target.value == "IFRS 16") ? 'financing' : leaseDraftData.classify ? leaseDraftData.classify : 'operating')
                            }}
                            disabled={mode == 'lessor'}
                            value={formik.values.reporting_standard} style={{display: "inline-block"}}>
                                <option value="FASB ASC 842">FASB ASC 842</option>
                                <option value="GASB 87">GASB 87</option>
                                <option value="IFRS 16">IFRS 16</option>
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="mt-4"><h5>{mode == 'lessor'? 'Lessee details' : 'Lessor details'}</h5></div>
                </Row>
                <Row>
                    <Col md="6">
                        <div className="mt-2">
                            <Label for="lessee_or_lessor_name">
                                Name
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="lessee_or_lessor_name"
                                onChange={formik.handleChange}
                                value={formik.values.lessee_or_lessor_name}
                            />
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mt-2">
                            <Label for="lessee_or_lessor_email">
                                Email
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="lessee_or_lessor_email"
                                onChange={formik.handleChange}
                                value={formik.values.lessee_or_lessor_email}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mt-4">
                            <Label for="lessee_or_lessor_address">
                                Address
                            </Label>
                            <Input
                                type="textarea"
                                rows={1}
                                className="form-control"
                                id="lessee_or_lessor_address"
                                onChange={formik.handleChange}
                                value={formik.values.lessee_or_lessor_address}
                            />      
                        </div>
                    </Col>
                </Row>
            </Form>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    setModalOpen(!modalOpen);
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Reporting Standard</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setModalOpen(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="mb-3">
                            The associated client reports under {clientData.reporting_standard ? clientData.reporting_standard : "FASB ASC 842"}, are you sure you want {reportingStandard} ?
                        </div>
                        <div className="modal-footer mt-3">
                            <button
                                type="button"
                                className="btn btn-lbusers"
                                onClick={() =>
                                    setModalOpen(false)
                                }
                                disabled={loading} 
                            >
                                {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                No
                            </button>
                            <button type="button" className="btn btn-lbusers" onClick={() => {formik.setFieldValue("reporting_standard",reportingStandard); setModalOpen(false)}} disabled={loading} >
                                {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                Yes
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default AddLeaseStepOne