/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Card, CardBody, Progress, Spinner, Badge } from "reactstrap"
import { useNavigate } from "react-router-dom"
import NewButtonDropdown from "./actionsDropdown";
import moment from 'moment';
import DeleteLease from "pages/Leases/deleteLease";
import ModifyDate from "pages/Leases/modifyDate";
import RevertLease from "pages/Leases/revertLease";
import TerminateDate from "pages/Leases/terminateLease";
import Impairment from "pages/Leases/impairment";
import toast from "react-hot-toast";
import axios from 'axios'
import AppConfig from 'constants/config'
import AuditCategoryDropdown from "./auditCatDropdown";
import { AuthContext } from "context/authContext"
import { capitalize, isArray } from "lodash"
import MultiSelect from "components/Common/MultiSelect";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import ShortTermLease from "pages/Leases/shortTermLease";
import UsefulLife from "pages/Leases/usefulLife";
import { useMaterialReactTable, MaterialReactTable, MRT_ShowHideColumnsButton } from 'material-react-table';
import { Box } from "@mui/material";


const LeaseListView = (prop) => {
  const { clientModules, mode } = useContext(AuthContext)
  const { InitialData, clientAccess, getLeaseData, loading, reviewAccess, clientData } = prop;
  const navigate = useNavigate()
  const [productData, setProductData] = useState([]);
  const [viewBtn, setViewBtn] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [revertOpen, setRevertOpen] = useState(false);
  const [modifyOpen, setModifyOpen] = useState(false);
  const [terminateOpen, setTerminateOpen] = useState(false);
  const [impairment, setImpairment] = useState(false);
  const [selectedLease, setSelectedLease] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [auditCategoryAcccess, setAuditCategoryAccess] = useState(false)
  const [options, setOptions] = useState([]);
  const [selectedClients, setSelectedClients] = useState(null)
  const [termiDate, setTermiDate] = useState('');
  const [shortTermOpen, setShortTermOpen] = useState(false);
  const [usefulLife, setUsefulLife] = useState(false);
  const [columns, setColumns] = useState([])
  const unChangedColumns = [
    {
      accessorKey: 'client_id.client',
      header: 'Client',
      filterVariant: 'text',
      id: "client",
      size: 200,
      muiFilterTextFieldProps: { placeholder: 'Client' },
      Cell: ({ cell, table }) => {
        const rowData = cell?.row?.original
        return <>
          {rowData && rowData.client_id && !rowData.client_id.logo ? (
            <div style={{ display: 'flex', flexDirection: 'row', minWidth: '300px' }}>
              <div className="avatar-sm">
                <button type="button" className="btn rounded-circle avatar-sm bg-lbusers text-white title" >
                  {rowData && rowData.client_id && rowData.client_id.client.charAt(0)}
                </button>
              </div>
              <div className="client">
                <div>{rowData && rowData.client_id && rowData.client_id.client}</div>
                <div style={clientAccess[rowData.client_id.client] === "read_only" ? { color: 'orange', fontWeight: 'bold' } : { color: 'green', fontWeight: 'bold' }}>{rowData.client_id && clientAccess[rowData.client_id.client] ? clientAccess[rowData.client_id.client].replace('_', " ").toUpperCase() : ''}</div>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row', minWidth: '300px' }}>
              <div className="avatar-sm">
                <img
                  className="rounded-circle avatar-sm"
                  src={rowData && rowData.client_id && rowData.client_id.logo}
                  alt=""
                />
              </div>
              <div className="client">
                <div>{rowData && rowData.client_id && rowData.client_id.client}</div>
                <div style={clientAccess[rowData && rowData.client_id && rowData.client_id.client] === "read_only" ? { color: 'orange', fontWeight: 'bold' } : { color: 'green', fontWeight: 'bold' }}>{rowData && rowData.client_id && clientAccess[rowData && rowData.client_id && rowData.client_id.client] ? clientAccess[rowData.client_id.client].replace('_', " ").toUpperCase() : ''}</div>
              </div>
            </div>
          )}
        </>
      },
    },
    {
      accessorKey: 'name',
      header: "Lease name",
      filterVariant: 'text',
      id: "name",
      size: 200,
      muiFilterTextFieldProps: { placeholder: 'Lease name' },
      Cell: ({ cell, table }) => {
        const rowData = cell?.row?.original
        return <div style={{ display: 'flex', flexDirection: 'row', minWidth:'300px'  }}>
          <div className="avatar-sm">
            <button
              type="button"
              className="btn rounded-circle avatar-sm bg-lbusers mt-0 ms-0 text-align-center"
            >
              {rowData && rowData.type === 'Headquarters' ? <i className='bx bx-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                : rowData && rowData.type === 'Super Car' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                  : rowData && rowData.type === 'Trade Center' ? <i className='bx bxs-building-house text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                    : rowData && rowData.type === 'Vehicle' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : (rowData && rowData.type === 'Building' || rowData && rowData.type === 'Office') ? <i className='bx bxs-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                        : rowData && rowData.type === 'Land' ? <i className='bx bxs-landscape text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                          : rowData && rowData.type === 'Equipment' ? <i className='bx bx-list-ol text-white' style={{ fontSize: '180%', padding: '0' }}></i>
                            : rowData && rowData.type === 'Others' ? <i className='bx bxs-customize text-white' style={{ fontSize: '150%', padding: '0' }}></i> : ''
              }
            </button>
          </div>
          <div className="client">
            <div>{rowData ? (rowData.name).toUpperCase() : ''}</div>
          </div>
        </div>
      },
    },
    {
      accessorKey: 'type',
      header: "Type",
      filterVariant: 'text',
      id: "type",
      size: 200,
      muiFilterTextFieldProps: { placeholder: 'Type' },
    },
    {
      accessorKey: "term",
      header: "Lease term",
      id: "term",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ cell, table }) => {
        const rowData = cell?.row?.original
        let val = progressBarForLeases(rowData ? rowData : {});
        return <div className="mt-2" style={{ minWidth: '200px' }}>
          <div className="mb-2">
            <Progress
              value={val}
              color={'warning'}
              className="progress-sm"
            ></Progress>
          </div>
          <div className="">
            <span className="text-muted mb-0">{moment.utc(rowData && rowData.commencement).format('MM/DD/YYYY')}</span> -
            <span className="text-muted mb-0">{moment.utc(rowData && rowData.terminal_end_date).format('MM/DD/YYYY')}</span>
          </div>
        </div>
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      filterVariant: 'text',
      id: "status",
      size: 200,
      muiFilterTextFieldProps: { placeholder: 'Status' },
    },
    {
      accessorKey: 'classification',
      header: "Classification",
      filterVariant: 'text',
      id: "classification",
      size: 200,
      muiFilterTextFieldProps: { placeholder: 'Classification' },
      Cell: ({ cell, table }) => {
        const rowData = cell?.row?.original
        return <span>{rowData && rowData.classification == 'operating' ? 'Operating' : 'Financing'}</span>
      },
    },
    {
      accessorKey: "discount_rate",
      header: "Discount rate",
      filterVariant: 'text',
      id: "discount_rate",
      size: 200,
      muiFilterTextFieldProps: { placeholder: 'Discount rate' },
      Cell: ({ cell, table }) => {
        const rowData = cell?.row?.original
        return <span>{rowData && rowData.discount_rate ? rowData.discount_rate+'%' : '0%'}</span>
      },
    },
    {
      accessorKey: "state",
      header: "State",
      filterVariant: 'text',
      id: "state",
      size: 200,
      muiFilterTextFieldProps: { placeholder: 'State' },
    },
    {
      accessorKey: "reporting_standard",
      header: "Reporting Standard",
      filterVariant: 'text',
      id: "reporting_standard",
      size: 200,
      muiFilterTextFieldProps: { placeholder: 'Reporting Standard' },
      Cell: ({ cell, table }) => {
        const rowData = cell?.row?.original
        return <span>{rowData && rowData.reporting_standard ? rowData.reporting_standard : 'FASB ASC 842'}</span>
      },
    },
    {
      accessorKey: "calculation_type",
      header: "Lease Type",
      filterVariant: 'text',
      id: "calculation_type",
      size: 200,
      muiFilterTextFieldProps: { placeholder: 'Lease Type' },
      Cell: ({ cell, table }) => {
        const rowData = cell?.row?.original
        return <span>{rowData && rowData.calculation_type ? capitalize(rowData.calculation_type) : 'Daily'}</span>
      },
    },
    {
      accessorKey: "is_short_term",
      header: "ST lease",
      id: "is_short_term",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ cell, table }) => {
        const rowData = cell?.row?.original
        return  <Badge
          className="font-size-14"
          color={rowData && rowData.is_short_term ? 'success' : 'danger'}
          pill
          style={{padding:'5px 10px'}}
        >
          {rowData && rowData.is_short_term ? 'Yes' : 'No'}
        </Badge>
      },
    },
    {
      accessorKey: "actions",
      header: "",
      id: "actions",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ cell, table }) => {
        const rowData = cell?.row?.original
        return <div>
          {
            rowData && rowData.is_processing ? "Processing" :
              <NewButtonDropdown state={viewBtn} handleClick={(e) => handleClick(e)} user={rowData} setDeleteOpen={setDeleteOpen} setSelectedLease={setSelectedLease}
                setModifyOpen={setModifyOpen} setRevertOpen={setRevertOpen} setTerminateOpen={setTerminateOpen} setUsefulLife={setUsefulLife} setImpairment={setImpairment} refreshData={refreshLeaseData} />
          }
        </div>
      },
    },
  ]

  const toggleUseful = () => {
    setUsefulLife(!usefulLife)
  }

  const refreshLeaseData = () => {
    const clients = selectedClients.map(v => v.value)
    getLeaseData({ clients: clients, mode: mode ? mode : 'lessee' })
  }

  const toggleDeleteLease = () => {
    setDeleteOpen(!deleteOpen)
  }

  const toggleModifyLease = () => {
    setModifyOpen(!modifyOpen)
  }

  const toggleRevertLease = () => {
    setRevertOpen(!revertOpen)
  }

  const toggleImpairment = () => {
    setImpairment(!impairment)
  }

  const toggleTerminateLease = () => {
    setTerminateOpen(!terminateOpen)
  }

  const progressBarForLeases = (data) => {
    let today = moment(new Date(), 'M/D/YYYY')
    let start = moment(new Date(data.commencement), 'M/D/YYYY')
    let end = moment(new Date(data.terminal_end_date), 'M/D/YYYY')
    let startEndDiff = end.diff(start, 'days');
    let todayEndDiff = end.diff(today, 'days');
    let per = ((startEndDiff - todayEndDiff) / startEndDiff) * 100;
    return Math.round(per)
  }

  const handleClick = () => {
    setViewBtn(!viewBtn)
  }
  
  useEffect(() => {
    if (InitialData && Object.keys(clientAccess).length > 0 && Object.keys(reviewAccess).length > 0 && auditCategoryAcccess) {
      setProductData(InitialData)
      setColumns([
        {
          accessorKey: "audit_category",
          header: "Audit Category",
          filterVariant: 'text',
          id: "audit_category",
          size: 200,
          muiFilterTextFieldProps: { placeholder: 'Audit Category' },
          Cell: ({ cell, table }) => {
            const rowData = cell?.row?.original
            return <span className="d-inline-block" dataToggle="tooltip" dataPlacement="top" data-custom-class="tooltip-danger" title={rowData && rowData.audit_category == "drafted" ? "Drafted" : rowData && rowData.audit_category == "reviewed" ? "Reviewed" : rowData && rowData.audit_category == "submitted" ? "Submitted" : "Created"}>
              <AuditCategoryDropdown reviewAccess={reviewAccess} leaseDetails={rowData} clientAccess={clientAccess} type="list" refreshData={refreshLeaseData} mode={mode} />
            </span>
          },
        }, ...unChangedColumns
      ])      
    }
    else if (InitialData && Object.keys(clientAccess).length > 0 && Object.keys(reviewAccess).length > 0) {
      setProductData(InitialData)
      setColumns(unChangedColumns)
    } else {
      setProductData([])
      setColumns(unChangedColumns)
    } 
  }, [InitialData, clientAccess, reviewAccess, auditCategoryAcccess])

  useEffect(() => {
    if (clientModules) {
      for (const c of Object.keys(clientModules)) {
        if (clientModules[c] && isArray(clientModules[c]) && clientModules[c].length > 0 && clientModules[c].includes('audit_category')) {
          setAuditCategoryAccess(true);
          break;
        }
      }
    }
  }, [clientModules])

  const deleteL = async (d) => {
    setLoading1(true)
    try {
      const { data } = await axios.post(`${AppConfig.baseUrl}/lease/delete_lease`, { Id: d, mode: mode ? mode : 'lessee' }, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        refreshLeaseData()
        toast.success(data.title || 'Lease deleted successfully');
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    toggleDeleteLease();
    setLoading1(false)
  }

  const modifyL = (data) => {
    let a = new Date(data.date)
    window.location.href = `/${localStorage.getItem('company')}/leases/modify?lease_id=${data.lease_id}&client_id=${data.client_id}&m_date=${data.date}&mod_type=${data.mod_type}&percentage=${data.percentage}`
  }

  const revertL = async (d) => {
    setLoading1(true)
    try {
      const { data } = await axios.post(`${AppConfig.baseUrl}/lease_draft/revertLease`, { lease_id: d, mode: mode ? mode : 'lessee', type:'revert' }, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        toast.success(data.title || 'Lease reverted successfully');
        refreshLeaseData()
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    toggleRevertLease();
    setLoading1(false)
  }

  const terminateL = async(d) => {
    setLoading1(true)
    try {
      let newDate = moment(d.date).format('YYYY-MM-DD');
      let amt = d.check === true ? Number(d.amount) : 0;
      const {data} = await axios.post(`${AppConfig.baseUrl}/lease/terminate_lease`, {lease_id: d.lease_id, date: newDate, amount: amt, mode: mode ? mode : 'lessee'}, {
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        toast.success(data.title || 'Lease terminated successfully');
        navigate(`/${localStorage.getItem('company')}/leases/details/${data.lease_id}/refresh`);
      }
      else{
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    toggleTerminateLease();
    setLoading1(false)
  }

  const handleChange = (selected) => {
    setSelectedClients(selected)
  }

  useEffect(() => {
    setOptions(clientData)
  }, [clientData])

  useEffect(() => {
    setSelectedClients([])
    setProductData([])
  }, [mode])

  useEffect(() => {
      if (selectedLease) {
          setTermiDate(moment(selectedLease.commencement).utc().add(1, 'day').format('MM/DD/YYYY'));
      }        
  },[selectedLease])

  const table = useMaterialReactTable({
    columns: columns,
    data: productData, 
    enableColumnActions: false,
    enableSorting: false,
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableFilterMatchHighlighting: false,
    muiTableContainerProps: { sx: { maxHeight: '70vh' } },
    muiTableHeadCellProps: { sx: {backgroundColor: "rgb(239, 242, 247)", fontWeight: 600, fontSize: "0.8125rem"}},
    muiTopToolbarProps: {className: "lease-listing-table",sx: {padding: 0}},
    muiFilterTextFieldProps: () => {return{variant: "outlined", size: "small", inputProps: {sx: {padding: "7px 0 7px 5px", fontSize: "0.8125rem", backgroundColor: "white"}}}},
    state: { showColumnFilters: true, columnOrder: ["audit_category", "client", "name", "type", "term", "status", "classification", "discount_rate", "state", "reporting_standard", "calculation_type", "is_short_term", "actions"] },
    renderTopToolbarCustomActions: () => (
      <Box sx={{display: 'flex', gap: '1rem', width: '40%'}}>
          <MultiSelect className="w-100" options={options} handleChange={handleChange} selectedOptions={selectedClients} loading={loading} placeholder="Select clients for fetching leases..." />
          <button className="btn btn-lbusers" style={{minWidth: loading ? "140px" : "120px", height: "37px"}} onClick={() => refreshLeaseData()} disabled={loading} >
            {
              loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            }
            Fetch Leases
          </button>
      </Box>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <Box>
        <MRT_ShowHideColumnsButton table={table} />
      </Box>
    ),
  });

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <ThemeProvider theme={createTheme({components: {...getMuiTheme(), 
            MuiInputBase: {
              styleOverrides: {
                root: {
                  padding: "0 !important",
                }
              }
            },
            MuiPaper: {
              styleOverrides: {
                root: {
                  border: "0 !important",
                  boxShadow: "none"
                }
              }
            },
            MuiCollapse: {
              styleOverrides: {
                root: {
                  backgroundColor: "#fff !important",
                }
              }
            },
            MuiSvgIcon: {
              styleOverrides: {
                root: {
                  width: "0.8em",
                }
              }
            },
            MuiIconButton: {
              styleOverrides: {
                root: {
                  padding: 0
                }
              }
            },
            MuiSvgIcon: {
              styleOverrides: {
                root: {
                  fontSize: '1.200rem',
                  marginRight: '-10px' 
                }
              }
            },
            MuiTableCell: {
              styleOverrides:{
                root: {
                  fontSize: "0.8125rem"
                }
              }
            },
          }})}>
            <MaterialReactTable table={table} />
          </ThemeProvider>
        </CardBody>
      </Card>
      <DeleteLease deleteOpen={deleteOpen} toggleDelete={toggleDeleteLease} setDeleteOpen={setDeleteOpen} lease={selectedLease} deleteL={deleteL} loading={loading1} />
      <ModifyDate modifyOpen={modifyOpen} toggleModify={toggleModifyLease} setModifyOpen={setModifyOpen} lease={selectedLease} modifyL={modifyL} termiDate={termiDate} />
      <RevertLease deleteOpen={revertOpen} toggleDelete={toggleRevertLease} setDeleteOpen={setRevertOpen} lease={selectedLease} deleteL={revertL} loading={loading1} />
      <TerminateDate terminateOpen={terminateOpen} toggleTerminateLease={toggleTerminateLease} setTerminateOpen={setTerminateOpen} termiDate={termiDate} setTermiDate={setTermiDate} modifyOpen={modifyOpen} toggleModify={toggleModifyLease} setModifyOpen={setModifyOpen} lease={selectedLease} terminateL={terminateL} modifyL={modifyL} loading={loading1} />
      <Impairment impairment={impairment} setImpairment={setImpairment} toggleImpairment={toggleImpairment} leaseId={selectedLease._id} refreshData={refreshLeaseData} />
      <ShortTermLease shortTermOpen={shortTermOpen} setShortTermOpen={setShortTermOpen} lease={selectedLease} refreshData={refreshLeaseData}/>
      <UsefulLife usefulLife={usefulLife} setUsefulLife={setUsefulLife} toggleUseful={toggleUseful} leaseId={selectedLease._id} refreshData={refreshLeaseData} />
    </React.Fragment>)
}

export default LeaseListView