import React, { useEffect, useState } from "react"
import { Col, Input, Label, Row, Card, CardBody } from "reactstrap"
import { FieldArray, FormikProvider } from 'formik';

const AddLeaseStepSix = (prop) => {
    let { formik, mode } = prop;
    let nonLeaseAmt = formik.values.nonLeasingComponent
    const [per, setPer] = useState({lease: 100, nonLease: 0});

    const amountFunction = (val, key, index) => {
        
        let leaseAmt = Number(formik.values.leasingComponentAmount);
        
        if(key == 'leasing'){
            leaseAmt = Number(val)
        }
        if(key == 'nonLease'){
            if(val == 'remove'){                
                nonLeaseAmt.splice(index, 1)
            } else {
                if(index != 0) {
                    nonLeaseAmt.splice(index, 1, {label: nonLeaseAmt[index].label,amount: Number(val)})
                    // nonLeaseAmt = formik.values.nonLeasingComponent
                } else {
                    // nonLeaseAmt = [{label: nonLeaseAmt[0].label, amount: Number(val)}]
                    if(nonLeaseAmt.length == 1) {
                        nonLeaseAmt = [{label: nonLeaseAmt[0].label, amount: Number(val)}]
                    } else {
                        nonLeaseAmt.splice(0, 1, {label: nonLeaseAmt[0].label,amount: Number(val)})
                    }
                }
            }
        }
        let total = leaseAmt + nonLeaseAmt.reduce((acc, current) => acc + Number(current.amount),0);
        let nonLeasetotal = nonLeaseAmt.reduce((acc, current) => acc + Number(current.amount),0);
        let leasePer = ((leaseAmt / total) * 100)
        let nonLeasePer = ((nonLeasetotal / total) * 100)
        setPerFunction(leasePer, nonLeasePer)
    }

    const setPerFunction = (leasePer, nonLeasePer) => {
        setPer({lease: isNaN(leasePer) ? 0 : Number((leasePer).toFixed(2)), nonLease: isNaN(nonLeasePer) ? 0 : nonLeasePer == 0 ? 0.00 : Number((nonLeasePer).toFixed(2)) })
        formik.setFieldValue(`leasingPer`, (isNaN(leasePer) ? 0 : leasePer))
        formik.setFieldValue(`nonLeasingPer`, (isNaN(nonLeasePer) ? 0 : nonLeasePer))
    }
    
    useEffect(() => {
        if(prop.per) {
            setPerFunction(prop.per.leasingPer,prop.per.nonLeasingPer)
        }
    },[prop.per])

    useEffect(() => {
        if(formik.values.leasingComponentAmount) {
            let leaseAmt = Number(formik.values.leasingComponentAmount);
            let total = leaseAmt + nonLeaseAmt.reduce((acc, current) => acc + Number(current.amount),0);
            let nonLeasetotal = nonLeaseAmt.reduce((acc, current) => acc + Number(current.amount),0);
            let leasePer = ((leaseAmt / total) * 100)
            let nonLeasePer = ((nonLeasetotal / total) * 100)
            setPerFunction(leasePer, nonLeasePer)
        }
    },[formik.values.leasingComponentAmount])
    
    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Card className="mt-4 mb-0 card-bg">
                    <CardBody>
                        <Row>
                            <div className="d-flex justify-content-between mt-4">
                                <h3 className="font-size-18">Leasing Component</h3>
                                <h5 className="text-lbusers">{(Number(per.lease)).toFixed(2)}%</h5>
                            </div>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="leaseAmount">
                                        Label
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="leasingComponentLabel"
                                        onChange={formik.handleChange}
                                        onBlur={() => formik.setFieldTouched("leasingComponentLabel")}
                                        value={formik.values.leasingComponentLabel}
                                        disabled={mode == 'lessor'}
                                    />
                                    {formik.touched.leasingComponentLabel && formik.errors.leasingComponentLabel && (
                                        <span className='text-danger'>{formik.touched.leasingComponentLabel && formik.errors.leasingComponentLabel}</span>
                                    )}
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="leaseAmount">
                                        Amount
                                    </Label>
                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="leasingComponentAmount"
                                        value={formik.values.leasingComponentAmount}
                                        onChange={(e) =>{
                                            formik.setFieldValue(`leasingComponentAmount`, (e.target.value))
                                            amountFunction(e.target.value, 'leasing', null)
                                        }}
                                        onBlur={() => formik.setFieldTouched("leasingComponentAmount")}
                                        disabled={mode == 'lessor'}
                                    />
                                    { formik.errors && formik.errors.leasingComponentAmount && (
                                        <span className='text-danger'>{'Must be greater than or equal to 0'}</span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card className="mt-4 mb-0 card-bg">
                    <CardBody>
                        <Row>
                            <div className="d-flex justify-content-between mt-4">
                                <h3 className="font-size-18">Non-Leasing Component</h3>
                                <h5 className="text-lbusers">{(Number(per.nonLease)).toFixed(2)}%</h5>
                            </div>
                        </Row>
                        <FieldArray
                            name="nonLeasingComponent"
                            id="nonLeasingComponent"
                            validateOnChange={false}
                            render={arrayHelpers => <>
                            { (formik.values.nonLeasingComponent).map((formRow, key) => (
                                <React.Fragment key={key}>
                                    {
                                        formik.values.nonLeasingComponent.length > 1 && key > 0 &&
                                            <Row>
                                                <Col xs="12">
                                                    <div className="mt-4 d-flex">
                                                        <hr style={{ flexGrow: '1' }} />
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger gridbtn-m"
                                                            style={{ marginRight: '0', marginLeft: 'auto' }}
                                                            onClick={() =>{ arrayHelpers.remove(key); amountFunction('remove' , 'nonLease', key);}}
                                                            
                                                        >
                                                            <i className='bx bx-x'></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                    }
                                    <Row>
                                        <Col md="6">
                                            <div className="mt-4">
                                                <Label for="nonleaseAmount">
                                                    Label
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="label"
                                                    value={formik.values.nonLeasingComponent[key].label}
                                                    onChange={(e) =>{
                                                        formik.setFieldValue(`nonLeasingComponent[${key}].label`, (e.target.value))
                                                    }}
                                                    disabled={mode == 'lessor'}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mt-4">
                                                <Label for="nonleaseAmount">
                                                    Amount
                                                </Label>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    id={`nonLeasingComponent[${key}].amount`}
                                                    value={formik.values.nonLeasingComponent[key].amount}
                                                    onChange={(e) =>{
                                                        formik.setFieldValue(`nonLeasingComponent[${key}].amount`, (e.target.value))
                                                        amountFunction(e.target.value , 'nonLease', key);
                                                    }}
                                                    disabled={mode == 'lessor'}
                                                />
                                                {/* <InputMask
                                                    mask="99,999,999"
                                                    // value={props.value}
                                                    id="amount"
                                                    className="form-control input-color"
                                                    value={formik.values.nonLeasingComponent[key].amount}
                                                    onChange={(e) =>{
                                                        formik.setFieldValue(`nonLeasingComponent[${key}].amount`, (e.target.value))
                                                        amountFunction(e.target.value , 'nonLease', formik);
                                                    }}
                                                    >
                                                </InputMask> */}
                                            </div>
                                            { formik.errors && formik.errors.nonLeasingComponent && formik.errors.nonLeasingComponent.length > 0  &&  formik.errors.nonLeasingComponent[key] != null && formik.errors.nonLeasingComponent[key].amount && (
                                                <span className='text-danger'>{'Must be greater than or equal to 0'}</span>
                                            )}
                                        </Col>
                                    </Row>
                                </React.Fragment>

                            ))} 
                            <Row className="mt-4">
                                <Col xs="12">
                                    <div className="mt-4 d-flex">
                                        <hr style={{ flexGrow: '1' }} />
                                        <button
                                            type="button"
                                            className="btn btn-success gridbtn-m"
                                            style={{ marginRight: '0', marginLeft: 'auto' }}
                                            onClick={() => arrayHelpers.insert(formik.values.nonLeasingComponent.length, {
                                                label: '',
                                                amount: ''
                                            })}
                                            disabled={mode == 'lessor'}
                                        >
                                            <i className='bx bx-plus text-white'></i>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            </>} />
                    </CardBody>
                </Card>
            </FormikProvider>
        </React.Fragment>
    )
}

export default AddLeaseStepSix