import React, { useState, useEffect, useContext } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import userImg from "../../../assets/images/users/avatar-1.jpg"
import { AuthContext } from "context/authContext"
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";

const ProfileMenu = props => {
	const { userData } = useContext(AuthContext);
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")
  const [profilePicture, setProfilePicture] = useState("")

  const getUsersData = async() => {
    try {
      const {data} = await axios.get(`${AppConfig.baseUrl}/users/details?id=${userData.id}`,{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        setProfilePicture(data.userDetails && data.userDetails.profile_picture || '');
      }
      else{
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
  }

  useEffect(() => {
    if(userData.id) {
      setusername(userData.name);
      getUsersData();
    }
  }, [userData])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profilePicture || userImg}
            alt="Header Avatar"
            onError={(e) => {
              e.target.src = userImg
            }}
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href={`/${localStorage.getItem('company')}/profile`}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            Profile{" "}
          </DropdownItem>
          <div className="dropdown-divider"/>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileMenu
