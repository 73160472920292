import PropTypes from 'prop-types'
import React, {useEffect, useContext, useState} from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import VerticalLayout from "./components/VerticalLayout/"
import "./assets/scss/theme.scss"
import { useIdleTimer } from 'react-idle-timer'
import moment from 'moment'
import { AuthContext } from 'context/authContext'
import axios from "axios";
import AppConfig from "../src/constants/config";
import Login from 'pages/Authentication/Login'
import QuickAccess from 'pages/QuickAccess/quickaccess'
import MyClients from 'pages/Myclients/myclients'
import Logout from 'pages/Authentication/Logout'
import Register from "pages/Authentication/Register"
import ForgetPwd from "pages/Authentication/ForgetPassword"
import FactorAuth from "pages/Authentication/FactorAuth"
import SetPassword from "pages/Authentication/setPassword"
import TermsAndConditions from "pages/Authentication/TermsAndConditions"
import ClientSelection from "pages/Authentication/clientSelection"
import ClientDetails from 'components/client/clientdetails'
import ActivityLog from 'pages/Myclients/activityLog'
import GLSetup from "pages/GLsetup/index"
import Leases from 'pages/Leases/leases'
import LeaseDetails from 'pages/LeaseDetails'
import EditModifyLease from 'pages/Leases/editModifyLease'
import ModifyLease from 'pages/Leases/modifyLease'
import AddLease from "pages/Leases/addLease"
import JournalEntries from "pages/JournalEntries/index"
import MappedJE from "pages/JournalEntries/mappedJE"
import Disclosure from "pages/Disclosure/index"
import Reports from "pages/Reports/index"
import BulkUpload from "pages/BulkUpload/index"
import BulkUploadDetails from "pages/BulkUpload/details"
import BulkUploadLogs from "pages/BulkUpload/logs"
import UserProfile from "pages/Authentication/user-profile"
import ResetPassword from "pages/Authentication/resetPassword"

const App = () => {
  const [interval, setInt] = useState('')
  const [interval1, setInt1] = useState('')
  const [interval2, setInt2] = useState('')
  const {getLastActiveTime} = useIdleTimer()
  const { isProcessingLease, reloadProcessingStatus, isProcessingSearch, reloadProcessingSearch, isProcessingBatch, reloadProcessingBatches } = useContext(AuthContext);

  const setLastLogout = async()=>{
    const lastActive = localStorage.getItem('last_active')
    if(moment().diff(moment(lastActive), 'minutes') > 30){
      try{
        const response = await axios.get('https://api.ipify.org?format=json')
        await axios.post(`${AppConfig.baseUrl}/users/update_last_logout`, {date: new Date(moment(lastActive).add(30,'minutes')),ip: (response && response.data && response.data.ip) || ''}, {
          headers: { token: localStorage.getItem("token") }
        });
      }catch(error){
        console.log(error)
      }
      localStorageClear()
      sessionStorage.clear();
      window.location.href = "/login"
    }
  }

  const setLastActiveTime = async() =>{
    setInterval(() => {
      localStorage.setItem("last_active", getLastActiveTime())
    }, 10000)
  }

  useEffect(() => {
    setLastLogout()
    setLastActiveTime()
  },[])

  useEffect(() => {
    if(isProcessingLease){
      let int = setInterval(() => {
        reloadProcessingStatus()
      }, 3000)
      setInt(int)
    }
    else{
      clearInterval(interval)
    }
  }, [isProcessingLease])

  useEffect(() => {
    if(isProcessingSearch){
      let int = setInterval(() => {
        reloadProcessingSearch()
      }, 3000)
      setInt1(int)
    }
    else{
      clearInterval(interval1)
    }
  }, [isProcessingSearch])

  useEffect(() => {
    if(isProcessingBatch){
      let int = setInterval(() => {
        reloadProcessingBatches()
      }, 3000)
      setInt2(int)
    }
    else{
      clearInterval(interval2)
    }
  }, [isProcessingBatch])

  function localStorageClear() {    
		localStorage.removeItem("last_active")
		localStorage.removeItem("company")
		localStorage.removeItem("token")
		localStorage.removeItem("temp_token")
	}

  function getLayout() {
    let layoutCls = VerticalLayout
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
        <Routes>
          {/* quickaccess */}
          <Route path="/:company/quickaccess" element={ !localStorage.getItem("token") ? <Navigate to="/login"/> : <QuickAccess /> }/>
          
          {/* client */}
          <Route path= "/:company/clients" element={!localStorage.getItem("token") ? <Navigate to="/login"/> : <MyClients />} />
          <Route path= "/:company/clients/details" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <ClientDetails/> } />,
          <Route path= "/:company/clients/activitylog/:id" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <ActivityLog/> } />,
          <Route path= "/:company/clients/glsetup" element={  !localStorage.getItem("token") ? <Navigate to="/login"/> : <GLSetup/> }/>
          
          {/* Leases */}
          <Route path= "/:company/leases" element={ !localStorage.getItem("token") ? <Navigate to="/login"/> : <Leases/> }/>
          <Route path= "/:company/leases/details/:id/:type" element={ !localStorage.getItem("token") ? <Navigate to="/login"/> : <LeaseDetails/> }/>
          <Route path= "/:company/leases/add/:id" element={ !localStorage.getItem("token") ? <Navigate to="/login"/> : <AddLease/> }/>
          <Route path= "/:company/leases/edit" element={ !localStorage.getItem("token") ? <Navigate to="/login"/> : <AddLease/> }/>
          <Route path= "/:company/leases/clone" element={ !localStorage.getItem("token") ? <Navigate to="/login"/> : <AddLease/> }/>
          <Route path= "/:company/leases/edit_modify_lease" element={ !localStorage.getItem("token") ? <Navigate to="/login"/> : <EditModifyLease/> }/>
          <Route path= "/:company/leases/modify" element={ !localStorage.getItem("token") ? <Navigate to="/login"/> : <ModifyLease/> }/>
          <Route path= "/:company/leases/add_bulk_lease" element={ !localStorage.getItem("token") ? <Navigate to="/login"/> : <AddLease/> }/>

          {/* User Profile */}
          <Route path= "/:company/profile" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <UserProfile/> }/>
          
          {/* Journal Entries */}
          <Route path= "/:company/journal" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <JournalEntries/> }/>
          <Route path= "/:company/gl_mapped" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <MappedJE/> }/>

          {/* Disclosure and Report */}
          <Route path = "/:company/disclosures" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Disclosure/> }/>
          <Route path = "/:company/reports" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Reports/> }/>

          {/* Bulk Upload */}
          <Route path = "/:company/bulk_upload" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <BulkUpload/>}/>
          <Route path = "/:company/bulk_upload/details/:id" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <BulkUploadDetails/>}/>
          <Route path = "/:company/bulk_upload/logs/:id/:mode" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <BulkUploadLogs/>}/>

          {/* publicRoutes */}
          <Route path="/login" element={<Login/>} />
          <Route path= "/logout" element={ <Logout/> } />
          <Route path= "/forgot-password" element={ <ForgetPwd/> } />
          <Route path= "/factorauth" element={ <FactorAuth/> } />
          <Route path= "/register" element={ <Register/> } />
          <Route path= "/set_password" element={ <SetPassword/> } />
          <Route path= "/terms_and_conditions/:type" element={ <TermsAndConditions/> } />
          <Route path= "/client_selection" element={ <ClientSelection/> } />
          <Route path= "/reset_password" element={ <ResetPassword/> } />

          <Route path="/" element={ !localStorage.getItem("token") ? <Navigate to="/login"/> : <Navigate to={`/${localStorage.getItem('company')}/quickaccess`} /> } />
          <Route path="/:any" element={ !localStorage.getItem("token") ? <Navigate to="/login"/> : <Navigate to={`/${localStorage.getItem('company')}/quickaccess`} /> } />
          
        </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

export default App
