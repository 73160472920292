import React, { useState, useEffect } from "react"
import { Row, Col, Modal } from "reactstrap"
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "../../constants/config";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const AllocationDetails = (prop) => {
  const { indetifyOpen, setIdentifyOpen, id, setSelectedAllocation} = prop
  const [allocations, setAllocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState({})
  const [updateRow, setUpdateRow] = useState(false)
  const [deleteRow, setDeleteRow] = useState(false)
  const [index, setIndex] = useState(false)
  const columns = [
    {
      name: "preview",
      label: "Preview",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px',height: '70px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px',height: '50px'}}),
        customBodyRenderLite:(dataIndex,rowIndex)=>(
          <div style={{fontSize:'13px'}} className="table-data-margin">
            {allocations && allocations[dataIndex] && allocations[dataIndex].preview}
          </div>
        ),
      }
    },
    {
      label: "Allocation Number",
      name: "allocation_number",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite:(dataIndex,rowIndex)=>{
          return <Editable value={allocations && allocations[dataIndex] && allocations[dataIndex].allocation_number} rowIndex={dataIndex} setRowData={setRowData} setUpdateRow={setUpdateRow} column={'allocation_number'} key={`${dataIndex}-allocation_number`}/>
        },
      }
    },
    {
      label: "Allocation Description",
      name: "allocation_description",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite:(dataIndex,rowIndex)=>{
          return <Editable value={allocations && allocations[dataIndex] && allocations[dataIndex].allocation_description} rowIndex={dataIndex} setRowData={setRowData} setUpdateRow={setUpdateRow} column={'allocation_description'} key={`${dataIndex}-allocation_description`}/>
        }
      },
    },
    {
      label:"",
      name:"",
      options: {
        customBodyRenderLite:(dataIndex,rowIndex)=>{
          return <button type="button" style={{ backgroundColor: 'transparent', borderColor: 'transparent'}}
                onClick={()=>{setIndex(dataIndex);setDeleteRow(true)}}
                disabled={loading}
            ><i className="fas fa-trash-alt" style={{color: "#e32400",fontSize:"20px"}}></i>
            </button>
        },
      }
    },
  ]

  const addRow = () => {
    const updatedData = [...allocations]
    updatedData.push({
      allocation_id:id,
      preview:'',
      allocation_number:'',
      allocation_description:'',
    })
    setAllocations(updatedData)
  }

  useEffect(()=>{
    if(deleteRow){
      const updatedData = [...allocations]
      updatedData.splice(index,1)
      setAllocations(updatedData)
      setDeleteRow(false)
    }
  },[deleteRow])

  useEffect(() => {
    if(id){
      getAllocations()
    }
  },[id])

  const getAllocations = async()=>{
    setLoading(true)
    try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/allocation/get_allocation_details?id=${id}`,{
            headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
          setAllocations(data.allocationData || [])
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  const saveAllocations = async() => {
    setLoading(true)
    try {
        let values = {id,allocations}
        const {data} = await axios.post(`${AppConfig.baseUrl}/allocation/add_allocation_details`,values,{
            headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
            toast.success(data.message || 'Allocation added successfully');
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  useEffect(()=>{
    if(updateRow){
      const updatedData = [...allocations]
      updatedData[rowData.rowIndex][rowData.column] = rowData.inputValue
      updatedData[rowData.rowIndex].preview = `${updatedData[rowData.rowIndex].allocation_number}-${updatedData[rowData.rowIndex].allocation_description}`
      setAllocations(updatedData)
      setUpdateRow(false)
    }
  },[updateRow])

  return (
    <Modal
    isOpen={indetifyOpen}
    toggle={() => {
        setIdentifyOpen(false);
        setSelectedAllocation({})
    }}
    size="lg"
    centered
    >
      <div className="modal-header">
          <h5 className="modal-title mt-0">Add Allocation</h5>
          <button
              type="button"
              onClick={() => {
                  setIdentifyOpen(false);
                  setSelectedAllocation({})
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div className="modal-body">
          <form>
              <Row>
                  <Col lg={12} md={12} xl={12} xxl={12}>
                    <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                      MUIDataTableToolbar:{
                        styleOverrides:{
                          actions: {
                            display: 'none'
                          }
                        }
                      }
                      }})}>
                      <MUIDataTable
                        title={
                          <Row className="row">
                            <Col md='9'></Col>
                            <Col md='3' style={{ textAlign: 'end' }}>
                                <button
                                    type="button"
                                    className="btn btn-lbusers me-2"
                                    onClick={addRow}
                                    disabled={loading}
                                >
                                    ADD MORE
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-lbusers"
                                    onClick={saveAllocations}
                                    disabled={loading}
                                >
                                    SAVE
                                </button>
                            </Col>
                          </Row>
                        }
                        data={allocations}
                        columns={columns}
                        options={{ 
                          rowsPerPage:10, 
                          print:false, 
                          download:false, 
                          filter:false, 
                          viewColumns: false,
                          selectableRows:"none", 
                          search:false, 
                          pagination:false,
                          rowsPerPageOptions:[], 
                          responsive:'scroll',
                          textLabels: {
                            viewColumns: {
                              title: "",
                            },
                          } 
                        }}
                      />
                    </ThemeProvider>
                  </Col>
              </Row>
          </form>
      </div>
    </Modal>
  )
}

const Editable = (prop) => {
  const {value, rowIndex, column, setRowData, setUpdateRow } = prop;
  const [inputValue, setInputValue] = useState(value);
  
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    setRowData({rowIndex, column, inputValue})
    setUpdateRow(true)
  };

  useEffect(()=>{
    setInputValue(value)
  },[value])

  return (
    <>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          className={`form-control`} 
        />
    </>
  );
}

export default AllocationDetails
