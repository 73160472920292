import React, { useState } from "react"
import { Col, Container, Row , Card, CardBody, Spinner, Input } from "reactstrap"
import { map } from "lodash"
import CardGrid from "./cardgrid"

const ClientGridView = prop => {
  const {clientData, getClientsData, loading} = prop;
  const [selectedOptions, setSelectedOption] = useState("");
  const [searchText, setSearchText] = useState("");
  
  const handleChangeStatus = (e) => {
    setSelectedOption(e.target.value);
  }

  return (
    <React.Fragment>
        <Container fluid className="p-0">
          <Row>
          <Card>
            <CardBody>
              <div className="d-flex mt-2">
                <Col xs="3">
                  <div className="me-2">
                    <div className="position-relative sw">
                      <Input type="text" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                    </div>
                  </div>
                </Col>
                <Col xs="3">
                    <select
                      value={selectedOptions}
                      onChange={handleChangeStatus}
                      className="form-control"
                      style={{width: '100%'}}
                    >
                      <option name="accesstype" value="">Access Type</option>
                      <option name="read_only" value="read_only">Read Only</option>
                      <option name="full_acess" value="full_access">Full Access</option>
                    </select>
                  </Col>
                  <Col xs="3">
                    <div className="me-2">
                      <div className="position-relative sw">
                        <button className="btn bg-theme-color w-md ms-3" disabled={loading} onClick={() => getClientsData({search: searchText, access_type: selectedOptions})}>
                            {
                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            SEARCH
                        </button>
                      </div>
                    </div>
                  </Col>
              </div>
            </CardBody>
          </Card>
          {
            loading ?
            <div style={{textAlign: 'center', width:'100%'}}><Spinner className="text-lbusers" /></div>
            : clientData.length < 1 ? <div style={{textAlign: 'center', width:'100%'}}>No data found</div> :
            map(clientData, (user, key) => (
              <CardGrid user={user} getClientsData={getClientsData}/> 
            ))
          }
          </Row>
        </Container>
    </React.Fragment>
  )
}

export default ClientGridView
