import React, { useEffect, useState,useContext } from "react"
import { Row, Col, Input } from "reactstrap";
import moment from 'moment';
import '../../pages/Myclients/datatables.scss'
import { commaSeperated } from "helpers/formatters"
import ExcelJS from "exceljs";
import { AuthContext } from "context/authContext";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const Payment = (prop) => {
  const { mode } = useContext(AuthContext)
  const [actualData, setActualData] = useState([]);
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState("");
  const [originalData, setOriginalData] = useState([]);

  const columns = [
    {
      label: 'Date',
      name: 'due_date',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px',height: '50px'}}),
      }
    },
    {
      label: 'Type',
      name: 'type',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      label: 'Amount',
      name: 'amount',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      label: 'Leasing',
      name: 'leasing_value',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      label: 'Non leasing',
      name: 'non_leasing_value',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      label: 'Category',
      name: 'category',
      options: {
        filter: false,
        sort: true,
      }
    },
  ]

  useEffect(() => {
    if (prop.data) {
      const allPayments = []
      for(const d of prop.data){
        let obj = {
          type: d.type,
          due_date: moment.utc(d.due_date).format('MM/DD/YYYY'),
          amount: d.amount && Number(Number(d.amount).toFixed(2)) != 0 ? prop.currency + " " + commaSeperated(d.amount) : 0,
          leasing_value: d.leasing_value && Number(Number(d.leasing_value).toFixed(2)) != 0 ? prop.currency + " " + commaSeperated(d.leasing_value) : 0,
          non_leasing_value: d.non_leasing_value && Number(Number(d.non_leasing_value).toFixed(2)) != 0 ? prop.currency + " " + commaSeperated(d.non_leasing_value) : 0,
          category: d.category && mode == 'lessor' ? d.category.replace('Lease Liability', "Receivable").replace('ROU Asset', "Deferred Inflow Of Resources") : d.category,
          _id: d._id
        }
        allPayments.push(obj)
      }
      setTableData(allPayments)
      setOriginalData(allPayments)
      setActualData(allPayments)
    }
  }, [prop.data])

  const filterPayments = (e) => {
    if (e.target.value == 'variable') {
      const filteredArray = [...actualData].filter(v => v.type == 'Variable lease payments')
      const sortedData = filteredArray.sort((a, b) => (new Date(a.due_date) - new Date(b.due_date)))
      setTableData(sortedData)
      setOriginalData(sortedData)
    } else if (e.target.value == 'no_variable') {
      const filteredArray = [...actualData].filter(v => v.type != 'Variable lease payments')
      const sortedData = filteredArray.sort((a, b) => (new Date(a.due_date) - new Date(b.due_date)))
      setTableData(sortedData)
      setOriginalData(sortedData)
    } else {
      const sortedData = [...actualData].sort((a, b) => (new Date(a.due_date) - new Date(b.due_date)))
      setTableData(sortedData)
      setOriginalData(sortedData)
    }
  }

  const handleSearch = (value) => {
    const data = originalData.filter(v => v.due_date.toString().match(new RegExp(value,"i")) || v.type.match(new RegExp(value,"i")) || v.amount.match(new RegExp(value,"i")) || v.leasing_value.toString().match(new RegExp(value,"i")) || v.non_leasing_value.toString().match(new RegExp(value,"i")) || v.category.match(new RegExp(value,"i")))
    setTableData(data)
  }

  const downloadCSVFile = () => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();

    var worksheet = ExcelJSWorkbook.addWorksheet("Payments");
    worksheet.getColumn("A").width = 10
    worksheet.getColumn("B").width = 10
    worksheet.getColumn("C").width = 10
    worksheet.getColumn("D").width = 10
    worksheet.getColumn("E").width = 10
    worksheet.getColumn("F").width = 10
    
    worksheet.getCell("A1").value = `Date`        
    worksheet.getCell("B1").value = `Type`        
    worksheet.getCell("C1").value = `Amount`        
    worksheet.getCell("D1").value = `Leasing`        
    worksheet.getCell("E1").value = `Non leasing`        
    worksheet.getCell("F1").value = `Category` 
           
    tableData && tableData.length > 0 && tableData.map((value, i) => {
      return worksheet.addRow([ value.due_date, value.type, value.amount, value.leasing_value, value.non_leasing_value,value.category]);
    })
    
    worksheet.getColumn(1).numFmt = 'mm/dd/yyyy'
    worksheet.getColumn(2).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
    worksheet.getColumn(3).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
    worksheet.getColumn(4).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
    worksheet.getColumn(5).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
    worksheet.getColumn(6).numFmt = `"${prop.currency}"##,##,##,##,##0.00`

    ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
        var csv_file, download_link;
        csv_file = new Blob([buffer], { type: "application/octet-stream" });
        download_link = document.createElement("a");
        download_link.download = "Payments.xlsx" ;
        download_link.href = window.URL.createObjectURL(csv_file);
        download_link.style.display = "none";
        document.body.appendChild(download_link);
        download_link.click();
    });
  }
  return ( 
    <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
          MUIDataTableToolbar:{
            styleOverrides:{
              actions: {
                display: 'contents'
              }
            }
          }
        }})}>
      <MUIDataTable
        title={
          <Row className="row">
            <Col className="search-p">
              <div className="ms-2 me-2 mb-3 mt-3">
                <div className="position-relative sw">
                <Input
                    placeholder="Search"
                    type="text"
                    value={search}
                    onChange={(e) => {handleSearch(e.target.value);setSearch(e.target.value)}}
                />
                </div>
              </div>
            </Col>
            <Col ></Col>
            <Col>
            <div className="d-flex mt-3" style={{ float: 'right'}}>
                <select className="me-2" style={{ padding: '5px', borderColor: '#d2d2d2' }} onChange={filterPayments}>
                  <option value="">All</option>
                  <option value="variable">Variable payments only</option>
                  <option value="no_variable">No variable payments</option>
                </select>
                <button type="button" disabled={prop.loading} onClick={()=>{downloadCSVFile()}} className="btn btn-lbusers">
                    {
                        prop.loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                    }
                    EXPORT CSV
                </button>
              </div>
            </Col>
          </Row>
        }
        data={tableData}
        columns={columns}
        options={{ 
          rowsPerPage:50000, //tableData.length/10
          print:false, 
          download:false, 
          filter:false, 
          selectableRows:"none", 
          search:false, 
          rowsPerPageOptions:[], 
          responsive:'scroll',
          textLabels: {
            body: {
              noMatch: ""
            },
            viewColumns: {
              title: "",
            },
          },
          rowHover: false,
          setRowProps: (row, dataIndex, rowIndex) => {
            return {
              style: { backgroundColor: rowIndex % 2 === 0 ? '#ffffff' : '#eeeeee'},
            };
          },
        }}
      />
    </ThemeProvider>
  )
}

export default Payment;
