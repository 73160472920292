import React, { useState } from "react"
import { ButtonDropdown, Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const ButtonDropdownClient = (prop) => {
  const { setEditOpen, setDeleteOpen, setSelectedClient, client, type, access} = prop;
  const [viewBtn, setViewBtn] = useState(false);
    return (
        <div className="btn-group mb-2 dropstart">
          <ButtonDropdown
            isOpen={viewBtn}
            toggle={() => { setViewBtn(!viewBtn) }}
          >
            <DropdownToggle
              caret
              color="lbusers"
              className="btn btn-primary btn-sm"
            >
              Actions &nbsp;{" "}
              <i className="mdi mdi-chevron-down" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={Link} to={{pathname:`/${localStorage.getItem('company')}/clients/details`, search: client._id}}> View</DropdownItem>
              {
                access == 'full_access' && 
                <>
                  <DropdownItem onClick={() => {setEditOpen(true); setSelectedClient(client)}}> Edit</DropdownItem>
                  <DropdownItem onClick={() => {setDeleteOpen(true); setSelectedClient(client)}}> Remove Access</DropdownItem>
                </>
              }
              <DropdownItem tag={Link} to={{pathname:`/${localStorage.getItem('company')}/clients/glsetup`, search: client._id}}> G/L setup </DropdownItem>             
            </DropdownMenu>
          </ButtonDropdown>
        </div>
    )
}

ButtonDropdownClient.propTypes = {
  state: PropTypes.any,
  handleClick: PropTypes.any
}

export default ButtonDropdownClient