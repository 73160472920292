import React, { useState, useEffect, useContext } from "react"
import { Col, Container, Row, Card, CardBody, Spinner, Input } from "reactstrap"
import { map } from "lodash";
import CardGrid from "./cardgrid"
import MultiSelect from "components/Common/MultiSelect";
import { AuthContext } from "context/authContext";

const LeaseGridView = prop => {
  const {leaseData, getLeaseData, loading, clientAccess, clientData ,setLeaseData} = prop;
  const [searchText, setSearchText] = useState("");
  const [selectedOptions, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedClients,setSelectedClients] = useState(null)
  const {mode} = useContext(AuthContext)

  const deleteLease = (d) => {
    prop.deleteLease(d)
  }

  const refreshLeaseData = () => {
    const clients = selectedClients.map(v => v.value)
    getLeaseData({clients: clients, search: searchText, type: selectedOptions, mode: mode ? mode : 'lessee'})
  }
    
  const handleChange = (selected) =>{
      setSelectedClients(selected)
  }

  useEffect(() => {
    setSelectedClients([])
    setLeaseData([])
  },[mode])

  useEffect(() => {
    setOptions(clientData)
  },[clientData])

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Row>
          <Card>
            <CardBody>
                  <Row className="row">
                    <Col md='3'>
                        <MultiSelect options={options} handleChange={handleChange} selectedOptions={selectedClients} loading={loading} placeholder="Select clients for fetching leases..." />
                    </Col>
                    <Col md="3" className="search-p">
                      <div className="me-2 mb-3 ">
                        <div className="position-relative sw">
                          <Input type="text" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                        </div>
                      </div>
                    </Col>
                    <Col md="3" className="dropdown-p">
                      <div className="mb-3 ">
                        <div className="position-relative sw">
                          <select
                            value={selectedOptions}
                            onChange={(e) => setSelectedOption(e.target.value)}
                            className="form-control"
                          >
                            <option value="">Select Type</option>
                            <option value="Vehicle">Vehicle</option>
                            <option value="Building">Building</option>
                            <option value="Land">Land</option>
                            <option value="Equipment">Equipment</option>
                            <option value="Office">Office</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col md='3'>
                      <div className="mb-3 ">
                        <div className="position-relative sw">
                          <button className="btn bg-theme-color w-md ms-3" disabled={loading} onClick={() => refreshLeaseData()}>
                              {
                              loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                              }
                              SEARCH
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col md='3'></Col>
                  </Row>
            </CardBody>
          </Card>
          {
            loading ?
            <div style={{textAlign: 'center', width:'100%'}}><Spinner className="text-lbusers" /></div>
            :!leaseData || leaseData.length < 1 ? <div className="text-center">No data found</div> :
            map(leaseData, (lease, key) => (
              <CardGrid lease={lease} deleteLease={deleteLease} key={"_user_" + lease._id} getLeaseData={refreshLeaseData} clientAccess={clientAccess}/>
            ))
          }
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default LeaseGridView
