import React, { useState } from "react"
import { Tooltip} from "reactstrap"

const LeaseName = (prop) => {
  const { lease, index } = prop;
  const [tooltipOpen, setTooltipOpen] = useState(false)
    return (
        <div className="client lease">
            {
                lease.name && lease.name.length > 40 ? 
                <>
                <span onMouseOver={() => setTooltipOpen(true)} id={`TooltipExample${index}`}>
                    <div className="lease-name">{lease.name}</div>
                </span>
                <Tooltip placement="top" isOpen={tooltipOpen} target={`TooltipExample${index}`} style={{backgroundColor: '#005691'}} toggle={() => setTooltipOpen(false)}>
                    {lease.name}
                </Tooltip>
                </>
                : 
                <div>{lease.name}</div>
            }
        </div>
    )
}


export default LeaseName