import React, { useState } from "react";
import { InputGroup, Modal } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import toast from "react-hot-toast";
import AppConfig from "constants/config";
import axios from "axios";

const AdoptionDateModal = (prop) => {
    const {popupOpen, setPopupOpen, createDownloadLink, selectedLeases,mode} = prop;
    const [startDate, setStartDate] = useState('');
    const [loading, setLoading] = useState(false);
   
    const handleSubmit = async() => {
        setLoading(true)
        try {
            let ids = []
            for(const lease of selectedLeases){
                ids.push(lease._id)
            }
            const body = {
                lease_ids: ids,
                date: startDate,
                mode: mode ? mode : 'lessee'
            }
            let {data:value} = await axios.post(`${AppConfig.baseUrl}/report/exportAdoptionReport`,body, {
                headers: { token: localStorage.getItem("token") },
            })
            if (!value.error) {
                createDownloadLink(value.adoption,'adoption_report.xlsx') 
                toast.success('Adoption report downloaded successfully');
            }
            else {
                throw new Error(value.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setPopupOpen(false)
        setLoading(false)
    }

    return (
        <Modal
            isOpen={popupOpen}
            toggle={() => {
                setStartDate('')
                setPopupOpen(!popupOpen);
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Select Date</h5>
                <button
                    type="button"
                    onClick={() => setPopupOpen(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                }}>
                    <InputGroup className="mb-2">
                        <Flatpickr
                            onClose={([date]) => {
                                if(date){
                                    setStartDate(moment(date).format('MM/DD/YYYY'));
                                }
                            }}
                            value={startDate}
                            className="form-control d-block bg-white"
                            placeholder="Select date"
                            options={{
                                altInput: true,
                                altFormat: "m/d/Y",
                                dateFormat: "m/d/Y",
                                allowInput: true,
                            }}
                        />
                        <div className="input-group-append">
                            <button
                                type="button"
                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                disabled
                            >
                                <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </InputGroup>
                    <div className="modal-footer mt-3">
                        <button type="submit" className="btn btn-lbusers" disabled={!startDate || loading}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default AdoptionDateModal