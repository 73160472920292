import React, { useState, useEffect, useContext } from "react"
import { Row, Col } from "reactstrap"
import "./datatables.scss"
import "../../assets/scss/manual/manual.scss"
import { AuthContext } from "context/authContext"
import { useLocation } from "react-router-dom";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "../../constants/config";
import AddAllocation from "./addAllocation"
import EditAllocation from "./editAllocation"
import DeleteAllocation from "./deleteAllocation"
import AllocationDetails from "./allocationDetails"
import moment from "moment"
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const Allocations = (prop) => {
  const {setactiveTab,activeTab} = prop;
  const location = useLocation();
  const { mode } = useContext(AuthContext)
  const [allocations, setAllocations] = useState([]);
  const [id, setId] = useState('')
  const [loading, setLoading] = useState(false);
  const [addOpen, setAddOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [indetifyOpen, setIdentifyOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [selectedAllocation, setSelectedAllocation] = useState({})
  const columns = [
    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '300px',height: '70px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '300px',height: '50px'}}),
        customBodyRenderLite:(dataIndex,rowIndex)=>(
          <div>
            {allocations && allocations[dataIndex] && allocations[dataIndex].title}
          </div>
        )
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '300px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '300px'}}),
        customBodyRenderLite:(dataIndex,rowIndex)=>(
          <div>
            { moment(allocations && allocations[dataIndex] && allocations[dataIndex].createdAt).format('MM/DD/YYYY HH:mm')}
          </div>
        )
      }
    },
    {
      name: "menu",
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px',}}),
        customBodyRenderLite:(dataIndex,rowIndex)=>(
          <div className="mt-2">
            <button
                type="button"
                className="btn btn-sm btn-lbusers me-2"
                onClick={() => {setIdentifyOpen(true); setSelectedAllocation(allocations[dataIndex])}}
                disabled={loading}
            >
                IDENTIFY
            </button>
            <button
                type="button"
                className="btn btn-sm btn-lbusers me-2"
                onClick={() => {setEditOpen(true); setSelectedAllocation(allocations[dataIndex])}}
                disabled={loading}
            >
                RENAME
            </button>
            <button
                type="button"
                className="btn btn-sm btn-lbusers"
                onClick={() => {setDeleteOpen(true); setSelectedAllocation(allocations[dataIndex])}}
                disabled={loading}
            >
                DELETE
            </button>
          </div>
        ),
      }
    },
  ];

  useEffect(() => {
    setId((location.search).replace('?', ''));
  }, [])

  useEffect(() => {
    if(id && activeTab == 2){
      getAllocations()
    }
  },[id,activeTab])

  const getAllocations = async()=>{
    setLoading(true)
    setAllocations([])
    try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/allocation/get_allocations?id=${id}`,{
            headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
          setAllocations(data.allocations || []);
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
          <Row>
            <Col className="col-12">
              <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                MUIDataTableToolbar:{
                  styleOverrides:{
                    actions: {
                      display: 'none'
                    }
                  }
                }
              }})}>
                <MUIDataTable
                  title={
                    <Row className="row">
                      <Col md='9'></Col>
                      <Col md='3' style={{ textAlign: 'end' }}>
                          <button
                              type="button"
                              className="btn btn-lbusers me-2"
                              onClick={() => setAddOpen(true)}
                              disabled={loading}
                          >
                              ADD MORE
                          </button>
                      </Col>
                    </Row>
                  }
                  data={allocations}
                  columns={columns}
                  options={{ 
                    rowsPerPage:10, 
                    print:false, 
                    download:false, 
                    filter:false, 
                    viewColumns: false,
                    selectableRows:"none", 
                    search:false, 
                    pagination:false,
                    rowsPerPageOptions:[], 
                    responsive:'scroll',
                    textLabels: {
                      viewColumns: {
                        title: "",
                      },
                    } 
                  }}
                />
              </ThemeProvider>
            </Col>
          </Row>
          <Row className="row mb-2 mt-3">
            <Col md='9'></Col>
            <Col md='3' style={{ textAlign: 'end' }}>
                <button
                    type="button"
                    className="btn btn-lbusers me-2"
                    onClick={()=>setactiveTab(1)}
                    disabled={loading}
                >
                    PREVIOUS
                </button>
                <button
                    type="button"
                    className="btn btn-lbusers"
                    onClick={()=>{setactiveTab(3)}}
                    disabled={loading}
                >
                    NEXT
                </button>
            </Col>
          </Row>
      <AddAllocation addOpen={addOpen} setAddOpen={setAddOpen} getAllocations={getAllocations} id={id}/>
      <EditAllocation editOpen={editOpen} setEditOpen={setEditOpen} getAllocations={getAllocations} id={id} allocation={selectedAllocation} setSelectedAllocation={setSelectedAllocation}/>
      <AllocationDetails indetifyOpen={indetifyOpen} setIdentifyOpen={setIdentifyOpen} getAllocations={getAllocations} id={selectedAllocation && selectedAllocation._id} setSelectedAllocation={setSelectedAllocation} allocation={selectedAllocation}/>
      <DeleteAllocation deleteOpen={deleteOpen} setDeleteOpen={setDeleteOpen} getAllocations={getAllocations} id={id} allocation={selectedAllocation} setSelectedAllocation={setSelectedAllocation}/>
    </React.Fragment>
  )
}

export default Allocations
