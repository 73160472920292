import React, { useState } from "react"
import { Row, Col, Modal, Label, Input } from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import axios from "axios";
import AppConfig from "../../constants/config";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from 'react-hot-toast';

const AddAllocation = (prop) => {
    const { addOpen, setAddOpen, getAllocations, id } = prop
    const [loading, setLoading] = useState(false);

    const handleSubmit = async(values) => {
        setLoading(true)
        try {
            values.client_id = id
            const {data} = await axios.post(`${AppConfig.baseUrl}/allocation/add_allocation`, values,{
                headers:{token: localStorage.getItem('token')}
            });
            if(!data.error){
                toast.success('Allocation added successfully');
                getAllocations({});
                handleCloseDialog();
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    const handleCloseDialog = () => {
        formik.resetForm();
        setAddOpen(false);
    }
  
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: '',
        },
        validationSchema: Yup.object({            
            title: Yup.string().required("Please enter title"),         
        }),
        onSubmit: handleSubmit
    });
    
    return (
        <Modal
            isOpen={addOpen}
            toggle={() => {
                setAddOpen(false);
            }}
            size="md"
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Add Allocation</h5>
                <button
                    type="button"
                    onClick={() => {
                        setAddOpen(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12}>
                            <div>
                                <Label for="title" className="col-form-label">
                                    Title:
                                </Label>
                                <Input
                                    type="text"
                                    id="title"
                                    name="title"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.title || ""}
                                />
                                {formik.touched.title && formik.errors.title &&
                                    <div className="text-danger">{formik.errors.title}</div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <div className="modal-footer mt-3">
                        <button type="submit" className="btn btn-lbusers" disabled={loading} >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default AddAllocation